import React, { useState, useEffect } from "react";
import { validate_multiple_roles } from "../validations";
import {
  getSellers,
  deleteSeller,
  verifySeller,
  verifyOrganicSeller,
} from "../actions/userActions";
import { updateUser } from "../actions/userActions";
import { updateOrder } from "../actions/orderActions";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import { API_URL, suspension_types } from "../config";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "../components/spinner";

function AdminSellerScreen(props) {
  const id = props.match.params.id ? props.match.params.id : "";

  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["admin", "sales-support"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [suspend_account_dialog, set_suspend_account_dialog] =
    React.useState(false);

  const suspend_account_dialog_open = () => {
    set_suspend_account_dialog(true);
  };

  const suspend_account_dialog_close = () => {
    set_suspend_account_dialog(false);
  };

  const [error_message, set_error_message] = useState("");
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState("");
  const [page_loaded, set_page_loaded] = useState(false);

  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  const [order_details, set_order_details] = useState({});
  const [seller_details, set_seller_details] = useState({});
  // eslint-disable-next-line
  const [order_items, set_order_items] = useState([]);

  const [approved_details, set_approved_details] = useState({});

  const [organic_details, set_organic_details] = useState({});

  const [suspension_status, set_suspension_status] = useState("");
  const [suspension_duration, set_suspension_duration] = useState("");
  const [suspension_reasons, set_suspension_reasons] = useState("");

  const load_page_data = async () => {
    set_action_loading(true);
    let get_seller_details;

    try {
      get_seller_details = await getSellers(id, "role", "status");

      if (get_seller_details.success) {
        const { data } = get_seller_details;
        set_order_details(data[0]);
        // console.log('data0', data[0])
        //   console.log(data[0].seller_details, data[0])
        set_seller_details(data[0].seller_details[0]);
        set_approved_details(data[0].approved);
        set_organic_details(data[0].organic);
        set_order_items(data[0].products);
      } else {
        set_error_message(get_seller_details.message);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching seller orders: ${error.message} `
      );
    }

    // when the time to pul seller product comes, pull this one out.
    //  try {
    //     get_seller_products = await getOrderItems('id', id, 'seller_id', 'product', 'status');

    //     if (get_seller_products.success) {
    //       const { data } = get_seller_products;
    //       set_order_items(data);
    //     } else {
    //       set_error_message(get_seller_products.message);
    //     }
    //   } catch (error) {
    //     set_error_message(`An error occured fetching seller orders: ${error.message} `);
    //   }

    set_action_loading(false);
    return;
  };

  /**
   * Updates user profile
   * @param {*} e
   */
  // eslint-disable-next-line
  const suspend_user_account = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const update_data = {
      status: "Paid",
    };

    const response = await updateOrder(update_data, id, "payment");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      await load_page_data();

      set_success_message("Payment status successfully updated");
    }
    set_action_loading(false);

    return;
  };

  /**
   * Deletes user details
   * @param {*} e
   */
  const delete_user_account = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const response = await deleteSeller(id);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message("Account deleted");
      props.history.push("/sellers");
    }
    set_action_loading(false);

    return;
  };

  /**
   * Verifies user acccount details
   * @param {*} e
   */
  const verify_user_account = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const response = await verifySeller(id);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message("Account successfully verified");
      await load_page_data();
    }
    set_action_loading(false);

    return;
  };

  /**
   * Verifies organic acccount details
   * @param {*} e
   */
  const verify_organic_account = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const response = await verifyOrganicSeller(id);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message("Organic farmer successfully verified");
      await load_page_data();
    }
    set_action_loading(false);

    return;
  };

  /**
   * Updates user profile
   * @param {*} e
   */
  // eslint-disable-next-line
  const activate_user_account = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const update_data = {};

    const response = await updateOrder(update_data, id, "cancel");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      await load_page_data();

      set_success_message("Order cancelled");
    }
    set_action_loading(false);

    return;
  };

  const suspend_or_ban_user_account = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    if (suspension_status === "") {
      set_error_message("The suspension type cannot be null");
      set_action_loading(false);
      return;
    }

    if (suspension_duration === "") {
      set_error_message("The suspension duration cannot be null");
      set_action_loading(false);
      return;
    }

    if (suspension_reasons === "") {
      set_error_message("The suspension reasons cannot be null");
      set_action_loading(false);
      return;
    }

    const update_data = {
      suspension_status,
      suspension_duration,
      suspension_reasons,
    };

    const response = await updateUser(update_data, id, "suspend_ban");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      await load_page_data();

      suspend_account_dialog_close();

      set_success_message("Success");
    }
    set_action_loading(false);

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  const confirmAndDeleteUserAccount = async (e) => {
    e.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this user? This action cannot be undone."
    );
    if (isConfirmed) {
      delete_user_account(e);
    }
  };

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="black-text">Viewing </span>{" "}
              <span class="green-text">
                {order_details.first_name} {order_details.last_name}{" "}
              </span>
            </h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== "" ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : (
                    ""
                  )}
                  {success_message !== "" ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div class="row"></div>

              <div class="row">
                <div class="col-3">
                  {order_details.image !== null &&
                  order_details.image !== undefined &&
                  order_details.image !== "" ? (
                    <img
                      src={`${API_URL}/${order_details.image}`}
                      class="avatar img-circle img-thumbnail"
                      alt="avatar"
                    />
                  ) : (
                    <img
                      src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                      class="avatar img-circle img-thumbnail"
                      alt="avatar"
                    />
                  )}
                </div>
                <div class="col-9">
                  <h2>Basic Details</h2>
                  <div class="table-responsive mb-5">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Full name</th>
                          <td>
                            {order_details.first_name} {order_details.last_name}
                          </td>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th>Email</th>
                          <td>{order_details.email}</td>
                        </tr>

                        <tr>
                          <th>Phone</th>
                          <td>{order_details.phone}</td>
                        </tr>

                        <tr>
                          <th>Telephone</th>
                          <td>{order_details.tel}</td>
                        </tr>

                        <tr>
                          <th>Verification status</th>
                          <td>
                            {order_details.verified ? (
                              "Verified"
                            ) : (
                              <>
                                <button
                                  class="btn btn-lg btn-success"
                                  type="submit"
                                  onClick={verify_user_account}
                                  color="primary"
                                >
                                  Verify account details
                                </button>
                              </>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <th>Status</th>
                          <td>
                            {order_details.status}
                            <br />
                            {order_details.status === "active" ? (
                              <>
                                <button
                                  class="btn btn-lg btn-danger"
                                  type="submit"
                                  onClick={confirmAndDeleteUserAccount}
                                  color="primary"
                                >
                                  Delete account
                                </button>
                                &nbsp;&nbsp;
                                {/* <button 
                            class="btn btn-lg btn-warning" 
                            type="submit"
                            onClick={suspend_user_account} color="primary">
                                Suspend account
                            </button> */}
                              </>
                            ) : (
                              ""
                            )}
                            <br />
                            {order_details.status === "deleted" ? (
                              <button
                                class="btn btn-lg btn-success"
                                type="submit"
                                onClick={confirmAndDeleteUserAccount}
                                color="primary"
                              >
                                Delete account
                              </button>
                            ) : (
                              ""
                            )}
                            <br />
                            {order_details.status === "suspend" ? (
                              <>
                                {/* //     <button 
                        //     class="btn btn-lg btn-success" 
                        //     type="submit"
                        //     onClick={activate_user_account} color="primary">
                        //     Activate account
                        // </button> */}
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>

                        <tr>
                          <th>Admin Verification Status</th>
                          <td>
                            {approved_details.status}
                            <br />
                            {approved_details.status ===
                            "Verification Request Sent" ? (
                              <>
                                <button
                                  class="btn btn-lg btn-success"
                                  type="submit"
                                  onClick={verify_user_account}
                                  color="primary"
                                >
                                  Verify account
                                </button>
                                {/* &nbsp;&nbsp;
                            <button 
                                class="btn btn-lg btn-success" 
                                type="submit"
                                onClick={verify_user_account} color="primary">
                                Reject account
                            </button> */}
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <th>Organic Farming Specialisation</th>
                          <td>
                            {organic_details.check
                              ? "Organic Farmer"
                              : "Not Organic Farmer"}
                            <br />
                            {organic_details.check ? (
                              <>
                                {organic_details.status !==
                                "Verified Organic Farmer" ? (
                                  <>
                                    <button
                                      class="btn btn-lg btn-success"
                                      type="submit"
                                      onClick={verify_organic_account}
                                      color="primary"
                                    >
                                      Verify Organic Farmer
                                    </button>
                                    {/* &nbsp;&nbsp;
                            <button 
                                class="btn btn-lg btn-success" 
                                type="submit"
                                onClick={verify_user_account} color="primary">
                                Reject account
                            </button> */}
                                  </>
                                ) : (
                                  <>Verified</>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <th>Suspension Status</th>
                          <td>
                            {order_details.suspension_status}
                            <br />
                            {order_details.suspension_status === "Active" ? (
                              <>
                                <button
                                  class="btn btn-lg btn-danger"
                                  type="submit"
                                  onClick={suspend_account_dialog_open}
                                  color="primary"
                                >
                                  Suspend account
                                </button>
                              </>
                            ) : (
                              <>
                                <a href={`/review-suspension/${id}`}>
                                  <button
                                    class="btn btn-lg btn-success"
                                    type="submit"
                                    onClick={suspend_account_dialog_open}
                                    color="primary"
                                  >
                                    View more details
                                  </button>
                                </a>
                              </>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <h2 class="mt-5">Seller Details</h2>
                  <div class="table-responsive mb-5">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Selling from</th>
                          <td>{seller_details.seller_type}</td>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th>Trading name</th>
                          <td>{seller_details.trading_name}</td>
                        </tr>

                        <tr>
                          <th>Address</th>
                          <td>
                            {seller_details.address_line1}{" "}
                            {seller_details.address_line2}{" "}
                            {seller_details.suburb} {seller_details.city}{" "}
                            {seller_details.province} {seller_details.country}
                          </td>
                        </tr>

                        <tr>
                          <th>Address coordinates</th>
                          <td>
                            {seller_details.latitude},{" "}
                            {seller_details.longitude}
                          </td>
                        </tr>

                        <tr>
                          <th>Description</th>
                          <td>{seller_details.description}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-3"></div>
              </div>

              <div class="row"></div>
            </>
          )}
        </div>
      </section>

      <Dialog
        open={suspend_account_dialog}
        onClose={suspend_account_dialog_close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <h2>Suspend Account</h2>
        </DialogTitle>
        <DialogContent>
          <div class="row">
            <div class="col-12">
              {error_message !== "" ? (
                <div class="alert alert-danger" role="alert">
                  {error_message}
                </div>
              ) : (
                ""
              )}
              {success_message !== "" ? (
                <div class="alert alert-success" role="alert">
                  {success_message}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <form onSubmit={activate_user_account}>
            <div class="row">
              <div class="col-md-6">
                <label>Suspension Type</label>

                <select
                  class="form-control"
                  onChange={(e) => set_suspension_status(e.target.value)}
                >
                  <option></option>
                  {suspension_types.map((st) => (
                    <option value={st.status}>{st.status}</option>
                  ))}
                </select>
              </div>

              <div class="col-md-6">
                <label>Duration (in days)</label>
                <input
                  class="form-control"
                  type="number"
                  onChange={(e) => set_suspension_duration(e.target.value)}
                  min="1"
                />
              </div>

              <div class="col-md-12 mt-5">
                <label>Reasons</label>
                <textarea
                  class="form-control"
                  onChange={(e) => set_suspension_reasons(e.target.value)}
                ></textarea>
              </div>

              <div class="col-md-12 mt-3">
                <small>
                  You can refer to the <a href="/admin-help">help section</a> to
                  know the actual suspension type and reasons each type has
                </small>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={suspend_account_dialog_close}
            color="primary"
          >
            Cancel
          </button>
          {action_loading && <Spinner />}
          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={suspend_or_ban_user_account}
            color="primary"
            disabled={action_loading}
          >
            Suspend Account
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default AdminSellerScreen;
