import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  signin,
  signin_with_google_credentials,
  resendSellerOTP,
  verifyBuyerOrSellerOTP,
  resendBuyerOrSellerOTP,
} from "../actions/userActions";
import queryString from "query-string";

import { useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import Spinner from "../components/spinner";

function LoginSellerScreen(props) {
  const login_with_google = useGoogleLogin({
    onSuccess: (tokenResponse) => console.log("token", tokenResponse),
  });

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");
  // const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [otp, setOTP] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const userSignin = useSelector((state) => state.userSignin);
  const { loading, userInfo, error } = userSignin;
  const dispatch = useDispatch();
  // let redirect = props.location.search ? props.location.search.split("=")[1] : 'seller-dashboard';
  let redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "seller-dashboard";

  console.log("redirect", redirect);

  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {
      //
    };
    // eslint-disable-next-line
  }, [userInfo]);

  // console.log('props', props);

  const scrollToTopCustom = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const resendOTP = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    console.log("resending OTP.............");

    // if (otp === '') {
    //   set_error_message('Please send a valid OTP')
    //   set_action_loading(false)
    //   return
    // }

    let notify_admin = false;
    let notify_details = {};
    // check if has query strings
    const query = queryString.parse(props.location.search);
    if (query.lf !== undefined) {
      notify_details.product = query.lf;
      notify_admin = true;
      redirect = "shop";
    }

    if (query.q !== undefined) notify_details.quantity = query.q;
    if (query.u !== undefined) notify_details.units = query.u;

    try {
      const response = await resendBuyerOrSellerOTP(
        email,
        notify_admin,
        notify_details
      );
      scrollToTopCustom();
      set_error_message("");
      set_success_message("");
      console.log(response);
      if (response.success === true) {
        set_success_message(response.message);
      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message("An error occured resending OTP. Try again");
    }

    set_action_loading(false);

    return;
  };

  const submitOTP = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    if (otp === "") {
      set_error_message("Please send a valid OTP");
      set_action_loading(false);
      return;
    }

    let notify_admin = false;
    let notify_details = {};
    // check if has query strings
    const query = queryString.parse(props.location.search);
    if (query.lf !== undefined) {
      notify_details.product = query.lf;
      notify_admin = true;
      redirect = "shop";
    }

    if (query.q !== undefined) notify_details.quantity = query.q;
    if (query.u !== undefined) notify_details.units = query.u;

    try {
      const response = await verifyBuyerOrSellerOTP(
        email,
        otp,
        notify_admin,
        notify_details
      );
      scrollToTopCustom();
      set_error_message("");
      set_success_message("");

      if (response.success === true) {
        // localStorage.setItem('token', response.token);
        set_success_message("Account successfully verified. You can now login");
        // console.log(email, password)
        await dispatch(
          signin(email, password, notify_admin, notify_details, props, redirect)
        );
        // console.log('after dispatch', userInfo);
        // props.history.push(redirect);
        // setFirstName('');
        // setLastName('');
        // setTradingName('');
        // setPhone('');
        // setEmail('');
        // setPassword('');
        // setRePassword('');
        // setSellerType('Farm');
      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message("An error occured verifying your account details");
    }

    set_action_loading(false);

    return;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    // console.log(userInfo);
    let notify_admin = false;
    let notify_details = {};
    // check if has query strings
    const query = await queryString.parse(props.location.search);

    // console.log(query)
    if (query.lf !== undefined) {
      notify_details.product = query.lf;
      notify_admin = true;
      // redirect = '/shop?st=7'
      redirect = "/my-requests";
    }

    if (query.q !== undefined) notify_details.quantity = query.q;
    if (query.u !== undefined) notify_details.units = query.u;

    // console.log(query.lf, query.q, query.u )
    // if (query.lf !== undefined && query.q !== undefined && query.u !== undefined) {
    //   redirect = `/?st=7&lf=${query.lf}&q=${query.q}&u=${query.u}`
    // }

    // console.log(redirect)

    // return;

    let user_type = localStorage.getItem("potential_user_type");
    if (user_type === null || user_type === undefined || user_type === "") {
      user_type = "buyer";
    }

    if (user_type === "buyer" && query.lf === undefined) {
      redirect = "shop";
    }

    await dispatch(
      signin(email, password, notify_admin, notify_details, props, redirect)
    );

    // console.log('after dispatch', userInfo);
  };

  const login_google_credential = async (google_credentials) => {
    let notify_admin = false;
    let notify_details = {};
    const query = await queryString.parse(props.location.search);

    if (query.lf !== undefined) {
      notify_details.product = query.lf;
      notify_admin = true;
      redirect = "/my-requests";
    }

    if (query.q !== undefined) notify_details.quantity = query.q;
    if (query.u !== undefined) notify_details.units = query.u;

    let user_type = localStorage.getItem("potential_user_type");
    if (user_type === null || user_type === undefined || user_type === "") {
      user_type = "buyer";
    }

    if (user_type === "buyer" && query.lf === undefined) {
      redirect = "shop";
    }

    await dispatch(
      signin_with_google_credentials(
        google_credentials,
        user_type,
        notify_admin,
        notify_details,
        props,
        redirect
      )
    );
  };

  return (
    <section id="about" class="about main-content mt-5 login-content">
      <div class="container-fluid" data-aos="fade-up">
        <div class="row mt-5">
          <div class="col-md-4"></div>
          <div class="col-md-4 text-center">
            <h3 class="green-text">SIGN IN</h3>

            {error && (
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  {error}
                </div>
              </div>
            )}

            {/*
		  {error_message !== '' && <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error_message}
            </div>
          </div>}
		  */}

            {error_message !== "" ? (
              <div class="alert alert-danger" role="alert">
                {error_message}
              </div>
            ) : (
              ""
            )}
            {success_message !== "" ? (
              <div class="alert alert-success" role="alert">
                {success_message}
              </div>
            ) : (
              ""
            )}

            {error ===
            "We sent you an OTP via email to verify your account, please check your email, (including the spam folder), and enter the OTP on this screen." ? (
              <>
                <form onSubmit={submitOTP}>
                  <div class="form-group my-4">
                    <input
                      class="form-control"
                      type="number"
                      min="1"
                      name="otp"
                      id="otp"
                      onChange={(e) => setOTP(e.target.value)}
                      placeholder="OTP"
                    />
                  </div>
                  <div class="form-group my-4 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      onClick={scrollToTopCustom()}
                      disabled={action_loading}
                      //  || lastNameInvalid || phoneInvalid || emailInvalid
                    >
                      {!action_loading ? (
                        "Confirm"
                      ) : (
                        <>
                          <div
                            class="spinner-border text-orange"
                            role="status"
                          ></div>
                          {/* &nbsp;Please wait */}
                          <Spinner />
                        </>
                      )}
                    </button>
                    {/* &nbsp;or&nbsp; */}
                    {/* <Link to={redirect === "/" ? "login-seller" : "login-seller?redirect=" + redirect} className="btn btn-primary faded-green-shade" >Login</Link> */}
                  </div>
                </form>
                <div class="form-group my-4 text-center">
                  <form onSubmit={resendOTP}>
                    OTP not received? &nbsp;
                    <button
                      class="btn btn-lg btn-success"
                      type="submit"
                      onClick={scrollToTopCustom()}
                      disabled={action_loading}
                      //  || lastNameInvalid || phoneInvalid || emailInvalid
                    >
                      {!action_loading ? (
                        "Resend OTP"
                      ) : (
                        <>
                          <div class="spinner-border text-orange" role="status">
                            {/* <span class="sr-only">Loading...</span> */}
                          </div>
                          {/* &nbsp;Please wait */}
                          <Spinner />
                        </>
                      )}
                    </button>
                  </form>
                </div>
              </>
            ) : (
              <form onSubmit={submitHandler}>
                <div class="form-group my-4">
                  <input
                    class="form-control"
                    type="email"
                    name="email"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                </div>
                <div class="form-group my-4">
                  <input
                    class="form-control"
                    type="password"
                    id="password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                </div>
                <div class="form-group my-4 text-end">
                  <Link to="forgot-password" className="green-text">
                    Forgot password ?
                  </Link>
                </div>
                <div class="form-group my-4">
                  <button
                    type="submit"
                    class="btn btn-primary form-control login-button"
                    disabled={loading}
                  >
                    Sign in
                  </button>
                </div>
                <div class="form-group">
                  <Link
                    to={redirect === "/" ? "" : "register?search=" + redirect}
                    className="green-text"
                  >
                    Not a member? Register
                  </Link>
                </div>

                <div class="mt-5 text-center">
                  {/* <button onClick={() => login_with_google()}>
                  Sign in with google
                </button> */}

                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      // console.log(credentialResponse);
                      login_google_credential(credentialResponse);
                    }}
                    onError={() => {
                      // console.log('Login Failed');
                      set_error_message(
                        "Login with your Google account failed. Please try again"
                      );
                    }}
                  />
                </div>
              </form>
            )}
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
    </section>
  );
}
export default LoginSellerScreen;
