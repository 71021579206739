import React, { useState, useEffect } from "react";
import { validate_multiple_roles } from "../validations";
import { getOrders, getOrderItems, updateOrder } from "../actions/orderActions";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import { Link } from "react-router-dom";
import Spinner from "../components/spinner";

function AdminOrderScreen(props) {
  const id = props.match.params.id ? props.match.params.id : "";

  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["admin", "sales-support"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [error_message, set_error_message] = useState("");
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState("");
  const [page_loaded, set_page_loaded] = useState(false);

  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  const [order_details, set_order_details] = useState({});
  const [order_items, set_order_items] = useState([]);

  const load_page_data = async () => {
    set_action_loading(true);
    let get_orders;
    let get_order_items;

    try {
      get_orders = await getOrders(
        id,
        "phone",
        "email",
        "city",
        "delivery_zone",
        "payment_method",
        "client_id",
        "status"
      );

      if (get_orders.success) {
        const { data } = get_orders;
        set_order_details(data[0]);
        set_order_items(data[0].products);
      } else {
        set_error_message(get_orders.message);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching seller orders: ${error.message} `
      );
    }

    try {
      get_order_items = await getOrderItems(
        "id",
        id,
        "seller_id",
        "product",
        "status"
      );

      if (get_order_items.success) {
        const { data } = get_order_items;
        set_order_items(data);
      } else {
        set_error_message(get_order_items.message);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching seller orders: ${error.message} `
      );
    }

    set_action_loading(false);
    return;
  };

  /**
   * Updates user profile
   * @param {*} e
   */
  const updatePaymentStatus = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const update_data = {
      status: "Paid",
    };

    const response = await updateOrder(update_data, id, "payment");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      await load_page_data();

      set_success_message("Payment status successfully updated");
    }
    set_action_loading(false);

    return;
  };

  /**
   * Updates user profile
   * @param {*} e
   */
  const updateCompletionStatus = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const update_data = {};

    const response = await updateOrder(update_data, id, "completed");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      await load_page_data();

      set_success_message("Order completed");
    }
    set_action_loading(false);

    return;
  };

  /**
   * Updates user profile
   * @param {*} e
   */
  const updateCancelledStatus = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const update_data = {};

    const response = await updateOrder(update_data, id, "cancel");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      await load_page_data();

      set_success_message("Order cancelled");
    }
    set_action_loading(false);

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="black-text">Viewing Order</span> <br />{" "}
              <span class="green-text">{id} </span>
            </h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== "" ? (
                    <div class="alert alert-danger" role="alert">
                      <button type="button" class="close" data-dismiss="alert">
                        &times;
                      </button>
                      {error_message}
                    </div>
                  ) : (
                    ""
                  )}
                  {success_message !== "" ? (
                    <div class="alert alert-success" role="alert">
                      <button type="button" class="close" data-dismiss="alert">
                        &times;
                      </button>
                      {success_message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div class="row"></div>

              <div class="row">
                <div class="col-3"></div>
                <div class="col-6">
                  <div class="table-responsive mb-5">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Order</th>
                          <td>{id}</td>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th>Tracking Number</th>
                          <td>{order_details.tracking_number}</td>
                        </tr>

                        <tr>
                          <th>Status</th>
                          <td>{order_details.status}</td>
                        </tr>

                        <tr>
                          <th>Customer details</th>
                          <td>
                            {order_details.first_name} {order_details.last_name}
                            <br />
                            {order_details.email}
                            <br />
                            {order_details.phone}
                          </td>
                        </tr>

                        <tr>
                          <th>Delivery details</th>
                          <td>
                            {order_details.address1} {order_details.address2}
                            <br />
                            {order_details.suburb}
                            <br />
                            {order_details.city}
                          </td>
                        </tr>
                        <tr>
                          <th></th>
                          <td>
                            {order_details.status === "Pending" ? (
                              <button
                                class="btn btn-lg btn-success"
                                type="submit"
                                onClick={updatePaymentStatus}
                                color="primary"
                              >
                                Mark as paid
                              </button>
                            ) : (
                              ""
                            )}
                            <br />
                            {order_details.status === "Paid" ? (
                              <button
                                class="btn btn-lg btn-success"
                                type="submit"
                                onClick={updateCompletionStatus}
                                color="primary"
                              >
                                Mark as Completed
                              </button>
                            ) : (
                              ""
                            )}
                            <br />
                            {order_details.status === "Pending" ? (
                              <button
                                class="btn btn-lg btn-success"
                                type="submit"
                                onClick={updateCancelledStatus}
                                color="primary"
                              >
                                Cancel order
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <h3>Order Items</h3>
                  <div class="table-responsive mb-5">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Order</th>
                          <th>Name</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Supplier</th>
                          <th>Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        {order_items.length > 0
                          ? order_items.map((o) => (
                              <tr key={o._id} class="my-5">
                                <td>
                                  <Link to={`/sales-order-item/${o._id}`}>
                                    {o.display_order_number}
                                  </Link>
                                </td>
                                <td>{o.name}</td>
                                <td>{o.price}</td>
                                <td>{o.quantity}</td>
                                <td>{o.seller_id}</td>
                                <td>{o.status}</td>
                              </tr>
                            ))
                          : "Order not yet paid for."}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-3"></div>
              </div>

              <div class="row"></div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
export default AdminOrderScreen;
