import React, { useState, useEffect, useRef } from "react";
import { validate_multiple_roles } from "../validations";
import { getUser, updateUserDetails } from "../actions/userActions";
import {
  getCities,
  getCountries,
  getSuburbs,
} from "../actions/categoryActions";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import axios from "axios";
import { APP_URL, API_URL, IMAGES_URL, API_KEY, token_string } from "../config";
import { Link } from "react-router-dom";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Spinner from "../components/spinner";

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//BY ANY MEANS NECESSARY, DO NOT LEAVE THIS PAGE UNTIL SELLER PROFILE HAS AT LEAST COUNTRY, CITY, ADDRESS_LINE_1 AND SUBURB
//ALSO NOTE THAT WHEN I LOG ONTO THE PAGE, SOMEHOW I LOSE THE COUNTRY, SUBURB AND CITY OPTIONS. I ONLY REMAIN WITH THE COUNTRY THAT WAS SEELECTED BUT OTHERWISE I NEED TO RESELECT EVERYTHING, STARTING WITH THE COUNTRY. THIS HAS TO BE FIXED
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

function SellerProfileScreen(props) {
  let user_profile;
  const permitted_roles = ["seller", "buyer"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;

    if (!user_profile.roles.includes("seller")) {
      props.history.push("buyer-profile");
    }
  }

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");
  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [cities, set_cities] = useState([]);
  const [countries, set_countries] = useState([]);
  const [suburbs, set_suburbs] = useState([]);

  const [first_name, set_first_name] = useState("");
  const [username, set_username] = useState("");
  const [last_name, set_last_name] = useState("");
  const [trading_name, set_trading_name] = useState("");
  const [phone, set_phone] = useState("");
  const [email, set_email] = useState("");
  const [seller_type, set_seller_type] = useState("Farm");
  const [seller_country, set_seller_country] = useState("");

  const [telephone, set_telephone] = useState("");
  const [display_name, set_display_name] = useState("");
  const [description, set_description] = useState("");
  const [address_line1, set_address_line1] = useState("");
  const [address_line2, set_address_line2] = useState("");
  const [suburb, set_suburb] = useState("");
  const [city, set_city] = useState("");
  const [province, set_province] = useState("");
  const [country, set_country] = useState("");
  const [country_meta, set_country_meta] = useState("");
  const [city_meta, set_city_meta] = useState("");
  // eslint-disable-next-line
  const [seller_image, set_seller_image] = useState("");

  const [provide_delivery, set_provide_delivery] = useState(false);
  const [delivery_charge_type, set_delivery_charge_type] = useState("");
  const [delivery_charge, set_delivery_charge] = useState("");
  const [delivery_purchase_minimum, set_delivery_purchase_minimum] =
    useState("");
  const [delivery_maximum_radius, set_delivery_maximum_radius] = useState("");

  const [organic_check, set_organic_check] = useState(false);
  const [organic_check_status, set_organic_check_status] = useState(false);
  const [admin_verified_status, set_admin_verified_status] = useState("");
  const [admin_verified_check, set_admin_verified_check] = useState(false);

  const [latitude, set_latitude] = useState(0);
  const [longitude, set_longitude] = useState(0);

  const [admin_verification, set_admin_verification] = useState({
    status: "Not Verified",
    comments: "",
  });

  // eslint-disable-next-line

  const [redirect_message, set_redirect_message] = useState("");

  const [first_name_invalid, set_first_name_invalid] = useState(true);
  // eslint-disable-next-line
  const [last_name_invalid, set_last_name_invalid] = useState(true);
  // eslint-disable-next-line
  const [trading_name_invalid, set_trading_name_invalid] = useState(true);
  // eslint-disable-next-line
  const [phone_invalid, set_phone_invalid] = useState(true);
  // eslint-disable-next-line
  const [email_invalid, set_email_invalid] = useState(true);
  // eslint-disable-next-line
  const [seller_type_invalid, set_seller_type_invalid] = useState(true);
  // eslint-disable-next-line
  const [telephone_invalid, set_telephone_invalid] = useState(true);
  // eslint-disable-next-line
  const [display_name_invalid, set_display_name_invalid] = useState(true);
  // eslint-disable-next-line
  const [description_invalid, set_description_invalid] = useState(true);
  // eslint-disable-next-line
  const [address_line1_invalid, set_address_line1_invalid] = useState(true);
  // eslint-disable-next-line
  const [address_line2_invalid, set_address_line2_invalid] = useState(true);
  // eslint-disable-next-line
  const [suburb_invalid, set_suburb_invalid] = useState(true);
  // eslint-disable-next-line
  const [city_invalid, set_city_invalid] = useState(true);
  // eslint-disable-next-line
  const [province_invalid, set_province_invalid] = useState(true);
  // eslint-disable-next-line
  const [country_invalid, set_country_invalid] = useState(false);
  // eslint-disable-next-line
  const [onsite_invalid, set_onsite_invalid] = useState(true);

  // eslint-disable-next-line
  const [user_profile_in_db, set_user_profile_in_db] = useState("");

  const [file, setFile] = useState(""); // storing the uploaded file
  // eslint-disable-next-line
  const [progress, setProgess] = useState(0); // progess bar

  const [selectedImage, setSelectedImage] = useState(null);
  const [crop, setCrop] = useState({
    aspect: 4 / 3,
    x: 0,
    y: 0,
    width: 240,
    height: 180,
  });
  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [upload_error_message, set_upload_error_message] = useState("");
  const [upload_success_message, set_upload_success_message] = useState("");

  const imageRef = useRef();
  const fileInputRef = useRef(null);

  const el = useRef(); // accesing input element
  const handleChange = (e) => {
    setProgess(0);
    const file = e.target.files[0]; // accessing file
    setFile(file); // storing file
  };

  /**
   *
   * @returns updates a client profile picture
   */
  // eslint-disable-next-line

  async function fetchBuyerCountry(buyer_country_meta) {
    try {
      const response = await getCountries(
        "id",
        buyer_country_meta,
        "active",
        "name"
      );
      if (response.success && response.data.length > 0) {
        const buyer_country_data = response.data[0];
        return buyer_country_data.country;
      } else {
        throw new Error("Country not found or no currency symbol available");
      }
    } catch (error) {
      console.error("Error fetching buyer currency:", error.message);
      return null; // or a default value
    }
  }

  const get_user_profile = async () => {
    const { _id } = user_profile;

    let _countries = [];
    let _cities = [];

    let get_countries;
    try {
      get_countries = await getCountries("id", "meta", "active", "name"); //('id', 'meta', 'active');
      if (get_countries.success) {
        const { data } = get_countries;
        set_countries(data);
        _countries = data;
      }
    } catch (error) {
      // set_error_message(`An error occured fetching countries: ${error.message} `);
    }
    let country_meta; //change to use iso2_code instead of country
    if (country === "") {
      country_meta = "country_meta";
    } else {
      country_meta = country;
    }

    if (country_meta === "country_meta") {
      let get_cities;
      try {
        get_cities = await getCities("id", "meta", "status", "country_meta"); //change to use iso2_code instead of country
        if (get_cities.success) {
          const { data } = get_cities;
          set_cities(data);
          _cities = data;
        }
      } catch (error) {
        // set_error_message(`An error occured fetching cities: ${error.message} `);
      }

      let get_suburbs;
      try {
        get_suburbs = await getSuburbs("id", "meta", "city", "status");
        if (get_suburbs.success) {
          const { data } = get_suburbs;
          set_suburbs(data);
        }
      } catch (error) {
        // set_error_message(`An error occured fetching suburbs: ${error.message} `);
      }
    }

    let _country_meta;
    let _city_meta;

    let seller_details;
    let this_user_profile;
    try {
      this_user_profile = await getUser(_id);

      if (this_user_profile.success === false) {
        set_error_message(
          "An error occured retrieving your profile. Please reload page to try again"
        );
      } else {
        const user_details = this_user_profile.data[0];
        set_user_profile_in_db(user_details);
        set_page_loaded(true);
        let delivery;
        let organic;
        let approved;
        ({ seller_details, delivery, organic, approved } = user_details);
        set_first_name(user_details.first_name);
        set_username(user_details.username);
        set_last_name(user_details.last_name);
        set_phone(user_details.phone);
        set_email(user_details.email);
        set_telephone(user_details.telephone);
        set_display_name(user_details.display_name);

        set_seller_type(seller_details[0].seller_type);
        set_trading_name(seller_details[0].trading_name);
        set_description(seller_details[0].description);
        set_address_line1(seller_details[0].address_line1);
        set_address_line2(seller_details[0].address_line2);
        set_suburb(seller_details[0].suburb);
        set_city(seller_details[0].city);
        set_province(seller_details[0].province);
        set_country(seller_details[0].country);
        await set_seller_country(seller_details[0].country);

        if (
          seller_details[0].country === "" ||
          seller_details[0].country === undefined
        ) {
          const buyer_country_meta = localStorage.getItem("buyer_country_meta");
          const buyerCountry = await fetchBuyerCountry(buyer_country_meta);
          await set_country(buyerCountry);
          await country_changed(buyerCountry);
        } else {
          await country_changed(seller_details[0].country);
        }

        _country_meta = await country_loaded(
          _countries,
          seller_details[0].country
        );
        _city_meta = await city_loaded(_cities, seller_details[0].city);
        set_country_meta(_country_meta);
        set_city_meta(_city_meta);

        set_seller_image(user_details.image);

        set_latitude(seller_details[0].latitude);
        set_longitude(seller_details[0].longitude);

        if (
          seller_details[0].latitude == "" ||
          seller_details[0].longitude === ""
        ) {
          const geolocationAPI = navigator.geolocation;
          if (!geolocationAPI) {
            console.error("Geolocation not found in browser");
          } else {
            await geolocationAPI.getCurrentPosition(
              (position) => {
                const { coords } = position;
                set_latitude(coords.latitude);
                set_longitude(coords.longitude);
              },
              (error) => {
                // setError('Something went wrong getting your position!')
              }
            );
          }
        }

        if (approved !== undefined) {
          set_admin_verification(approved);
        }

        if (delivery !== undefined && delivery.provides === true) {
          set_provide_delivery(delivery.provides);
          set_delivery_charge_type(delivery.charge_type);
          set_delivery_charge(delivery.charge);
          set_delivery_purchase_minimum(delivery.purchase_minimum);
          set_delivery_maximum_radius(delivery.maximum_radius);
        }

        if (organic && organic.check) {
          set_organic_check(organic.check);
          set_organic_check_status(organic.check_status);
        }
      }
    } catch (error) {
      set_error_message("An error occured fetching seller profile");
    }

    // latitude and longitude check

    // country check

    return;
  };

  const country_loaded = async (countries, new_country) => {
    set_country(new_country);
    // set_city('')
    // set_suburb('')

    for (let i = 0; i < countries.length; i++) {
      if (countries[i].country === new_country) {
        return countries[i].meta;
      }
    }
    return "country_meta";
  };

  const city_loaded = async (cities, new_city) => {
    set_city(new_city);
    // set_city('')
    // set_suburb('')

    for (let i = 0; i < cities.length; i++) {
      if (cities[i].city === new_city) {
        return cities[i].meta;
      }
    }
    return "city_meta";
  };

  useEffect(() => {
    if (props.location.state && props.location.state.alert) {
      //alert(props.location.state.alert);
      set_redirect_message(props.location.state.alert);
    }
  }, [props.location.state]);

  useEffect(() => {
    // This immediately invoked async function is a common pattern
    // to use async logic inside useEffect
    (async () => {
      if (country) {
        await country_changed(country);
        await city_changed(city);
        await set_suburb(suburb);
        // If needed, here you can add additional logic
        // that should run after country has changed
      } else {
        // Clear cities and suburbs if no country is selected
        set_cities([]);
        set_suburbs([]);
      }
    })();
  }, [country]);

  /**
   * Updates user profile
   * @param {*} e
   */
  const updateProfile = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");
    set_redirect_message("");

    set_action_loading(true);

    let currency_data = await axios.get(
      `${API_URL}/countries/currency_symbol/?country=${country}`
    );
    let currency = currency_data.data.currency_symbol;

    {
      /*
    let currency = '$'
    if (country === 'Botswana') {
        currency = 'P'
    } else if (country === 'South Africa') {
        currency = 'R'
    } else if(country === 'Zambia') {
        currency = 'K'
    }
	*/
    }

    localStorage.setItem("currency", currency);

    // Ensure latitude and longitude are converted to numbers
    const numericLatitude = parseFloat(latitude);
    const numericLongitude = parseFloat(longitude);

    const update_data = {
      first_name,
      last_name,
      phone,
      email,
      telephone,
      display_name,
      organic: {
        check: organic_check,
      },
      delivery: {
        provides: provide_delivery,
        charge: delivery_charge,
        charge_type: delivery_charge_type,
        purchase_minimum: delivery_purchase_minimum,
        maximum_radius: delivery_maximum_radius,
      },
      seller_details: [
        {
          seller_type,
          trading_name,
          description,
          address_line1,
          address_line2,
          suburb,
          city,
          province,
          country,
          latitude: numericLatitude, // Use the converted latitude
          longitude: numericLongitude, // Use the converted longitude
        },
      ],
    };

    const response = await updateUserDetails(update_data, "basic_detail");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message("Account details successfully updated.");
    }
    set_action_loading(false);

    return;
  };

  const requestVerification = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const update_data = {};

    const response = await updateUserDetails(
      update_data,
      "request_verification"
    );
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      await get_user_profile();
      set_success_message("Verification request successfully sent.");
    }
    set_action_loading(false);

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      get_user_profile();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  const country_changed = async (new_country) => {
    set_country(new_country);
    set_city("");
    set_suburb("");

    let _country_meta = "country_meta";
    let _cities = [];

    for (let i = 0; i < countries.length; i++) {
      if (countries[i].country === new_country) {
        set_country_meta(countries[i].meta);
        _country_meta = countries[i].meta;
        break;
      }
    }

    let get_cities;
    try {
      get_cities = await getCities("id", "meta", "status", _country_meta); //change to use iso2_code instead of country
      if (get_cities.success) {
        const { data } = get_cities;
        set_cities(data);
        _cities = data;
      }
    } catch (error) {
      // set_error_message(`An error occured fetching cities: ${error.message} `);
    }

    set_cities(_cities);
  };

  const city_changed = async (new_city) => {
    set_city(new_city);
    set_suburb("");

    let _city_meta = "city";
    let _suburbs = [];
    for (let i = 0; i < cities.length; i++) {
      if (cities[i].city === new_city) {
        set_city_meta(cities[i].meta);
        _city_meta = cities[i].city;
        break;
      }
    }

    let get_suburbs;
    try {
      get_suburbs = await getSuburbs("id", "meta", _city_meta, "status");
      if (get_suburbs.success) {
        const { data } = get_suburbs;
        _suburbs = data;
      }
    } catch (error) {
      // set_error_message(`An error occured fetching suburbs: ${error.message} `);
    }
    set_suburbs(_suburbs);
  };

  const organic_check_changed = async (new_organic_check) => {
    let _organic_check = new_organic_check;
    if (new_organic_check === "true") {
      _organic_check = true;
    } else if (new_organic_check === "false") {
      _organic_check = false;
    }

    set_organic_check(_organic_check);
  };

  const provide_delivery_changed = async (new_provide_delivery) => {
    let _provide_delivery = new_provide_delivery;
    if (new_provide_delivery === "true") {
      _provide_delivery = true;
    } else if (new_provide_delivery === "false") {
      _provide_delivery = false;
    }

    set_provide_delivery(_provide_delivery);
  };

  const onCropComplete = (crop) => {
    if (!selectedImage || !crop.width || !crop.height) return;

    const canvas = document.createElement("canvas");
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
    canvas.width = 400; // Width of the output image
    canvas.height = 300; // Height of the output image
    const ctx = canvas.getContext("2d");

    const image = new Image();
    image.src = selectedImage;
    image.onload = () => {
      ctx.drawImage(
        image,
        crop.x * scaleX, // Scaled x-coordinate
        crop.y * scaleY, // Scaled y-coordinate
        crop.width * scaleX, // Scaled width
        crop.height * scaleY, // Scaled height
        0,
        0,
        400,
        300
      );

      canvas.toBlob((blob) => {
        const croppedFile = new File([blob], "cropped.jpg", {
          type: "image/jpeg",
        });
        setCroppedImageFile(croppedFile);
      }, "image/jpeg");
    };
  };

  const handleImageFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setIsCropModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
    setSelectedImage(null); // Reset selected image
    setCroppedImageFile(null); // Reset cropped image file
  };

  const handleImageOkClick = () => {
    if (crop.width && crop.height && selectedImage) {
      onCropComplete(crop);
      update_client_profile_picture();
      setIsCropModalOpen(false);
      setSelectedImage(null); // Reset selected image after successful crop
    }
  };

  const handleImageCancelClick = () => {
    setIsCropModalOpen(false); // Close the modal
    setSelectedImage(null); // Reset selected image
    setCroppedImageFile(null); // Reset cropped image file
    if (fileInputRef.current) fileInputRef.current.value = ""; // Reset file input
  };

  const onImageLoaded = (image) => {
    imageRef.current = image; // Set the image reference
    const imageWidth = image.naturalWidth;
    const imageHeight = image.naturalHeight;
    setImageDimensions({ width: imageWidth, height: imageHeight });

    // Calculate initial crop for 4:3 ratio
    const targetRatio = 4 / 3;
    let cropWidth = Math.min(imageWidth, imageHeight * targetRatio);
    let cropHeight = cropWidth / targetRatio;

    // If the height exceeds image height after calculating width, adjust it
    if (cropHeight > imageHeight) {
      cropHeight = imageHeight;
      cropWidth = cropHeight * targetRatio;
    }

    const x = (imageWidth - cropWidth) / 2;
    const y = (imageHeight - cropHeight) / 2;

    setCrop({
      aspect: targetRatio,
      width: cropWidth,
      height: cropHeight,
      x: x,
      y: y,
    });
  };

  const update_client_profile_picture = async () => {
    //e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_upload_success_message("");
    set_upload_error_message("");

    set_action_loading(true);

    // Check if cropped image is ready
    if (!croppedImageFile) {
      set_upload_error_message("No cropped image file is ready for upload.");
      set_action_loading(false);
      return;
    }

    const formData = new FormData();
    formData.append("api_key", API_KEY);
    formData.append("file", croppedImageFile); // appending file
    formData.append("update_type", "picture");
    formData.append("id", user_profile._id);
    await axios
      .patch(
        `${API_URL}/sellers`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token_string,
          },
        },
        {
          onUploadProgress: (ProgressEvent) => {
            let progress =
              Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
              "%";
            setProgess(progress);
          },
        }
      )
      .then((res) => {
        const { data } = res;
        if (data.success === true) {
          set_success_message("Profile picture successfully updated");
        } else if (data.success === false) {
          set_error_message(data.message);
          set_upload_error_message(data.message);
        } else {
          set_error_message(`An error occured updating profile picture`);
          set_upload_error_message(`An error occured updating profile picture`);
        }
      })
      .catch
      // set_error_message('An error occured updating profile picture')
      ();

    get_user_profile();

    set_action_loading(false);

    setIsCropModalOpen(false); // Close the modal after upload
    setSelectedImage(null); // Reset selected image after upload
    setCroppedImageFile(null); // Reset cropped image file after upload
    if (fileInputRef.current) fileInputRef.current.value = ""; // Reset file input after successful upload

    return;
  };

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            {redirect_message !== "" ? (
              <div class="row">
                <div class="col-12 m-5">
                  <div class="alert alert-danger" role="alert">
                    {redirect_message}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <h2>
              <span class="green-text">My</span>{" "}
              <span class="orange-text">Profile</span>
            </h2>

            <p>
              My shop link:{" "}
              <Link
                to={`/store/${username}`}
              >{`${APP_URL}/store/${username}`}</Link>
              &nbsp;&nbsp;&nbsp;
              {/* <form onSubmit={requestVerification}>
                    <button class="btn btn-lg btn-success ">Request verification</button>
                  </form> */}
              {admin_verification.status === "Verified Seller" ? (
                <>
                  <button class="btn btn-lg btn-success" disabled>
                    <i class="bi bi-check">{admin_verification.status}</i>{" "}
                  </button>
                </>
              ) : admin_verification.status === "Not Verified" ||
                admin_verification.status === "New Account" ? (
                <form onSubmit={requestVerification}>
                  <button class="btn btn-lg btn-success ">
                    Request verification
                  </button>
                </form>
              ) : (
                <>
                  <button class="btn btn-lg btn-info" disabled>
                    {admin_verification.status}
                  </button>
                </>
              )}
            </p>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== "" ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : (
                    ""
                  )}
                  {success_message !== "" ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">
                  <div class="text-center">
                    {seller_image === "" ||
                    seller_image === null ||
                    seller_image === undefined ? (
                      <img
                        src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                        class="avatar img-circle img-thumbnail"
                        alt="avatar"
                      />
                    ) : (
                      <img
                        src={`${IMAGES_URL}/${seller_image}`}
                        class="avatar img-circle img-thumbnail"
                        alt="avatar"
                      />
                    )}

                    <h4 class="mt-5">Upload a new profile photo</h4>

                    {/* <form onSubmit={update_client_profile_picture}>*/}
                    <form>
                      <div class="form-group col-12 my-3 text-center">
                        {/* <input type="file" ref={el} onChange={handleChange} class="text-center center-block file-upload form-control" /> */}
                        <input
                          type="file"
                          onChange={handleImageFileChange}
                          ref={fileInputRef}
                          class="text-center center-block file-upload form-control"
                        />

                        {isCropModalOpen && (
                          <div
                            style={{
                              position: "fixed",
                              top: "0",
                              left: "0",
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "20px",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <div
                              style={{
                                marginBottom: "20px",
                                color: "#fff",
                                fontSize: "18px",
                              }}
                            >
                              Select the area of the photo to show for the
                              product
                            </div>
                            <div
                              style={{
                                width: "400px",
                                height: "auto",
                                position: "relative",
                              }}
                            >
                              <ReactCrop
                                src={selectedImage}
                                crop={crop}
                                onImageLoaded={onImageLoaded}
                                ruleOfThirds
                                onChange={(newCrop) => setCrop(newCrop)}
                                onComplete={onCropComplete}
                              />
                            </div>
                            <div className="button-group mt-3">
                              <button
                                className="btn btn-primary me-2"
                                onClick={handleImageOkClick}
                              >
                                Upload new image
                              </button>
                              <button
                                className="btn btn-secondary"
                                onClick={handleImageCancelClick}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        )}

                        {/*
                      <button
                        class="btn btn-lg btn-success mt-1"
                        type="submit"
                        disabled={action_loading} >
                        Upload
                      </button>
                    */}
                      </div>
                    </form>
                  </div>
                </div>

                <div class="col-md-9">
                  <hr />
                  <form onSubmit={updateProfile}>
                    <h3>Personal details</h3>
                    <div class="row">
                      <div class="form-group col-md-4 my-3">
                        <label for="first_name">Email</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "email",
                            name: "email",
                            type: "text",
                            placeholder: "Email",
                          }}
                          // validate={validate}
                          validationCallback={(res) => set_email_invalid(res)}
                          value={email}
                          disabled={true}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(email, e) => {
                            set_email(e.target.value);
                          }}
                          // onBlur={e => { console.info(e);}}
                          // onFocus={(e) => {console.info(e);}}
                          // onClick={(e) => {console.info(e);}}
                          validationOption={{
                            name: "email",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="last_name">First name</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Name",
                            name: "Name",
                            type: "text",
                            placeholder: "First name",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_first_name_invalid(res)
                          }
                          value={first_name}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(first_name, e) => {
                            set_first_name(e.target.value);
                          }}
                          // onBlur={e => {console.info(e); }}
                          // onFocus={(e) => {console.info(e);}}
                          // onClick={(e) => {console.info(e);}}
                          validationOption={{
                            name: "Name",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="phone">Surname</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Surname",
                            name: "Surname",
                            type: "text",
                            placeholder: "Surname",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_last_name_invalid(res)
                          }
                          value={last_name}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(last_name, e) => {
                            set_last_name(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.info(e);}}
                          // onClick={(e) => {console.info(e);}}
                          validationOption={{
                            name: "Surname",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="phone">Display name</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "displayName",
                            name: "displayName",
                            type: "text",
                            placeholder: "Display name",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_display_name_invalid(res)
                          }
                          value={display_name}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(display_name, e) => {
                            set_display_name(e.target.value);
                          }}
                          // onBlur={e => { console.info(e);}}
                          // onFocus={(e) => {console.info(e);}}
                          // onClick={(e) => {console.info(e);}}
                          validationOption={{
                            name: "displayName",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="phone">Mobile number</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Phone",
                            name: "Phone",
                            type: "text",
                            placeholder: "Phone number. eg 263774756502",
                          }}
                          // validate={validate}
                          validationCallback={(res) => set_phone_invalid(res)}
                          value={phone}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(phone, e) => {
                            set_phone(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.info(e);}}
                          // onClick={(e) => {console.info(e);}}
                          validationOption={{
                            name: "Phone number",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            reg: /^\d{8}|\d{16}$/,
                            regMsg: "Invalid phone number",
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="phone">Telephone number</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Telephone",
                            name: "Telephone",
                            type: "text",
                            placeholder: "Telephone number. eg 2635422256736",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_telephone_invalid(res)
                          }
                          value={telephone}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(telephone, e) => {
                            set_telephone(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.info(e);}}
                          // onClick={(e) => {console.info(e);}}
                          validationOption={{
                            name: "Name",
                            check: false,
                            required: false,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            reg: /^\d{6}|\d{15}$/,
                            regMsg: "Invalid telephone number",
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>
                    </div>

                    <h3 class="mt-5">Seller details</h3>
                    <div class="row">
                      <div class="form-group col-md-6 my-3">
                        <label>Sell produce from</label>
                        <select
                          class="form-control"
                          name="seller_type"
                          id="seller_type"
                          onChange={(e) => set_seller_type(e.target.value)}
                        >
                          <option value={seller_type}>{seller_type}</option>
                          <option value="Farm">Farm</option>
                          <option value="Flea Market">Flea Market</option>
                          <option value="Home">Home</option>
                          <option value="Market Stall">Market Stall</option>
                          <option value="Shop/Tuckshop/Spaza">
                            Shop/Tuckshop/Spaza
                          </option>
                          <option value="Supermarket">Supermarket</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>

                      <div class="form-group col-md-6 my-3">
                        <label for="phone">Trading name</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "tradingName",
                            name: "tradingName",
                            type: "text",
                            placeholder: "Trading name",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_trading_name_invalid(res)
                          }
                          value={trading_name}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(trading_name, e) => {
                            set_trading_name(e.target.value);
                          }}
                          // onBlur={e => { console.info(e);}}
                          // onFocus={(e) => {console.info(e);}}
                          // onClick={(e) => {console.info(e);}}
                          validationOption={{
                            name: "tradingName",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-12 my-3">
                        <label>
                          Do you specialise in organic farming and would you
                          like to be registered as an organic farmer on Farm
                          Fresh Delights?
                        </label>
                        <select
                          class="form-control"
                          name="organic_check"
                          id="organic_check"
                          onChange={(e) =>
                            organic_check_changed(e.target.value)
                          }
                        >
                          <option value={organic_check}>
                            {organic_check ? "Yes" : "No"}
                          </option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </div>

                      <div class="form-group col-md-12 my-3">
                        <label for="phone">Business description</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "description",
                            name: "description",
                            type: "text",
                            placeholder: "Description",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_description_invalid(res)
                          }
                          value={description}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(description, e) => {
                            set_description(e.target.value);
                          }}
                          // onBlur={e => { console.info(e);}}
                          // onFocus={(e) => {console.info(e);}}
                          // onClick={(e) => {console.info(e);}}
                          validationOption={{
                            name: "description",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <h3 class="mt-5">Location details</h3>
                      <div class="form-group col-md-4 my-3">
                        <label for="country">
                          Country<span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          class="form-control"
                          name="country"
                          id="country"
                          onChange={(e) => country_changed(e.target.value)}
                          style={{ height: "26px" }}
                        >
                          <option value={country}>{country}</option>
                          {countries.length > 0
                            ? countries.map((c) => (
                                <option key={c._id} value={c.country}>
                                  {c.country}
                                </option>
                              ))
                            : ""}
                        </select>
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="phone">Province</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "province",
                            name: "province",
                            type: "text",
                            placeholder: "Province",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_province_invalid(res)
                          }
                          value={province}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(province, e) => {
                            set_province(e.target.value);
                          }}
                          // onBlur={e => { console.info(e);}}
                          // onFocus={(e) => {console.info(e);}}
                          // onClick={(e) => {console.info(e);}}
                          validationOption={{
                            name: "province",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="city">
                          City or town<span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          class="form-control"
                          name="city"
                          id="city"
                          onChange={(e) => city_changed(e.target.value)}
                          required
                          style={{ height: "26px" }}
                        >
                          <option value={city}>{city}</option>
                          {cities.length > 0
                            ? cities.map((c) =>
                                country_meta === c.country ? (
                                  <option key={c._id} value={c.city}>
                                    {c.city}
                                  </option>
                                ) : (
                                  ""
                                )
                              )
                            : ""}
                        </select>
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="suburb">
                          Suburb<span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          class="form-control"
                          name="suburb"
                          id="suburb"
                          onChange={(e) => set_suburb(e.target.value)}
                          style={{ height: "26px" }}
                        >
                          <option value={suburb}>{suburb}</option>
                          {suburbs.length > 0
                            ? suburbs.map((c) =>
                                city_meta === c.city ? (
                                  <option key={c._id} value={c.suburb}>
                                    {c.suburb}
                                  </option>
                                ) : (
                                  ""
                                )
                              )
                            : ""}
                        </select>
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="address1">
                          Street address line 1{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "address1",
                            name: "address1",
                            type: "text",
                            placeholder: "Street address",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_address_line1_invalid(res)
                          }
                          value={address_line1}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(address_line1, e) => {
                            set_address_line1(e.target.value);
                          }}
                          // onBlur={e => { console.info(e);}}
                          // onFocus={(e) => {console.info(e);}}
                          // onClick={(e) => {console.info(e);}}
                          validationOption={{
                            name: "address1",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>
                      <div class="form-group col-md-4 my-3">
                        <label for="phone">Street address line 2</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "address2",
                            name: "address2",
                            type: "text",
                            placeholder: "Street address line 2",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_address_line2_invalid(res)
                          }
                          value={address_line2}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(address_line2, e) => {
                            set_address_line2(e.target.value);
                          }}
                          // onBlur={e => { console.info(e);}}
                          // onFocus={(e) => {console.info(e);}}
                          // onClick={(e) => {console.info(e);}}
                          validationOption={{
                            name: "address2",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-6 my-3">
                        <label>Latitude</label>
                        <input
                          type="text"
                          class="form-control"
                          value={latitude}
                          id="latitude"
                          onChange={(e) => set_latitude(e.target.value)}
                        />
                      </div>

                      <div class="form-group col-md-6 my-3">
                        <label>Longitude</label>
                        <input
                          type="text"
                          class="form-control"
                          value={longitude}
                          id="longitude"
                          onChange={(e) => set_longitude(e.target.value)}
                        />
                      </div>

                      <div class="form-group col-md-12 my-3">
                        <label>Do you provide delivery</label>
                        <select
                          class="form-control"
                          name="provide_delivery"
                          id="provide_delivery"
                          onChange={(e) =>
                            provide_delivery_changed(e.target.value)
                          }
                        >
                          <option value={provide_delivery}>
                            {provide_delivery ? "Yes" : "No"}
                          </option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </div>

                      {provide_delivery ? (
                        <>
                          <div class="form-group col-md-6 my-3">
                            <label>What is your delivery charge type</label>
                            <select
                              class="form-control"
                              name="delivery_charge_type"
                              id="delivery_charge_type"
                              onChange={(e) =>
                                set_delivery_charge_type(e.target.value)
                              }
                            >
                              <option value={delivery_charge_type}>
                                {delivery_charge_type}
                              </option>
                              <option value="flat rate">flat rate</option>
                              <option value="per km">per km</option>
                            </select>
                          </div>

                          <div class="form-group col-md-6 my-3">
                            <label>Delivery charge</label>
                            <input
                              type="number"
                              class="form-control"
                              value={delivery_charge}
                              id="delivery_charge"
                              onChange={(e) =>
                                set_delivery_charge(e.target.value)
                              }
                            />
                          </div>

                          <div class="form-group col-md-6 my-3">
                            <label>Delivery purchase minimum (in $)</label>
                            <input
                              type="number"
                              step="0.01"
                              min="0.01"
                              class="form-control"
                              value={delivery_purchase_minimum}
                              id="delivery_purchase_minimum"
                              onChange={(e) =>
                                set_delivery_purchase_minimum(e.target.value)
                              }
                            />
                          </div>

                          <div class="form-group col-md-6 my-3">
                            <label>Delivery maximum radius (in km)</label>
                            <input
                              type="number"
                              min="1"
                              class="form-control"
                              value={delivery_maximum_radius}
                              id="delivery_maximum_radius"
                              onChange={(e) =>
                                set_delivery_maximum_radius(e.target.value)
                              }
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* <div class="form-group col-md-12 my-3">
                          <label>Are you currently at the farm?</label>
                          <select  class="form-control" name="onsite" id="onsite" onChange={(e) => set_onsite(e.target.value)}>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div> */}
                    </div>

                    <div class="form-group col-12 my-3">
                      <br />
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        disabled={action_loading}
                        // check invalids
                      >
                        <i class="glyphicon glyphicon-ok-sign"></i> Save
                      </button>
                      {/* <button class="btn btn-lg" type="reset"><i class="glyphicon glyphicon-repeat"></i> Reset</button> */}
                    </div>
                  </form>

                  {/* <hr /> */}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
export default SellerProfileScreen;
