import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { validate_multiple_roles } from "../validations";
import { getCategories, getProductTypes } from "../actions/categoryActions";
import Spinner from "../components/spinner";

function ProductTypesScreen(props) {
  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["admin", "technical-support"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const [error_message, set_error_message] = useState("");
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState("");
  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  const [all_categories, set_all_categories] = useState([]);
  const [all_product_types, set_all_product_types] = useState([]);

  const productList = useSelector((state) => state.productList);
  const { loading, error } = productList;

  const load_page_data = async () => {
    let get_all_categories;
    let get_all_product_types;

    try {
      get_all_categories = await getCategories("id", "meta", "active");
      if (get_all_categories.success === false) {
        set_error_message(get_all_categories.message);
      } else {
        const { data } = get_all_categories;
        set_all_categories(data);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching categories: ${error.message} `
      );
    }

    try {
      get_all_product_types = await getProductTypes(
        "id",
        "meta",
        "active",
        "search_term",
        "category_id",
        "sub_category_meta"
      );
      if (get_all_product_types.success === false) {
        set_error_message(get_all_product_types.message);
      } else {
        const { data } = get_all_product_types;
        console.log(data);
        set_all_product_types(data);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching product types: ${error.message} `
      );
    }

    return;
  };

  // eslint-disable-next-line
  const dispatch = useDispatch();
  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">Product</span>{" "}
              <span class="orange-text">types</span>
            </h2>
          </div>

          <div class="row">
            <div class="col-12 mb-5">
              <Link to="/add-product-type" class="btn btn-primary">
                <i class="bi bi-plus"> </i>Add product type
              </Link>
            </div>
          </div>

          {loading ? ( // so that only products will be loading at this time
            <Spinner />
          ) : error ? (
            <div class="row">
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  No product types found. Please add one using the "Add product
                  type" button.
                </div>
              </div>
            </div>
          ) : (
            <div class="row">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Sub category</th>
                      <th>Longevity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all_categories.map((category) => (
                      <>
                        <tr
                          key={category._id}
                          style={{ backgroundColor: "#d3d3d3" }}
                        >
                          <th>
                            <Link to={`/category/${category._id}`}>
                              {category.category}
                            </Link>
                          </th>
                          <th></th>
                          <th></th>
                        </tr>

                        {all_product_types.map((p) =>
                          p.category_id === category._id ? (
                            <tr key={p._id}>
                              <td>
                                <Link to={`/product-type/${p._id}`}>
                                  {p.product}
                                </Link>
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                {p.sub_category_meta.split("-").join(" ")}
                              </td>
                              <td>{p.longevity} days</td>
                            </tr>
                          ) : (
                            ""
                          )
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
export default ProductTypesScreen;
