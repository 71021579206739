//AppProvider.js
import React, { useState, useEffect } from 'react';
import AppContext from '../contexts/AppContext';

const AppProvider = ({ children, headerHeight, footerHeight }) => {

  return (
    <AppContext.Provider value={{ headerHeight, footerHeight }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;