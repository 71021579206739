import React, { useState, useEffect } from "react";
import Spinner from "../components/spinner";

function PaymentScreen(props) {
  // eslint-disable-next-line
  const id = props.match.params.order_id ? props.match.params.order_id : "";
  // eslint-disable-next-line
  const gateway = props.match.params.gateway ? props.match.params.gateway : "";
  // eslint-disable-next-line
  const status = props.match.params.status ? props.match.params.status : "";
  // eslint-disable-next-line
  const result_text = props.match.params.result_text
    ? props.match.params.result_text
    : "";

  console.log(id, gateway, status, result_text);
  // eslint-disable-next-line
  const [error_message, set_error_message] = useState("");
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState("");

  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  const load_page_data = async () => {
    // update payment details
    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== "" ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : (
                    ""
                  )}
                  {success_message !== "" ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
export default PaymentScreen;
