import React, { useState, useEffect } from "react";
import { validate_multiple_roles } from "../validations";
import {
  getBuyers,
  deleteBuyer,
  verifySeller,
  verifyOrganicSeller,
  deactivateBuyer,
} from "../actions/userActions";
import { updateOrder } from "../actions/orderActions";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import { API_URL } from "../config";
import Spinner from "../components/spinner";

function AdminBuyerScreen(props) {
  const id = props.match.params.id ? props.match.params.id : "";

  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["admin", "sales-support"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [error_message, set_error_message] = useState("");
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState("");
  const [page_loaded, set_page_loaded] = useState(false);

  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  const [order_details, set_order_details] = useState({});
  const [buyer_details, set_buyer_details] = useState({});
  // eslint-disable-next-line
  const [order_items, set_order_items] = useState([]);

  const [approved_details, set_approved_details] = useState({});

  const [organic_details, set_organic_details] = useState({});

  const load_page_data = async () => {
    set_action_loading(true);
    let get_buyer_details;

    try {
      get_buyer_details = await getBuyers(id, "role", "status");

      if (get_buyer_details.success) {
        const { data } = get_buyer_details;
        set_order_details(data[0]);
        console.log("data0", data[0]);
        //   console.log(data[0].buyer_details, data[0])
        set_buyer_details(data[0].buyer_details[0]);
        set_approved_details(data[0].approved);
        set_organic_details(data[0].organic);
        set_order_items(data[0].products);
      } else {
        set_error_message(get_buyer_details.message);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching seller orders: ${error.message} `
      );
    }

    // when the time to pul seller product comes, pull this one out.
    //  try {
    //     get_seller_products = await getOrderItems('id', id, 'seller_id', 'product', 'status');

    //     if (get_seller_products.success) {
    //       const { data } = get_seller_products;
    //       set_order_items(data);
    //     } else {
    //       set_error_message(get_seller_products.message);
    //     }
    //   } catch (error) {
    //     set_error_message(`An error occured fetching seller orders: ${error.message} `);
    //   }

    set_action_loading(false);
    return;
  };

  /**
   * Updates user profile
   * @param {*} e
   */
  // eslint-disable-next-line
  const suspend_user_account = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const update_data = {
      status: "Paid",
    };

    const response = await updateOrder(update_data, id, "payment");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      await load_page_data();

      set_success_message("Payment status successfully updated");
    }
    set_action_loading(false);

    return;
  };

  /**
   * Deletes user details
   * @param {*} e
   */
  const delete_user_account = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const response = await deleteBuyer(id);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message("Account deleted");
      props.history.push("/buyers");
    }
    set_action_loading(false);

    return;
  };

  /**
   * Verifies user acccount details
   * @param {*} e
   */
  const verify_user_account = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const response = await verifySeller(id);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message("Account successfully verified");
      await load_page_data();
    }
    set_action_loading(false);

    return;
  };

  /**
   * Verifies user acccount details
   * @param {*} e
   */
  const deactivate_user_account = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const response = await deactivateBuyer(id);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message("Account successfully deactivated");
      await load_page_data();
    }
    set_action_loading(false);

    return;
  };

  /**
   * Verifies organic acccount details
   * @param {*} e
   */
  const verify_organic_account = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const response = await verifyOrganicSeller(id);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message("Organic farmer successfully verified");
      await load_page_data();
    }
    set_action_loading(false);

    return;
  };

  /**
   * Updates user profile
   * @param {*} e
   */
  // eslint-disable-next-line
  const activate_user_account = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const update_data = {};

    const response = await updateOrder(update_data, id, "cancel");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      await load_page_data();

      set_success_message("Order cancelled");
    }
    set_action_loading(false);

    return;
  };

  const confirmAndDeleteUserAccount = async (e) => {
    e.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this user? This action cannot be undone."
    );
    if (isConfirmed) {
      delete_user_account(e);
    }
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="black-text">Viewing </span>{" "}
              <span class="green-text">
                {order_details.first_name} {order_details.last_name}{" "}
              </span>
            </h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== "" ? (
                    <div class="alert alert-danger" role="alert">
                      <button type="button" class="close" data-dismiss="alert">
                        &times;
                      </button>
                      {error_message}
                    </div>
                  ) : (
                    ""
                  )}
                  {success_message !== "" ? (
                    <div class="alert alert-success" role="alert">
                      <button type="button" class="close" data-dismiss="alert">
                        &times;
                      </button>
                      {success_message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div class="row"></div>

              <div class="row">
                <div class="col-3">
                  {order_details.image !== null &&
                  order_details.image !== undefined &&
                  order_details.image !== "" ? (
                    <img
                      src={`${API_URL}/${order_details.image}`}
                      class="avatar img-circle img-thumbnail"
                      alt="avatar"
                    />
                  ) : (
                    <img
                      src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                      class="avatar img-circle img-thumbnail"
                      alt="avatar"
                    />
                  )}
                </div>
                <div class="col-9">
                  <h2>Basic Details</h2>
                  <div class="table-responsive mb-5">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Full name</th>
                          <td>
                            {order_details.first_name} {order_details.last_name}
                          </td>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th>Email</th>
                          <td>{order_details.email}</td>
                        </tr>

                        <tr>
                          <th>Phone</th>
                          <td>{order_details.phone}</td>
                        </tr>

                        <tr>
                          <th>Telephone</th>
                          <td>{order_details.tel}</td>
                        </tr>

                        <tr>
                          <th>Verification status</th>
                          <td>
                            {order_details.verified ? (
                              <>
                                <p>Verified</p>
                                <button
                                  class="btn btn-lg btn-warning"
                                  type="submit"
                                  onClick={deactivate_user_account}
                                  color="primary"
                                >
                                  Deactivate account
                                </button>
                                &nbsp;&nbsp;&nbsp;
                                <button
                                  class="btn btn-lg btn-danger"
                                  type="submit"
                                  onClick={confirmAndDeleteUserAccount}
                                  color="primary"
                                >
                                  Delete acccount
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  class="btn btn-lg btn-success"
                                  type="submit"
                                  onClick={verify_user_account}
                                  color="primary"
                                >
                                  Verify account details
                                </button>
                                &nbsp;&nbsp;&nbsp;
                                <button
                                  class="btn btn-lg btn-danger"
                                  type="submit"
                                  onClick={confirmAndDeleteUserAccount}
                                  color="primary"
                                >
                                  Delete acccount
                                </button>
                              </>
                            )}
                          </td>
                        </tr>

                        {/* <tr>
                          <th>Status</th>
                          <td>
                            {order_details.status}
                            <br />
                            {order_details.status === 'active' ? (
                              <>
                                <button
                                  class="btn btn-lg btn-danger"
                                  type="submit"
                                  onClick={delete_user_account} color="primary">
                                  Delete account
                                </button>
                                &nbsp;&nbsp;
                              </>
                            ) : ('')}
                            <br />
                            {order_details.status === 'deleted' ? (
                              <button
                                class="btn btn-lg btn-success"
                                type="submit"
                                onClick={delete_user_account} color="primary">
                                Delete account
                              </button>
                            ) : ('')}
                            <br />
                            {order_details.status === 'suspend' ? (
                              <>
                              </>
                            ) : ('')}
                          </td>
                        </tr> */}

                        {/* <tr>
                          <th>Admin Verification Status</th>
                          <td>
                            {approved_details.status}
                            <br />
                            {approved_details.status === 'Verification Request Sent' ? (
                              <>
                                <button
                                  class="btn btn-lg btn-success"
                                  type="submit"
                                  onClick={verify_user_account} color="primary">
                                  Verify account
                                </button>
                              </>
                            ) : (<>

                            </>)}</td>
                        </tr> */}

                        {/* <tr>
                          <th>Organic Farming Specialisation</th>
                          <td>
                            {organic_details.check ? ('Organic Farmer') : ('Not Organic Farmer')}
                            <br />
                            {organic_details.check ? (<>
                              {organic_details.status !== 'Verified Organic Farmer' ? (
                                <>
                                  <button
                                    class="btn btn-lg btn-success"
                                    type="submit"
                                    onClick={verify_organic_account} color="primary">
                                    Verify Organic Farmer
                                  </button>
                                </>
                              ) : (<>
  Verified
                              </>)}
                            </>) : (<></>)}
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>

                  <h2 class="mt-5">Buyer location details</h2>
                  <div class="table-responsive mb-5">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <th>Address</th>
                          <td>
                            {buyer_details.address_line1}{" "}
                            {buyer_details.address_line2} {buyer_details.suburb}{" "}
                            {buyer_details.city} {buyer_details.province}{" "}
                            {buyer_details.country}
                          </td>
                        </tr>

                        <tr>
                          <th>Address coordinates</th>
                          <td>
                            {buyer_details.latitude}, {buyer_details.longitude}
                          </td>
                        </tr>

                        {/* <tr>
                          <th>Description</th>
                          <td>
                            {buyer_details.description}
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-3"></div>
              </div>

              <div class="row"></div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
export default AdminBuyerScreen;
