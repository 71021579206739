import React, { useState, useEffect } from "react";
import { addProductType } from "../actions/categoryActions";
import { getCategories, getProductTypes } from "../actions/categoryActions";
import { validate_multiple_roles } from "../validations";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import Spinner from "../components/spinner";

function AddProductTypesScreen(props) {
  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["admin", "technical-support"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");
  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [add_category, set_add_category] = useState("");
  const [add_sub_category, set_add_sub_category] = useState("");
  const [add_product, set_add_product] = useState("");
  const [add_units, set_add_units] = useState("");
  const [add_search_keys, set_add_search_keys] = useState("");
  const [add_longevity, set_add_longevity] = useState(1);

  // eslint-disable-next-line
  const [add_product_invalid, set_add_product_invalid] = useState(true);
  // eslint-disable-next-line
  const [add_search_keys_invalid, set_add_search_keys_invalid] = useState(true);
  // eslint-disable-next-line
  const [add_units_invalid, set_add_units_invalid] = useState(true);

  const [all_categories, set_all_categories] = useState([]);
  const [all_product_types, set_all_product_types] = useState([]);

  const [display_sub_categories, set_display_sub_categories] = useState([]);
  // eslint-disable-next-line
  const [display_product_types, set_display_product_types] = useState([]);

  const category_changed = (new_category) => {
    let _display_sub_categories = [];
    let _display_product_types = [];

    set_add_category(new_category);
    set_add_sub_category("");
    set_add_product("");

    if (new_category !== "") {
      for (let i = 0; i < all_categories.length; i++) {
        if (all_categories[i]._id === new_category) {
          _display_sub_categories = all_categories[i].sub_categories;
        }
      }

      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].category_id === new_category) {
          _display_product_types.push(all_product_types[i]);
        }
      }
    }

    set_display_sub_categories(_display_sub_categories);
    set_display_product_types(_display_product_types);
  };

  const sub_category_changed = (new_sub_category) => {
    let _display_product_types = [];

    set_add_sub_category(new_sub_category);
    set_add_product("");

    if (new_sub_category !== "") {
      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].sub_category_meta === new_sub_category) {
          _display_product_types.push(all_product_types[i]);
        }
      }
    }

    set_display_product_types(_display_product_types);
  };

  const load_page_data = async () => {
    let get_all_categories;
    let get_all_product_types;

    try {
      get_all_categories = await getCategories("id", "meta", "active");
      if (get_all_categories.success === false) {
        set_error_message(get_all_categories.message);
      } else {
        const { data } = get_all_categories;
        set_all_categories(data);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching categories: ${error.message} `
      );
    }

    try {
      get_all_product_types = await getProductTypes(
        "id",
        "meta",
        "active",
        "search_term",
        "category_id",
        "sub_category_meta"
      );
      if (get_all_product_types.success === false) {
        set_error_message(get_all_product_types.message);
      } else {
        const { data } = get_all_product_types;
        console.log(data);
        set_all_product_types(data);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching product types: ${error.message} `
      );
    }

    return;
  };

  /**
   * Updates product types
   * @param {*} e
   */
  const addProduce = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    if (add_category === "") {
      set_error_message("Please select the product category");
      set_action_loading(false);
      return;
    }

    let units = [];
    let search_keys = [];

    if (add_units !== "") {
      units = add_units.split(",");
    }
    if (add_search_keys !== "") {
      search_keys = add_search_keys.split(",");
    }

    const update_data = {
      category_id: add_category,
      sub_category_meta: add_sub_category,
      product_id: add_product,
      units,
      search_keys,
      dimensions: [],
      product: add_product,
      longevity: add_longevity,
    };

    const response = await addProductType(update_data);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      props.history.push("product-types");
    }
    set_action_loading(false);

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">Add</span>{" "}
              <span class="orange-text">Products</span>
            </h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== "" ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : (
                    ""
                  )}
                  {success_message !== "" ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div class="row">
                <div class="col-md-4"></div>

                <div class="col-md-4">
                  <form onSubmit={addProduce}>
                    <div class="row">
                      <div class="form-group col-md-12 my-3">
                        <label for="">Category</label>
                        <select
                          class="form-control"
                          name="add_category"
                          id="add_category"
                          onChange={(e) => category_changed(e.target.value)}
                        >
                          <option value="">Select category</option>
                          {all_categories.map((c) => (
                            <option key={c._id} value={c._id}>
                              {c.category}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div class="form-group col-md-12 my-3">
                        <label for="">Sub category</label>
                        <select
                          class="form-control"
                          name="add_sub_category"
                          id="add_sub_category"
                          onChange={(e) => sub_category_changed(e.target.value)}
                        >
                          <option value="">Select sub category</option>
                          {display_sub_categories.map((c) => (
                            <option
                              key={c.sub_category_meta}
                              value={c.sub_category_meta}
                            >
                              {c.sub_category}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div class="form-group col-md-12 my-3">
                        <label for="">Product</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Product",
                            name: "Product",
                            type: "text",
                            placeholder: "",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_add_product_invalid(res)
                          }
                          value={add_product}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(add_product, e) => {
                            set_add_product(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "Product",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            //   min: 0.01,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-12 my-3">
                        <label for="">Units</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "units",
                            name: "Units",
                            type: "text",
                            placeholder: "",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_add_units_invalid(res)
                          }
                          value={add_units}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(add_units, e) => {
                            set_add_units(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "units",
                            //   check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            //   min: 0.01,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-12 my-3">
                        <label for="">Keywords</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "searchKeys",
                            name: "Search keys",
                            type: "text",
                            placeholder: "",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_add_search_keys_invalid(res)
                          }
                          value={add_search_keys}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(add_search_keys, e) => {
                            set_add_search_keys(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "searchKeys",
                            //   check: true,
                            required: false,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            //   min: 0.01,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-12 my-3">
                        <label>Longevity</label>
                        <input
                          type="number"
                          min="1"
                          value={add_longevity}
                          onChange={(e) => {
                            set_add_longevity(e.target.value);
                          }}
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-group col-12 my-3 text-center">
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        disabled={action_loading}
                        // check invalids
                      >
                        <i class="glyphicon glyphicon-plus"></i> Add product
                        type
                      </button>
                    </div>
                  </form>
                </div>

                <div class="col-md-4"></div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
export default AddProductTypesScreen;
