import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { addProduct } from '../actions/productActions';
import { getCategories, getProductTypes } from '../actions/categoryActions';
import { validate_role } from '../validations';
import { Textbox } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { stateToHTML } from 'draft-js-export-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import stringSimilarity from "string-similarity"


function SellerAddProductScreen(props) {
  // eslint-disable-next-line
  let user_profile;
  const permitted_role = 'seller';
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = 'login';
    if (permitted_role === 'seller') login_link = 'access-forbidden'
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;

    // console.log(user_profile)
  }

  const selling_methods = [
    {
      name: 'peleka',
      description: 'Order fulfillment by Farm Fresh Delights',
    },
    {
      name: 'direct',
      description: 'Buyer-seller direct arrangement'
    },
    {
      name: 'hybrid',
      description: 'Buyer to choose fulfilment method'
    }
  ]

  const [mark_order_ready, set_mark_order_ready] = React.useState(false);
  const mark_order_ready_open = () => { set_mark_order_ready(true); };
  const mark_order_ready_close = () => { set_mark_order_ready(false); };


  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [add_category, set_add_category] = useState('');
  const [add_sub_category, set_add_sub_category] = useState('');
  const [add_product, set_add_product] = useState('');
  const [add_product_id, set_add_product_id] = useState('');
  const [add_product_name, set_add_product_name] = useState('');
  // eslint-disable-next-line
  const [add_description, set_add_description] = useState('');
  const [add_units, set_add_units] = useState('');
  const [add_quantity, set_add_quantity] = useState('');
  const [add_price, set_add_price] = useState('');
  const [add_organic, set_add_organic] = useState(true);
  const [selling_method, set_selling_method] = useState(true);
  const [search_product_found, set_search_product_found] = useState(false);
  const [alternatively_search_product, set_alternatively_search_product] = useState(true);

  const [searched_product, set_searched_product] = useState('');
  const [possible_search_alternatives, set_possible_search_alternatives] = useState([]);

  const [add_currency, set_add_currency] = useState(localStorage.getItem('currency'));

  // const [add_category_invalid, set_add_category_invalid] = useState(true);
  // const [add_sub_category_invalid, set_add_sub_category_invalid] = useState(true);
  // const [add_product_invalid, set_add_product_invalid] = useState(true);
  // const [add_description_invalid, set_add_description_invalid] = useState(true);
  // const [add_units_invalid, set_add_units_invalid] = useState(true);
  // eslint-disable-next-line
  const [add_quantity_invalid, set_add_quantity_invalid] = useState(true);
  // eslint-disable-next-line
  const [add_product_invalid, set_add_product_invalid] = useState(true);
  // eslint-disable-next-line
  const [add_price_invalid, set_add_price_invalid] = useState(true);
  // const [add_organic_invalid, set_add_organic_invalid] = useState(true);
  // eslint-disable-next-line
  const [all_categories, set_all_categories] = useState([]);
  // eslint-disable-next-line
  const [all_product_types, set_all_product_types] = useState([]);
  const [all_product_types_in_alphabetical_order, set_all_product_types_in_alphabetical_order] = useState([]);

  const [display_sub_categories, set_display_sub_categories] = useState([]);
  // eslint-disable-next-line
  const [display_product_types, set_display_product_types] = useState([]);
  const [display_units, set_display_units] = useState([]);

  const [units_method, set_units_method] = useState('ordinary');
  const [units_container, set_units_container] = useState('');
  const [units_size, set_units_size] = useState('');

  const [units_size_invalid, set_units_size_invalid] = useState(true);



  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  // eslint-disable-next-line
  const [description_invalid, set_description_invalid] = useState(true);


  const category_changed = (new_category) => {
    let _display_sub_categories = [];
    let _display_product_types = [];

    console.log(new_category, new_category)

    set_add_category(new_category);
    set_add_sub_category('');

    if (new_category !== '') {
      for (let i = 0; i < all_categories.length; i++) {
        if (all_categories[i]._id === new_category) {
          _display_sub_categories = all_categories[i].sub_categories;
        }
      }

      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].category_id === new_category) {
          _display_product_types.push(all_product_types[i]);
        }
      }
    }

    set_display_sub_categories(_display_sub_categories);
    set_display_product_types(_display_product_types);
  }

  const sub_category_changed = (new_sub_category) => {
    let _display_product_types = [];

    set_add_sub_category(new_sub_category);

    if (new_sub_category !== '') {
      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].sub_category_meta === new_sub_category) {
          _display_product_types.push(all_product_types[i]);
        }
      }
    }

    set_display_product_types(_display_product_types);
  }

  const product_type_changed = (new_product_type) => {
    let _display_units = [];

    set_add_product(new_product_type);

    // console.log(new_product_type)

    if (new_product_type !== '') {
      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].product_meta === new_product_type) {
          set_add_product_id(all_product_types[i]._id)
          set_add_product_name(all_product_types[i].product)
          // console.log('found')
          // console.log(all_product_types[i]._id, all_product_types[i].units);
          _display_units = all_product_types[i].units;
        }
      }
    }

    // console.log('display units', _display_units)

    set_display_units(_display_units);
    set_search_product_found(true)
  }

  const product_type_changed_with_search = (new_product_type) => {
    let _display_units = [];

    set_add_product(new_product_type)
    set_possible_search_alternatives([])

    let _found = false
    if (new_product_type !== '') {
      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].product === new_product_type) {
          // console.log('found', all_product_types[i].category_id, all_product_types[i].sub_category_meta)
          set_add_product_id(all_product_types[i]._id)
          set_add_product_name(all_product_types[i].product)
          category_changed(all_product_types[i].category_id)
          sub_category_changed(all_product_types[i].sub_category_meta)
          _display_units = all_product_types[i].units
          _found = true
        }
      }
    }

    let _possible_search_alternatives = []
    if (_found === false) {
      // find possible matches 

      // const best_match = stringSimilarity.findBestMatch(searched_product, all_product_types_in_alphabetical_order);
      // console.log(best_match)
      for (let i = 0; i < all_product_types_in_alphabetical_order.length; i++) {
        const similarity = stringSimilarity.compareTwoStrings(searched_product, all_product_types_in_alphabetical_order[i]);
        if (similarity > 0.4) {
          _possible_search_alternatives.push(all_product_types_in_alphabetical_order[i])
        }
      }
      set_possible_search_alternatives(_possible_search_alternatives)
    }

    set_search_product_found(_found)
    set_display_units(_display_units);
  }


  const load_page_data = async () => {
    let get_all_categories;
    try {
      get_all_categories = await getCategories('id', 'meta', 'active');
      if (get_all_categories.success === false) {
        set_error_message(get_all_categories.message);
      }
      else {
        const { data } = get_all_categories;
        set_all_categories(data);
      }
    } catch (error) {
      set_error_message(`An error occured fetching categories: ${error.message} `);
    }

    let get_all_product_types;
    try {
      get_all_product_types = await getProductTypes('id', 'meta', 'active', 'search_term', 'category_id', 'sub_category_meta');
      if (get_all_product_types.success === false) {
        set_error_message(get_all_product_types.message);
      }
      else {
        const { data } = get_all_product_types;
        // console.log(data);
        set_all_product_types(data);

        let _all_product_types_in_alphabetical_order = []
        for (let i = 0; i < data.length; i++) {
          _all_product_types_in_alphabetical_order.push(data[i].product)
        }

        set_all_product_types_in_alphabetical_order(_all_product_types_in_alphabetical_order.sort())

      }
    } catch (error) {
      set_error_message(`An error occured fetching product types: ${error.message} `);
    }

    return;
  }

  /**
   * Updates user profile
   * @param {*} e 
   */
  const addProduce = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    if (parseFloat(add_quantity) < 0) {
      set_error_message('Quantity cannot be less than 0')
      set_action_loading(false)
      return
    }

    let quantity_units = add_units;
	let use_package;
	let package_units;
	let package_size;
    if (units_method === 'complex') {
      quantity_units = `${units_size}${add_units} ${units_container}`;
	  use_package = true;
	  package_units = add_units;
	  package_size = units_size;
    } else {
	  use_package = false;
	}

    let _product_name = ''
    // for (let i = 0; i < all_product_types.length; i++) {
    //   if (all_product_types[i].product_meta === new_product_type) {
    //     set_add_product_id(all_product_types[i]._id)
    //     set_add_product_name(all_product_types[i].product)
    //     // console.log('found')
    //     // console.log(all_product_types[i]._id, all_product_types[i].units);
    //     _display_units = all_product_types[i].units;
    //   }
    // }

    const update_data = {
      category_id: add_category,
      sub_category: add_sub_category,
      product_id: add_product_id,
      product_meta: add_product,
      quantity_units,
	  use_package,
	  package_units,
	  package_size,	  
      product: add_product_name,
      quantity: add_quantity,
      price: add_price,
      organic: add_organic,
      description: stateToHTML(editorState.getCurrentContent()),
      selling_method: 'direct',
      currency: add_currency,
    };

    // console.log(update_data);

    const response = await addProduct(update_data);
    // console.log(response)
    // return;
    if (response.success === false) {
      set_error_message(response.message);

    } else {
      set_add_category('');
      set_add_sub_category('');
      set_add_product('');
      set_add_description('');
      set_add_units('');
      set_add_quantity('');
      set_add_price('');
      set_add_organic(true);
      set_success_message('New product successfully added');
      //props.history.push('seller-products');
      props.history.push(`seller-product-images/${response.id}`);
	  //{/`}
    }
    set_action_loading(false);



    return;
  }

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, [editorState]);



  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2><span class="green-text">Add</span> <span class="orange-text">Products</span></h2>
          </div>


          {!page_loaded ? ( // so that only products will be loading at this time 
            <div>Loading ... </div>
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== '' ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : ('')}
                  {success_message !== '' ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : ('')}
                </div>
              </div>
              <form onSubmit={addProduce}>
                <div class="row">

                  {/* <div class="col-md-4"></div> */}

                  <div class="col-md-4">

                    <div class="row">

                      {alternatively_search_product ? (
                        <div class="form-group col-md-12 my-3">
                          <label for="">
                            <b>Searching for product by name</b><br/>
                            &nbsp;&nbsp;
                            <span>
                              <button type="button" class="btn btn-success btn-lg" onClick={() => set_alternatively_search_product(false)}>
                                search product by category/sub category instead?
                              </button>
                              <br /><br/>
							  Type product name to search for
                            </span>
                          </label>
                          <input list="brow" class="form-control" onChange={(e) => {
                            set_searched_product(e.target.value)
                            product_type_changed_with_search(e.target.value);
                          }}
                            value={searched_product} />
                          <datalist id="brow" >
                            {all_product_types_in_alphabetical_order.map((c) => (
                              <option value={c} />
                            ))}
                          </datalist>

                          {search_product_found ? ('') : (
                            searched_product === '' ? ('') : (
                              possible_search_alternatives.length < 1 ? (
                                <span>
                                  {searched_product} not found - please search again, or <Link to={`/specify-new-product/${searched_product}`}>Contact Us</Link> describing the product you would like to sell
                                </span>
                              ) : (
                                <>
                                  <span class="mt-3">
                                    Do you mean?
                                    <ul>
                                      {possible_search_alternatives.map(psa =>
                                      (<li class="green-text" onClick={() => {
                                        set_searched_product(psa)
                                        product_type_changed_with_search(psa)
                                      }}>{psa}</li>))
                                      }
                                    </ul>
                                  </span>
                                </>
                              )

                            )
                          )}

                        </div>
                      ) : (
                        <>
                          <div class="form-group col-md-12 my-3">
                            <label for="">
                              <b>Searching for product by category</b><br/>
                              &nbsp;&nbsp;
                              <span>
                                <button type="button" class="btn btn-success btn-lg" onClick={() => set_alternatively_search_product(true)}>
                                search for products by name instead?
                                </button>
                                <br /><br/>
                              </span>
                            </label>
                            <select class="form-control" name="add_category" id="add_category" onChange={(e) => category_changed(e.target.value)}>
                              <option value="">Select category</option>
                              {all_categories.map((c) => (
                                <option key={c._id} value={c._id}>{c.category}</option>
                              ))}
                            </select>
                          </div>

                          <div class="form-group col-md-12 my-3">
                            <label for="">Sub category</label>
                            <select class="form-control" name="add_sub_category" id="add_sub_category" onChange={(e) => sub_category_changed(e.target.value)}>
                              <option value="">Select sub category</option>
                              {display_sub_categories.map((c) => (
                                <option key={c.sub_category_meta} value={c.sub_category_meta}>{c.sub_category}</option>
                              ))}
                            </select>
                          </div>

                          <div class="form-group col-md-12 my-3">
                            <label for="">
                              Product
                            </label>
                            <select class="form-control" name="add_product" id="brow" onChange={(e) => product_type_changed(e.target.value)}>
                              <option value="">Select product</option>
                              {display_product_types.map((c) => (
                                <option key={c.product_meta} value={c.product_meta}>{c.product}</option>
                              ))}
                            </select>
                          </div>


                        </>
                      )}






                      <div class="form-group col-md-12 my-3">
                        <label for="add_quantity">Quantity</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Quantity",
                            name: "Quantity",
                            type: "number",
                            placeholder: ""
                          }}
                          // validate={validate}
                          validationCallback={res =>
                            set_add_quantity_invalid(res)
                          }
                          value={add_quantity}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(add_quantity, e) => {
                            set_add_quantity(e.target.value);
                          }}
                          onBlur={e => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "Quantity",
                            check: true,
                            required: true,
                            type: 'number', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            min: 0,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      {units_method === 'ordinary' ? (
                        <>
                          <div class="form-group col-md-12 my-3">
                            <button type="button" class="btn btn-success btn-lg"
                              style={{ color: "#fff", fontSize: "12px" }}
                              onClick={() => set_units_method('complex')}>
                                Sell products packaged as bags, packets etc
                              </button>
                          </div>

                          <div class="form-group col-md-12 my-3">
                            <label for="">Units</label>
                            <select class="form-control" name="add_units" id="add_units" onChange={(e) => set_add_units(e.target.value)}>
                              <option value="">Select units</option>
                              {display_units.map((c) => (
                                <option key={c} value={c}>{c}</option>
                              ))}
                            </select>
                          </div>

                          <div class="form-group col-md-12 my-3">
                        <label for="add_price">Price {add_units === 'each' ? (''):('per') } {add_units}</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Price",
                            name: "Price",
                            type: "number",
                            placeholder: ""
                          }}
                          // validate={validate}
                          validationCallback={res =>
                            set_add_price_invalid(res)
                          }
                          value={add_price}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(add_price, e) => {
                            set_add_price(e.target.value);
                          }}
                          onBlur={e => { console.log(e); }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "Price",
                            check: true,
                            required: true,
                            type: 'number', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            min: 1,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                        </>
                      ) : ('')}

{units_method === 'complex' ? (
                        <>
                          <div class="form-group col-md-12 my-3">
                            
                            <button type="button" class="btn btn-success btn-lg"
                              style={{ color: "#fff", fontSize: "12px" }}
                              onClick={() => set_units_method('ordinary')}>
                                Sell product as individual items/quantities, not packages
                              </button>
                          </div>

                          <div class="form-group col-md-12 my-3">
                            <label for="">Sell as</label>
                            <select class="form-control" name="add_units" id="add_units" onChange={(e) => set_units_container(e.target.value)}>
                              <option value="">Select</option>
                              <option value="bag">Bags</option>
                              <option value="packet">Packets</option>
                              <option value="sack">Sacks</option>
                              <option value="box">Boxes</option>
                            </select>
                          </div>

                          <div class="form-group col-md-12 my-3">
                            <label for="">Units of measurement for bag</label>
                            <select class="form-control" name="add_units" id="add_units" onChange={(e) => set_add_units(e.target.value)}>
                              <option value="">Select units</option>
                              {display_units.map((c) => (
                                <option key={c} value={c}>{c}</option>
                              ))}
                            </select>
                          </div>

                          <div class="form-group col-md-12 my-3">
                        <label for="add_price">Size of {units_container}</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Size",
                            name: "Size",
                            type: "number",
                            placeholder: ""
                          }}
                          // validate={validate}
                          validationCallback={res =>
                            set_units_size_invalid(res)
                          }
                          value={units_size}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(units_size, e) => {
                            set_units_size(e.target.value);
                          }}
                          onBlur={e => { console.log(e); }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "Size",
                            // check: true,
                            // required: true,
                            type: 'number', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            min: 1,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>




                          <div class="form-group col-md-12 my-3">
                        <label for="add_price">Price of {units_size}{add_units} {units_container}</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Price",
                            name: "Price",
                            type: "number",
                            placeholder: ""
                          }}
                          // validate={validate}
                          validationCallback={res =>
                            set_add_price_invalid(res)
                          }
                          value={add_price}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(add_price, e) => {
                            set_add_price(e.target.value);
                          }}
                          onBlur={e => { console.log(e); }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "Price",
                            check: true,
                            required: true,
                            type: 'number', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            min: 1,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                        </>
                      ) : ('')}


                      






                    </div>


                  </div>

                  <div class="col-md-8">

                    {/* <div class="form-group col-md-12 my-3"> 
                          <label for="" class="mb-2">
                           Is this an organic product?</label>
                          <select  class="form-control" name="selling_method" id="selling_method" onChange={(e) => set_selling_method(e.target.value)}>
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          
                        </div> */}

                    {/* <div class="form-group col-md-12 my-3"> 
                          <label for="" class="mb-2">
                            How do you wish to sell this product?
                            &nbsp;&nbsp;
                            <button type="button" onClick={() => mark_order_ready_open() } class="btn btn-secondary btn-lg" >selling options explained</button>
                          </label>
                          <select  class="form-control" name="selling_method" id="selling_method" onChange={(e) => set_selling_method(e.target.value)}>
                            <option value="">Select method</option>
                            {selling_methods.map((c) => (              
                              <option key={c.name} value={c.name}>{c.description}</option>
                            ))}
                          </select>
                          
                        </div> */}

                    <div class="col-md-12 mt-5 mb-3">
                      <div class="form-group">
                        <label>Product description </label>


                        {/* <div style={{border: '1px solid #d3d3d3', width: '100%', padding: '5px', minHeight: '200px', marginTop: '20px'}}>
                            <Editor editorState={description} onChange={set_description}  />
                        </div> */}
                        <div style={{ border: '1px solid #d3d3d3', width: '100%', padding: '5px', minHeight: '300px', marginTop: '20px' }}>
                          <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                          />
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="col-md-12 my-3 text-center">
                    <button
                      class="btn btn-lg btn-success"
                      type="submit"
                      disabled={action_loading || add_quantity_invalid }
                    // check invalids 
                    >
                      <i class="glyphicon glyphicon-plus"></i> Add product
                    </button>
                  </div>


                </div>
              </form>
            </>
          )}

        </div>
      </section>


      <Dialog open={mark_order_ready} onClose={mark_order_ready_close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <h3>Selling options explained</h3>
        </DialogTitle>
        <DialogContent>
          <p>
            Order fulfillment by Peleka and Buyer-to-choose will only be available:
            <br /><br />
            <ol>
              <li>in cities where PED is operating (currently Gweru) and </li>
              <li>for sellers that have been verified by Peleka and </li>
              <li>for items that Peleka currently has capacity to fulfil (e.g. we can't fulfill big items like bathtubs</li>
            </ol>
          </p>

        </DialogContent>
        <DialogActions>
          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={mark_order_ready_close} color="primary">
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default SellerAddProductScreen;
