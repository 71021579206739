import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { getProducts, updateProduct, deductQuantity } from '../actions/productActions';
import { getCategories } from '../actions/categoryActions';
import { validate_role } from '../validations';
import { Textbox, Checkbox } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { API_URL, IMAGES_URL, API_KEY, get_display_date} from '../config';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function SellerProductImagesScreen(props) {
    const [selectedImage, setSelectedImage] = useState(null);
    //const [crop, setCrop] = useState({ aspect: 4 / 3 });
	const [crop, setCrop] = useState({ aspect: 4 / 3, x: 0, y: 0, width: 240, height: 180 });
    const [croppedImageFile, setCroppedImageFile] = useState(null);
    const [isCropModalOpen, setIsCropModalOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);
	const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [product_name, set_product_name] = useState('');
    const [quantity, set_quantity] = useState('');
    const [quantity_units, set_quantity_units] = useState('');
    const [created_at, set_created_at] = useState('');
	
	const imageRef = useRef();
    const fileInputRef = useRef(null);

    const onSelectFile = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () => setSelectedImage(reader.result));
        reader.readAsDataURL(e.target.files[0]);
      }
    };
    
    
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [confirmationError, setConfirmationError] = useState("");
    
    // Function to handle the crop

    const onCropComplete = (crop) => {
      if (!selectedImage || !crop.width || !crop.height) return;
    
      const canvas = document.createElement('canvas');
      const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
      const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
      canvas.width = 400; // Width of the output image
      canvas.height = 300; // Height of the output image
      const ctx = canvas.getContext('2d');
    
      const image = new Image();
      image.src = selectedImage;
      image.onload = () => {
        ctx.drawImage(
          image,
          crop.x * scaleX, // Scaled x-coordinate
          crop.y * scaleY, // Scaled y-coordinate
          crop.width * scaleX, // Scaled width
          crop.height * scaleY, // Scaled height
          0,
          0,
          400,
          300
        );
    
        canvas.toBlob((blob) => {
          const croppedFile = new File([blob], "cropped.jpeg", { type: 'image/jpeg' });
          setCroppedImageFile(croppedFile);
        }, 'image/jpeg');
      };
    };




    const [file, setFile] = useState(''); // storing the uploaded file
    // eslint-disable-next-line
    const [progress, setProgess] = useState(0); // progess bar
    const el = useRef(); // accesing input element    
	


    const handleImageFileChange = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        setFile(file);	  
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
          setIsCropModalOpen(true);
        };
        reader.readAsDataURL(file);
      }
        setIsConfirmed(false); // Reset confirmation state
        setSelectedImage(null); // Reset selected image
        setCroppedImageFile(null); // Reset cropped image file  
    };
    
    const handleImageOkClick = () => {
      if (!consent_photo) {
        setConfirmationError("Please confirm this is an original photo of your produce. If not, please cancel");
        return;
      }
      if (crop.width && crop.height && selectedImage) {
        onCropComplete(crop);
    	uploadFile();	
        setIsCropModalOpen(false);
        setConfirmationError(""); // Reset the error message
        setSelectedImage(null); // Reset selected image after successful crop
    	set_consent_photo(false);
      }
    };
    
    const handleImageCancelClick = () => {
        setIsCropModalOpen(false); // Close the modal
        setSelectedImage(null); // Reset selected image
        setCroppedImageFile(null); // Reset cropped image file
    	if (fileInputRef.current) fileInputRef.current.value = ''; // Reset file input
    	set_consent_photo(false);
    
    };
    
    const onImageLoaded = (image) => {
      imageRef.current = image; // Set the image reference
      const imageWidth = image.naturalWidth;
      const imageHeight = image.naturalHeight;
      setImageDimensions({ width: imageWidth, height: imageHeight });
    
      // Calculate initial crop for 4:3 ratio
      const targetRatio = 4 / 3;
      let cropWidth = Math.min(imageWidth, imageHeight * targetRatio);
      let cropHeight = cropWidth / targetRatio;
      
      // If the height exceeds image height after calculating width, adjust it
      if (cropHeight > imageHeight) {
        cropHeight = imageHeight;
        cropWidth = cropHeight * targetRatio;
      }
    
      const x = (imageWidth - cropWidth) / 2;
      const y = (imageHeight - cropHeight) / 2;
    
      setCrop({
        aspect: targetRatio,
        width: cropWidth,
        height: cropHeight,
        x: x,
        y: y,
      });
    };

    const [delete_stock_dialog, set_delete_stock_dialog] = React.useState(false);

    const delete_stock_dialog_open = () => {
      set_delete_stock_dialog(true);
      };
    
      const delete_stock_dialog_close = () => {
      set_delete_stock_dialog(false);
      };
  
  // eslint-disable-next-line
  let user_profile;
  const permitted_role = 'seller';
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = 'login';
    if (permitted_role === 'seller') login_link = 'access-forbidden'
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const id = props.match.params.id ? props.match.params.id : '';

  const [agree, set_agree] = useState(false);

  const [consent_photo, set_consent_photo] = useState(false);

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');

  const [upload_error_message, set_upload_error_message] = useState('');
  const [upload_success_message, set_upload_success_message] = useState('');

  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  // eslint-disable-next-line
  const [add_category, set_add_category] = useState('');
  // eslint-disable-next-line
  const [add_sub_category, set_add_sub_category] = useState('');
  const [add_product, set_add_product] = useState('');
  // eslint-disable-next-line
  const [add_description, set_add_description] = useState('');
  const [add_units, set_add_units] = useState('');
  const [add_quantity, set_add_quantity] = useState('');
  const [add_price, set_add_price] = useState('');

  const [deduct_quantity, set_deduct_quantity] = useState('');
  const [deduct_description, set_deduct_description] = useState('');

  // eslint-disable-next-line
  const [deduct_quantity_invalid, set_deduct_quantity_invalid] = useState(true);

  const [product_details, set_product_details] = useState([]);
  const [product_sales, set_product_sales] = useState([]);
  const [product_pictures, set_product_pictures] = useState([]);
  // eslint-disable-next-line
  const [all_categories, set_all_categories] = useState([]);
  // eslint-disable-next-line
  const [all_product_types, set_all_product_types] = useState([]);

  // eslint-disable-next-line
  const [display_sub_categories, set_display_sub_categories] = useState([]);
  // eslint-disable-next-line
  const [display_product_types, set_display_product_types] = useState([]);
  // eslint-disable-next-line
  const [display_units, set_display_units] = useState([]);  


  const uploadFile = async () => {
    set_success_message('');
    set_error_message('');
    set_upload_success_message('');
    set_upload_error_message('');


    set_action_loading(true);

    // Check if cropped image is ready
    if (!croppedImageFile) {
      set_upload_error_message('No cropped image file is ready for upload.');
      set_action_loading(false);
      return;
    }
  
  
    //   TODO: get these from config file 
    const token_string = localStorage.getItem('token');
    const formData = new FormData();        

    formData.append('file', croppedImageFile); // Use the cropped image file

    formData.append('api_key', API_KEY);
    formData.append('product_id', id);
    await axios.post(`${API_URL}/products/upload-image`, 
        formData, {
        headers: {
          Authorization: 'Bearer ' + token_string
        },
      }, {
        onUploadProgress: (ProgressEvent) => {
            let progress = Math.round(
            ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
            setProgess(progress);
        }
    }).then(res => {
        const { data } = res;
        if (data.success === true) {   
          set_upload_success_message('New image successfully added.'); 
        } else if(data.success === false) {
            set_upload_error_message(data.message);
        } else {
            set_upload_error_message(`An error occured uploading product image`);
        }
        // console.log(res);
        // getFile({ 
        //     name: res.data.name,
        //     path: 'http://127.0.0.1:4002/routes/public/' + res.data.path
        // });
    }).catch(
        // set_upload_error_message('An error occured uploading product image')
    );

    let get_product_details;
          try {
            get_product_details = await getProducts(id, 'seller', 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', 'quantity', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order' );
            if (get_product_details.success === false) {
              set_error_message(get_product_details.message);
            }
            else {
                // console.log(get_product_details);
                const { data } = get_product_details;
                set_product_pictures(data[0].pictures);

				
            }
          } catch (error) {
            set_error_message(`An error occured fetching categories: ${error.message} `);
          }
    set_action_loading(false);

    setIsCropModalOpen(false); // Close the modal after upload
    setSelectedImage(null); // Reset selected image after upload
    setCroppedImageFile(null); // Reset cropped image file after upload
    if (fileInputRef.current) fileInputRef.current.value = ''; // Reset file input after successful upload
	
}




  const load_page_data = async () => { 
     let get_all_categories;
     let get_product_details;
     let _product_details;

     try {
        get_product_details = await getProducts(id, 'seller', 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', 'quantity', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order' );
        if (get_product_details.success === false) {
          set_error_message(get_product_details.message);
        }
        else {
            // console.log(get_product_details);
            const { data } = get_product_details;
            _product_details = data[0];
            set_add_category(_product_details.category_id);
            set_add_sub_category(_product_details.sub_category_meta);
            set_add_product(_product_details.product_id);
            set_add_description(_product_details.description);
            set_add_units(_product_details.quantity_units);
            set_add_quantity(_product_details.quantity);
            set_add_price(_product_details.price);
            set_product_details(data[0]);
            // console.log('product sales', );
            set_product_sales(data[0].sales);
            set_product_pictures(data[0].pictures);
			
			set_product_name(data[0].product_name);
			set_quantity(data[0].quantity);
			set_quantity_units(data[0].quantity_units);
			set_created_at(formatDate(data[0].createdAt));
        }
      } catch (error) {
        set_error_message(`An error occured fetching categories: ${error.message} `);
      }

      try {
        get_all_categories = await getCategories('id', 'meta', 'active');
        if (get_all_categories.success === false) {
          set_error_message(get_all_categories.message);
        }
        else {
          const { data } = get_all_categories;
          set_all_categories(data);
        }
      } catch (error) {
        set_error_message(`An error occured fetching categories: ${error.message} `);
      }

    return;
  }

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }
 
  useEffect(() => {
    if (page_loaded === false ) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);


  /**
   * Removes a product image
   * @param {*} e 
   */
   const removeImage = async (remove_image) =>  {
    // e.preventDefault();

    set_success_message('');
    set_error_message('');
    set_upload_success_message('');
    set_upload_error_message('');

    set_action_loading(true);

    const update_data = {
      remove_image,
    };

    const response = await updateProduct(update_data, id, 'remove_image'); 
    if (response.success === false) {
        set_error_message(response.message); 
    } else {
      let get_product_details;
      let _product_details;
      try {
        get_product_details = await getProducts(id, 'seller', 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', 'quantity', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order' );
        if (get_product_details.success === false) {
          set_error_message(get_product_details.message);
        }
        else {
            // console.log(get_product_details);
            const { data } = get_product_details;
            _product_details = data[0];
            set_add_category(_product_details.category_id);
            set_add_sub_category(_product_details.sub_category_meta);
            set_add_product(_product_details.product_id);
            set_add_description(_product_details.description);
            set_add_units(_product_details.quantity_units);
            set_add_quantity(_product_details.quantity);
            set_add_price(_product_details.price);
            set_product_details(data[0]);
            // console.log('product sales', );
            set_product_sales(data[0].sales);
            set_product_pictures(data[0].pictures);
        }
      } catch (error) {
        set_error_message(`An error occured fetching categories: ${error.message} `);
      }

      set_success_message('Product details successfully updated');
    }
    set_action_loading(false);

    return; 
  }


  

  return (
    <>
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2><span class="green-text">Photos of your </span> <span class="orange-text"> {product_name}, added on {created_at}</span></h2>
        </div>       


        {!page_loaded  ? ( // so that only products will be loading at this time 
          <div>Loading ... </div>
        ) : (
      <>
        <div class="row">
          <div class="col-12">
              {error_message !== '' ? (
                  <div class="alert alert-danger" role="alert">                        
                    {error_message}
                  </div>
                ): ('')}
                {success_message !== '' ? (
                  <div class="alert alert-success" role="alert">                        
                      {success_message}
                  </div>
                ): ('')}     
            </div>
        </div>
        <div class="row">
            

            {/*
			    const [product_name, set_product_name] = useState('');
    const [quantity, set_quantity] = useState('');
    const [quantity_units, set_quantity_units] = useState('');
    const [created_at, set_created_at] = useState('');
			*/}
          
          <div class="col-md-12">
            <h2>Add new product photos</h2> (*only use original photos of your actual produce)
            <div class="row">
                <div class="col-6">
                  {upload_error_message !== '' ? (
                      <div class="alert alert-danger" role="alert">
                        
                        {upload_error_message}
                      </div>
                    ): ('')}
                    {upload_success_message !== '' ? (
                      <div class="alert alert-success" role="alert">
                        
                          {upload_success_message}
                      </div>
                    ): ('')}     
                </div>

                <div class="col-6 d-flex justify-content-end">
                  <Link to="/seller-products" class="btn btn-primary">Finish/List My Products</Link>
                </div>

                <div class="form-group col-4 my-3 text-center">
				{/* <label class="mb-2">Recommended image dimensions are 400*300</label>  */}
                    <input type="file" onChange={handleImageFileChange} ref={fileInputRef} />   

      
                    {isCropModalOpen && (
                      <div style={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '20px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                        }}>
                        <div style={{ marginBottom: '20px', color: '#fff', fontSize: '18px' }}>
                          Select the area of the photo to show for the product
                        </div>
                        <div style={{ width: '400px', height: 'auto', position: 'relative' }}>
                          <ReactCrop
                            src={selectedImage}
                            crop={crop}
                            onImageLoaded={onImageLoaded}
                            ruleOfThirds
                            onChange={(newCrop) => setCrop(newCrop)}
                            onComplete={onCropComplete}
                          />
                        </div>
                        <div className="d-flex align-items-center">
                             <Checkbox
                               attributesInput={{
                                 id: 'consent_photo',
                                 name: 'consent_photo',
                               }}
                               onBlur={() => {}} 
                               checked={consent_photo}
                               onChange={(e) => {
                                 set_consent_photo(e);
                               }}
                               labelHtml={
                                 <div style={{ color: '##fff', marginTop: '2px' }}>
                                   I confirm this is an original photo of the actual produce I am selling
                                 </div>
                               }
                               validationOption={{
                                 name: 'consent_photo',
                                 check: true,
                                 required: true,
                               }}
                             />	
                        </div>
                        {confirmationError && <p className="text-danger">{confirmationError}</p>}
                        <div className="button-group mt-3">
                          <button className="btn btn-primary me-2" onClick={handleImageOkClick}>Upload new image</button>
                          <button className="btn btn-secondary" onClick={handleImageCancelClick}>Cancel</button>
                        </div>    
                      </div>
                    )}				
                    {/* <div className="progessBar" style={{ width: progress }}>
                    {progress}
                    </div> */}
                    
                {/* {data.path && <img src={data.path} alt={data.name} />} */}
                </div>

            

  
                <div class="col-4"></div>
                <div class="col-4"></div>


            
            </div>
            <hr/>

            <div class="row">
                    {product_pictures.map((c) => (    
                                        <div class="col-md-3" key={c}>
                                          <div class="info-box product-box">
                                            <img src={`${IMAGES_URL}/${c}`} alt="Product" width="400" height="300" />
                                            <br/><br/>
                                            { c === 'defaults/default-image.png' ? (''):(
                                              <button onClick={() => { removeImage(c) }} class="btn btn-lg btn-success" >                   
                                                Remove this image
                                              </button>
                                            )}
                                            
                                          </div>
                                        </div>
                                    ))}
            </div>
          </div>

          
          {/* <div class="col-md-12">
              <h2>Notes</h2>
                TBI
              <hr/>
          </div> */}

        </div>
     </>
        ) }
        
      </div>
    </section>
    </>
  );
}
export default SellerProductImagesScreen;
