import React, { useState, useEffect } from "react";
import {
  getCategories,
  updateCategory,
  addSubCategory,
} from "../actions/categoryActions";
import { validate_multiple_roles } from "../validations";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "../components/spinner";

function ViewCategoryScreen(props) {
  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["admin", "technical-support"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [delete_category_dialog, set_delete_category_dialog] =
    React.useState(false);

  const delete_category_dialog_open = () => {
    set_delete_category_dialog(true);
  };

  const delete_category_dialog_close = () => {
    set_delete_category_dialog(false);
  };

  const [delete_sub_category_dialog, set_delete_sub_category_dialog] =
    React.useState(false);

  const delete_sub_category_dialog_open = () => {
    set_delete_sub_category_dialog(true);
  };

  const delete_sub_category_dialog_close = () => {
    set_delete_sub_category_dialog(false);
  };

  const [add_sub_category_dialog, set_add_sub_category_dialog] =
    React.useState(false);

  const add_sub_category_dialog_open = () => {
    set_add_sub_category_dialog(true);
  };

  const add_sub_category_dialog_close = () => {
    set_add_sub_category_dialog(false);
  };

  const id = props.match.params.id ? props.match.params.id : "";

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");

  const [
    update_subcategory_error_message,
    set_update_subcategory_error_message,
  ] = useState("");
  const [
    update_subcategory_success_message,
    set_update_subcategory_success_message,
  ] = useState("");

  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [add_category, set_add_category] = useState("");
  const [add_code, set_add_code] = useState("");
  const [add_priority, set_add_priority] = useState("");
  const [category_details, set_category_details] = useState({});
  const [sub_categories, set_sub_categories] = useState({});

  const [new_sub_category, set_new_sub_category] = useState("");
  const [new_sub_category_code, set_new_sub_category_code] = useState("");

  // eslint-disable-next-line
  const [add_category_invalid, set_add_category_invalid] = useState(true);
  // eslint-disable-next-line
  const [add_priority_invalid, set_add_priority_invalid] = useState(true);
  // eslint-disable-next-line
  const [add_code_invalid, set_add_code_invalid] = useState(true);
  // eslint-disable-next-line
  const [new_sub_category_invalid, set_new_sub_category_invalid] =
    useState(true);
  // eslint-disable-next-line
  const [new_sub_category_code_invalid, set_new_sub_category_code_invalid] =
    useState(true);

  const [remove_sub_category, set_remove_sub_category] = useState("");
  const [remove_sub_category_meta, set_remove_sub_category_meta] = useState("");

  /**
   * Deactivates a category
   *
   * @param {*} e
   */
  const deactivateCategory = async (e) => {
    e.preventDefault();
    set_success_message("");
    set_error_message("");
    set_update_subcategory_success_message("");
    set_update_subcategory_error_message("");

    set_action_loading(true);

    const update_data = {
      status: "deactivated",
    };

    const response = await updateCategory(update_data, id, "status_detail");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      props.history.push("/categories");
    }
    set_action_loading(false);

    return;
  };

  /**
   * Activates a category
   *
   * @param {*} e
   */
  const activateCategory = async (e) => {
    e.preventDefault();
    set_success_message("");
    set_error_message("");
    set_update_subcategory_success_message("");
    set_update_subcategory_error_message("");

    set_action_loading(true);

    const update_data = {
      status: "active",
    };

    const response = await updateCategory(update_data, id, "status_detail");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      props.history.push("/categories");
    }
    set_action_loading(false);

    return;
  };

  const load_page_data = async () => {
    let get_all_categories;

    try {
      get_all_categories = await getCategories(id, "meta", "active");
      if (get_all_categories.success === false) {
        set_error_message(get_all_categories.message);
      } else {
        const { data } = get_all_categories;
        set_add_category();
        set_add_category(data[0].category);
        set_add_code(data[0].code);
        set_add_priority(data[0].priority);
        set_category_details(data[0]);
        set_sub_categories(data[0].sub_categories);

        set_add_category_invalid(false);
        set_add_code_invalid(false);
        set_add_priority_invalid(false);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching category details: ${error.message} `
      );
    }

    return;
  };

  /**
   * Updates user profile
   * @param {*} e
   */
  const updateProduce = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");
    set_update_subcategory_success_message("");
    set_update_subcategory_error_message("");

    set_action_loading(true);

    const update_data = {
      category: add_category,
      code: add_code,
      priority: add_priority,
    };

    const response = await updateCategory(update_data, id, "basic_detail");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      let get_all_categories;
      try {
        get_all_categories = await getCategories(id, "meta", "active");
        if (get_all_categories.success === false) {
          set_error_message(get_all_categories.message);
        } else {
          const { data } = get_all_categories;
          set_add_category();
          set_add_category(data[0].category);
          set_add_code(data[0].code);

          set_add_priority(data[0].priority);
          set_category_details(data[0]);
          set_sub_categories(data[0].sub_categories);
        }
      } catch (error) {
        set_error_message(
          `An error occured fetching category details: ${error.message} `
        );
      }

      set_success_message("Category details successfully updated");
    }
    set_action_loading(false);

    return;
  };

  /**
   * Deletes a subcategory
   * @param {*} e
   */
  const deleteSubCategory = async (e) => {
    e.preventDefault();

    set_action_loading(true);

    set_success_message("");
    set_error_message("");
    set_update_subcategory_success_message("");
    set_update_subcategory_error_message("");
    delete_sub_category_dialog_close();

    let new_sub_categories = [];

    for (let i = 0; i < sub_categories.length; i++) {
      if (sub_categories[i].sub_category_meta !== remove_sub_category_meta) {
        new_sub_categories.push(sub_categories[i]);
      }
    }

    const update_data = {
      sub_categories: new_sub_categories,
    };

    const response = await updateCategory(
      update_data,
      id,
      "sub_categories_detail"
    );
    if (response.success === false) {
      set_update_subcategory_error_message(response.message);
    } else {
      set_update_subcategory_success_message(
        "Subcategory successfully deleted"
      );
      try {
        await load_page_data();
      } catch (error) {
        set_error_message(
          `An error occured fetching category details after update: ${error.message} `
        );
      }
    }
    set_action_loading(false);

    return;
  };

  /**
   * Deletes a subcategory
   * @param {*} e
   */
  const addNewSubCategory = async (e) => {
    e.preventDefault();

    set_action_loading(true);

    set_success_message("");
    set_error_message("");
    set_update_subcategory_success_message("");
    set_update_subcategory_error_message("");
    add_sub_category_dialog_close();

    // let new_sub_categories = sub_categories;

    // let new_sub_category_meta = new_sub_category;
    // new_sub_category_meta = new_sub_category_meta.replace(/ /g, '-');
    // new_sub_category_meta= new_sub_category_meta.replace('/', '-');
    // new_sub_category_meta = new_sub_category_meta.toLowerCase();

    // new_sub_categories.push(
    //   {
    //     code: new_sub_category_code,
    //     sub_category: new_sub_category,
    //     sub_category_meta: new_sub_category_meta,
    //     signature: '',
    //   }
    // );

    const update_data = {
      sub_category: new_sub_category,
      category_id: id,
    };

    const response = await addSubCategory(update_data);
    if (response.success === false) {
      set_update_subcategory_error_message(response.message);
    } else {
      set_update_subcategory_success_message("Subcategory successfully added");
      try {
        await load_page_data();
      } catch (error) {
        set_error_message(
          `An error occured fetching category details after update: ${error.message} `
        );
      }
    }
    set_action_loading(false);

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">Viewing</span>{" "}
              <span class="orange-text">{category_details.category}</span>
            </h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== "" ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : (
                    ""
                  )}
                  {success_message !== "" ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h2>Basic details</h2>
                  <form onSubmit={updateProduce}>
                    <div class="row">
                      <div class="form-group col-md-4 my-3">
                        <label for="">Category name</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "categoryName",
                            name: "Category",
                            type: "text",
                            placeholder: "",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_add_category_invalid(res)
                          }
                          value={add_category}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(add_category, e) => {
                            set_add_category(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "Category",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            //   min: 0.01,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="">Category code</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "categoryCode",
                            name: "Code",
                            type: "text",
                            placeholder: "",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_add_code_invalid(res)
                          }
                          value={add_code}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(add_code, e) => {
                            set_add_code(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          validationOption={{
                            name: "Code",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            //   min: 0.01,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="">Category priority</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Priority",
                            name: "Priority",
                            type: "number",
                            placeholder: "",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_add_priority_invalid(res)
                          }
                          value={add_priority}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(add_priority, e) => {
                            set_add_priority(e.target.value);
                          }}
                          onBlur={(e) => {
                            // console.log(e);
                          }}
                          validationOption={{
                            name: "Priority",
                            check: true,
                            required: true,
                            type: "number", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            min: 1,
                          }}
                        />
                      </div>
                    </div>

                    <div class="form-group col-12 my-3 text-center">
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        disabled={
                          action_loading ||
                          add_priority_invalid ||
                          add_code_invalid ||
                          add_category_invalid
                        }
                        // check invalids
                      >
                        <i class="glyphicon glyphicon-plus"></i> Update category
                        details
                      </button>
                    </div>
                  </form>

                  <hr />
                </div>

                <div class="col-md-12">
                  <h2>Subcategories</h2>
                  <div class="row">
                    <div class="col-12">
                      {update_subcategory_error_message !== "" ? (
                        <div class="alert alert-danger" role="alert">
                          {update_subcategory_error_message}
                        </div>
                      ) : (
                        ""
                      )}
                      {update_subcategory_success_message !== "" ? (
                        <div class="alert alert-success" role="alert">
                          {update_subcategory_success_message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-12">
                      <div class="row">
                        {sub_categories.length > 0 ? (
                          sub_categories.map((c) => (
                            <div class="col-md-3" key={c}>
                              <div class="info-box product-box">
                                {c.sub_category}
                                <br />
                                <button
                                  class="btn btn-danger btn-sm"
                                  disabled={action_loading}
                                  onClick={() => {
                                    set_remove_sub_category(c.sub_category);
                                    set_remove_sub_category_meta(
                                      c.sub_category_meta
                                    );
                                    delete_sub_category_dialog_open();
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div class="col-md-3">No subcategories found.</div>
                        )}

                        <div class="col-md-3">
                          <div class="info-box product-box">
                            <button
                              class="btn btn-primary btn-lg"
                              disabled={action_loading}
                              onClick={() => {
                                add_sub_category_dialog_open();
                              }}
                            >
                              Add new
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  {category_details.status !== "active" ? (
                    <div class="form-group col-12 my-3 text-left">
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={activateCategory}
                        disabled={action_loading}
                      >
                        {!action_loading ? (
                          "Activate category"
                        ) : (
                          <>
                            <div
                              class="spinner-border text-orange"
                              role="status"
                            ></div>
                            <Spinner />
                          </>
                        )}
                      </button>
                    </div>
                  ) : (
                    <div class="form-group col-12 my-3 text-left">
                      <button
                        class="btn btn-lg btn-danger"
                        type="submit"
                        onClick={delete_category_dialog_open}
                        disabled={action_loading}
                      >
                        {!action_loading ? (
                          "Deactivate category"
                        ) : (
                          <>
                            <div
                              class="spinner-border text-orange"
                              role="status"
                            ></div>
                            <Spinner />
                          </>
                        )}
                      </button>
                    </div>
                  )}

                  <Dialog
                    open={delete_category_dialog}
                    onClose={delete_category_dialog_close}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Are you sure you want to deactivate this category?
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        This action will not entirely delete the category but
                        will make the category become inactive and the product
                        will no longer show when adding products.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={delete_category_dialog_close}
                        color="primary"
                      >
                        Cancel
                      </button>

                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={deactivateCategory}
                        color="primary"
                      >
                        Yes, I'm sure
                      </button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    open={delete_sub_category_dialog}
                    onClose={delete_sub_category_dialog_close}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Are you sure you want to delete the{" "}
                      <b>{remove_sub_category}</b> subcategory{" "}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        This action will delete the subcategory and you cannot
                        undo this action.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={delete_sub_category_dialog_close}
                        color="primary"
                      >
                        Cancel
                      </button>

                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={deleteSubCategory}
                        color="primary"
                      >
                        Yes, I'm sure
                      </button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    open={add_sub_category_dialog}
                    onClose={add_sub_category_dialog_close}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Add new subcategory{" "}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <div class="row">
                          <div class="form-group col-md-12 my-3">
                            <label for="">Subcategory name</label>
                            <Textbox
                              attributesWrapper={{}}
                              attributesInput={{
                                id: "subCategoryName",
                                name: "Sub category",
                                type: "text",
                                placeholder: "",
                              }}
                              // validate={validate}
                              validationCallback={(res) =>
                                set_new_sub_category_invalid(res)
                              }
                              value={new_sub_category}
                              disabled={false}
                              classNameInput=""
                              classNameWrapper=""
                              classNameContainer=""
                              customStyleInput={{}}
                              customStyleWrapper={{}}
                              customStyleContainer={{}}
                              onChange={(new_sub_category, e) => {
                                set_new_sub_category(e.target.value);
                              }}
                              onBlur={(e) => {
                                console.log(e);
                              }}
                              // onFocus={(e) => {console.log(e);}}
                              // onClick={(e) => {console.log(e);}}
                              validationOption={{
                                name: "Category",
                                check: true,
                                required: true,
                                type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                                // showMsg: true,
                                //   min: 0.01,
                                // max: 10,
                                // length: 2,
                                // compare: '3',
                                // reg: /^\d{12}|\d{14}$/,
                                // regMsg: 'Invalid phone number',
                                // locale: 'en-US',
                                // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                                // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                                // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                                //   if (res != 'milk') {
                                //     return 'Name cannot be other things but milk';
                                //   }
                                //   return true;
                                // }
                              }}
                            />
                          </div>

                          <div class="form-group col-md-12 my-3">
                            <label for="">Sub category code</label>
                            <Textbox
                              attributesWrapper={{}}
                              attributesInput={{
                                id: "subCategoryCode",
                                name: "Sub category code",
                                type: "text",
                                placeholder: "",
                              }}
                              // validate={validate}
                              validationCallback={(res) =>
                                set_new_sub_category_code_invalid(res)
                              }
                              value={new_sub_category_code}
                              disabled={false}
                              classNameInput=""
                              classNameWrapper=""
                              classNameContainer=""
                              customStyleInput={{}}
                              customStyleWrapper={{}}
                              customStyleContainer={{}}
                              onChange={(new_sub_category_code, e) => {
                                set_new_sub_category_code(e.target.value);
                              }}
                              onBlur={(e) => {
                                console.log(e);
                              }}
                              validationOption={{
                                name: "Sub category code",
                                check: true,
                                required: true,
                                type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                                // showMsg: true,
                                //   min: 0.01,
                                // max: 10,
                                // length: 2,
                                // compare: '3',
                                // reg: /^\d{12}|\d{14}$/,
                                // regMsg: 'Invalid phone number',
                                // locale: 'en-US',
                                // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                                // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                                // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                                //   if (res != 'milk') {
                                //     return 'Name cannot be other things but milk';
                                //   }
                                //   return true;
                                // }
                              }}
                            />
                          </div>
                        </div>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={add_sub_category_dialog_close}
                        color="primary"
                      >
                        Cancel
                      </button>

                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={addNewSubCategory}
                        color="primary"
                      >
                        Add sub category
                      </button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
export default ViewCategoryScreen;
