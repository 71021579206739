import React, { createContext, useState } from "react";

const ProductsContext = createContext({
  productName: "",
  description: "",
  totalPrice: 0,
  paymentOptionActive: false,
  loaderActive: false,
  setProductName: () => {},
  setDescription: () => {},
  setTotalPrice: () => {},
  setPaymentOptionActive: () => {},
  setLoaderActive: () => {},
});

const ProductsProvider = ({ children }) => {
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentOptionActive, setPaymentOptionActive] = useState(false);
  const [loaderActive, setLoaderActive] = useState(false);

  return (
    <ProductsContext.Provider
      value={{
        productName,
        description,
        totalPrice,
        paymentOptionActive,
        loaderActive,
        setProductName,
        setDescription,
        setTotalPrice,
        setPaymentOptionActive,
        setLoaderActive,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export { ProductsContext, ProductsProvider };
