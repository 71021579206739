import axios from "axios";
import { API_URL } from "../config";

export const checkout = async (productName, description, totalPrice) => {
  try {
    const response = await axios.post(`${API_URL}/dpo/create-payment-token`, {
      productName,
      description,
      totalPrice,
    });

    return response.data;
  } catch (error) {}
};
