import React, { useState, useEffect, useRef } from "react";
import { validate_multiple_roles } from "../validations";
import { updateUserDetails } from "../actions/userActions";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import axios from "axios";
import { APP_URL, API_URL, API_KEY, token_string } from "../config";
import { Link } from "react-router-dom";
import {
  getVerifications,
  updateVerification,
} from "../actions/verificationActions";
import Spinner from "../components/spinner";

function AppealScreen(props) {
  let user_profile;
  const permitted_roles = ["seller"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const id = props.match.params.id ? props.match.params.id : "";

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");
  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [reason_for_appeal, set_reason_for_appeal] = useState("");

  const [supporting_documents, set_supporting_documents] = useState("");

  const el = useRef(); // accesing input element

  const new_supporting_documents = (e) => {
    set_supporting_documents(e.target.files[0]);
  };

  /**
   *
   * @returns updates a client profile picture
   */
  // eslint-disable-next-line
  const update_verification_details = async (e) => {
    e.preventDefault();
    set_success_message("");
    set_error_message("");

    set_action_loading(true);
    try {
      if (token_string === null) {
        token_string = localStorage.getItem("token");
      }

      const formData = new FormData();
      formData.append("api_key", API_KEY);
      formData.append("supporting_documents", supporting_documents);
      formData.append("reason_for_appeal", reason_for_appeal);

      formData.append("update_key", "basic_detail");
      formData.append("id", id);
      const { data } = await axios.post(
        `${API_URL}/suspensions/appeal`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token_string,
          },
        }
      );

      // console.log('data', data)
      if (data.success === true) {
        set_success_message("Update successful");
        props.history.push(`/seller-dashboard`);
      } else if (data.success === false) {
        set_error_message(data.message);
      }
    } catch (error) {
      console.log("error", error.message);
      set_error_message("An error occured updating verification details");
    }

    set_action_loading(false);
    return;
  };

  const load_page_data = async () => {
    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">Appeal</span>{" "}
              <span class="orange-text"></span>
            </h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== "" ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : (
                    ""
                  )}
                  {success_message !== "" ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <hr />
                  <form onSubmit={update_verification_details}>
                    <div class="row">
                      <div class="form-group col-md-12 my-3">
                        <label>Reason for appeal</label>
                        <textarea
                          class="form-control"
                          onChange={(e) =>
                            set_reason_for_appeal(e.target.value)
                          }
                        ></textarea>
                      </div>

                      <div class="form-group col-md-12 my-3">
                        <label for="first_name">Supporting evidence</label>
                        <input
                          class="form-control"
                          type="file"
                          ref={el}
                          onChange={new_supporting_documents}
                        />
                      </div>
                    </div>

                    <div class="form-group col-12 my-3">
                      <br />
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        disabled={action_loading}
                        // check invalids
                      >
                        <i class="glyphicon glyphicon-ok-sign"></i> Save
                      </button>
                      {/* <button class="btn btn-lg" type="reset"><i class="glyphicon glyphicon-repeat"></i> Reset</button> */}
                    </div>
                  </form>

                  {/* <hr /> */}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
export default AppealScreen;
