import {
  CATEGORIES_LIST_REQUEST,
  CATEGORIES_LIST_SUCCESS,
  CATEGORIES_LIST_FAIL,
  CATEGORIES_DETAILS_REQUEST,
  CATEGORIES_DETAILS_SUCCESS,
  CATEGORIES_DETAILS_FAIL,
  CATEGORIES_SAVE_REQUEST,
  CATEGORIES_SAVE_SUCCESS,
  CATEGORIES_SAVE_FAIL,
  CATEGORIES_DELETE_SUCCESS,
  CATEGORIES_DELETE_FAIL,
  CATEGORIES_DELETE_REQUEST,
} from "../constants/categoryConstants";

import axios from "axios";
import Axios from "axios";

import { API_URL, API_KEY } from "../config";

let token_string = localStorage.getItem("token");

const listCategories =
  (category = "", searchKeyword = "", sortOrder = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: CATEGORIES_LIST_REQUEST });
      const { data } = await axios.get(
        API_URL +
          "/categories?category=" +
          category +
          "&searchKeyword=" +
          searchKeyword +
          "&sortOrder=" +
          sortOrder
      );
      dispatch({ type: CATEGORIES_LIST_SUCCESS, payload: data.data });
    } catch (error) {
      console.error(error.message);
      dispatch({
        type: CATEGORIES_LIST_FAIL,
        payload: "An error occured fetching categories",
      });
    }
  };

const saveCategory = (category) => async (dispatch, getState) => {
  try {
    dispatch({ type: CATEGORIES_SAVE_REQUEST, payload: category });
    const {
      userSignin: { userInfo },
    } = getState();
    if (!category._id) {
      const { data } = await Axios.post("/api/categories", category, {
        headers: {
          Authorization: "Bearer " + userInfo.token,
        },
      });
      dispatch({ type: CATEGORIES_SAVE_SUCCESS, payload: data });
    } else {
      const { data } = await Axios.put(
        "/api/categories/" + category._id,
        category,
        {
          headers: {
            Authorization: "Bearer " + userInfo.token,
          },
        }
      );
      dispatch({ type: CATEGORIES_SAVE_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({ type: CATEGORIES_SAVE_FAIL, payload: error.message });
  }
};

const detailsCategory = (categoryId) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORIES_DETAILS_REQUEST, payload: categoryId });
    const { data } = await axios.get("/api/categories/" + categoryId);
    dispatch({ type: CATEGORIES_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CATEGORIES_DETAILS_FAIL, payload: error.message });
  }
};

const deleteCategory = (categoryId) => async (dispatch, getState) => {
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    dispatch({ type: CATEGORIES_DELETE_REQUEST, payload: categoryId });
    const { data } = await axios.delete("/api/categories/" + categoryId, {
      headers: {
        Authorization: "Bearer " + userInfo.token,
      },
    });
    dispatch({ type: CATEGORIES_DELETE_SUCCESS, payload: data, success: true });
  } catch (error) {
    dispatch({ type: CATEGORIES_DELETE_FAIL, payload: error.message });
  }
};

const getCategories = async (id, meta, status) => {
  try {
    const { data } = await Axios.get(
      `${API_URL}/categories?id=${id}&meta=${meta}&status=${status}`
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: "Failed to retrieve categories. Please reload page",
    };
  }
};

const getCategoriesV2 = async (id, meta, status, featured, limit, order) => {
  try {
    const { data } = await Axios.get(
      `${API_URL}/categories?id=${id}&meta=${meta}&status=${status}&featured=${featured}&limit=${limit}&order=${order}`
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: "Failed to retrieve categories. Please reload page",
    };
  }
};

const getProductTypes = async (
  id,
  meta,
  status,
  search_term,
  category_id,
  sub_category_meta
) => {
  try {
    const { data } = await Axios.get(
      `${API_URL}/product-types?id=${id}&meta=${meta}&status=${status}&search_term=${search_term}&category_id=${category_id}&sub_category_meta=${sub_category_meta}`
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: "Failed to retrieve product types. Please reload page",
    };
  }
};

/**
 * Adds a new category
 * @param {Object} add_data
 */
const addCategory = async (add_data) => {
  if (token_string === null) {
    token_string = localStorage.getItem("token");
  }
  try {
    const { data } = await Axios.post(
      `${API_URL}/categories`,
      {
        api_key: API_KEY,
        ...add_data,
      },
      {
        headers: {
          Authorization: "Bearer " + token_string,
        },
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message:
        "Sorry we could not add product. Please try again. Please try again",
    };
  }
};

/**
 * Adds a new sub category
 * @param {Object} add_data
 */
const addSubCategory = async (add_data) => {
  if (token_string === null) {
    token_string = localStorage.getItem("token");
  }
  try {
    const { data } = await Axios.post(
      `${API_URL}/sub-categories`,
      {
        api_key: API_KEY,
        ...add_data,
      },
      {
        headers: {
          Authorization: "Bearer " + token_string,
        },
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message:
        "Sorry we could not add sub category. Please try again. Please try again",
    };
  }
};

/**
 * Updates category details
 * @param {Object} update_data
 * @param {String} id
 * @param {String} update_key
 */
const updateCategory = async (update_data, id, update_key) => {
  if (token_string === null) {
    token_string = localStorage.getItem("token");
  }
  try {
    const { data } = await Axios.patch(
      `${API_URL}/categories`,
      {
        api_key: API_KEY,
        update_data,
        id,
        update_key,
      },
      {
        headers: {
          Authorization: "Bearer " + token_string,
        },
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: `Sorry we could not update category details. ${error.message}`,
    };
  }
};

/**
 * Updates category details
 * @param {Object} update_data
 * @param {String} id
 * @param {String} update_key
 */
const updateProductType = async (update_data, id, update_key) => {
  if (token_string === null) {
    token_string = localStorage.getItem("token");
  }
  try {
    const { data } = await Axios.patch(
      `${API_URL}/product-types`,
      {
        api_key: API_KEY,
        update_data,
        id,
        update_key,
      },
      {
        headers: {
          Authorization: "Bearer " + token_string,
        },
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: `Sorry we could not update product type details. ${error.message}`,
    };
  }
};

/**
 * Adds a new product type
 * @param {Object} add_data
 */
const addProductType = async (add_data) => {
  if (token_string === null) {
    token_string = localStorage.getItem("token");
  }
  try {
    const { data } = await Axios.post(
      `${API_URL}/product-types`,
      {
        api_key: API_KEY,
        ...add_data,
      },
      {
        headers: {
          Authorization: "Bearer " + token_string,
        },
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: "Sorry we could not add product type. Please try again",
    };
  }
};

/**
 * Adds a new city
 * @param {String} city
 */
const addCity = async (city) => {
  if (token_string === null) {
    token_string = localStorage.getItem("token");
  }
  try {
    const { data } = await Axios.post(
      `${API_URL}/cities`,
      {
        api_key: API_KEY,
        city,
      },
      {
        headers: {
          Authorization: "Bearer " + token_string,
        },
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: "Sorry we could not add city. Please try again",
    };
  }
};

/**
 * Updates city details
 * @param {Object} update_data
 * @param {String} id
 * @param {String} update_key
 */
const removeCity = async (id) => {
  if (token_string === null) {
    token_string = localStorage.getItem("token");
  }
  try {
    const { data } = await Axios.patch(
      `${API_URL}/cities`,
      {
        api_key: API_KEY,
        id,
      },
      {
        headers: {
          Authorization: "Bearer " + token_string,
        },
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: `Sorry we could not remove city. ${error.message}`,
    };
  }
};

const getCities = async (id, meta, status, country_meta) => {
  try {
    const { data } = await Axios.get(
      `${API_URL}/cities?id=${id}&meta=${meta}&status=${status}&country_meta=${country_meta}`
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: "Failed to retrieve cities. Please reload page",
    };
  }
};

const getCountries = async (id, meta, status, name) => {
  try {
    const { data } = await Axios.get(
      `${API_URL}/countries?id=${id}&meta=${meta}&status=${status}&name=${name}`
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: "Failed to retrieve countries. Please reload page",
    };
  }
};

const getSuburbs = async (id, meta, city, status) => {
  try {
    const { data } = await Axios.get(
      `${API_URL}/suburbs?id=${id}&meta=${meta}&status=${status}`
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: "Failed to retrieve countries. Please reload page",
    };
  }
};

const getProductDemand = async () => {
  if (token_string === null) {
    token_string = localStorage.getItem("token");
  }
  try {
    const { data } = await axios.get(
      `${API_URL}/product-types/demand-statistics-for-sellers`,
      {
        headers: {
          Authorization: "Bearer " + token_string,
        },
      }
    );
    return data;
  } catch (error) {
    console.erro("error", error.message);
    return {
      success: false,
      message: "Failed to retrieve demand statistics",
    };
  }
};

const getProductDemandByCity = async (id) => {
  if (token_string === null) {
    token_string = localStorage.getItem("token");
  }
  try {
    const { data } = await axios.get(
      `${API_URL}/product-types/demand-statistics-by-city-for-sellers?id=${id}`,
      {
        headers: {
          Authorization: "Bearer " + token_string,
        },
      }
    );
    return data;
  } catch (error) {
    console.error("error", error.message);
    return {
      success: false,
      message: "Failed to retrieve demand statistics",
    };
  }
};

export {
  addCategory,
  addCity,
  addSubCategory,
  addProductType,
  listCategories,
  detailsCategory,
  getCategories,
  getCategoriesV2,
  getCities,
  getCountries,
  getSuburbs,
  getProductTypes,
  saveCategory,
  deleteCategory,
  removeCity,
  updateCategory,
  updateProductType,
  getProductDemand,
  getProductDemandByCity,
};
