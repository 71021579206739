import React, { useState, useEffect, useRef } from "react";
import { getCities, addCity, removeCity } from "../actions/categoryActions";
import { validate_multiple_roles } from "../validations";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import { APP_URL, API_URL, API_KEY, token_string } from "../config";
import Spinner from "../components/spinner";

function CountriesUploadScreen(props) {
  const [add_city_dialog, set_add_city_dialog] = React.useState(false);

  const add_city_dialog_open = () => {
    set_add_city_dialog(true);
  };

  const add_city_dialog_close = () => {
    set_add_city_dialog(false);
  };

  const [remove_city_dialog, set_remove_city_dialog] = React.useState(false);

  const remove_city_dialog_open = () => {
    set_remove_city_dialog(true);
  };

  const remove_city_dialog_close = () => {
    set_remove_city_dialog(false);
  };

  const [file, setFile] = useState(""); // storing the uploaded file
  // eslint-disable-next-line
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element
  const handleChange = (e) => {
    setProgess(0);
    const file = e.target.files[0]; // accessing file
    setFile(file); // storing file
  };

  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["admin", "technical-support"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const id = props.match.params.id ? props.match.params.id : "";

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");

  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [stage, set_stage] = useState("1");
  const [title, set_title] = useState("");

  const [add_city, set_add_city] = useState("");

  const [remove_city, set_remove_city] = useState("");

  const [remove_city_name, set_remove_city_name] = useState("");

  const [cities, set_cities] = useState([]);

  // eslint-disable-next-line
  const [add_city_invalid, set_add_city_invalid] = useState(true);

  const load_page_data = async () => {
    let get_cities;

    try {
      get_cities = await getCities("id", "meta", "status", "country_meta");
      if (get_cities.success) {
        const { data } = get_cities;
        set_cities(data);
      } else {
        set_error_message("No cities found");
      }
    } catch (error) {
      set_error_message(`An error occured fetching cities: ${error.message} `);
    }

    return;
  };

  /**
   * Removes a city
   *
   */
  const removeCityDetails = async () => {
    // e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const response = await removeCity(remove_city);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      let get_cities;

      try {
        get_cities = await getCities("id", "meta", "status", "country_meta");
        if (get_cities.success) {
          const { data } = get_cities;
          set_cities(data);
        } else {
          set_error_message("No cities found");
        }
      } catch (error) {
        set_error_message(
          `An error occured fetching cities: ${error.message} `
        );
      }
      set_add_city("");
      set_add_city_invalid(true);
      set_success_message("City successfully removed");
    }
    remove_city_dialog_close();
    set_action_loading(false);

    return;
  };

  /**
   * Updates user profile
   * @param {*} e
   */
  const addNewCity = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const response = await addCity(add_city);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      let get_cities;

      try {
        get_cities = await getCities("id", "meta", "status", "country_meta");
        if (get_cities.success) {
          const { data } = get_cities;
          set_cities(data);
        } else {
          set_error_message("No cities found");
        }
      } catch (error) {
        set_error_message(
          `An error occured fetching cities: ${error.message} `
        );
      }
      set_add_city("");
      set_add_city_invalid(true);
      set_success_message("New city successfully added.");
    }
    add_city_dialog_close();
    set_action_loading(false);

    return;
  };

  const check_distance_between_cities = async (city1, city2) => {
    return "";
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  /**
   *
   * @returns updates a client profile picture
   */
  // eslint-disable-next-line
  const uploadCSV = async (e) => {
    e.preventDefault();
    let token_string = await localStorage.getItem("token");

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const formData = new FormData();
    formData.append("file", file); // appending file
    formData.append("api_key", API_KEY);
    formData.append("title", title);

    try {
      const { status, data } = await axios.post(
        `${API_URL}/setup/upload-csv`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token_string,
          },
        }
      );

      // console.log(data)

      if (data.success) {
        set_success_message(data.message);

        if (title === "countries") {
          set_stage("add-cities");
          set_title("cities");
        } else if (title === "cities") {
          set_stage("add-suburbs");
          set_title("suburbs");
        } else if (title === "suburbs") {
          set_stage("run-areas-csv-file");
          set_title("suburbs-distance");
        } else if (title === "cities-distance") {
          set_stage("upload-suburbs-csv-file");
          set_title("suburbs-distance");
        } else if (title === "suburbs-distance") {
          set_stage("run-distances-csv-files");
          set_title("suburbs-distance");
        }
      } else {
        set_error_message(data.message);
      }
    } catch (error) {
      console.log("An error occured", error.message);
      set_error_message("An error occured");
    }

    set_action_loading(false);
    return;
  };

  const update_areas_in_db = async (e) => {
    e.preventDefault();
    let token_string = await localStorage.getItem("token");

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const formData = new FormData();
    formData.append("api_key", API_KEY);

    try {
      const { status, data } = await axios.post(
        `${API_URL}/setup/update-areas-in-db`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token_string,
          },
        }
      );

      // console.log(data)

      if (data.success) {
        set_success_message(data.message);
        set_stage("download-areas-csv-file");
      } else {
        set_error_message(data.message);
      }
    } catch (error) {
      console.log("An error occured", error.message);
      set_error_message("An error occured");
    }

    set_action_loading(false);
    return;
  };

  const update_distances_in_db = async (e) => {
    e.preventDefault();
    let token_string = await localStorage.getItem("token");

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const formData = new FormData();
    formData.append("api_key", API_KEY);

    try {
      const { status, data } = await axios.post(
        `${API_URL}/setup/update-distances-in-db`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token_string,
          },
        }
      );

      // console.log(data)

      if (data.success) {
        set_success_message(data.message);
        set_stage("done");
      } else {
        set_error_message(data.message);
      }
    } catch (error) {
      console.log("An error occured", error.message);
      set_error_message("An error occured");
    }

    set_action_loading(false);
    return;
  };

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">
                Countries, Cities &amp; Suburbs Configuration
              </span>{" "}
              <span class="orange-text"></span>
            </h2>
          </div>

          <div class="row">
            <div class="col-12">
              {error_message !== "" ? (
                <div class="alert alert-danger" role="alert">
                  {error_message}
                </div>
              ) : (
                ""
              )}
              {success_message !== "" ? (
                <div class="alert alert-success" role="alert">
                  {success_message}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {stage === "1" ? (
            <>
              <div class="row mt-5">
                <div class="col-md-12 text-center">
                  {/* <h2>Adding {title}</h2> */}
                  <p class="alert alert-info">
                    This section allows you to add countries, cities and
                    suburbs, and specify the distance between cities and
                    suburbs.{" "}
                  </p>
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("add-countries");
                      set_title("countries");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Next"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>{/* Stage 1 ends  */}</>
          )}

          {stage === "add-countries" ? (
            <>
              <div class="row mt-5">
                <div class="col-md-12 text-center">
                  <h2>Adding {title}</h2>
                  <p class="alert alert-info">
                    Upload the CSV file with the list of your countries with the
                    columns outlined in the table below
                  </p>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tr>
                        <th>Country</th>
                      </tr>
                    </table>
                  </div>
                  <input
                    type="file"
                    ref={el}
                    onChange={handleChange}
                    class="text-center center-block file-upload form-control m-5"
                  />
                  <form onSubmit={uploadCSV} class="m-1">
                    <button
                      class="btn btn-primary"
                      type="submit"
                      disabled={action_loading}
                    >
                      {!action_loading ? (
                        "Upload countries csv file"
                      ) : (
                        <>
                          <div
                            class="spinner-border text-orange"
                            role="status"
                          ></div>
                          <Spinner />
                        </>
                      )}
                    </button>
                  </form>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("1");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Back"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("add-cities");
                      set_title("cities");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Skip"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>{/* Add countries ends  */}</>
          )}

          {stage === "add-cities" ? (
            <>
              <div class="row mt-5">
                <div class="col-md-12 text-center">
                  <h2>Adding {title}</h2>
                  <p class="alert alert-info">
                    Upload the CSV file with the list of your cities with the
                    columns outlined in the table below
                  </p>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tr>
                        <th>City</th>
                        <th>Country</th>
                      </tr>
                    </table>
                  </div>
                  <input
                    type="file"
                    ref={el}
                    onChange={handleChange}
                    class="text-center center-block file-upload form-control m-5"
                  />
                  <form onSubmit={uploadCSV} class="m-1">
                    <button
                      class="btn btn-primary"
                      type="submit"
                      // onClick={() => {
                      //   set_stage('add-countries');
                      //   set_title('countries');
                      // }
                      // }
                      disabled={action_loading}
                    >
                      {!action_loading ? (
                        "Upload cities csv file"
                      ) : (
                        <>
                          <div
                            class="spinner-border text-orange"
                            role="status"
                          ></div>
                          &nbsp;Please wait
                        </>
                      )}
                    </button>
                  </form>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("add-countries");
                      set_title("countries");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Back"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("add-suburbs");
                      set_title("suburbs");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Skip"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>{/* Add cities ends  */}</>
          )}

          {stage === "add-suburbs" ? (
            <>
              <div class="row mt-5">
                <div class="col-md-12 text-center">
                  <h2>Adding {title}</h2>
                  <p class="alert alert-info">
                    Upload the CSV file with the list of your suburbs with the
                    columns outlined in the table below
                  </p>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tr>
                        <th>Suburb</th>
                        <th>City</th>
                      </tr>
                    </table>
                  </div>
                  <input
                    type="file"
                    ref={el}
                    onChange={handleChange}
                    class="text-center center-block file-upload form-control m-5"
                  />
                  <form onSubmit={uploadCSV} class="m-1">
                    <button
                      class="btn btn-primary"
                      type="submit"
                      // onClick={() => {
                      //   set_stage('add-countries');
                      //   set_title('countries');
                      // }
                      // }
                      disabled={action_loading}
                    >
                      {!action_loading ? (
                        "Upload suburbs csv file"
                      ) : (
                        <>
                          <div
                            class="spinner-border text-orange"
                            role="status"
                          ></div>
                          &nbsp;Please wait
                        </>
                      )}
                    </button>
                  </form>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("add_cities");
                      set_title("cities");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Back"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("run-areas-csv-file");
                      set_title("run-areas-csv-file");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Skip"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>{/* Add suburbs ends  */}</>
          )}

          {stage === "run-areas-csv-file" ? (
            <>
              <div class="row mt-5">
                <div class="col-md-12 text-center">
                  <h2>Update areas in database</h2>
                  <p class="alert alert-info">
                    This section allows you to update the system database with
                    the countries, cities &amp; suburbs in the uploaded CSV
                    files.{" "}
                  </p>
                  <form onSubmit={update_areas_in_db} class="m-1">
                    <button
                      class="btn btn-primary"
                      type="submit"
                      // onClick={() => {
                      //   set_stage('add-countries');
                      //   set_title('countries');
                      // }
                      // }
                      disabled={action_loading}
                    >
                      {!action_loading ? (
                        "Update database with uploaded countries, cities & suburbs"
                      ) : (
                        <>
                          <div
                            class="spinner-border text-orange"
                            role="status"
                          ></div>
                          &nbsp;Please wait
                        </>
                      )}
                    </button>
                  </form>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("add-suburbs");
                      set_title("suburbs");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Back"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("download-areas-csv-file");
                      set_title("download-areas-csv-file");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Skip"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>{/* Run areas csv files end  */}</>
          )}

          {stage === "download-areas-csv-file" ? (
            <>
              <div class="row mt-5">
                <div class="col-md-12 text-center">
                  <h2>Download files</h2>
                  <p class="alert alert-info">
                    This section allows you to download the files so as to
                    update the distances between the areas. After downloading,
                    update the distance and move on to the next stage to update
                    the files.{" "}
                  </p>
                  {/* <form onSubmit={update_areas_in_db} class="m-1">
                    <button
                      class="btn btn-primary"
                      type="submit"
                      // onClick={() => {
                      //   set_stage('add-countries');
                      //   set_title('countries');
                      // }
                      // }
                      disabled={action_loading}
                    >
                      {
                        !action_loading ? ('Update database with uploaded countries, cities & suburbs') : (
                          <>
                            <div class="spinner-border text-orange" role="status">
                            </div>
                            &nbsp;Please wait
                          </>
                        )
                      }
                    </button>
                  </form> */}
                  <p>
                    <a href={`${API_URL}/cities-distance-new.csv`}>
                      Cities distance
                    </a>
                  </p>
                  <p>
                    <a href={`${API_URL}/suburbs-distance-new.csv`}>
                      Suburbs distance
                    </a>
                  </p>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("add-suburbs");
                      set_title("suburbs");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Back"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("upload-cities-csv-file");
                      set_title("cities-distance");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Next"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>{/* Download areas csv files end  */}</>
          )}

          {stage === "upload-cities-csv-file" ? (
            <>
              <div class="row mt-5">
                <div class="col-md-12 text-center">
                  <h2>Upload cities distance files</h2>
                  <p class="alert alert-info">
                    This section allows you to upload the updated files with
                    distances between cities
                  </p>
                  <input
                    type="file"
                    ref={el}
                    onChange={handleChange}
                    class="text-center center-block file-upload form-control m-5"
                  />
                  <form onSubmit={uploadCSV} class="m-1">
                    <button
                      class="btn btn-primary"
                      type="submit"
                      // onClick={() => {
                      //   set_stage('add-countries');
                      //   set_title('countries');
                      // }
                      // }
                      disabled={action_loading}
                    >
                      {!action_loading ? (
                        "Upload cities distance csv file"
                      ) : (
                        <>
                          <div
                            class="spinner-border text-orange"
                            role="status"
                          ></div>
                          &nbsp;Please wait
                        </>
                      )}
                    </button>
                  </form>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("download-areas-csv-file");
                      set_title("suburbs");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Back"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("upload-suburbs-csv-file");
                      set_title("suburbs-distance");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Skip"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>{/* Upload cities csv files end  */}</>
          )}

          {stage === "upload-suburbs-csv-file" ? (
            <>
              <div class="row mt-5">
                <div class="col-md-12 text-center">
                  <h2>Upload suburbs distance files</h2>
                  <p class="alert alert-info">
                    This section allows you to upload the updated files with
                    distances between suburbs
                  </p>
                  <input
                    type="file"
                    ref={el}
                    onChange={handleChange}
                    class="text-center center-block file-upload form-control m-5"
                  />
                  <form onSubmit={uploadCSV} class="m-1">
                    <button
                      class="btn btn-primary"
                      type="submit"
                      // onClick={() => {
                      //   set_stage('add-countries');
                      //   set_title('countries');
                      // }
                      // }
                      disabled={action_loading}
                    >
                      {!action_loading ? (
                        "Upload suburbs distance csv file"
                      ) : (
                        <>
                          <div
                            class="spinner-border text-orange"
                            role="status"
                          ></div>
                          &nbsp;Please wait
                        </>
                      )}
                    </button>
                  </form>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("upload-cities-csv-file");
                      set_title("cities-distance");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Back"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("run-distances-csv-files");
                      set_title("suburbs-distance");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Skip"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>{/* Upload suburbs csv files end  */}</>
          )}

          {stage === "run-distances-csv-files" ? (
            <>
              <div class="row mt-5">
                <div class="col-md-12 text-center">
                  <h2>Update distances in database</h2>
                  <p class="alert alert-info">
                    This section allows you to update the system database with
                    distances between cities and suburbs.{" "}
                  </p>
                  <form onSubmit={update_distances_in_db} class="m-1">
                    <button
                      class="btn btn-primary"
                      type="submit"
                      // onClick={() => {
                      //   set_stage('add-countries');
                      //   set_title('countries');
                      // }
                      // }
                      disabled={action_loading}
                    >
                      {!action_loading ? (
                        "Update database with uploaded distances files"
                      ) : (
                        <>
                          <div
                            class="spinner-border text-orange"
                            role="status"
                          ></div>
                          &nbsp;Please wait
                        </>
                      )}
                    </button>
                  </form>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("upload-suburbs-csv-file");
                      set_title("cities-distance");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Back"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("done");
                      set_title("done");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Skip"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>{/* Run distances csv files end  */}</>
          )}

          {stage === "done" ? (
            <>
              <div class="row mt-5">
                <div class="col-md-12 text-center">
                  <h2>Awesome</h2>
                  <p class="alert alert-info">
                    You are done configuring countries, cities and suburbs.{" "}
                  </p>

                  <button
                    class="btn btn-primary"
                    type="submit"
                    onClick={() => {
                      set_stage("run-distances-csv-file");
                      set_title("suburbs-distance");
                    }}
                    disabled={action_loading}
                  >
                    {!action_loading ? (
                      "Back"
                    ) : (
                      <>
                        <div
                          class="spinner-border text-orange"
                          role="status"
                        ></div>
                        &nbsp;Please wait
                      </>
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>{/* Run distances csv files end  */}</>
          )}
        </div>

        <Dialog
          open={add_city_dialog}
          onClose={add_city_dialog_close}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Enter city details</DialogTitle>
          <DialogContent>
            {/* <DialogContentText> */}
            <form onSubmit={addNewCity}>
              <div class="form-group col-12 my-3">
                <label for="">City name</label>
                <Textbox
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "city",
                    name: "City name",
                    type: "text",
                    placeholder: "",
                  }}
                  // validate={validate}
                  validationCallback={(res) => set_add_city_invalid(res)}
                  value={add_city}
                  disabled={false}
                  classNameInput=""
                  classNameWrapper=""
                  classNameContainer=""
                  customStyleInput={{}}
                  customStyleWrapper={{}}
                  customStyleContainer={{}}
                  onChange={(add_city, e) => {
                    set_add_city(e.target.value);
                  }}
                  onBlur={(e) => {}}
                  validationOption={{
                    name: "City name",
                    check: true,
                    required: true,
                    type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                  }}
                />
              </div>
            </form>
            {/* </DialogContentText> */}
          </DialogContent>
          <DialogActions>
            <button
              class="btn btn-lg btn-success"
              type="submit"
              onClick={add_city_dialog_close}
              color="primary"
            >
              Cancel
            </button>

            <button
              class="btn btn-lg btn-success"
              type="submit"
              onClick={addNewCity}
              color="primary"
              disabled={action_loading || add_city.length < 2}
            >
              Add city
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={remove_city_dialog}
          onClose={remove_city_dialog_close}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Are you sure you want to delete <b>{remove_city_name}</b>{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              This action will delete the city and you cannot undo this action.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              class="btn btn-lg btn-success"
              type="submit"
              onClick={remove_city_dialog_close}
              color="primary"
            >
              Cancel
            </button>

            <button
              class="btn btn-lg btn-success"
              type="submit"
              onClick={removeCityDetails}
              color="primary"
            >
              Yes, I'm sure
            </button>
          </DialogActions>
        </Dialog>
      </section>
    </>
  );
}
export default CountriesUploadScreen;
