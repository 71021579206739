import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addAdmin } from "../actions/userActions";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import {
  getCities,
  getCountries,
  getSuburbs,
} from "../actions/categoryActions";
import { FFD_STAFF_ROLES, FFD_COUNTRIES } from "../config";

function AddStaffMemberScreen(props) {
  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");
  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [phone, set_phone] = useState("");
  const [email, set_email] = useState("");
  const [password, set_password] = useState("");
  const [re_password, set_re_password] = useState("");
  const [add_role, set_add_role] = useState("");
  const [city, set_city] = useState("");
  const [country, set_country] = useState("");
  const [suburb, set_suburb] = useState("");
  const [country_meta, set_country_meta] = useState("");
  const [city_meta, set_city_meta] = useState("");

  const [countries, set_countries] = useState([]);
  const [cities, set_cities] = useState([]);
  const [suburbs, set_suburbs] = useState([]);

  const [first_name_invalid, set_first_name_invalid] = useState(true);
  const [last_name_invalid, set_last_name_invalid] = useState(true);
  const [phone_invalid, set_phone_invalid] = useState(true);
  const [email_invalid, set_email_invalid] = useState(true);
  const [password_invalid, set_password_invalid] = useState(true);
  const [re_password_invalid, set_re_password_invalid] = useState(true);

  const userRegister = useSelector((state) => state.userRegister);
  const { userInfo, error } = userRegister;

  const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "/";

  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    return () => {
      //
    };
    // eslint-disable-next-line
  }, [userInfo]);

  const load_page_data = async () => {
    let get_countries;
    try {
      get_countries = await getCountries("id", "meta", "active");
      if (get_countries.success) {
        const { data } = get_countries;
        set_countries(data);
      }
    } catch (error) {
      // set_error_message(`An error occured fetching countries: ${error.message} `);
    }

    //let iso2_code = get_countries.iso2_code;
    let get_cities;
    try {
      get_cities = await getCities("id", "meta", "status", "country_meta");
      if (get_cities.success) {
        const { data } = get_cities;
        set_cities(data);
      }
    } catch (error) {
      // set_error_message(`An error occured fetching cities: ${error.message} `);
    }

    let get_suburbs;
    try {
      get_suburbs = await getSuburbs("id", "meta", "city", "status");
      if (get_suburbs.success) {
        const { data } = get_suburbs;
        set_suburbs(data);
      }
    } catch (error) {
      // set_error_message(`An error occured fetching suburbs: ${error.message} `);
    }
  };

  const country_changed = async (new_country) => {
    set_country(new_country);
    set_city("");
    set_suburb("");

    for (let i = 0; i < countries.length; i++) {
      if (countries[i].country === new_country) {
        set_country_meta(countries[i].meta);
        return;
      }
    }
  };

  const city_changed = async (new_city) => {
    set_city(new_city);
    set_suburb("");

    for (let i = 0; i < cities.length; i++) {
      if (cities[i].city === new_city) {
        // console.log(cities[i].meta)
        set_city_meta(cities[i].meta);
        return;
      }
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    if (add_role === "") {
      set_error_message("Please select the staff member role");
      set_action_loading(false);
      return;
    }

    try {
      // first_name, last_name, phone, email, password, roles
      const roles = [add_role];
      const response = await addAdmin(
        first_name,
        last_name,
        phone,
        email,
        password,
        roles,
        suburb,
        city
      );
      if (response.success === true) {
        props.history.push("/staff-members");
      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message("An error occured signing up your account");
    }

    set_action_loading(false);

    return;
  };
  return (
    <section id="about" class="about main-content mt-5">
      <div class="container-fluid" data-aos="fade-up">
        <div class="row mt-5">
          <div class="col-md-4"></div>
          <div class="col-md-4 text-center">
            <h2>
              <span class="green-text">Add</span>{" "}
              <span class="orange-text">staff member</span>
            </h2>
            {error && (
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  {error}
                </div>
              </div>
            )}

            <div class="col-12">
              {error_message !== "" ? (
                <div class="alert alert-danger" role="alert">
                  {error_message}
                </div>
              ) : (
                ""
              )}
              {success_message !== "" ? (
                <div class="alert alert-success" role="alert">
                  {success_message}
                </div>
              ) : (
                ""
              )}
            </div>

            <form onSubmit={submitHandler}>
              <div class="form-group my-4">
                <Textbox
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "Name",
                    name: "Name",
                    type: "text",
                    placeholder: "First name",
                  }}
                  // validate={validate}
                  validationCallback={(res) => set_first_name_invalid(res)}
                  value={first_name}
                  disabled={false}
                  classNameInput=""
                  classNameWrapper=""
                  classNameContainer=""
                  customStyleInput={{}}
                  customStyleWrapper={{}}
                  customStyleContainer={{}}
                  onChange={(first_name, e) => {
                    set_first_name(e.target.value);
                  }}
                  onBlur={(e) => {
                    // console.log(e);
                  }}
                  // onFocus={(e) => {console.log(e);}}
                  // onClick={(e) => {console.log(e);}}
                  validationOption={{
                    name: "First name",
                    check: true,
                    required: true,
                    type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                    // showMsg: true,
                    // min: 2,
                    // max: 10,
                    // length: 2,
                    // compare: '3',
                    // reg: /^\d{18}|\d{15}$/,
                    // regMsg: 'failed in reg.test(${value})',
                    // locale: 'en-US',
                    // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                    // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                    // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                    //   if (res != 'milk') {
                    //     return 'Name cannot be other things but milk';
                    //   }
                    //   return true;
                    // }
                  }}
                />
              </div>
              <div class="form-group my-4">
                {/* <input  class="form-control" type="name" name="last_name" id="last_name" onChange={(e) => set_last_name(e.target.value)} placeholder="Surname" /> */}
                <Textbox
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "Surname",
                    name: "Surname",
                    type: "text",
                    placeholder: "Surname",
                  }}
                  // validate={validate}
                  validationCallback={(res) => set_last_name_invalid(res)}
                  value={last_name}
                  disabled={false}
                  classNameInput=""
                  classNameWrapper=""
                  classNameContainer=""
                  customStyleInput={{}}
                  customStyleWrapper={{}}
                  customStyleContainer={{}}
                  onChange={(last_name, e) => {
                    set_last_name(e.target.value);
                  }}
                  onBlur={(e) => {
                    console.log(e);
                  }}
                  // onFocus={(e) => {console.log(e);}}
                  // onClick={(e) => {console.log(e);}}
                  validationOption={{
                    name: "Surname",
                    check: true,
                    required: true,
                    type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                    // showMsg: true,
                    // min: 2,
                    // max: 10,
                    // length: 2,
                    // compare: '3',
                    // reg: /^\d{18}|\d{15}$/,
                    // regMsg: 'failed in reg.test(${value})',
                    // locale: 'en-US',
                    // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                    // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                    // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                    //   if (res != 'milk') {
                    //     return 'Name cannot be other things but milk';
                    //   }
                    //   return true;
                    // }
                  }}
                />
              </div>
              <div class="form-group my-4">
                {/* <input  class="form-control" type="text" name="phone" id="phone" onChange={(e) => set_phone(e.target.value)} placeholder="Phone Number" /> */}
                <Textbox
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "Phone",
                    name: "Phone",
                    type: "text",
                    placeholder: "Phone number. eg 263774756502",
                  }}
                  // validate={validate}
                  validationCallback={(res) => set_phone_invalid(res)}
                  value={phone}
                  disabled={false}
                  classNameInput=""
                  classNameWrapper=""
                  classNameContainer=""
                  customStyleInput={{}}
                  customStyleWrapper={{}}
                  customStyleContainer={{}}
                  onChange={(phone, e) => {
                    set_phone(e.target.value);
                  }}
                  onBlur={(e) => {
                    console.log(e);
                  }}
                  // onFocus={(e) => {console.log(e);}}
                  // onClick={(e) => {console.log(e);}}
                  validationOption={{
                    name: "Phone number",
                    check: true,
                    required: true,
                    type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                    // showMsg: true,
                    // min: 2,
                    // max: 10,
                    // length: 2,
                    // compare: '3',
                    reg: /^\d{12}|\d{14}$/,
                    regMsg: "Invalid phone number",
                    // locale: 'en-US',
                    // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                    // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                    // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                    //   if (res != 'milk') {
                    //     return 'Name cannot be other things but milk';
                    //   }
                    //   return true;
                    // }
                  }}
                />
              </div>
              <div class="form-group my-4">
                {/* <input class="form-control" type="email" name="email" id="email" onChange={(e) => set_email(e.target.value)} placeholder="Email" /> */}
                <Textbox
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "email",
                    name: "email",
                    type: "text",
                    placeholder: "Email",
                  }}
                  // validate={validate}
                  validationCallback={(res) => set_email_invalid(res)}
                  value={email}
                  disabled={false}
                  classNameInput=""
                  classNameWrapper=""
                  classNameContainer=""
                  customStyleInput={{}}
                  customStyleWrapper={{}}
                  customStyleContainer={{}}
                  onChange={(email, e) => {
                    set_email(e.target.value);
                  }}
                  onBlur={(e) => {
                    console.log(e);
                  }}
                  // onFocus={(e) => {console.log(e);}}
                  // onClick={(e) => {console.log(e);}}
                  validationOption={{
                    name: "Email address",
                    check: true,
                    required: true,
                    //type: 'email', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                    // showMsg: true,
                    // min: 2,
                    // max: 10,
                    // length: 2,
                    // compare: '3',
                    // reg: /^\d{18}|\d{15}$/,
                    // regMsg: 'failed in reg.test(${value})',
                    // locale: 'en-US',
                    // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                    // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                    // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                    //   if (res != 'milk') {
                    //     return 'Name cannot be other things but milk';
                    //   }
                    //   return true;
                    // }
                  }}
                />
              </div>

              <div class="form-group my-4">
                {/* <input class="form-control" type="password" id="password" name="password" onChange={(e) => set_password(e.target.value)} placeholder="Password" /> */}
                <Textbox
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "password",
                    name: "password",
                    type: "password",
                    placeholder: "Password",
                    minLength: "6",
                    maxLength: "14",
                  }}
                  // validate={validate}
                  validationCallback={(res) => set_password_invalid(res)}
                  value={password}
                  disabled={false}
                  classNameInput=""
                  classNameWrapper=""
                  classNameContainer=""
                  customStyleInput={{}}
                  customStyleWrapper={{}}
                  customStyleContainer={{}}
                  onChange={(password, e) => {
                    set_password(e.target.value);
                  }}
                  onBlur={(e) => {
                    console.log(e);
                  }}
                  // onFocus={(e) => {console.log(e);}}
                  // onClick={(e) => {console.log(e);}}
                  validationOption={{
                    name: "Account password",
                    check: true,
                    required: true,
                    type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                    // showMsg: true,
                    // min: 2,
                    // max: 10,
                    // length: 2,
                    // compare: '3',
                    // reg: /^\d{18}|\d{15}$/,
                    // regMsg: 'failed in reg.test(${value})',
                    // locale: 'en-US',
                    // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                    // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                    // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                    //   if (res != 'milk') {
                    //     return 'Name cannot be other things but milk';
                    //   }
                    //   return true;
                    // }
                  }}
                />
              </div>
              <div class="form-group my-4">
                {/* <input class="form-control" type="password" id="re_password" name="re_password" onChange={(e) => set_re_password(e.target.value)} placeholder="Confirm Password" /> */}
                <Textbox
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "confirmPassword",
                    name: "confirmPassword",
                    type: "password",
                    placeholder: "Confirm password",
                    minLength: "6",
                    maxLength: "14",
                  }}
                  // validate={validate}
                  validationCallback={(res) => set_re_password_invalid(res)}
                  value={re_password}
                  disabled={false}
                  classNameInput=""
                  classNameWrapper=""
                  classNameContainer=""
                  customStyleInput={{}}
                  customStyleWrapper={{}}
                  customStyleContainer={{}}
                  onChange={(re_password, e) => {
                    set_re_password(e.target.value);
                  }}
                  onBlur={(e) => {
                    console.log(e);
                  }}
                  // onFocus={(e) => {console.log(e);}}
                  // onClick={(e) => {console.log(e);}}
                  validationOption={{
                    name: "Confirm password",
                    check: true,
                    required: true,
                    type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                    // showMsg: true,
                    // min: 2,
                    // max: 10,
                    // length: 2,
                    // compare: '3',
                    // reg: /^\d{18}|\d{15}$/,
                    // regMsg: 'failed in reg.test(${value})',
                    // locale: 'en-US',
                    // msgOnError: "Your two passwords do not match",
                    // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                    customFunc: (res) => {
                      // Optional.[Func].Default: none. Custom function. Returns true or err message
                      if (res !== password) {
                        return "Your two passwords do not match";
                      }
                      return true;
                    },
                  }}
                />
              </div>
              <div class="form-group my-4">
                <select
                  class="form-control"
                  name="add_role"
                  id="add_role"
                  onChange={(e) => set_add_role(e.target.value)}
                >
                  <option>Select staff member role</option>

                  {FFD_STAFF_ROLES.map((r) => (
                    <option value={r.role}>{r.display_name}</option>
                  ))}
                </select>
              </div>

              <div class="form-group my-4">
                <select
                  class="form-control"
                  name="country"
                  id="country"
                  onChange={(e) => country_changed(e.target.value)}
                >
                  <option value={country}>
                    {country === "" ? "Select country" : country}
                  </option>
                  {FFD_COUNTRIES.map((c) =>
                    c.meta === localStorage.getItem("buyer_country_meta") ? (
                      <option key={c._id} value={c.country}>
                        {c.country}
                      </option>
                    ) : (
                      ""
                    )
                  )}
                </select>
              </div>

              <div class="form-group my-4">
                <select
                  class="form-control"
                  name="city"
                  id="city"
                  onChange={(e) => city_changed(e.target.value)}
                >
                  <option value={city}>
                    {city === "" ? "Select city" : city}
                  </option>
                  {cities.map((c) =>
                    c.country === localStorage.getItem("buyer_country_meta") ? (
                      <option key={c.city} value={c.city}>
                        {c.city}
                      </option>
                    ) : (
                      <></>
                    )
                  )}
                </select>
              </div>

              <div class="form-group my-4">
                <select
                  class="form-control"
                  name="suburb"
                  id="suburb"
                  onChange={(e) => set_suburb(e.target.value)}
                >
                  <option value={suburb}>
                    {suburb === "" ? "Select suburb" : suburb}
                  </option>
                  {suburbs.length > 0
                    ? suburbs.map((c) =>
                        city_meta === c.city ? (
                          <option key={c._id} value={c.suburb}>
                            {c.suburb}
                          </option>
                        ) : (
                          ""
                        )
                      )
                    : ""}
                </select>
              </div>

              <div class="form-group my-4">
                <button
                  type="submit"
                  class="btn btn-primary"
                  disabled={
                    action_loading ||
                    first_name_invalid ||
                    last_name_invalid ||
                    phone_invalid ||
                    email_invalid ||
                    password_invalid ||
                    re_password_invalid
                  }
                >
                  {!action_loading ? (
                    "Add staff member"
                  ) : (
                    <>
                      <div
                        class="spinner-border text-orange"
                        role="status"
                      ></div>
                      &nbsp;Creating staff account
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
    </section>
  );
}
export default AddStaffMemberScreen;
