import React, { useState, useEffect, useRef } from "react";
import {
  getCategories,
  getProductTypes,
  updateProductType,
} from "../actions/categoryActions";
import { validate_multiple_roles } from "../validations";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";

import { API_URL, API_KEY } from "../config";
import Spinner from "../components/spinner";

function ViewProductTypeScreen(props) {
  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["admin", "technical-support"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [file, setFile] = useState(""); // storing the uploaded file
  // eslint-disable-next-line
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element
  const handleChange = (e) => {
    setProgess(0);
    const file = e.target.files[0]; // accesing file
    setFile(file); // storing file
  };

  const [delete_product_type_dialog, set_delete_product_type_dialog] =
    React.useState(false);

  const delete_product_type_dialog_open = () => {
    set_delete_product_type_dialog(true);
  };

  const delete_product_type_dialog_close = () => {
    set_delete_product_type_dialog(false);
  };

  const id = props.match.params.id ? props.match.params.id : "";

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");
  // eslint-disable-next-line
  const [
    update_product_type_error_message,
    set_update_product_type_error_message,
  ] = useState("");
  // eslint-disable-next-line
  const [
    update_product_type_success_message,
    set_update_product_type_success_message,
  ] = useState("");

  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [add_category, set_add_category] = useState("");
  // eslint-disable-next-line
  const [add_code, set_add_code] = useState("");
  // eslint-disable-next-line
  const [add_priority, set_add_priority] = useState("");
  const [product_type_details, set_product_type_details] = useState({});

  const [add_sub_category, set_add_sub_category] = useState("");
  const [add_product, set_add_product] = useState("");
  const [add_longevity, set_add_longevity] = useState(1);

  const [current_category_text, set_current_category_text] = useState("");
  const [current_sub_category_text, set_current_sub_category_text] =
    useState("");

  const [upload_error_message, set_upload_error_message] = useState("");
  const [upload_success_message, set_upload_success_message] = useState("");

  // eslint-disable-next-line
  const [add_category_invalid, set_add_category_invalid] = useState(true);
  // eslint-disable-next-line
  const [add_priority_invalid, set_add_priority_invalid] = useState(true);
  // eslint-disable-next-line
  const [add_code_invalid, set_add_code_invalid] = useState(true);
  // eslint-disable-next-line

  const [all_categories, set_all_categories] = useState([]);
  // eslint-disable-next-line
  const [all_product_types, set_all_product_types] = useState([]);

  const [display_sub_categories, set_display_sub_categories] = useState([]);
  // eslint-disable-next-line
  const [display_product_types, set_display_product_types] = useState([]);

  const [add_units, set_add_units] = useState("");
  const [add_search_keys, set_add_search_keys] = useState("");
  // eslint-disable-next-line
  const [add_product_invalid, set_add_product_invalid] = useState(true);
  // eslint-disable-next-line
  const [add_search_keys_invalid, set_add_search_keys_invalid] = useState(true);
  // eslint-disable-next-line
  const [add_units_invalid, set_add_units_invalid] = useState(true);

  const category_changed = (new_category) => {
    let _display_sub_categories = [];
    let _display_product_types = [];

    set_add_category(new_category);
    set_add_sub_category("");
    set_add_product("");

    if (new_category !== "") {
      for (let i = 0; i < all_categories.length; i++) {
        if (all_categories[i]._id === new_category) {
          _display_sub_categories = all_categories[i].sub_categories;
        }
      }

      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].category_id === new_category) {
          _display_product_types.push(all_product_types[i]);
        }
      }
    }

    set_display_sub_categories(_display_sub_categories);
    set_display_product_types(_display_product_types);
  };

  const sub_category_changed = (new_sub_category) => {
    let _display_product_types = [];

    set_add_sub_category(new_sub_category);
    set_add_product("");

    if (new_sub_category !== "") {
      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].sub_category_meta === new_sub_category) {
          _display_product_types.push(all_product_types[i]);
        }
      }
    }

    set_display_product_types(_display_product_types);
  };

  /**
   * Adds product type
   * @param {*} e
   */
  const updateProductDetails = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    let units = [];
    let search_keys = [];

    if (add_units !== "") {
      units = add_units.split(",");
    }
    if (add_search_keys !== "") {
      search_keys = add_search_keys.split(",");
    }

    const update_data = {
      category_id: add_category,
      sub_category_meta: add_sub_category,
      units,
      search_keys,
      dimensions: [],
      product: add_product,
      longevity: add_longevity,
    };

    const response = await updateProductType(update_data, id, "basic_detail");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      // props.history.push('/product-types');
      set_success_message("Product type successfully updated.");
      set_action_loading(false);
      // return load_page_data();
      try {
        let get_product_type = await getProductTypes(
          id,
          "meta",
          "active",
          "search_term",
          "category_id",
          "sub_category_meta"
        );
        if (get_product_type.success === false) {
          set_error_message(get_product_type.message);
        } else {
          const { data } = get_product_type;
          set_add_product(data[0].product);
          let units_text = "";
          let keywords_text = "";
          if (data[0].units.length > 0) {
            for (let i = 0; i < data[0].units.length; i++) {
              if (i + 1 === data[0].units.length) {
                units_text += `${data[0].units[i]}`;
              } else {
                units_text += `${data[0].units[i]},`;
              }
            }
          }

          if (data[0].search_keys.length > 0) {
            for (let i = 0; i < data[0].search_keys.length; i++) {
              if (i + 1 === data[0].search_keys.length) {
                keywords_text += `${data[0].search_keys[i]}`;
              } else {
                keywords_text += `${data[0].search_keys[i]},`;
              }
            }
          }

          set_add_units(units_text);
          set_add_search_keys(keywords_text);
          set_product_type_details(data[0]);

          // set_sub_categories(data[0].sub_categories);

          set_add_product_invalid(true);
          set_add_units_invalid(true);
          set_add_search_keys_invalid(true);
        }
      } catch (error) {
        set_error_message(
          `An error occured fetching category details: ${error.message} `
        );
      }
    }
    set_action_loading(false);

    return;
  };

  const uploadFile = async () => {
    set_success_message("");
    set_error_message("");
    set_upload_success_message("");
    set_upload_error_message("");

    set_action_loading(true);

    const token_string = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file); // appending file
    formData.append("api_key", API_KEY);
    formData.append("product_id", id);
    await axios
      .post(
        `${API_URL}/product-types/upload-image`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token_string,
          },
        },
        {
          onUploadProgress: (ProgressEvent) => {
            let progress =
              Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
              "%";
            setProgess(progress);
          },
        }
      )
      .then((res) => {
        const { data } = res;
        if (data.success === true) {
          set_upload_success_message(
            "Product type image successfully updated. "
          );
        } else if (data.success === false) {
          set_upload_error_message(data.message);
        } else {
          set_upload_error_message(
            `An error occured uploading product type image`
          );
        }
      })
      .catch
      // set_upload_error_message('An error occured uploading product image')
      ();

    set_action_loading(false);
  };

  /**
   * Deducts all remaining inventory items of this product
   *
   * @param {*} e
   */
  const deleteProductType = async (e) => {
    e.preventDefault();
    set_delete_product_type_dialog(false);
    set_success_message("");
    set_error_message("");
    set_update_product_type_success_message("");
    set_update_product_type_error_message("");

    set_action_loading(true);

    const update_data = {
      status: "deactivated",
    };

    const response = await updateProductType(update_data, id, "status_detail");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      props.history.push("/product-types");
    }
    set_action_loading(false);

    return;
  };

  const load_page_data = async () => {
    let get_all_categories;
    let get_product_type;
    let categories_list;

    try {
      get_all_categories = await getCategories("id", "meta", "active");
      if (get_all_categories.success === false) {
        set_error_message(get_all_categories.message);
      } else {
        const { data } = get_all_categories;
        categories_list = data;
        set_all_categories(data);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching category details: ${error.message} `
      );
    }

    try {
      get_product_type = await getProductTypes(
        id,
        "meta",
        "active",
        "search_term",
        "category_id",
        "sub_category_meta"
      );
      if (get_product_type.success === false) {
        set_error_message(get_product_type.message);
      } else {
        const { data } = get_product_type;
        set_add_product(data[0].product);

        let units_text = "";
        let keywords_text = "";

        if (data[0].units.length > 0) {
          for (let i = 0; i < data[0].units.length; i++) {
            if (i + 1 === data[0].units.length) {
              units_text += `${data[0].units[i]}`;
            } else {
              units_text += `${data[0].units[i]},`;
            }
          }
        }

        if (data[0].search_keys.length > 0) {
          for (let i = 0; i < data[0].search_keys.length; i++) {
            if (i + 1 === data[0].search_keys.length) {
              keywords_text += `${data[0].search_keys[i]}`;
            } else {
              keywords_text += `${data[0].search_keys[i]},`;
            }
          }
        }

        set_add_units(units_text);
        set_add_search_keys(keywords_text);
        set_product_type_details(data[0]);

        set_add_category(data[0].category_id);
        set_add_sub_category(data[0].sub_category_meta);
        set_add_longevity(data[0].longevity);

        for (let i = 0; i < categories_list.length; i++) {
          if (categories_list[i]._id === data[0].category_id) {
            set_current_category_text(categories_list[i].category);
          }
        }

        if (data[0].sub_category_meta !== "") {
          let sub_category_text = data[0].sub_category_meta;
          sub_category_text = sub_category_text.replace(/-/g, " ");
          // sub_category_text = sub_category_text[0].toUpperCase();
          set_current_sub_category_text(sub_category_text);
        }

        set_add_product_invalid(true);
        set_add_units_invalid(true);
        set_add_search_keys_invalid(true);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching category details: ${error.message} `
      );
    }

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">Viewing</span>{" "}
              <span class="orange-text">{product_type_details.product}</span>
            </h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            // <div>Loading ... </div>
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== "" ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : (
                    ""
                  )}
                  {success_message !== "" ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h2>Basic details</h2>
                  <form onSubmit={updateProductDetails}>
                    <div class="row">
                      <div class="form-group col-md-4 my-3">
                        <label for="">Category</label>
                        <select
                          class="form-control"
                          name="add_category"
                          id="add_category"
                          onChange={(e) => category_changed(e.target.value)}
                        >
                          <option value={product_type_details.category_id}>
                            {current_category_text}
                          </option>
                          {all_categories.map((c) => (
                            <option key={c._id} value={c._id}>
                              {c.category}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="">Sub category</label>
                        <select
                          class="form-control"
                          name="add_sub_category"
                          id="add_sub_category"
                          onChange={(e) => sub_category_changed(e.target.value)}
                        >
                          <option
                            value={product_type_details.sub_category_meta}
                          >
                            {current_sub_category_text}
                          </option>
                          {display_sub_categories.map((c) => (
                            <option
                              key={c.sub_category_meta}
                              value={c.sub_category_meta}
                            >
                              {c.sub_category}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="">Product</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Product",
                            name: "Product",
                            type: "text",
                            placeholder: "",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_add_product_invalid(res)
                          }
                          value={add_product}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(add_product, e) => {
                            set_add_product(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "Product",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            //   min: 0.01,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="">Units</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "units",
                            name: "Units",
                            type: "text",
                            placeholder: "",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_add_units_invalid(res)
                          }
                          value={add_units}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(add_units, e) => {
                            set_add_units(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "units",
                            //   check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            //   min: 0.01,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="">Keywords</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "searchKeys",
                            name: "Search keys",
                            type: "text",
                            placeholder: "",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_add_search_keys_invalid(res)
                          }
                          value={add_search_keys}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(add_search_keys, e) => {
                            set_add_search_keys(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "searchKeys",
                            //   check: true,
                            required: false,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            //   min: 0.01,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label>Longevity</label>
                        <input
                          type="number"
                          min="1"
                          value={add_longevity}
                          onChange={(e) => {
                            set_add_longevity(e.target.value);
                          }}
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-group col-12 my-3 text-center">
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        disabled={
                          action_loading ||
                          !add_priority_invalid ||
                          !add_code_invalid ||
                          !add_category_invalid
                        }
                        // check invalids
                      >
                        <i class="glyphicon glyphicon-plus"></i> Update product
                        type details
                      </button>
                    </div>
                  </form>

                  <hr />
                </div>

                <div class="col-md-12">
                  <div class="form-group col-12 my-3 text-left">
                    <button
                      class="btn btn-lg btn-danger"
                      type="submit"
                      onClick={delete_product_type_dialog_open}
                      disabled={action_loading}
                    >
                      {!action_loading ? (
                        "Delete product type"
                      ) : (
                        <>
                          <div
                            class="spinner-border text-orange"
                            role="status"
                          ></div>
                          {/* &nbsp;Please wait */}
                          <Spinner />
                        </>
                      )}
                    </button>
                  </div>

                  <Dialog
                    open={delete_product_type_dialog}
                    onClose={delete_product_type_dialog_close}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Are you sure you want to delete this product type?
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        This action will not entirely delete the product type
                        but will make the product type become inactive and it
                        stops showing when product types are called.
                        <br />
                        <br />
                        You cannot undo this action.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={delete_product_type_dialog_close}
                        color="primary"
                      >
                        Cancel
                      </button>

                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={deleteProductType}
                        color="primary"
                      >
                        Yes, I'm sure
                      </button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
export default ViewProductTypeScreen;
