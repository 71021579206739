import React, { useState, useEffect } from "react";
import { getCities, addCity, removeCity } from "../actions/categoryActions";
import {
  retrieveBuyerProducts,
  updateBuyerRequestedProduct,
} from "../actions/productActions";
import { validate_multiple_roles } from "../validations";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { get_display_date } from "../config";
import Spinner from "../components/spinner";

function BuyerRequestedProductsScreen(props) {
  const [add_city_dialog, set_add_city_dialog] = React.useState(false);

  const add_city_dialog_open = () => {
    set_add_city_dialog(true);
  };

  const add_city_dialog_close = () => {
    set_add_city_dialog(false);
  };

  const [remove_city_dialog, set_remove_city_dialog] = React.useState(false);

  const remove_city_dialog_open = () => {
    set_remove_city_dialog(true);
  };

  const remove_city_dialog_close = () => {
    set_remove_city_dialog(false);
  };

  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["admin", "technical-support"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const id = props.match.params.id ? props.match.params.id : "";

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");

  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [add_city, set_add_city] = useState("");

  const [remove_city, set_remove_city] = useState("");

  const [remove_city_name, set_remove_city_name] = useState("");

  const [orders, set_orders] = useState([]);

  // eslint-disable-next-line
  const [add_city_invalid, set_add_city_invalid] = useState(true);

  const load_page_data = async () => {
    let get_orders;
    try {
      get_orders = await retrieveBuyerProducts(
        "id",
        "product",
        "buyer",
        "status"
      );
      // console.log(get_orders)
      // console.log('get orders', get_orders);
      if (get_orders.success) {
        let { data } = get_orders;

        for (let i = 0; i < data.length; i++) {
          //  console.log(data[i]);
          data[i].product = data[i].product;
          data[i].quantity = data[i].quantity;
          data[i].units = data[i].units;
          data[i].status = data[i].status;
          data[i].buyer_details = data[i].buyer_details;
          //  console.log(data[i].buyer_details)
          // data[i].action = <Link class="" to={`/track/${data[i].tracking_number}/${data[i]._id}`}><i class="bi bi-truck"></i> Track order</Link>
          //  data[i].action = <button class="btn btn-danger btn-lg" onClick={()=>cancelRequest(data[i]._id)}>Delete request</button>
        }
        set_orders(data);
      } else {
        set_error_message("Requests history is empty.");
      }
    } catch (error) {
      console.log(error.message);
      set_error_message("Requests history is empty.");
    }

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">Buyer</span>{" "}
              <span class="orange-text">Requested Products</span>
            </h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-12">
                      {error_message !== "" ? (
                        <div class="alert alert-danger" role="alert">
                          {error_message}
                        </div>
                      ) : (
                        ""
                      )}
                      {success_message !== "" ? (
                        <div class="alert alert-success" role="alert">
                          {success_message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-12">
                      <div class="row">
                        <div class="table-responsive">
                          <table class="table table-bordered table-striped">
                            <tr>
                              <th>Date of request</th>
                              <th>Produce name</th>
                              <th>Requested quantity (amount)</th>
                              <th>Requested quantity (units)</th>
                              <th>Buyer ID</th>
                              <th>City</th>
                              <th>Delivery</th>
                              <th>Search radius</th>
                            </tr>
                            {orders.length > 0
                              ? orders.map((c) => (
                                  <tr key={c._id} class="my-5">
                                    <td>{get_display_date(c.createdAt)}</td>
                                    <td>{c.product}</td>
                                    <td>{c.quantity}</td>
                                    <td>{c.units}</td>
                                    <td>{c.buyer}</td>

                                    <td>{c.buyer_details[0].city}</td>
                                    <td>{c.delivery}</td>
                                    <td>{c.radius}</td>
                                  </tr>
                                ))
                              : "No requested products found."}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <Dialog
          open={add_city_dialog}
          onClose={add_city_dialog_close}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Enter city details</DialogTitle>
          <DialogContent>
            {/* <DialogContentText> */}
            <form>
              <div class="form-group col-12 my-3">
                <label for="">City name</label>
                <Textbox
                  attributesWrapper={{}}
                  attributesInput={{
                    id: "city",
                    name: "City name",
                    type: "text",
                    placeholder: "",
                  }}
                  // validate={validate}
                  validationCallback={(res) => set_add_city_invalid(res)}
                  value={add_city}
                  disabled={false}
                  classNameInput=""
                  classNameWrapper=""
                  classNameContainer=""
                  customStyleInput={{}}
                  customStyleWrapper={{}}
                  customStyleContainer={{}}
                  onChange={(add_city, e) => {
                    set_add_city(e.target.value);
                  }}
                  onBlur={(e) => {}}
                  validationOption={{
                    name: "City name",
                    check: true,
                    required: true,
                    type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                  }}
                />
              </div>
            </form>
            {/* </DialogContentText> */}
          </DialogContent>
          <DialogActions>
            <button
              class="btn btn-lg btn-success"
              type="submit"
              onClick={add_city_dialog_close}
              color="primary"
            >
              Cancel
            </button>

            <button
              class="btn btn-lg btn-success"
              type="submit"
              color="primary"
              disabled={action_loading || add_city.length < 2}
            >
              Add city
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={remove_city_dialog}
          onClose={remove_city_dialog_close}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Are you sure you want to delete <b>{remove_city_name}</b>{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              This action will delete the city and you cannot undo this action.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              class="btn btn-lg btn-success"
              type="submit"
              onClick={remove_city_dialog_close}
              color="primary"
            >
              Cancel
            </button>

            <button
              class="btn btn-lg btn-success"
              type="submit"
              color="primary"
            >
              Yes, I'm sure
            </button>
          </DialogActions>
        </Dialog>
      </section>
    </>
  );
}
export default BuyerRequestedProductsScreen;
