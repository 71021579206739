import React, { useState, useEffect, useRef } from "react";
import { validate_role } from "../validations";
import { getBuyer, updateBuyerDetails } from "../actions/userActions";
import {
  getCities,
  getCountries,
  getSuburbs,
} from "../actions/categoryActions";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import axios from "axios";
import { APP_URL, API_URL, IMAGES_URL, API_KEY, token_string } from "../config";
import { Link } from "react-router-dom";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Spinner from "../components/spinner";

function BuyerProfileScreen(props) {
  let user_profile;
  const permitted_role = "buyer";
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = "login";
    if (permitted_role === "buyer") login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");
  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [cities, set_cities] = useState([]);
  const [countries, set_countries] = useState([]);
  const [suburbs, set_suburbs] = useState([]);

  const [first_name, set_first_name] = useState("");
  const [username, set_username] = useState("");
  const [last_name, set_last_name] = useState("");
  const [phone, set_phone] = useState("");
  const [email, set_email] = useState("");

  const [telephone, set_telephone] = useState("");
  const [display_name, set_display_name] = useState("");
  const [address_line1, set_address_line1] = useState("");
  const [address_line2, set_address_line2] = useState("");
  const [suburb, set_suburb] = useState("");
  const [city, set_city] = useState("Select");
  const [province, set_province] = useState("");
  const [country, set_country] = useState("");
  const [country_meta, set_country_meta] = useState("");
  const [city_meta, set_city_meta] = useState("");
  // eslint-disable-next-line
  const [seller_image, set_seller_image] = useState("");

  const [require_delivery, set_require_delivery] = useState(false);

  const [latitude, set_latitude] = useState("");
  const [longitude, set_longitude] = useState("");

  // eslint-disable-next-line
  const [first_name_invalid, set_first_name_invalid] = useState(true);
  // eslint-disable-next-line
  const [last_name_invalid, set_last_name_invalid] = useState(true);
  // eslint-disable-next-line
  const [phone_invalid, set_phone_invalid] = useState(true);
  // eslint-disable-next-line
  const [email_invalid, set_email_invalid] = useState(true);
  // eslint-disable-next-line
  const [address_line1_invalid, set_address_line1_invalid] = useState(true);
  // eslint-disable-next-line
  const [address_line2_invalid, set_address_line2_invalid] = useState(true);
  // eslint-disable-next-line
  const [province_invalid, set_province_invalid] = useState(true);

  // eslint-disable-next-line
  const [user_profile_in_db, set_user_profile_in_db] = useState("");

  const [file, setFile] = useState(""); // storing the uploaded file
  // eslint-disable-next-line
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element
  const handleChange = (e) => {
    setProgess(0);
    const file = e.target.files[0]; // accessing file
    setFile(file); // storing file
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [crop, setCrop] = useState({
    aspect: 3 / 4,
    x: 0,
    y: 0,
    width: 180,
    height: 240,
  });
  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [upload_error_message, set_upload_error_message] = useState("");
  const [upload_success_message, set_upload_success_message] = useState("");

  const imageRef = useRef();
  const fileInputRef = useRef(null);

  async function fetchBuyerCountry(buyer_country_meta) {
    try {
      const response = await getCountries(
        "id",
        buyer_country_meta,
        "active",
        "name"
      );
      if (response.success && response.data.length > 0) {
        const buyer_country_data = response.data[0];
        return buyer_country_data.country;
      } else {
        throw new Error("Country not found or no currency symbol available");
      }
    } catch (error) {
      console.error("Error fetching buyer currency:", error.message);
      return null; // or a default value
    }
  }

  const get_user_profile = async () => {
    const { _id } = user_profile;

    let _countries = [];
    let _cities = [];

    let get_countries;
    try {
      get_countries = await getCountries("id", "meta", "active", "name"); //('id', 'meta', 'active');
      if (get_countries.success) {
        const { data } = get_countries;
        set_countries(data);
        _countries = data;
      }
    } catch (error) {
      // set_error_message(`An error occured fetching countries: ${error.message} `);
    }
    let country_meta; //change to use iso2_code instead of country
    if (country === "") {
      country_meta = "country_meta";
    } else {
      country_meta = country;
    }

    let get_cities;
    try {
      get_cities = await getCities("id", "meta", "status", "country_meta"); //change to use iso2_code instead of country
      if (get_cities.success) {
        const { data } = get_cities;
        set_cities(data);
        _cities = data;
      }
    } catch (error) {
      // set_error_message(`An error occured fetching cities: ${error.message} `);
    }

    let _country_meta;
    let _city_meta;

    let this_user_profile;
    try {
      this_user_profile = await getBuyer(_id);

      if (this_user_profile.success === false) {
        set_error_message(
          "An error occured retrieving your profile. Please reload page to try again"
        );
      } else {
        const user_details = this_user_profile.data[0];
        set_user_profile_in_db(user_details);
        set_page_loaded(true);

        const { buyer_details } = user_details;

        set_first_name(user_details.first_name);
        set_username(user_details.username);
        set_last_name(user_details.last_name);
        set_phone(user_details.phone);
        set_email(user_details.email);
        set_telephone(user_details.telephone);
        set_display_name(user_details.display_name);

        set_address_line1(buyer_details[0].address_line1);
        set_address_line2(buyer_details[0].address_line2);
        set_suburb(buyer_details[0].suburb);
        set_city(buyer_details[0].city);
        set_province(buyer_details[0].province);
        set_country(buyer_details[0].country);

        _country_meta = await country_loaded(
          _countries,
          buyer_details[0].country
        );
        _city_meta = await city_loaded(_cities, buyer_details[0].city);

        set_country_meta(_country_meta);
        set_city_meta(_city_meta);

        set_seller_image(user_details.image);

        set_latitude(buyer_details[0].latitude);
        set_longitude(buyer_details[0].longitude);
        set_require_delivery(buyer_details[0].require_delivery);
      }
    } catch (error) {
      console.log(error.message);
      set_error_message("An error occured fetching buyer profile");
    }

    let get_suburbs;
    try {
      get_suburbs = await getSuburbs("id", "meta", "city", "status");
      if (get_suburbs.success) {
        const { data } = get_suburbs;
        set_suburbs(data);
      }
    } catch (error) {
      // set_error_message(`An error occured fetching suburbs: ${error.message} `);
    }

    return;
  };

  /**
   * Updates user profile
   * @param {*} e
   */
  const updateProfile = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    // Ensure latitude and longitude are converted to numbers
    const numericLatitude = parseFloat(latitude);
    const numericLongitude = parseFloat(longitude);

    const update_data = {
      first_name,
      last_name,
      phone,
      email,
      buyer_details: [
        {
          address_line1,
          address_line2,
          suburb,
          city,
          province,
          country,
          latitude: numericLatitude, // Use the converted latitude
          longitude: numericLongitude, // Use the converted longitude
          require_delivery,
        },
      ],
    };

    const response = await updateBuyerDetails(update_data, "basic_detail");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message("Account details successfully updated.");
    }
    set_action_loading(false);

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      get_user_profile();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  const country_changed = async (new_country) => {
    set_country(new_country);
    set_city("");
    set_suburb("");
    set_province("");
    // console.log('new country', new_country)
    let _country_meta = "country_meta";
    for (let i = 0; i < countries.length; i++) {
      if (countries[i].country === new_country) {
        set_country_meta(countries[i].meta);
        _country_meta = countries[i].meta;
        // return;
      }
    }
  };

  const country_loaded = async (countries, new_country) => {
    set_country(new_country);
    // set_city('')
    // set_suburb('')

    for (let i = 0; i < countries.length; i++) {
      if (countries[i].country === new_country) {
        return countries[i].meta;
      }
    }
    return "country_meta";
  };

  const city_loaded = async (cities, new_city) => {
    set_city(new_city);
    // set_city('')
    // set_suburb('')

    for (let i = 0; i < cities.length; i++) {
      if (cities[i].city === new_city) {
        return cities[i].meta;
      }
    }
    return "city_meta";
  };

  const city_changed = async (new_city) => {
    set_city(new_city);
    set_suburb("");

    for (let i = 0; i < cities.length; i++) {
      if (cities[i].city === new_city) {
        set_city_meta(cities[i].meta);
        return;
      }
    }
  };

  const require_delivery_changed = async (new_require_delivery) => {
    let _require_delivery = new_require_delivery;
    if (new_require_delivery === "true") {
      _require_delivery = true;
    } else if (new_require_delivery === "false") {
      _require_delivery = false;
    }

    set_require_delivery(_require_delivery);
  };

  const onCropComplete = (crop) => {
    if (!selectedImage || !crop.width || !crop.height) return;

    const canvas = document.createElement("canvas");
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
    canvas.width = 300; // Width of the output image
    canvas.height = 400; // Height of the output image
    const ctx = canvas.getContext("2d");

    const image = new Image();
    image.src = selectedImage;
    image.onload = () => {
      ctx.drawImage(
        image,
        crop.x * scaleX, // Scaled x-coordinate
        crop.y * scaleY, // Scaled y-coordinate
        crop.width * scaleX, // Scaled width
        crop.height * scaleY, // Scaled height
        0,
        0,
        300,
        400
      );

      canvas.toBlob((blob) => {
        const croppedFile = new File([blob], "cropped.jpg", {
          type: "image/jpeg",
        });
        setCroppedImageFile(croppedFile);
      }, "image/jpeg");
    };
  };

  const handleImageFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setIsCropModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
    setSelectedImage(null); // Reset selected image
    setCroppedImageFile(null); // Reset cropped image file
  };

  const handleImageOkClick = () => {
    if (crop.width && crop.height && selectedImage) {
      onCropComplete(crop);
      update_client_profile_picture();
      setIsCropModalOpen(false);
      setSelectedImage(null); // Reset selected image after successful crop
    }
  };

  const handleImageCancelClick = () => {
    setIsCropModalOpen(false); // Close the modal
    setSelectedImage(null); // Reset selected image
    setCroppedImageFile(null); // Reset cropped image file
    if (fileInputRef.current) fileInputRef.current.value = ""; // Reset file input
  };

  const onImageLoaded = (image) => {
    imageRef.current = image; // Set the image reference
    const imageWidth = image.naturalWidth;
    const imageHeight = image.naturalHeight;
    setImageDimensions({ width: imageWidth, height: imageHeight });

    // Calculate initial crop for 4:3 ratio
    const targetRatio = 3 / 4;
    let cropWidth = Math.min(imageWidth, imageHeight * targetRatio);
    let cropHeight = cropWidth / targetRatio;

    // If the height exceeds image height after calculating width, adjust it
    if (cropHeight > imageHeight) {
      cropHeight = imageHeight;
      cropWidth = cropHeight * targetRatio;
    }

    const x = (imageWidth - cropWidth) / 2;
    const y = (imageHeight - cropHeight) / 2;

    setCrop({
      aspect: targetRatio,
      width: cropWidth,
      height: cropHeight,
      x: x,
      y: y,
    });
  };

  /**
   *
   * @returns updates a client profile picture
   */
  // eslint-disable-next-line
  const update_client_profile_picture = async () => {
    //e.preventDefault();
    set_success_message("");
    set_error_message("");

    set_upload_success_message("");
    set_upload_error_message("");

    set_action_loading(true);

    // Check if cropped image is ready
    if (!croppedImageFile) {
      set_upload_error_message("No cropped image file is ready for upload.");
      set_action_loading(false);
      return;
    }

    const formData = new FormData();
    formData.append("api_key", API_KEY);
    formData.append("file", croppedImageFile); // appending file
    formData.append("update_type", "picture");
    formData.append("id", user_profile._id);
    await axios
      .patch(
        `${API_URL}/sellers`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token_string,
          },
        },
        {
          onUploadProgress: (ProgressEvent) => {
            let progress =
              Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
              "%";
            setProgess(progress);
          },
        }
      )
      .then((res) => {
        const { data } = res;
        if (data.success === true) {
          set_success_message("Profile picture successfully updated");
        } else if (data.success === false) {
          set_error_message(data.message);
          set_upload_error_message(data.message);
        } else {
          set_error_message(`An error occured updating profile picture`);
          set_upload_error_message(`An error occured updating profile picture`);
        }
      })
      .catch
      // set_error_message('An error occured updating profile picture')
      ();

    await get_user_profile();

    set_action_loading(false);

    setIsCropModalOpen(false); // Close the modal after upload
    setSelectedImage(null); // Reset selected image after upload
    setCroppedImageFile(null); // Reset cropped image file after upload
    if (fileInputRef.current) fileInputRef.current.value = ""; // Reset file input after successful upload

    return;
  };

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">My</span>{" "}
              <span class="orange-text">Profile</span>
            </h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== "" ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : (
                    ""
                  )}
                  {success_message !== "" ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">
                  <div class="text-center">
                    {seller_image === "" ||
                    seller_image === null ||
                    seller_image === undefined ? (
                      <img
                        src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                        class="avatar img-circle img-thumbnail"
                        alt="avatar"
                      />
                    ) : (
                      <img
                        src={`${IMAGES_URL}/${seller_image}`}
                        class="avatar img-circle img-thumbnail"
                        alt="avatar"
                      />
                    )}

                    <h4 class="mt-5">Upload a new profile photo</h4>

                    <form>
                      <div class="form-group col-12 my-3 text-center">
                        {/* <input type="file" ref={el} onChange={handleChange} class="text-center center-block file-upload form-control" /> */}
                        <input
                          type="file"
                          onChange={handleImageFileChange}
                          ref={fileInputRef}
                          class="text-center center-block file-upload form-control"
                        />

                        {isCropModalOpen && (
                          <div
                            style={{
                              position: "fixed",
                              top: "0",
                              left: "0",
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "20px",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <div
                              style={{
                                marginBottom: "20px",
                                color: "#fff",
                                fontSize: "18px",
                              }}
                            >
                              Select the area of the photo to show for the
                              product
                            </div>
                            <div
                              style={{
                                width: "400px",
                                height: "auto",
                                position: "relative",
                              }}
                            >
                              <ReactCrop
                                src={selectedImage}
                                crop={crop}
                                onImageLoaded={onImageLoaded}
                                ruleOfThirds
                                onChange={(newCrop) => setCrop(newCrop)}
                                onComplete={onCropComplete}
                              />
                            </div>
                            <div className="button-group mt-3">
                              <button
                                className="btn btn-primary me-2"
                                onClick={handleImageOkClick}
                              >
                                Upload new image
                              </button>
                              <button
                                className="btn btn-secondary"
                                onClick={handleImageCancelClick}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        )}

                        {/*
                      <button
                        class="btn btn-lg btn-success mt-1"
                        type="submit"
                        disabled={action_loading} >
                        Upload
                      </button>
                    */}
                      </div>
                    </form>
                  </div>
                </div>

                <div class="col-md-9">
                  <hr />
                  <form onSubmit={updateProfile}>
                    <div class="row" style={{ border: "1px solid #000" }}>
                      <h3>Personal details</h3>
                      <div class="form-group col-md-6 my-3">
                        <label for="last_name">First name</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Name",
                            name: "Name",
                            type: "text",
                            placeholder: "First name",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_first_name_invalid(res)
                          }
                          value={first_name}
                          disabled={false}
                          classNameInput="form-control"
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(first_name, e) => {
                            set_first_name(e.target.value);
                          }}
                          validationOption={{
                            name: "Name",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="phone">Surname</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Surname",
                            name: "Surname",
                            type: "text",
                            placeholder: "Surname",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_last_name_invalid(res)
                          }
                          value={last_name}
                          disabled={false}
                          classNameInput="form-control"
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(last_name, e) => {
                            set_last_name(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          validationOption={{
                            name: "Surname",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="phone">Mobile number</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Phone",
                            name: "Phone",
                            type: "text",
                            placeholder: "Phone number. eg 263774756502",
                          }}
                          // validate={validate}
                          validationCallback={(res) => set_phone_invalid(res)}
                          value={phone}
                          disabled={false}
                          classNameInput="form-control"
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(phone, e) => {
                            set_phone(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          validationOption={{
                            name: "Name",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            reg: /^\d{7}|\d{16}$/,
                            regMsg: "Invalid phone number",
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-6 my-3">
                        <label for="first_name">Email</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "email",
                            name: "email",
                            type: "text",
                            placeholder: "Email",
                          }}
                          // validate={validate}
                          validationCallback={(res) => set_email_invalid(res)}
                          value={email}
                          disabled={true}
                          classNameInput="form-control"
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(email, e) => {
                            set_email(e.target.value);
                          }}
                          validationOption={{
                            name: "email",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>
                    </div>

                    <div class="row mt-5" style={{ border: "1px solid #000" }}>
                      {/* <h3>Personal details</h3> */}
                      <div class="form-group col-md-4 my-3">
                        <label for="phone">Default buying country</label>
                        <select
                          class="form-control"
                          name="country"
                          id="country"
                          onChange={(e) => country_changed(e.target.value)}
                        >
                          <option value={country}>{country}</option>
                          {countries.length > 0
                            ? countries.map((c) => (
                                <option key={c._id} value={c.country}>
                                  {c.country}
                                </option>
                              ))
                            : ""}
                        </select>
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="city">Default buying location</label>
                        <select
                          class="form-control"
                          name="city"
                          id="city"
                          onChange={(e) => city_changed(e.target.value)}
                        >
                          <option value={city}>{city}</option>
                          {cities.length > 0
                            ? cities.map((c) =>
                                country_meta === c.country ? (
                                  <option key={c._id} value={c.city}>
                                    {c.city}
                                  </option>
                                ) : (
                                  <></>
                                )
                              )
                            : ""}
                        </select>
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label>Do you usually require delivery</label>
                        <select
                          class="form-control"
                          name="require_delivery"
                          id="require_delivery"
                          onChange={(e) =>
                            require_delivery_changed(e.target.value)
                          }
                        >
                          <option value={require_delivery}>
                            {require_delivery ? "Yes" : "No"}
                          </option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </div>
                    </div>

                    <div class="row mt-5" style={{ border: "1px solid #000" }}>
                      <h3>Delivery location details</h3>

                      <div class="form-group col-md-4 my-3">
                        <label for="phone">Province</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "province",
                            name: "province",
                            type: "text",
                            placeholder: "Province",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_province_invalid(res)
                          }
                          value={province}
                          disabled={false}
                          classNameInput="form-control"
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(province, e) => {
                            set_province(e.target.value);
                          }}
                          validationOption={{
                            name: "province",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="city">City or town</label>
                        <select
                          class="form-control"
                          name="city"
                          id="city"
                          onChange={(e) => city_changed(e.target.value)}
                        >
                          <option value={city}>{city}</option>
                          {cities.length > 0
                            ? cities.map((c) =>
                                country_meta === c.country ? (
                                  <option key={c._id} value={c.city}>
                                    {c.city}
                                  </option>
                                ) : (
                                  ""
                                )
                              )
                            : ""}
                        </select>
                      </div>

                      <div class="form-group col-md-4 my-3"></div>

                      <div class="form-group col-md-4 my-3">
                        <label for="phone">Suburb</label>
                        <select
                          class="form-control"
                          name="suburb"
                          id="suburb"
                          onChange={(e) => set_suburb(e.target.value)}
                        >
                          <option value={suburb}>{suburb}</option>
                          {suburbs.length > 0
                            ? suburbs.map((c) =>
                                city_meta === c.city ? (
                                  <option key={c._id} value={c.suburb}>
                                    {c.suburb}
                                  </option>
                                ) : (
                                  ""
                                )
                              )
                            : ""}
                        </select>
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="phone">Street address line 1</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "address1",
                            name: "address1",
                            type: "text",
                            placeholder: "Street address",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_address_line1_invalid(res)
                          }
                          value={address_line1}
                          disabled={false}
                          classNameInput="form-control"
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(address_line1, e) => {
                            set_address_line1(e.target.value);
                          }}
                          validationOption={{
                            name: "address1",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>
                      <div class="form-group col-md-4 my-3">
                        <label for="phone">Street address line 2</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "address2",
                            name: "address2",
                            type: "text",
                            placeholder: "Street address line 2",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_address_line2_invalid(res)
                          }
                          value={address_line2}
                          disabled={false}
                          classNameInput="form-control"
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(address_line2, e) => {
                            set_address_line2(e.target.value);
                          }}
                          validationOption={{
                            name: "address2",
                            check: true,
                            required: true,
                            type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            // min: 2,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{18}|\d{15}$/,
                            // regMsg: 'failed in reg.test(${value})',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div
                        class="col-md-9 row m-5"
                        style={{ border: "1px solid #000" }}
                      >
                        <h3>Coordinates</h3>
                        <div class="form-group col-md-6 my-3">
                          <label>Latitude</label>
                          <input
                            type="text"
                            class="form-control"
                            value={latitude}
                            id="latitude"
                            onChange={(e) => set_latitude(e.target.value)}
                          />
                        </div>

                        <div class="form-group col-md-6 my-3">
                          <label>Longitude</label>
                          <input
                            type="text"
                            class="form-control"
                            value={longitude}
                            id="longitude"
                            onChange={(e) => set_longitude(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-12 my-3">
                      <br />
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        disabled={action_loading}
                        // check invalids
                      >
                        <i class="glyphicon glyphicon-ok-sign"></i> Save
                      </button>
                      {/* <button class="btn btn-lg" type="reset"><i class="glyphicon glyphicon-repeat"></i> Reset</button> */}
                    </div>
                  </form>

                  {/* <hr /> */}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
export default BuyerProfileScreen;
