//AppContext.js
import React from "react";

const AppContext = React.createContext({
  headerHeight: 0,
  footerHeight: 0,
  setHeaderHeight: () => {},
  setFooterHeight: () => {},
});

export default AppContext;
