import React, { useState, useEffect } from "react";
import { getOrders, getOrdersV2 } from "../actions/orderActions";
import { validate_multiple_roles } from "../validations";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { Link } from "react-router-dom";
import { get_date_string, get_display_date } from "../config";
import Spinner from "../components/spinner";

function AdminOrdersScreen(props) {
  // console.log('page loaded')

  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["admin", "sales-support"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const status = props.match.params.status
    ? props.match.params.status
    : "Pending";
  // console.log(status)

  // console.log(status)

  const [error_message, set_error_message] = useState("");
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState("");

  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  const [pending_orders, set_pending_orders] = useState([]);
  const [confirmed_orders, set_confirmed_orders] = useState([]);
  const [rejected_orders, set_rejected_orders] = useState([]);

  // eslint-disable-next-line
  const [id, set_id] = useState("id");
  // eslint-disable-next-line
  const [phone, set_phone] = useState("phone");
  // eslint-disable-next-line
  const [email, set_email] = useState("email");
  // eslint-disable-next-line
  const [city, set_city] = useState("city");
  // eslint-disable-next-line
  const [delivery_zone, set_delivery_zone] = useState("delivery_zone");
  // eslint-disable-next-line
  const [payment_method, set_payment_method] = useState("payment_method");
  // eslint-disable-next-line
  const [client_id, set_client_id] = useState("client_id");
  // eslint-disable-next-line
  // const [status, set_status] = useState('status');

  // eslint-disable-next-line
  const [add_city_invalid, set_add_city_invalid] = useState(true);

  const link_cell_formatter = (cell, row) => {
    return (
      <div>
        <Link to={`${cell}/${row.age}`}>{cell}</Link>
      </div>
    );
  };

  const load_page_data = async () => {
    // console.log('load_page_data')
    let get_orders;
    try {
      get_orders = await getOrdersV2("id", "buyer", "seller", "status");
      console.log(get_orders);
      // console.log('get orders', get_orders);
      if (get_orders.success) {
        const { data } = get_orders;

        let _pending_orders = [];
        let _confirmed_orders = [];
        let _rejected_orders = [];

        for (let i = 0; i < data.length; i++) {
          data[i].order_value =
            data[i].quantity * data[i].product_details.price;
          data[i].order_date = get_display_date(data[i].createdAt); //(data[i].createdAt).format('MMMM d, YYYY')
          data[i].customer = "";
          data[i].address = "";
          data[i].seller = data[i].seller_details.display_name;
          data[i].buyer = data[i].buyer_details.display_name;
          data[i].status = data[i].status;
          data[i].order_items =
            data[i].quantity +
            " " +
            data[i].product_details.price_per +
            " " +
            data[i].product_details.product_name;
          // data[i].action = <button class="btn btn-success btn-lg" onClick={() => redo_order(data[i].product, data[i].quantity)}>Buy again</button>

          if (data[i].status === "Pending") _pending_orders.push(data[i]);
          if (data[i].status === "Confirmed") _confirmed_orders.push(data[i]);
          if (data[i].status === "Rejected") _rejected_orders.push(data[i]);
        }

        set_pending_orders(_pending_orders);
        set_confirmed_orders(_confirmed_orders);
        set_rejected_orders(_rejected_orders);
      } else {
        set_error_message("No orders found");
      }
    } catch (error) {
      set_error_message(`An error occured fetching orders: ${error.message} `);
    }

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  const redo_order = async (product_id, quantity) => {
    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    // try {
    //   const response = await createOrderV2(product_id, quantity);
    //   if (response.success) {
    //     await load_page_data();

    //     set_success_message('New order successfully placed.');

    //   } else {
    //     set_error_message(response.message);
    //   }
    // } catch (error) {
    //   console.log(`${error.message}`);
    //   set_error_message('An error occured in completing your order. Please try again');
    // }

    set_action_loading(false);

    return;
  };

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">{status}</span>{" "}
              <span class="orange-text">Orders</span>
            </h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-12">
                      {error_message !== "" ? (
                        <div class="alert alert-danger" role="alert">
                          {error_message}
                        </div>
                      ) : (
                        ""
                      )}
                      {success_message !== "" ? (
                        <div class="alert alert-success" role="alert">
                          {success_message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div class="row">
            <div class="col-12">
              <BootstrapTable
                data={
                  status === "Pending"
                    ? pending_orders
                    : status === "Confirmed"
                    ? confirmed_orders
                    : status === "Rejected"
                    ? rejected_orders
                    : []
                }
                search
                pagination
              >
                <TableHeaderColumn dataField="order_date" isKey={true}>
                  Order date
                </TableHeaderColumn>
                {/* <TableHeaderColumn dataField='tracking_number'>Tracking number</TableHeaderColumn> */}
                <TableHeaderColumn dataField="order_items">
                  Description
                </TableHeaderColumn>
                <TableHeaderColumn dataField="order_value">
                  Order value
                </TableHeaderColumn>
                <TableHeaderColumn dataField="seller">Seller</TableHeaderColumn>
                <TableHeaderColumn dataField="buyer">Buyer</TableHeaderColumn>
                <TableHeaderColumn dataField="status">Status</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='status'>Status</TableHeaderColumn> */}
                {/* <TableHeaderColumn dataField='action' dataFormat={link_cell_formatter} isKey={true}></TableHeaderColumn> */}
              </BootstrapTable>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default AdminOrdersScreen;
