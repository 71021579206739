import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  getProducts,
  updateProduct,
  deductQuantity,
} from "../actions/productActions";
import { getCategories } from "../actions/categoryActions";
import { validate_role } from "../validations";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import { API_URL, API_KEY, get_display_date } from "../config";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "../components/spinner";

function AdminProductScreen(props) {
  const selling_methods = [
    {
      name: "peleka",
      description:
        "Peleka Marketplace to sell and deliver on behalf of seller.",
    },
    {
      name: "direct",
      description: "Customer to buy directly from seller. ",
    },
    {
      name: "hybrid",
      description:
        "Customer should choose if they want to buy directly from seller or through MarketPlace",
    },
  ];

  const [file, setFile] = useState(""); // storing the uploaded file
  // eslint-disable-next-line
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element
  const handleChange = (e) => {
    setProgess(0);
    const file = e.target.files[0]; // accesing file
    setFile(file); // storing file
  };

  const [delete_stock_dialog, set_delete_stock_dialog] = React.useState(false);

  const delete_stock_dialog_open = () => {
    set_delete_stock_dialog(true);
  };

  const delete_stock_dialog_close = () => {
    set_delete_stock_dialog(false);
  };

  // eslint-disable-next-line
  let user_profile;
  const permitted_role = "admin";
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = "login";
    if (permitted_role === "seller") login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const id = props.match.params.id ? props.match.params.id : "";

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");

  const [upload_error_message, set_upload_error_message] = useState("");
  const [upload_success_message, set_upload_success_message] = useState("");

  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  // eslint-disable-next-line
  const [add_category, set_add_category] = useState("");
  // eslint-disable-next-line
  const [add_sub_category, set_add_sub_category] = useState("");
  const [add_product, set_add_product] = useState("");
  // eslint-disable-next-line
  const [add_description, set_add_description] = useState("");
  // eslint-disable-next-line
  const [add_units, set_add_units] = useState("");
  const [add_quantity, set_add_quantity] = useState("");
  const [add_price, set_add_price] = useState("");
  // eslint-disable-next-line
  const [add_organic, set_add_organic] = useState(true);

  const [selling_method, set_selling_method] = useState("");
  const [current_selling_method, set_current_selling_method] = useState("");

  const [deduct_quantity, set_deduct_quantity] = useState("");
  const [deduct_description, set_deduct_description] = useState("");

  // const [add_category_invalid, set_add_category_invalid] = useState(true);
  // const [add_sub_category_invalid, set_add_sub_category_invalid] = useState(true);
  // const [add_product_invalid, set_add_product_invalid] = useState(true);
  // const [add_description_invalid, set_add_description_invalid] = useState(true);
  // const [add_units_invalid, set_add_units_invalid] = useState(true);
  // const [add_quantity_invalid, set_add_quantity_invalid] = useState(true);
  // const [add_price_invalid, set_add_price_invalid] = useState(true);
  // const [add_organic_invalid, set_add_organic_invalid] = useState(true);

  // const [deduct_description_invalid, set_deduct_description_invalid] = useState(true);
  // eslint-disable-next-line
  const [deduct_quantity_invalid, set_deduct_quantity_invalid] = useState(true);

  const [product_details, set_product_details] = useState([]);
  const [product_sales, set_product_sales] = useState([]);
  const [product_pictures, set_product_pictures] = useState([]);
  // eslint-disable-next-line
  const [all_categories, set_all_categories] = useState([]);
  // eslint-disable-next-line
  const [all_product_types, set_all_product_types] = useState([]);

  // eslint-disable-next-line
  const [display_sub_categories, set_display_sub_categories] = useState([]);
  // eslint-disable-next-line
  const [display_product_types, set_display_product_types] = useState([]);
  // eslint-disable-next-line
  const [display_units, set_display_units] = useState([]);

  const uploadFile = async () => {
    set_success_message("");
    set_error_message("");
    set_upload_success_message("");
    set_upload_error_message("");

    set_action_loading(true);

    //   TODO: get these from config file
    const token_string = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", file); // appending file
    formData.append("api_key", API_KEY);
    formData.append("product_id", id);
    await axios
      .post(
        `${API_URL}/products/upload-image`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token_string,
          },
        },
        {
          onUploadProgress: (ProgressEvent) => {
            let progress =
              Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
              "%";
            setProgess(progress);
          },
        }
      )
      .then((res) => {
        const { data } = res;
        if (data.success === true) {
          set_upload_success_message("New image successfully added.");
        } else if (data.success === false) {
          set_upload_error_message(data.message);
        } else {
          set_upload_error_message(`An error occured uploading product image`);
        }
        // console.log(res);
        // getFile({
        //     name: res.data.name,
        //     path: 'http://127.0.0.1:4002/routes/public/' + res.data.path
        // });
      })
      .catch
      // set_upload_error_message('An error occured uploading product image')
      ();

    let get_product_details;
    try {
      get_product_details = await getProducts(
        id,
        "seller",
        "farm",
        "product_meta",
        "sub_category_meta",
        "category_meta",
        "product_name",
        "quantity",
        "quantity_units",
        "min_price",
        "max_price",
        "delivery_zone",
        "featured",
        "on_sale",
        "sort_order"
      );
      if (get_product_details.success === false) {
        set_error_message(get_product_details.message);
      } else {
        // console.log(get_product_details);
        const { data } = get_product_details;
        set_product_pictures(data[0].pictures);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching categories: ${error.message} `
      );
    }
    set_action_loading(false);
  };

  /**
   * Deducts quantity. record offsite sales
   *
   * @param {*} e
   */
  const deductProductQuantity = async (e) => {
    e.preventDefault();
    if (page_loaded === false) {
      return;
    }

    set_success_message("");
    set_error_message("");
    set_upload_success_message("");
    set_upload_error_message("");

    set_action_loading(true);

    if (deduct_description === "") {
      set_error_message("Description cannot be null. Please try again");
      set_action_loading(false);
      return;
    }

    const response = await deductQuantity(
      id,
      deduct_quantity,
      deduct_description
    );
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      let get_product_details;
      let _product_details;
      try {
        get_product_details = await getProducts(
          id,
          "seller",
          "farm",
          "product_meta",
          "sub_category_meta",
          "category_meta",
          "product_name",
          "quantity",
          "quantity_units",
          "min_price",
          "max_price",
          "delivery_zone",
          "featured",
          "on_sale",
          "sort_order"
        );
        if (get_product_details.success === false) {
          set_error_message(get_product_details.message);
        } else {
          // console.log(get_product_details);
          const { data } = get_product_details;
          _product_details = data[0];
          set_add_category(_product_details.category_id);
          set_add_sub_category(_product_details.sub_category_meta);
          set_add_product(_product_details.product_id);
          set_add_description(_product_details.description);
          set_add_units(_product_details.quantity_units);
          set_add_quantity(_product_details.quantity);
          set_add_price(_product_details.price);
          set_add_organic(_product_details.organic);
          set_product_details(data[0]);
          // console.log('product sales', );
          set_product_sales(data[0].sales);
          set_product_pictures(data[0].pictures);
        }
      } catch (error) {
        set_error_message(
          `An error occured fetching categories: ${error.message} `
        );
      }

      set_success_message("Deduction successfully done.");
    }
    set_action_loading(false);

    return;
  };

  /**
   * Deducts all remaining inventory items of this product
   *
   * @param {*} e
   */
  const deductAllStockQuantity = async (e) => {
    e.preventDefault();
    if (page_loaded === false) {
      return;
    }

    set_success_message("");
    set_error_message("");
    set_upload_success_message("");
    set_upload_error_message("");

    set_action_loading(true);

    const response = await deductQuantity(id, add_quantity, "4");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      props.history.push("/admin-products");

      set_success_message("Product successfully removed from stock inventory");
    }
    set_action_loading(false);

    return;
  };

  // const category_changed = (new_category) => {
  //   let _display_sub_categories = [];
  //   let _display_product_types = [];

  //   set_add_category(new_category);
  //   set_add_sub_category('');
  //   set_add_product('');

  //   if (new_category !== '') {
  //     for(let i = 0; i < all_categories.length; i++ ) {
  //       if (all_categories[i]._id === new_category) {
  //         _display_sub_categories = all_categories[i].sub_categories;
  //       }
  //     }

  //     for (let i = 0; i < all_product_types.length; i++) {
  //       if (all_product_types[i].category_id === new_category) {
  //         _display_product_types.push(all_product_types[i]);
  //       }
  //     }
  //   }

  //   set_display_sub_categories(_display_sub_categories);
  //   set_display_product_types(_display_product_types);
  // }

  // const sub_category_changed = (new_sub_category) => {
  //   let _display_product_types = [];

  //   set_add_sub_category(new_sub_category);
  //   set_add_product('');

  //   if (new_sub_category !== '') {
  //     for (let i = 0; i < all_product_types.length; i++) {
  //       if (all_product_types[i].sub_category_meta === new_sub_category) {
  //         _display_product_types.push(all_product_types[i]);
  //       }
  //     }
  //   }

  //   set_display_product_types(_display_product_types);
  // }

  // const product_type_changed = (new_product_type) => {
  //   let _display_units = [];

  //   set_add_product(new_product_type);

  //   if (new_product_type !== '') {
  //     for (let i = 0; i < all_product_types.length; i++) {
  //       if (all_product_types[i]._id === new_product_type) {
  //         console.log(all_product_types[i]._id, all_product_types[i].units);
  //         _display_units = all_product_types[i].units;
  //       }
  //     }
  //   }

  //   set_display_units(_display_units);
  // }

  const load_page_data = async () => {
    let get_all_categories;
    let get_product_details;
    let _product_details;

    try {
      get_product_details = await getProducts(
        id,
        "seller",
        "farm",
        "product_meta",
        "sub_category_meta",
        "category_meta",
        "product_name",
        "quantity",
        "quantity_units",
        "min_price",
        "max_price",
        "delivery_zone",
        "featured",
        "on_sale",
        "sort_order"
      );
      if (get_product_details.success === false) {
        set_error_message(get_product_details.message);
      } else {
        // console.log(get_product_details);
        const { data } = get_product_details;
        _product_details = data[0];
        set_add_category(_product_details.category_id);
        set_add_sub_category(_product_details.sub_category_meta);
        set_add_product(_product_details.product_id);
        set_add_description(_product_details.description);
        set_add_units(_product_details.quantity_units);
        set_add_quantity(_product_details.quantity);
        set_add_price(_product_details.price);
        set_add_organic(_product_details.organic);
        set_product_details(data[0]);
        // console.log('product sales', );
        set_product_sales(data[0].sales);
        set_product_pictures(data[0].pictures);

        for (let i = 0; i < selling_methods.length; i++) {
          if (selling_methods[i].name === data[0].selling_method) {
            set_current_selling_method(selling_methods[i].description);
          }
        }
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching categories: ${error.message} `
      );
    }

    try {
      get_all_categories = await getCategories("id", "meta", "active");
      if (get_all_categories.success === false) {
        set_error_message(get_all_categories.message);
      } else {
        const { data } = get_all_categories;
        set_all_categories(data);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching categories: ${error.message} `
      );
    }

    return;
  };

  /**
   * Updates user profile
   * @param {*} e
   */
  const updateProduce = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");
    set_upload_success_message("");
    set_upload_error_message("");

    set_action_loading(true);

    const update_data = {
      product_id: add_product,
      selling_method,
    };

    const response = await updateProduct(update_data, id, "selling_method");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      let get_product_details;
      let _product_details;
      try {
        get_product_details = await getProducts(
          id,
          "seller",
          "farm",
          "product_meta",
          "sub_category_meta",
          "category_meta",
          "product_name",
          "quantity",
          "quantity_units",
          "min_price",
          "max_price",
          "delivery_zone",
          "featured",
          "on_sale",
          "sort_order"
        );
        if (get_product_details.success === false) {
          set_error_message(get_product_details.message);
        } else {
          // console.log(get_product_details);
          const { data } = get_product_details;
          _product_details = data[0];
          set_add_category(_product_details.category_id);
          set_add_sub_category(_product_details.sub_category_meta);
          set_add_product(_product_details.product_id);
          set_add_description(_product_details.description);
          set_add_units(_product_details.quantity_units);
          set_add_quantity(_product_details.quantity);
          set_add_price(_product_details.price);
          set_add_organic(_product_details.organic);
          set_product_details(data[0]);
          // console.log('product sales', );
          set_product_sales(data[0].sales);
          set_product_pictures(data[0].pictures);
        }
      } catch (error) {
        set_error_message(
          `An error occured fetching categories: ${error.message} `
        );
      }

      set_success_message("Product details successfully updated");
    }
    set_action_loading(false);

    return;
  };

  /**
   * Updates user profile
   * @param {*} e
   */
  const updateFeatured = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");
    set_upload_success_message("");
    set_upload_error_message("");

    set_action_loading(true);

    const update_data = {
      featured: !product_details.featured,
    };

    const response = await updateProduct(update_data, id, "mark_featured");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      let get_product_details;
      let _product_details;
      try {
        get_product_details = await getProducts(
          id,
          "seller",
          "farm",
          "product_meta",
          "sub_category_meta",
          "category_meta",
          "product_name",
          "quantity",
          "quantity_units",
          "min_price",
          "max_price",
          "delivery_zone",
          "featured",
          "on_sale",
          "sort_order"
        );
        if (get_product_details.success === false) {
          set_error_message(get_product_details.message);
        } else {
          const { data } = get_product_details;
          _product_details = data[0];
          console.log(data[0]);
          set_add_category(_product_details.category_id);
          set_add_sub_category(_product_details.sub_category_meta);
          set_add_product(_product_details.product_id);
          set_add_description(_product_details.description);
          set_add_units(_product_details.quantity_units);
          set_add_quantity(_product_details.quantity);
          set_add_price(_product_details.price);
          set_add_organic(_product_details.organic);
          set_product_details(data[0]);
          // console.log('product sales', );
          set_product_sales(data[0].sales);
          set_product_pictures(data[0].pictures);
        }
      } catch (error) {
        set_error_message(
          `An error occured fetching product details after update: ${error.message} `
        );
      }

      set_success_message("Product featured status successfully updated.");
    }
    set_action_loading(false);

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">Viewing your</span>{" "}
              <span class="orange-text">product details</span>
            </h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== "" ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : (
                    ""
                  )}
                  {success_message !== "" ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h2>Basic details</h2>
                  <form onSubmit={updateProduce}>
                    <div class="row">
                      <div class="form-group col-md-4 my-3">
                        <label for="">Category</label>
                        <input
                          class="form-control"
                          value={product_details.category}
                          disabled
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="">Sub category</label>
                        <input
                          class="form-control"
                          value={product_details.sub_category}
                          disabled
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="">Product</label>
                        <input
                          class="form-control"
                          value={product_details.product_name}
                          disabled
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="">Units</label>
                        <input
                          class="form-control"
                          value={product_details.quantity_units}
                          disabled
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="add_quantity">Quantity</label>

                        <input
                          class="form-control"
                          value={product_details.quantity}
                          disabled
                        />
                      </div>

                      {/* <div class="form-group col-md-4 my-3"> 
                          <label for="">Is this food organic ?</label>
                          <input class="form-control" value={add_organic === true ? 'Yes' : 'No'} disabled />
                        </div> */}

                      <div class="form-group col-md-4 my-3">
                        <label for="add_price">Price</label>
                        <input
                          type="number"
                          step="0.01"
                          class="form-control"
                          value={add_price}
                          required
                          name="add_price"
                          onChange={(e) => {
                            set_add_price(e.target.value);
                          }}
                          min="1"
                          disabled
                        />
                      </div>

                      <div class="form-group col-md-12 my-3">
                        <label for="">Selling method</label>
                        <select
                          class="form-control"
                          name="selling_method"
                          id="selling_method"
                          onChange={(e) => set_selling_method(e.target.value)}
                        >
                          <option value={selling_method}>
                            {current_selling_method}
                          </option>
                          {selling_methods.map((c) => (
                            <option key={c.name} value={c.name}>
                              {c.description}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div class="form-group col-12 my-3 text-center">
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        disabled={action_loading}
                        // check invalids
                      >
                        <i class="glyphicon glyphicon-plus"></i> Update product
                        price
                      </button>
                    </div>
                  </form>

                  <hr />
                </div>

                <div class="col-md-12">
                  <h2>Update pictures</h2>
                  <div class="row">
                    <div class="col-12">
                      {upload_error_message !== "" ? (
                        <div class="alert alert-danger" role="alert">
                          {upload_error_message}
                        </div>
                      ) : (
                        ""
                      )}
                      {upload_success_message !== "" ? (
                        <div class="alert alert-success" role="alert">
                          {upload_success_message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-12">
                      <div class="row">
                        {product_pictures.map((c) => (
                          <div class="col-md-3" key={c}>
                            <div class="info-box product-box">
                              <img src={`${API_URL}/${c}`} alt="Product" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div class="form-group col-4 my-3 text-center">
                      <input type="file" ref={el} onChange={handleChange} />
                      {/* <div className="progessBar" style={{ width: progress }}>
                    {progress}
                    </div> */}

                      {/* {data.path && <img src={data.path} alt={data.name} />} */}
                    </div>

                    <div class="col-4"></div>
                    <div class="col-4"></div>

                    <div class="form-group col-4 my-3 text-center">
                      <button
                        onClick={uploadFile}
                        class="btn btn-lg btn-success"
                      >
                        Upload new image
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>

                <div class="col-md-12">
                  <h2>Sales &amp; quantity deductions</h2>
                  {product_sales.length > 0 ? (
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <tr>
                          <th>Date of transaction</th>
                          <th>Quantity</th>
                          <th>Sale type</th>
                          <th>Description</th>
                        </tr>

                        {product_sales.map((c) => (
                          <tr key={c._id}>
                            <td>{get_display_date(c.date_time)}</td>
                            <td>{c.deduct_quantity}</td>
                            <td>{c.sale_type}</td>
                            <td>
                              {c.description === 1
                                ? "I sold this quantity to other buyers (not from Farm-Fresh)"
                                : ""}
                              {c.description === 2
                                ? "This quantity has gone bad and no longer fit for sale"
                                : ""}
                              {c.description === 3
                                ? "I gifted someone with this quantity"
                                : ""}
                              {c.description === 4
                                ? "Quantity is no longer in stock"
                                : ""}
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  ) : (
                    "No sales or deductions made so far."
                  )}

                  <br />
                  <br />
                  <br />
                  <h2>Deduct quantity</h2>
                  <form onSubmit={deductProductQuantity}>
                    <div class="row">
                      <div class="form-group col-md-4 my-3">
                        <label for="deduct_quantity">Deduct quantity</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "deduct_quantity",
                            name: "deduct_quantity",
                            type: "number",
                            placeholder: "",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_deduct_quantity_invalid(res)
                          }
                          value={deduct_quantity}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(deduct_quantity, e) => {
                            set_deduct_quantity(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "deduct_quantity",
                            check: true,
                            required: true,
                            type: "number", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            min: 1,
                            max: { add_quantity },
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="">
                          Why are you deducting this quantity?
                        </label>
                        <select
                          class="form-control"
                          name="deduct_description"
                          id="deduct_description"
                          onChange={(e) =>
                            set_deduct_description(e.target.value)
                          }
                        >
                          <option value=""></option>
                          <option value="1">Seller is unreachable</option>
                          <option value="2">
                            Seller no longer has the product
                          </option>
                          <option value="3">
                            The product has been there for too long
                          </option>
                          {/* <option value="4">Quantity is no longer in stock</option> */}
                        </select>
                      </div>
                    </div>

                    <div class="form-group col-12 my-3 text-center">
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        disabled={action_loading}
                        // TODO: check invalids
                      >
                        {!action_loading ? (
                          "Deduct quantity"
                        ) : (
                          <>
                            <div
                              class="spinner-border text-orange"
                              role="status"
                            ></div>
                            &nbsp;Please wait
                          </>
                        )}
                      </button>
                    </div>
                  </form>

                  {/* <form onSubmit={}> */}
                  <div class="form-group col-12 my-3 text-left">
                    <button
                      class="btn btn-lg btn-success"
                      type="submit"
                      onClick={delete_stock_dialog_open}
                      disabled={action_loading}
                    >
                      {!action_loading ? (
                        "Remove product from listed products"
                      ) : (
                        <>
                          <div
                            class="spinner-border text-orange"
                            role="status"
                          ></div>
                          &nbsp;Please wait
                        </>
                      )}
                    </button>
                  </div>

                  {/* </form> */}
                  <Dialog
                    open={delete_stock_dialog}
                    onClose={delete_stock_dialog_close}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Confirm removing all stock items
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Are you sure you want to remove all stock items?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={delete_stock_dialog_close}
                        color="primary"
                      >
                        Cancel
                      </button>

                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={deductAllStockQuantity}
                        color="primary"
                      >
                        Yes, remove all items
                      </button>
                    </DialogActions>
                  </Dialog>

                  <hr />
                </div>

                <div class="col-md-12">
                  {product_details.featured}

                  {product_details.featured ? (
                    <button
                      class="btn btn-lg btn-success"
                      type="submit"
                      onClick={updateFeatured}
                      color="primary"
                    >
                      Remove from featured products
                    </button>
                  ) : (
                    <button
                      class="btn btn-lg btn-success"
                      type="submit"
                      onClick={updateFeatured}
                      color="primary"
                    >
                      Mark as part of featured product
                    </button>
                  )}

                  <p class="mt-3">
                    Featured products are the editor's picks that will display
                    on the home page{" "}
                  </p>
                  <hr />
                </div>

                <div class="col-md-12">
                  <h2>Notes</h2>
                  TBI
                  <hr />
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
export default AdminProductScreen;
