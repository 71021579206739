import React, { useState, useEffect } from 'react';
import { validate_role, validate_multiple_roles } from '../validations';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { getOrderItems, getOrdersV2, updateOrder } from '../actions/orderActions';
import { retrieveProducts, getInventoryAlerts, } from '../actions/productActions';
import { get_display_date } from '../config';
import { Link } from 'react-router-dom';
import { getNotifications, updateNotification } from '../actions/notificationActions';
import { updateVerification, updateVerification2 } from '../actions/verificationActions';
import { getUser } from '../actions/userActions'; //check if user_city is nothing

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useSocket } from '../hooks/useSocket';

function SellerDashboardScreen(props) {
  let user_profile;
  const permitted_roles = ['seller', 'buyer'];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = 'access-forbidden';
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;

    if (!(user_profile.roles).includes('seller')) {
      props.history.push('shop')
    }
  }

  const [total_unread_messages, set_total_unread_messages] = useState('');

  // eslint-disable-next-line 
  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');
  const [order_confirmation_success_message, set_order_confirmation_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);

  const [new_notifications, set_new_notifications] = useState([]);

  const [buyer_to_message, set_buyer_to_message] = useState('');

  const [new_verification_id, set_new_verification_id] = useState('');
  const [new_notification_id, set_new_notification_id] = useState('');

  const [active_orders, set_active_orders] = useState('');
  const [products_count, set_products_count] = useState('');
  const [products_last_updated, set_products_last_updated] = useState('');
  const [products_last_updated_warning, set_products_last_updated_warning] = useState(false);
  // eslint-disable-next-line
  const [inventory_alerts_count, set_inventory_alerts_count] = useState('');
  // eslint-disable-next-line
  const [inventory_alerts_warning, set_inventory_alerts_warning] = useState(false);
  // eslint-disable-next-line
  const [inventory_alerts_text, set_inventory_alerts_text] = useState('');
  const [sales_history_value, set_sales_history_value] = useState('');
  // eslint-disable-next-line
  const [perfomance_summary_rating, set_perfomance_summary_rating] = useState('');
  // eslint-disable-next-line
  const [perfomance_summary_rating_last_update, set_perfomance_summary_rating_last_update] = useState('');
  // eslint-disable-next-line
  const [case_logs, set_case_logs] = useState('');

  const [new_orders, set_new_orders] = useState([]);

  const [listed_products, set_listed_products] = useState([]);

  const [confirm_farm_visit_dialog, set_confirm_farm_visit_dialog] = React.useState(false);
  const confirm_farm_visit_dialog_open = () => {
    set_confirm_farm_visit_dialog(true);
  };
  const confirm_farm_visit_dialog_close = () => {
    set_confirm_farm_visit_dialog(false);
  };

  const [visit_date, set_visit_date] = useState('');
  const [visit_time, set_visit_time] = useState('');
  const [visit_end_time, set_visit_end_time] = useState('');
  const [message_to_verifier, set_message_to_verifier] = useState('');

  const [suspension_status, set_suspension_status] = useState('');
  const [suspension_details, set_suspension_details] = useState({});


  const check_user_profile_completeness = async () => {
    // This function is to check if all the necessary profile elements are set,
    // and to redirect to seller-dashboard if not

    const { _id } = user_profile;
    try {
      const this_user_profile = await getUser(_id);

      if (this_user_profile.success === false) {
        set_error_message('An error occurred retrieving your profile. Please reload the page to try again');
      } else {
        const user_details = this_user_profile.data[0];

        const { seller_details, delivery, organic, approved, suspension_status, suspension_details } = user_details;

        // console.log('this user profile', this_user_profile.data[0])
        set_suspension_status(suspension_status)
        set_suspension_details(suspension_details)

        // Check if the necessary profile fields are set
        if (!seller_details[0].country || !seller_details[0].city ||
          !seller_details[0].suburb || !seller_details[0].address_line1) {
          // Redirect to the seller-profile page
          props.history.push({
            pathname: '/seller-profile',
            state: { alert: 'Please proceed to fill in the required location details on the Profile page before accessing Dashboard or Products page' }
          });

        }
      }
    } catch (error) {
      console.error(error);
      set_error_message('An error occurred fetching seller profile');
    }
  };




  const fetchSellerOrders = async () => {

    try {
      let totalUnreadMessagesCount = 0;
      const get_orders = await getOrdersV2('id', 'buyer', 'mine', 'status');
      if (get_orders.success) {
        const { data } = get_orders;
        for (let i = 0; i < data.length; i++) {

          totalUnreadMessagesCount += data[i].unread_messages_count;
        }

        set_total_unread_messages(totalUnreadMessagesCount);
        // You might also want to update the state or handle _active_orders and _sales_history_value
        // For example:
        // set_active_orders(_active_orders);
        // set_sales_history_value(_sales_history_value);
      }
    } catch (error) {
      set_error_message(`An error occurred fetching seller orders: ${error.message}`);
    }
  };


  const userId = user_profile._id
  // Define a callback function to fetch new messages
  //////////////////////////////////////////////////////////////////////////////////////
  const handleOrderUpdate = (data) => {
    // Assuming the event sends the ID of the updated order
    if (data.userId === userId) {
      //fetchSellerOrders();
      get_user_profile();
    }
  };

  // Use the useSocket hook with the callback
  useSocket(userId, null, null, handleOrderUpdate);
  //////////////////////////////////////////////////////////////////////////////////////



  const get_user_profile = async () => {
    



    let get_orders;
    try {
      get_orders = await getOrdersV2('id', 'buyer', 'mine', 'Pending');
      if (get_orders.success) {
        let { data } = get_orders;
        set_new_orders(data)
      }
    } catch (error) {
      set_error_message(`An error occured fetching new orders: ${error.message} `);
    }

    let get_notification;
    let _new_notifications = []
    try {
      get_notification = await getNotifications('id', user_profile._id, 'sender', 'false');
      if (get_notification.success) {
        let { data } = get_notification;
        // set_new_notifications(data)
        for (let i = 0; i < data.length; i++) {
          // data[i].seller_id = data[i].extra_details.seller_id
          // data[i].seller_name = data[i].extra_details.seller_name
          // data[i].seller_address = data[i].extra_details.seller_address
          _new_notifications.push(data[i])
        }
      }
    } catch (error) {
      set_error_message(`An error occured fetching notifications: ${error.message} `);
    }

    set_new_notifications(_new_notifications)

    let _active_orders = 0;
    let _products_count = 0;
    let _products_last_updated = '';
    let _products_last_updated_warning = false;
    let _inventory_alerts_count = 0;
    let _inventory_alerts_warning = false;
    let _inventory_alerts_text = '';
    let _sales_history_value = 0;
    let _perfomance_summary_rating = 0;
    let _perfomance_summary_rating_last_update = '';
    let _case_logs = 0;
    let totalUnreadMessagesCount = 0;

    try {
      get_orders = await getOrdersV2('id', 'buyer', 'mine', 'status');
      // console.log('get orders', get_orders)
      if (get_orders.success) {
        let { data } = get_orders;
        for (let i = 0; i < data.length; i++) {
          // console.log(i, data.length, data[i].status)

          if (data[i].status === 'Pending' || data[i].status === 'Confirmed' || data[i].status === 'Processing' || data[i].status === 'Awaiting collection' || data[i].status === 'In transit') {
            _active_orders++;
          }

          if (data[i].status === 'Delivered' || data[i].status === 'Collected' || data[i].status === 'Processing' || data[i].status === 'Awaiting collection' || data[i].status === 'In transit' || data[i].status === 'Confirmed') {
            // Confirmed, Processing, Awaiting collection, In transit, Collected/Delivered
            _sales_history_value += (parseFloat(data[i].quantity) * parseFloat(data[i].product_details.price));
          }

          totalUnreadMessagesCount += data[i].unread_messages_count;

        }
        set_total_unread_messages(totalUnreadMessagesCount);
      }
    } catch (error) {
      set_error_message(`An error occured fetching seller orders: ${error.message} `);
    }


    try {
      const get_seller_products = await retrieveProducts('id', user_profile._id, 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', '0', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order');

      if (get_seller_products.success) {
        const { count, data } = get_seller_products;
        _products_count = data.length;
        if (count > 0) {
          for (let i = 0; i < data.length; i++) {
            // console.log(data[i].updatedAt)
            // eslint-disable-next-line 
            if ((i + 1) == data.length) {
              _products_last_updated = get_display_date(data[i].updatedAt);
              _products_last_updated_warning = true;
            }
          }
        }

        let _listed_products = []
        for (let i = 0; i < data.length; i++) {
          // console.log(new Date(data[i].updatedAt), new Date())

          const difference_in_time = (new Date()).getTime() - (new Date(data[i].updatedAt)).getTime()
          const difference_in_days = difference_in_time / (1000 * 3600 * 24);

          // console.log(data[i].longevity, difference_in_days)
          if (data[i].longevity < difference_in_days) {
            _listed_products.push(data[i])
          }

        }

        // console.log(_listed_products)

        set_listed_products(_listed_products)
      } else {
        // set_error_message(get_seller_products.message);
      }
    } catch (error) {
      // set_error_message(`An error occured fetching seller orders: ${error.message} `);
    }

    try {
      const get_inventory_alerts = await getInventoryAlerts();
      if (get_inventory_alerts.success) {
        const { count, data } = get_inventory_alerts;
        _inventory_alerts_count = count;
        if (count > 0) {
          _inventory_alerts_warning = true;
          if (count > 2) {
            set_inventory_alerts_text('Over 2 products low')
          } else {
            for (let i = 0; i < data.length; i++) {
              _inventory_alerts_text = `${_inventory_alerts_text} ${data[i].product_name} `;
            }
            _inventory_alerts_text = `${_inventory_alerts_text} low`;
          }

        }
      } else {
        // set_error_message(get_inventory_alerts.message);
      }
    } catch (error) {
      // set_error_message(`An error occured fetching inventory alerts: ${error.message} `);
    }



    _active_orders = _active_orders < 10 ? `0${_active_orders}` : _active_orders;
    _products_count = _products_count < 10 ? `0${_products_count}` : _products_count;
    _inventory_alerts_count = _inventory_alerts_count < 10 ? `0${_inventory_alerts_count}` : _inventory_alerts_count;
    _sales_history_value = _sales_history_value < 10 ? `0${_sales_history_value}` : _sales_history_value;
    _perfomance_summary_rating = _perfomance_summary_rating < 10 ? `0${_perfomance_summary_rating}` : _perfomance_summary_rating;
    _case_logs = _case_logs < 10 ? `0${_case_logs}` : _case_logs;

    set_active_orders(_active_orders);
    set_products_count(_products_count);
    set_products_last_updated(_products_last_updated);
    set_products_last_updated_warning(_products_last_updated_warning);
    set_inventory_alerts_count(_inventory_alerts_count);
    set_inventory_alerts_warning(_inventory_alerts_warning);
    set_inventory_alerts_text(_inventory_alerts_text);
    set_sales_history_value(_sales_history_value);
    set_perfomance_summary_rating(_perfomance_summary_rating);
    set_perfomance_summary_rating_last_update(_perfomance_summary_rating_last_update);
    set_case_logs(_case_logs);


    return;
  }

  useEffect(() => {
    if (page_loaded === false) {
      get_user_profile();
      set_page_loaded(true);
      check_user_profile_completeness();
    }
    // eslint-disable-next-line
  }, []);

  const confirm_order = async (order_id) => {
    set_error_message('')
    set_success_message('')
    set_order_confirmation_success_message('')
    let update_data = {
      status: 'Confirmed',
      buyer_confirmation: 'confirm_order',
      buyer_confirmation_read: false,
    }

    let make_action;
    try {
      make_action = await updateOrder(update_data, order_id, 'confirm_order');
      if (make_action.success) {
        await get_user_profile()

        set_order_confirmation_success_message(make_action.message)
        set_buyer_to_message(order_id)

        // Update new_orders by filtering out the confirmed order
        const updatedOrders = new_orders.filter(nd => nd._id !== order_id);
        set_new_orders(updatedOrders);

        return
      } else {
        set_error_message(make_action.message)
      }
    } catch (error) {
      set_error_message(`An error occured fetching new orders: ${error.message} `);
    }
  }

  const confirm_availability = async (notification_id, verification_id) => {
    set_error_message('')
    set_success_message('')
    set_order_confirmation_success_message('')

    let update_data = {
      visit_date_confirmation: true,
    }

    let make_action;
    try {
      make_action = await updateNotification(update_data, notification_id, 'read');
      if (make_action.success) {
        await get_user_profile()

        // set_order_confirmation_success_message(make_action.message)
        set_success_message('Success')
        // set_buyer_to_message(make_action.buyer)

        return
      } else {
        set_error_message(make_action.message)
      }
    } catch (error) {
      set_error_message(`An error occured confirming availabiity: ${error.message} `);
    }




    // let make_action;
    try {
      make_action = await updateVerification(update_data, verification_id, 'visit_date_confirmation');
      if (make_action.success) {
        await get_user_profile()

        // set_order_confirmation_success_message(make_action.message)
        set_success_message('Success')
        // set_buyer_to_message(make_action.buyer)

        return
      } else {
        set_error_message(make_action.message)
      }
    } catch (error) {
      set_error_message(`An error occured confirming availabiity: ${error.message} `);
    }
  }


  const request_different_time = async (notification_id, verification_id) => {
    set_error_message('')
    set_success_message('')
    set_order_confirmation_success_message('')

    set_new_notification_id(notification_id)
    set_new_verification_id(verification_id)

    confirm_farm_visit_dialog_open()
  }

  const input_different_time = async (e) => {
    e.preventDefault();

    set_error_message('')
    set_success_message('')
    set_order_confirmation_success_message('')

    if (visit_date === '') {
      set_error_message('Visit date cannot be null')
      return
    }

    if (visit_time === '') {
      set_error_message('Visit start time cannot be null')
      return
    }

    if (visit_end_time === '') {
      set_error_message('Visit end time cannot be null')
      return
    }

    let update_data = {
      proposed_visit_date: visit_date,
      proposed_visit_time: visit_time,
      proposed_visit_end_time: visit_end_time,
      message_to_verifier,
    }

    // add new notification 

    let make_action;
    try {
      make_action = await updateVerification2(update_data, new_verification_id, 'visit_date_decline');
      if (make_action.success) {
        // await get_user_profile()

        // set_order_confirmation_success_message(make_action.message)
        // set_success_message('Success')
        // set_buyer_to_message(make_action.buyer)
        // confirm_farm_visit_dialog_close()

        // return
      } else {
        set_error_message(make_action.message)
      }
    } catch (error) {
      set_error_message(`An error occured confirming availabiity: ${error.message} `);
    }


    update_data = {
      visit_date_confirmation: true,
    }
    // console.log(new_notification_id)
    try {
      make_action = await updateNotification(update_data, new_notification_id, 'read');
      if (make_action.success) {
        await get_user_profile()

        // set_order_confirmation_success_message(make_action.message)
        set_success_message('Success')
        // set_buyer_to_message(make_action.buyer)
        confirm_farm_visit_dialog_close()

        return
      } else {
        set_error_message(make_action.message)
      }
    } catch (error) {
      set_error_message(`An error occured confirming availabiity: ${error.message} `);
    }


  }

  const reject_order = async (order_id) => {
    set_error_message('')
    set_success_message('')
    set_order_confirmation_success_message('')
    let update_data = {
      status: 'Declined',
      buyer_confirmation: 'reject_order',
      buyer_confirmation_read: false,
    }

    let make_action;
    try {
      make_action = await updateOrder(update_data, order_id, 'reject_order');
      if (make_action.success) {
        set_success_message('Order declined successfully')

        await get_user_profile()

        // Update new_orders by filtering out the confirmed order
        const updatedOrders = new_orders.filter(nd => nd._id !== order_id);
        set_new_orders(updatedOrders);


        return
      } else {
        set_error_message(make_action.message)
      }
    } catch (error) {
      set_error_message(`An error occured fetching new orders: ${error.message} `);
    }
  }



  return (
    <>
      <section id="contact" class="contact" style={{ backgroundColor: '#f8f8f8' }}>
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2><span class="black-text">My</span> <span class="green-text">Dashboard</span></h2>
          </div>


          {!page_loaded ? ( // so that only products will be loading at this time 
            <div>Loading ... </div>
          ) : (
            <>
              <div class="row">
                <div class="col-12 m-5">
                  {error_message !== '' ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : ('')}
                  {success_message !== '' ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : ('')}


                  {suspension_status !== 'Active' && suspension_status !== '' ? (
                    <div class="alert alert-danger" role="alert">
                      Your account is currently under <b>{suspension_status}</b> 

                      <br/><br/>
                      <p><b>Reasons: </b>{suspension_details.reasons}</p>

                      {suspension_details.reason_for_appeal === undefined ? (<Link to={`/appeal/${suspension_details._id}`} class="btn btn-success btn-sm"
                        style={{ color: "#fff", fontSize: "12px" }}>Appeal</Link>):(
                          <button class="btn btn-secondary" disabled>Review in Progress</button>
                        )}

                      
                    </div>
                  ) : (<></>)}

                  {order_confirmation_success_message !== '' ? (
                    <div class="alert alert-success" role="alert">
                      Order confirmation successful. Send buyer a message to arrange delivery/collection. <Link to={`/messages/${buyer_to_message}`} class="btn btn-success btn-sm"
                        style={{ color: "#fff", fontSize: "12px" }}>Send message now</Link>
                    </div>
                  ) : ('')}


                </div>
              </div>

              <div class="row">
                <div class="col-md-12 m-5">

                  {new_orders.length > 0 ? (
                    new_orders.map(nd => (
                      <p class="alert alert-success">
                        There is a buyer interested in your {nd.product_details.product_name}, the buyer wants to buy {nd.quantity} {nd.product_details.price_per === 'each' ? nd.product_details.product_name : nd.product_details.price_per} for {localStorage.getItem('currency')}{nd.product_details.price * nd.quantity}. Can you supply this request?
                        &nbsp;
                        <button type="button" class="btn btn-success btn-sm"
                          style={{ color: "#fff", fontSize: "12px" }}
                          onClick={() => confirm_order(nd._id)}
                        >Yes</button>
                        &nbsp;
                        /
                        &nbsp;
                        <button type="button" class="btn btn-success btn-sm"
                          style={{ color: "#fff", fontSize: "12px" }}
                          onClick={() => reject_order(nd._id)}
                        >No</button>
                      </p>
                    ))
                  ) : ('')}

                  {total_unread_messages > 0 ? (
                    <p class="alert alert-success">
                      You have {total_unread_messages} new messages from Buyers. View your Orders to see new messages.
                      &nbsp;
                      <Link to={`/seller-orders/${user_profile._id}`} class="btn btn-success btn-sm"
                        style={{ color: "#fff", fontSize: "12px" }}
                      >Go to orders</Link>
                    </p>
                  ) : ('')}


                  {listed_products.length > 0 ? (listed_products.map(pd => (
                    <p class="alert alert-danger">
                      {pd.product_name} was last updated {get_display_date(pd.updatedAt)} and is likely to have expired. Please update it to keep your customers with the latest info. Its advisable to delete old photos and take new photos of current state of produce. &nbsp;
                      <Link to={`/seller-product/${pd._id}`} class="btn btn-success btn-sm"
                        style={{ color: "#fff", fontSize: "12px" }}
                      >Click to update</Link>
                    </p>
                  ))) : ('')}

                  {new_notifications.length > 0 ? (
                    new_notifications.map(no => (
                      !no.read ? (
                        <p class="alert alert-success">
                          {no.message}
                          <br />
                          &nbsp;
                          <button type="button" class="btn btn-success btn-sm"
                            style={{ color: "#fff", fontSize: "12px" }}
                            onClick={() => { confirm_availability(no._id, (no.link).replace('/seller-verification-details/', '')) }}
                          >Confirm availability</button>

                          &nbsp;&nbsp;

                          <button type="button" class="btn btn-danger btn-sm"
                            style={{ color: "#fff", fontSize: "12px" }}
                            onClick={() => { request_different_time(no._id, (no.link).replace('/seller-verification-details/', '')) }}
                          >Request a different time</button>
                        </p>
                      ) : ('')
                    ))
                  ) : ('')}

                </div>
                <div class="col-md-3">
                  <div class="info-box product-box dashboard-box">
                    <Link to="seller-orders/active">
                      <div class="text-right mb-3 mr-3">
                        <span class="grey-text dashboard-box-text">
                          &nbsp;
                        </span>
                        <br />
                      </div>
                      <span class="grey-text dashboard-box-heading">Active Orders</span>
                      <br />
                      <span class="black-text dashboard-box-count">{active_orders}</span>
                      <br />
                      <span class="grey-text dashboard-box-text">&nbsp;</span>
                    </Link>
                  </div>
                </div>
                <div class="col-md-1"></div>


                <div class="col-md-3">
                  <div class="info-box product-box dashboard-box">
                    <Link to="seller-products">
                      <div class="text-right mb-3 mr-3">
                        {products_last_updated_warning ? (<span class="grey-text dashboard-box-text">
                          Last updated: {products_last_updated} <img src="/assets/img/warning.png" alt="warning" />
                          &nbsp;
                        </span>) : ('')}
                        <br />
                      </div>
                      <span class="grey-text dashboard-box-heading">Products Summary</span>
                      <br />
                      <span class="black-text dashboard-box-count">{products_count}</span>
                      <br />
                      <span class="grey-text dashboard-box-text">Products on sale</span>
                    </Link>
                  </div>
                </div>
                <div class="col-md-1"></div>



                <div class="col-md-3">
                  <div class="info-box product-box dashboard-box">
                    <Link to="seller-sales">
                      <div class="text-right mb-3 mr-3">
                        <span class="grey-text dashboard-box-text">
                          Total sales to date
                          &nbsp;
                        </span>
                        <br />
                      </div>
                      <span class="grey-text dashboard-box-heading">Sales History</span>
                      <br />
                      <span class="green-text dashboard-box-count">
                        {localStorage.getItem('currency')}{sales_history_value}
                      </span>
                      <br />
                      <span class="grey-text dashboard-box-text">Amount</span>
                    </Link>
                  </div>
                </div>
                <div class="col-md-1"></div>

              </div>
            </>
          )}

        </div>
      </section>


      <Dialog open={confirm_farm_visit_dialog} onClose={confirm_farm_visit_dialog_close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Request a different date and time</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div class="form-group">
              <label class="form-group">Please send a message to the verifier with your proposed date and time</label>
              <br /><br />
            </div>
            <div class="form-group">
              <label>Visit date</label>
              <input type="date" class="form-control" value={visit_date} onChange={(e) => set_visit_date(e.target.value)} />
            </div>
            <div class="form-group">
              <label>Start time</label>
              <input type="time" class="form-control" value={visit_time} onChange={(e) => set_visit_time(e.target.value)} />
            </div>
            <div class="form-group">
              <label>End time</label>
              <input type="time" class="form-control" value={visit_end_time} onChange={(e) => set_visit_end_time(e.target.value)} />
            </div>
            <div class="form-group">
              <label>Message to verifier</label>
              <textarea class="form-control" onChange={(e) => set_message_to_verifier(e.target.value)} >{message_to_verifier}</textarea>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            class="btn btn-lg btn-success"
            // type="submit"
            onClick={confirm_farm_visit_dialog_close}
            color="primary">
            Cancel
          </button>
          <form onSubmit={input_different_time}>


            &nbsp;&nbsp;&nbsp;&nbsp;

            <button
              class="btn btn-lg btn-success"
              type="submit"
              // onClick={()=>remove_recurring_order(remove_recurring_purchase_item.id)} 
              color="primary">
              Send message
            </button>
          </form>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default SellerDashboardScreen;
