//src\screens\messages\MessagesScreen.js
import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { getOrdersV3, createOrderV2, updateOrder } from '../../actions/orderActions';
//import { getMessages } from '../../actions/messageActions';
import { validate_multiple_roles } from '../../validations';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { get_date_string, get_display_date } from '../../config';
// import styled from "styled-components"
import { v4 as uuidv4 } from "uuid";
import { IoMdSend } from 'react-icons/io'
import { BsEmojiSmileFill } from 'react-icons/bs'
import Picker from 'emoji-picker-react'
import Robot from "./assets/robot.gif"

import { useSocket } from '../../hooks/useSocket'; // Adjust the import path
import AppContext from '../../contexts/AppContext'; // Adjust the import path as necessary


export default function MessagesScreen(props) {
  // Accessing header and footer heights from context
  const { headerHeight, footerHeight } = useContext(AppContext);  

  useEffect(() => {
    console.log("Header height in MessagesScreen:", headerHeight, "Footer height:", footerHeight);
    // Adjust layout based on header and footer heights
  }, [headerHeight, footerHeight]);


  const socket = useRef();
  const scrollRef = useRef();

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [message, set_message] = useState("");

  const id = props.match.params.order ? props.match.params.order : '';


  // Define a callback function to fetch new messages
  const handleOrderUpdate = useCallback((data) => {
    if (data.orderId === id) {
      find_new_messages();
    }
  }, [id]); // Dependency array

  // Use the useSocket hook with the callback
  useSocket(null, id, handleOrderUpdate, null);


  const handleEmojiPickerHideShow = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiClick = (e, emoji) => {
    let message = message
    message += emoji.emoji
    set_message(message)
  }

  const sendChat = async (e) => {
    e.preventDefault();
    if (message.length > 0) {
      // update message in DB
      let update_data = {
        message,
      }
      
      let make_action;
      try {
        make_action = await updateOrder(update_data, id, 'message');
        if (make_action.success) {
          let _messages = messages
          _messages.push({
            message,
            sender: user_profile._id,
          })
          set_messages(_messages)
          set_message('');
        } else {
          set_error_message(make_action.message)
        }
      } catch (error) {
        set_error_message(`An error occured fetching new orders: ${error.message} `);
      }

      
    }
  }

  // const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);

  const [messages, set_messages] = useState([])
  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentChat, setCurrentChat] = useState(0);
  const [isLoaded, setIsLoaded] = useState(true);

  const [currentSelected, setCurrentSelected] = useState(undefined);

  const [openChat, setOpenChat] = useState({});
  const [openUser, setOpenUser] = useState('');
  const [openUserImage, setOpenUserImage] = useState('');

  const [meUser, setMeUser] = useState('');
  const [meUserImage, setMeUserImage] = useState('');

  const [unreadCount, setUnreadCount] = useState(2);

  const [order_details, set_order_details] = useState({});
  // const [messages, set_messages] = useState({});
  const [product_details, set_product_details] = useState({});
  const [buyer_details, set_buyer_details] = useState({});
  const [seller_details, set_seller_details] = useState({});


  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ['seller', 'buyer'];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = 'access-forbidden';
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const status = props.match.params.status ? props.match.params.status : 'Pending';

  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line 
  const [success_message, set_success_message] = useState('');

  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line 
  const [action_loading, set_action_loading] = useState(false);


  const [orders, set_orders] = useState([]);

  // eslint-disable-next-line 
  // eslint-disable-next-line 
  const [phone, set_phone] = useState('phone');
  // eslint-disable-next-line 
  const [email, set_email] = useState(user_profile.email);
  // eslint-disable-next-line 
  const [city, set_city] = useState('city');
  // eslint-disable-next-line 
  const [delivery_zone, set_delivery_zone] = useState('delivery_zone');
  // eslint-disable-next-line 
  const [payment_method, set_payment_method] = useState('payment_method');
  // eslint-disable-next-line 
  const [client_id, set_client_id] = useState('client_id');
  // eslint-disable-next-line 
  // const [status, set_status] = useState('status');


  // eslint-disable-next-line
  const [add_city_invalid, set_add_city_invalid] = useState(true);

  const link_cell_formatter = (cell, row) => {
    return (
      <div>
        <Link class="btn btn-success btn-lg" to={`/buyer-order/${row._id}`}>View order</Link>
      </div>);
  }


  const load_page_data = async () => {
    set_action_loading(true);
    let get_orders;
    try {
      get_orders = await getOrdersV3(id, 'buyer', 'seller', 'status', 'yes');
      if (get_orders.success) {
        const orderData = get_orders.data[0];
  
        // Mark counterpart messages as read
        const updateResult = await updateOrder({}, id, 'mark_counterpart_messages_as_read');
        if (updateResult.success) {
          // Refetch the order details to get updated messages
          const refreshedOrderResult = await getOrdersV3(id, 'buyer', 'seller', 'status', 'yes');
          if (refreshedOrderResult.success) {
            const refreshedOrderData = refreshedOrderResult.data[0];
            set_order_details(refreshedOrderData);
            set_messages(refreshedOrderData.messages);
          }
        } else {
          console.error('Error marking messages as read:', updateResult.message);
        }
  
        // Set state with initial order details (in case refetch fails)
        set_order_details(orderData);
        set_messages(orderData.messages);
        set_buyer_details(orderData.buyer_details);
        set_seller_details(orderData.seller_details.seller_details[0]);
        set_product_details(orderData.product_details);
  
      } else {
        set_error_message('Messages not found');
      }
    } catch (error) {
      set_error_message(`An error occurred fetching messages: ${error.message}`);
    }
    set_action_loading(false);
    return;
  }


  const find_new_messages = async () => {
    console.log('finding new messages')
    set_action_loading(true);
    let get_orders;
    try {
      get_orders = await getOrdersV3(id, 'buyer', 'seller', 'status', 'yes');
      if (get_orders.success) {
        set_messages(get_orders.data[0].messages);
		//console.log("========MESSAGES MESSAGES MESSAGES =============");
		//console.log(get_orders.data[0].messages);
      } else {
        set_error_message('Messages not found');
      }
    } catch (error) {
      set_error_message(`An error occured fetching messages: ${error.message} `);
    }
    set_action_loading(false)

    return;
  }

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }

    /*
    const intervalId = setInterval(() => {
      find_new_messages();
    }, 60000);
    
    return () => {
      // Clear the interval when the component unmounts
      clearInterval(intervalId);
    };
    */

  }, [messages]); // Include messages as a dependency if needed



function scrollToBottom() {
  const scrollElement = scrollRef.current;
  if (scrollElement) {
    scrollElement.scrollTop = scrollElement.scrollHeight;
  }
}

useEffect(() => {
  scrollToBottom();
}, [messages]); // Call scrollToBottom when messages update


  const redo_order = async (product_id, quantity) => {

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    try {
      const response = await createOrderV2(product_id, quantity);
      if (response.success) {
        await load_page_data();

        set_success_message('New order successfully placed.');

      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message('An error occured in completing your order. Please try again');
    }

    set_action_loading(false);

    return;
  }
  
function formatDate(dateString) {
  //return moment(dateString).format('MMM Do YYYY, h:mm a'); // e.g., Apr 5th 2023, 2:30 PM
  return moment(dateString).format('h:mm a'); // e.g., Apr 5th 2023, 2:30 PM
}  

function groupMessagesByDate(messages) {
  const groupedMessages = [];
  let lastDate = null;

  messages.forEach(message => {
    const messageDate = moment(message.date).startOf('day');
    if (!lastDate || !messageDate.isSame(lastDate, 'day')) {
      groupedMessages.push({ type: 'date', date: messageDate });
      lastDate = messageDate;
    }
    groupedMessages.push(message);
  });

  return groupedMessages;
}

function renderDateHeader(date) {
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');

  if (date.isSame(today, 'day')) {
    return <div className="date-header">Today</div>;
  } else if (date.isSame(yesterday, 'day')) {
    return <div className="date-header">Yesterday</div>;
  } else {
    return <div className="date-header">{date.format('dddd DD MMM, YYYY')}</div>;
  }
}

function renderMessages() {
  const groupedMessages = groupMessagesByDate(messages);

  return groupedMessages.map((item, index) => {
    if (item.type === 'date') {
      return <div key={index}>{renderDateHeader(item.date)}</div>;
    } else {
      return (
        <div key={item.id} className={`message ${item.sender === user_profile._id ? "sended" : "recieved"}`}>
          <div className="content">
            <p>{item.message}</p>
			<small className="message-date">{formatDate(item.date)}</small>
          </div>
        </div>
      );
    }
  });
}



  const messageContainerHeight = `calc(100vh - ${headerHeight}px - ${footerHeight}px + 20px)`;
  console.log("headerHeight: " + headerHeight);
  console.log("messageContainerHeight:", messageContainerHeight);

  {/*
  if (!isReady) {
    return <div>Loading...</div>;
  }
  */}

  return (
    <div class="messages-container2" style={{ height: messageContainerHeight }}>
      <div className="messages-inner-container2">

        <div class="row">
          <div class="col-md-12 text-center message-title-container">
            <h3>
              Messaging {(user_profile.roles).includes('seller') ? `${buyer_details.first_name} ${buyer_details.last_name}` : seller_details.trading_name}: RE: Order for {order_details.quantity} {product_details.quantity_units !== 'each' ? `${product_details.quantity_units} of` : ''} {product_details.product_name}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link to={(user_profile.roles).includes('seller') ? `/seller-order/${id}` : `/buyer-order/${id}`} class="btn btn-primary">
                Back to order
              </Link>
              </h3>
          </div>
        </div>

        

        <div class="row mt-5 chat-messages-container2" ref={scrollRef}>
            <div class="col-md-12">
                <div class="chat-container">
                    <div className="chat-messages">
                        {renderMessages()}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="chat-container">
            <div class="chat-input-container">
            <div className="button-container">
              <div className="emoji" >
              </div>
            </div>
            <form className='input-container' onSubmit={(e) => sendChat(e)}>

              <input type="text" placeholder='Type your message here!' value={message} onChange={(e) => { set_message(e.target.value) }} />
              <button className="submit">
                <IoMdSend />
              </button>
            </form>

          </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}