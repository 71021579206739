import React, { useEffect, useState, useRef } from "react";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import { Textbox, Textarea } from "react-inputs-validation";
import axios from "axios";
import { APP_URL, API_URL, API_KEY, token_string } from "../config";
import { getCategories } from "../actions/categoryActions";
import Spinner from "../components/spinner";

function SpecifyNewProductScreen(props) {
  const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "";

  const supplied_name = props.match.params.supplied_name
    ? props.match.params.supplied_name
    : "";

  const [action_loading, set_action_loading] = useState(false);

  const [product, set_product] = useState(supplied_name);
  const [category, set_category] = useState("");
  const [other_names, set_other_names] = useState("");
  const [message, set_message] = useState("");

  const [all_categories, set_all_categories] = useState([]);

  const [product_invalid, set_product_invalid] = useState(true);
  const [category_invalid, set_category_invalid] = useState(true);
  const [other_names_invalid, set_other_names_invalid] = useState(true);
  const [message_invalid, set_message_invalid] = useState(true);

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");

  const [file, setFile] = useState(""); // storing the uploaded file
  // eslint-disable-next-line
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element
  const handleChange = (e) => {
    setProgess(0);
    const file = e.target.files[0]; // accessing file
    setFile(file); // storing file
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (action_loading === false) {
      set_action_loading(true);
      loadPage();
    }
  });

  /**
   * Checks if a supplied token in link can reset password and is valid.
   */
  const loadPage = async () => {
    // if (order_id !== '') {
    //     set_message(`Hi I have a problem with my order: ${order_id}. May you please check for me`)
    // }
    let get_all_categories;
    try {
      get_all_categories = await getCategories("id", "meta", "active");
      if (get_all_categories.success === false) {
        set_error_message(get_all_categories.message);
      } else {
        const { data } = get_all_categories;
        set_all_categories(data);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching categories: ${error.message} `
      );
    }
    // set_success_message('');
    // set_error_message('');
  };

  const specify_new_product = async (e) => {
    e.preventDefault();
    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const formData = new FormData();
    formData.append("api_key", API_KEY);
    formData.append("file", file); // appending file
    formData.append("product", product);
    formData.append("category", category);
    formData.append("other_names", other_names);
    // console.log('about to axio')
    await axios
      .post(
        `${API_URL}/products/seller-requested`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token_string,
          },
        },
        {
          onUploadProgress: (ProgressEvent) => {
            let progress =
              Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
              "%";
            setProgess(progress);
          },
        }
      )
      .then((res) => {
        const { data } = res;
        // console.log(data)
        // return
        if (data.success === true) {
          set_success_message("Product successfully requested");
          props.history.push("/seller-requests");
        } else {
          set_error_message(data.message);
        }
        // console.log(data, 'then')
      })
      .catch(
        // console.log('exc')
        set_success_message("Request successful")
      );

    set_action_loading(false);
    return;
  };

  return (
    <section id="about" class="about main-content mt-5 login-content">
      <div class="container-fluid" data-aos="fade-up">
        <div class="row mt-5">
          {/* <div class="col-md-3"></div> */}

          <div class="col-md-12 text-center mb-5">
            <h2 class="green-text">Request to sell a new product</h2>
            {success_message !== "" ? (
              <div class="col-12">
                <div class="alert alert-success" role="alert">
                  {success_message}
                </div>
              </div>
            ) : (
              ""
            )}

            {error_message !== "" ? (
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  {error_message}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div class="col-md-3"></div>

          <div class="col-md-6 row text-center">
            <div class="col-md-12 form-group my-3">
              <label for="">Name of the produce</label>
              <Textbox
                attributesWrapper={{}}
                attributesInput={{
                  id: "Name",
                  name: "Name",
                  type: "text",
                  //   placeholder: "Name"
                }}
                // validate={validate}
                validationCallback={(res) => set_product_invalid(res)}
                value={product}
                disabled={false}
                classNameInput=""
                classNameWrapper=""
                classNameContainer=""
                customStyleInput={{}}
                customStyleWrapper={{}}
                customStyleContainer={{}}
                onChange={(product, e) => {
                  set_product(e.target.value);
                }}
                onBlur={(e) => {
                  console.log(e);
                }}
                // onFocus={(e) => {console.log(e);}}
                // onClick={(e) => {console.log(e);}}
                validationOption={{
                  name: "Full name",
                  check: true,
                  required: true,
                  type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                  // showMsg: true,
                  // min: 2,
                  // max: 10,
                  // length: 2,
                  // compare: '3',
                  // reg: /^\d{18}|\d{15}$/,
                  // regMsg: 'failed in reg.test(${value})',
                  // locale: 'en-US',
                  // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                  // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                  // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                  //   if (res != 'milk') {
                  //     return 'Name cannot be other things but milk';
                  //   }
                  //   return true;
                  // }
                }}
              />
            </div>
            <div class="col-md-12 form-group my-3">
              <label for="">Category</label>
              <select
                class="form-control"
                name="add_category"
                id="add_category"
                onChange={(e) => set_category(e.target.value)}
              >
                <option value="">Select category</option>
                {all_categories.map((c) => (
                  <option key={c._id} value={c.category}>
                    {c.category}
                  </option>
                ))}
              </select>
            </div>
            <div class="col-md-12 form-group my-3">
              <label for="">Other names the produce is known by</label>
              <Textbox
                attributesWrapper={{}}
                attributesInput={{
                  id: "Other product names",
                  name: "Other product names",
                  type: "text",
                  //   placeholder: "Other product names"
                }}
                // validate={validate}
                validationCallback={(res) => set_other_names_invalid(res)}
                value={other_names}
                disabled={false}
                classNameInput=""
                classNameWrapper=""
                classNameContainer=""
                customStyleInput={{}}
                customStyleWrapper={{}}
                customStyleContainer={{}}
                onChange={(other_names, e) => {
                  set_other_names(e.target.value);
                }}
                onBlur={(e) => {
                  console.log(e);
                }}
                // onFocus={(e) => {console.log(e);}}
                // onClick={(e) => {console.log(e);}}
                validationOption={{
                  name: "Other product names",
                  check: true,
                  required: true,
                  type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                  // showMsg: true,
                  // min: 2,
                  // max: 10,
                  // length: 2,
                  // compare: '3',
                  // reg: /^\d{18}|\d{15}$/,
                  // regMsg: 'failed in reg.test(${value})',
                  // locale: 'en-US',
                  // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                  // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                  // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                  //   if (res != 'milk') {
                  //     return 'Name cannot be other things but milk';
                  //   }
                  //   return true;
                  // }
                }}
              />
            </div>

            <div class="col-md-12 form-group my-3">
              <label for="">Picture</label>
              <input
                type="file"
                ref={el}
                onChange={handleChange}
                class="text-center center-block file-upload form-control"
              />
            </div>

            <div class="col-md-12 form-group my-3">
              <form onSubmit={specify_new_product}>
                <button
                  type="submit"
                  class="btn btn-primary"
                  disabled={!action_loading || product === ""}
                >
                  {action_loading ? (
                    "Send request"
                  ) : (
                    <>
                      <div
                        class="spinner-border text-orange"
                        role="status"
                      ></div>
                      <Spinner />
                    </>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default SpecifyNewProductScreen;
