import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getRates } from "../actions/cartActions";
import { createOrder } from "../actions/orderActions";
import { Textbox, Textarea, Checkbox } from "react-inputs-validation";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { registerBuyer } from "../actions/userActions";
import Cookie from "js-cookie";
import { checkLoggedIn, getBuyer } from "../actions/userActions";
import { getCities } from "../actions/categoryActions";
// eslint-disable-next-line
import { buyer_city } from "../config";

// import { API_URL } from '../config';

function CheckoutScreen(props) {
  const logged_in = checkLoggedIn();

  const cart = useSelector((state) => state.cart);

  const { cartItems } = cart;
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  // eslint-disable-next-line
  const [error_message, set_error_message] = useState("");
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState("");
  // eslint-disable-next-line
  const [page_data_loaded, set_page_data_loaded] = useState(false);
  const [us_zw_rate, set_us_zw_rate] = useState(0);

  const [stage, set_stage] = useState("personal_details");
  const [cities, set_cities] = useState([]);
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [phone, set_phone] = useState("");
  const [email, set_email] = useState("");
  // eslint-disable-next-line
  const [password, setPassword] = useState("");
  // eslint-disable-next-line
  const [re_password, setRePassword] = useState("");
  const [description, set_description] = useState("");

  // eslint-disable-next-line
  const [agreement, set_agreement] = useState(true);
  const [is_agreement_checked, set_is_agreement_checked] = useState(true);
  // eslint-disable-next-line
  const [payment_method, set_payment_method] = useState("");

  // eslint-disable-next-line
  const [first_name_invalid, set_first_name_invalid] = useState(true);
  // eslint-disable-next-line
  const [last_name_invalid, set_last_name_invalid] = useState(true);
  // eslint-disable-next-line
  const [phone_invalid, set_phone_invalid] = useState(true);
  // eslint-disable-next-line
  const [email_invalid, set_email_invalid] = useState(true);
  // eslint-disable-next-line
  const [password_invalid, set_password_invalid] = useState(true);
  // eslint-disable-next-line
  const [re_password_invalid, set_re_password_invalid] = useState(true);
  // eslint-disable-next-line
  const [description_invalid, set_description_invalid] = useState(true);
  // eslint-disable-next-line
  const [agreement_invalid, set_agreement_invalid] = useState(true);

  const [address_line1, set_address_line1] = useState("");
  const [address_line2, set_address_line2] = useState("");
  const [suburb, set_suburb] = useState("");
  const [city, set_city] = useState(localStorage.getItem("buyer_city"));
  const [province, set_province] = useState("");
  const [country, set_country] = useState("Zimbabwe");
  // eslint-disable-next-line
  const [address_line1_invalid, set_address_line1_invalid] = useState(true);
  // eslint-disable-next-line
  const [address_line2_invalid, set_address_line2_invalid] = useState(true);
  // eslint-disable-next-line
  const [suburb_invalid, set_suburb_invalid] = useState(true);
  // eslint-disable-next-line
  const [city_invalid, set_city_invalid] = useState(false);
  // eslint-disable-next-line
  const [province_invalid, set_province_invalid] = useState(true);
  // eslint-disable-next-line
  const [country_invalid, set_country_invalid] = useState(true);

  const [save_details_dialog, set_save_details_dialog] = React.useState(false);
  const [save_password_dialog, set_save_password_dialog] =
    React.useState(false);

  const save_details_dialog_open = () => {
    set_save_details_dialog(true);
  };

  const save_details_dialog_close = () => {
    set_is_agreement_checked(true);
    set_save_details_dialog(false);
  };

  const save_password_dialog_open = () => {
    set_save_password_dialog(true);
    // if ( logged_in === '' || logged_in === null || logged_in === undefined ) {
    //   set_save_password_dialog(true);
    // }
  };

  const save_password_dialog_close = () => {
    set_is_agreement_checked(true);
    set_save_password_dialog(false);
  };

  // eslint-disable-next-line
  const dispatch = useDispatch();
  useEffect(
    () => async () => {
      if (page_data_loaded === false) {
        await load_page_data();
        set_page_data_loaded(true);
      }
      // eslint-disable-next-line
    },
    []
  );

  /**
   * Completes a purchase eing made by user
   *
   * @param {*} e
   */
  const complete_purchase = async (pay_using) => {
    // console.log('attempted to complete purchase on page load');
    // e.preventDefault();
    if (page_data_loaded === false) {
      return;
    }

    set_success_message("");
    set_error_message("");
    set_save_details_dialog(false);
    set_save_password_dialog(false);
    set_action_loading(true);

    let client_id = "";

    if (is_agreement_checked) {
      if (logged_in === "" || logged_in === null || logged_in === undefined) {
        if (password === "") {
          set_error_message("Please enter a password");
          set_action_loading(false);
          return;
        }

        if (password !== re_password) {
          set_error_message(
            "Your two passwords do not match. Please try again"
          );
          set_action_loading(false);
          return;
        }

        // save client details
        const response = await registerBuyer(
          first_name,
          last_name,
          phone,
          email,
          password,
          address_line1,
          address_line2,
          suburb,
          city,
          province,
          country,
          description
        );
        if (response.success === true) {
          client_id = response.id;
        }
      }
    }

    if (pay_using === "") {
      set_error_message("Please choose a payment method to complete purchase");
      set_action_loading(false);
      return;
    }

    const products = cartItems;
    const prices = {
      US: cartItems.reduce((a, c) => a + c.price * c.quantity, 0).toFixed(2),
      ZW: parseFloat(
        cartItems.reduce((a, c) => a + c.price * c.quantity, 0) * us_zw_rate
      ).toFixed(2),
    };

    const response = await createOrder(
      first_name,
      last_name,
      phone,
      email,
      address_line1,
      address_line2,
      suburb,
      city,
      province,
      country,
      description,
      pay_using,
      client_id,
      products,
      prices
    );
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      // remove cart items
      Cookie.remove("cartItems");
      if (pay_using === "Paynow") {
        set_success_message(
          "Please wait for redirection to the Paynow website so that you can complete payment"
        );
        window.location.replace(`${response.paynow_link}`);
      } else if (pay_using === "PayPal") {
        set_success_message(
          "Please wait for redirection to the PayPal website so that you can complete payment"
        );
        window.location.replace(`${response.paypal_link}`);
      } else {
        set_error_message("payment initiation failed");
        props.history.push(`/orders/fail`);
      }
    }

    set_action_loading(false);

    return;
  };

  const load_page_data = async () => {
    let get_all_rates;

    set_action_loading(false);

    let get_cities;
    try {
      get_cities = await getCities("id", "meta", "status", country);
      if (get_cities.success) {
        const { data } = get_cities;
        set_cities(data);
      }
    } catch (error) {
      // set_error_message(`An error occured fetching cities: ${error.message} `);
    }

    try {
      get_all_rates = await getRates("base", "foreign");
      //  console.log('load page data', get_all_rates);
      if (get_all_rates.success === false) {
        set_error_message(get_all_rates.message);
      } else {
        const { data } = get_all_rates;
        //  console.log('buy rate', data[0].buy);
        set_us_zw_rate(data[0].buy);
      }
    } catch (error) {
      set_error_message(`An error occured fetching rates: ${error.message} `);
    }

    if (logged_in === "" || logged_in === null || logged_in === undefined) {
    } else {
      set_first_name(logged_in.first_name);
      set_last_name(logged_in.last_name);
      set_email(logged_in.email);
      set_phone(logged_in.phone);

      set_first_name_invalid(false);
      set_last_name_invalid(false);
      set_email_invalid(false);
      set_phone_invalid(false);

      set_stage("delivery_details");

      // get customer profile for display here
      let get_buyer_profile;

      try {
        // console.log('buyer id', logged_in._id)
        get_buyer_profile = await getBuyer(logged_in._id);

        // console.log('get profile', get_buyer_profile)
        if (get_buyer_profile.success === false) {
          let attempt_number = 1;

          while (attempt_number < 3) {
            // props.history.push(`/checkout`);
            window.location.replace(`/checkout`);
            attempt_number++;
          }
        } else {
          const user_details = get_buyer_profile.data[0];
          const { buyer_details } = user_details;

          console.log("buyer details", buyer_details, buyer_details[0].city);

          set_description(buyer_details[0].delivery_instructions);
          set_address_line1(buyer_details[0].address1);
          set_address_line2(buyer_details[0].address2);
          set_suburb(buyer_details[0].suburb);
          set_city(buyer_details[0].city);
          set_province(buyer_details[0].province);
          set_country(buyer_details[0].country);

          // set_phone_invalid(false);
          set_description_invalid(false);
          set_address_line1_invalid(false);
          set_suburb_invalid(false);
          set_address_line2_invalid(false);
          set_city_invalid(false);
          set_province_invalid(false);
          set_country_invalid(false);

          set_is_agreement_checked(false);
        }
      } catch (error) {
        // set_error_message('An error occured fetching buyer profile');
      }
    }

    set_action_loading(false);
    return;
  };

  return (
    <section id="about" class="about main-content mt-5 login-content">
      <div class="container-fluid" data-aos="fade-up">
        <div class="row mt-5">
          {/* <div class="col-md-4"></div> */}
          <div class="col-md-12 text-center">
            <h3 class="green-text">Checkout</h3>
            {error_message !== "" ? (
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  {error_message}
                </div>
              </div>
            ) : (
              ""
            )}

            {success_message !== "" ? (
              <div class="col-12">
                <div class="alert alert-success" role="alert">
                  {success_message}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div class="col-md-8">
            {cartItems.length === 0 ? (
              <div>
                Your shopping cart is empty. <Link to="/shop">Shop now</Link>
              </div>
            ) : (
              <>
                <Link to="/cart">
                  <button class="btn btn-primary">
                    <i class="bi bi-basket"></i>
                    &nbsp;Return to cart
                  </button>
                </Link>
                <br />
                <br />

                <div class="table-responsive">
                  <table class="table table-bordered">
                    <tr>
                      <th>Quantity</th>
                      <th>Item</th>
                      <th>Item Cost</th>
                      <th>Subtotal</th>
                    </tr>

                    {cartItems.map((item) => (
                      <tr>
                        <td>
                          {item.quantity} {item.units}
                        </td>
                        <td>
                          <Link
                            to={`/product/${item.product_meta}/${item.product}`}
                          >
                            {item.name}
                          </Link>
                        </td>
                        <td>US${item.price}</td>
                        <td>US${(item.price * item.quantity).toFixed(2)}</td>
                      </tr>
                    ))}
                    <tr>
                      <th>{cartItems.length} items</th>

                      <th></th>
                      <th></th>
                      <th>
                        US${" "}
                        {cartItems
                          .reduce((a, c) => a + c.price * c.quantity, 0)
                          .toFixed(2)}
                        <br />
                        ZW${" "}
                        {parseFloat(
                          cartItems.reduce(
                            (a, c) => a + c.price * c.quantity,
                            0
                          ) * us_zw_rate
                        ).toFixed(2)}
                      </th>
                      <th></th>
                    </tr>
                  </table>
                </div>
              </>
            )}
          </div>

          <div class="col-md-4">
            {stage === "personal_details" ? (
              <>
                <h2>Personal details</h2>

                {logged_in === "" ||
                logged_in === null ||
                logged_in === undefined ? (
                  <p>
                    Already have an account?{" "}
                    <Link to="/login?redirect=checkout">Sign In</Link>
                  </p>
                ) : (
                  ""
                )}

                {/* <form onSubmit={complete_purchase} > */}
                <div class="row">
                  <div class="form-group my-4 col-lg-6">
                    <Textbox
                      attributesWrapper={{}}
                      attributesInput={{
                        id: "Name",
                        name: "Name",
                        type: "text",
                        placeholder: "First name",
                      }}
                      // validate={validate}
                      validationCallback={(res) => set_first_name_invalid(res)}
                      value={first_name}
                      disabled={false}
                      classNameInput=""
                      classNameWrapper=""
                      classNameContainer=""
                      customStyleInput={{}}
                      customStyleWrapper={{}}
                      customStyleContainer={{}}
                      onChange={(first_name, e) => {
                        set_first_name(e.target.value);
                      }}
                      onBlur={(e) => {
                        console.log(e);
                      }}
                      // onFocus={(e) => {console.log(e);}}
                      // onClick={(e) => {console.log(e);}}
                      validationOption={{
                        name: "First name",
                        check: true,
                        required: true,
                        type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                        // showMsg: true,
                        // min: 2,
                        // max: 10,
                        // length: 2,
                        // compare: '3',
                        // reg: /^\d{18}|\d{15}$/,
                        // regMsg: 'failed in reg.test(${value})',
                        // locale: 'en-US',
                        // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                        // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                        // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                        //   if (res != 'milk') {
                        //     return 'Name cannot be other things but milk';
                        //   }
                        //   return true;
                        // }
                      }}
                    />
                  </div>
                  <div class="form-group my-4 col-lg-6">
                    {/* <input  class="form-control" type="name" name="last_name" id="last_name" onChange={(e) => set_last_name(e.target.value)} placeholder="Surname" /> */}
                    <Textbox
                      attributesWrapper={{}}
                      attributesInput={{
                        id: "Surname",
                        name: "Surname",
                        type: "text",
                        placeholder: "Surname",
                      }}
                      // validate={validate}
                      validationCallback={(res) => set_last_name_invalid(res)}
                      value={last_name}
                      disabled={false}
                      classNameInput=""
                      classNameWrapper=""
                      classNameContainer=""
                      customStyleInput={{}}
                      customStyleWrapper={{}}
                      customStyleContainer={{}}
                      onChange={(laststName, e) => {
                        set_last_name(e.target.value);
                      }}
                      onBlur={(e) => {
                        console.log(e);
                      }}
                      // onFocus={(e) => {console.log(e);}}
                      // onClick={(e) => {console.log(e);}}
                      validationOption={{
                        name: "Surname",
                        check: true,
                        required: true,
                        type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                        // showMsg: true,
                        // min: 2,
                        // max: 10,
                        // length: 2,
                        // compare: '3',
                        // reg: /^\d{18}|\d{15}$/,
                        // regMsg: 'failed in reg.test(${value})',
                        // locale: 'en-US',
                        // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                        // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                        // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                        //   if (res != 'milk') {
                        //     return 'Name cannot be other things but milk';
                        //   }
                        //   return true;
                        // }
                      }}
                    />
                  </div>

                  {/* <br/><br/>
    <h2>Contact details for delivery updates</h2> */}
                  <div class="form-group my-4 col-lg-6">
                    {/* <input  class="form-control" type="text" name="phone" id="phone" onChange={(e) => set_phone(e.target.value)} placeholder="Phone Number" /> */}
                    <Textbox
                      attributesWrapper={{}}
                      attributesInput={{
                        id: "Phone",
                        name: "Phone",
                        type: "text",
                        placeholder: "Phone number. eg 263774756502",
                      }}
                      // validate={validate}
                      validationCallback={(res) => set_phone_invalid(res)}
                      value={phone}
                      disabled={false}
                      classNameInput=""
                      classNameWrapper=""
                      classNameContainer=""
                      customStyleInput={{}}
                      customStyleWrapper={{}}
                      customStyleContainer={{}}
                      onChange={(phone, e) => {
                        set_phone(e.target.value);
                      }}
                      onBlur={(e) => {
                        console.log(e);
                      }}
                      // onFocus={(e) => {console.log(e);}}
                      // onClick={(e) => {console.log(e);}}
                      validationOption={{
                        name: "Phone number",
                        check: true,
                        required: true,
                        type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                        // showMsg: true,
                        // min: 2,
                        // max: 10,
                        // length: 2,
                        // compare: '3',
                        reg: /^\d{12}|\d{14}$/,
                        regMsg: "Invalid phone number",
                        // locale: 'en-US',
                        // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                        // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                        // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                        //   if (res != 'milk') {
                        //     return 'Name cannot be other things but milk';
                        //   }
                        //   return true;
                        // }
                      }}
                    />
                  </div>
                  <div class="form-group my-4 col-lg-6">
                    {/* <input class="form-control" type="email" name="email" id="email" onChange={(e) => set_email(e.target.value)} placeholder="Email" /> */}
                    <Textbox
                      attributesWrapper={{}}
                      attributesInput={{
                        id: "email",
                        name: "email",
                        type: "text",
                        placeholder: "Email",
                      }}
                      // validate={validate}
                      validationCallback={(res) => set_email_invalid(res)}
                      value={email}
                      disabled={false}
                      classNameInput=""
                      classNameWrapper=""
                      classNameContainer=""
                      customStyleInput={{}}
                      customStyleWrapper={{}}
                      customStyleContainer={{}}
                      onChange={(email, e) => {
                        set_email(e.target.value);
                      }}
                      onBlur={(e) => {
                        console.log(e);
                      }}
                      // onFocus={(e) => {console.log(e);}}
                      // onClick={(e) => {console.log(e);}}
                      validationOption={{
                        name: "Email address",
                        check: true,
                        required: true,
                        //type: 'email', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                        // showMsg: true,
                        // min: 2,
                        // max: 10,
                        // length: 2,
                        // compare: '3',
                        // reg: /^\d{18}|\d{15}$/,
                        // regMsg: 'failed in reg.test(${value})',
                        // locale: 'en-US',
                        // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                        // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                        // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                        //   if (res != 'milk') {
                        //     return 'Name cannot be other things but milk';
                        //   }
                        //   return true;
                        // }
                      }}
                    />
                  </div>

                  {logged_in === "" ||
                  logged_in === null ||
                  logged_in === undefined ? (
                    <div class="form-group my-3">
                      <Checkbox
                        attributesWrapper={{}}
                        attributesInput={{
                          id: "agreement",
                          name: "agreement",
                        }}
                        value={agreement}
                        checked={is_agreement_checked}
                        disabled={false}
                        validationCallback={(res) => {
                          set_agreement_invalid(res);
                        }}
                        classNameWrapper=""
                        classNameInputBox=""
                        classNameContainer=""
                        customStyleWrapper={{}}
                        customStyleInputBox={{}}
                        customStyleContainer={{}}
                        onBlur={() => {}}
                        onChange={(is_agreement_checked, e) => {
                          set_is_agreement_checked(is_agreement_checked);
                          console.log(e);
                        }}
                        labelHtml={
                          <div style={{ marginTop: "2px" }}>
                            Save my details to make future purchases easier
                          </div>
                        } // Required.[Html].Default: none.
                        validationOption={{
                          name: "agreement", // Optional.[String].Default: "". To display in the Error message. i.e Please check the ${name}.
                          check: false, // Optional.[Bool].Default: true. To determin if you need to validate.
                          // required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                          // showMsg: true, // Optional.[Bool].Default: true. To determin display the error message or not.
                          // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", // Optional.[String].Default: "". Show your custom error message no matter what when it has error if it is provied.
                          // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." // Optional.[String].Default: "". Show your custom success message no matter what when it has error if it is provied.
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <Dialog
                    open={save_details_dialog}
                    onClose={save_details_dialog_close}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Are you sure you don't want to save your details for
                      future purchases?
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Saving your details helps your future purchases to be
                        quicker. Are you sure you dont want to save your details
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={() => {
                          save_details_dialog_close();
                          set_is_agreement_checked(false);
                          set_stage("delivery_details");
                        }}
                        color="primary"
                      >
                        Yes I'm sure
                      </button>

                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        onClick={() => {
                          save_details_dialog_close();
                          save_password_dialog_open();
                        }}
                        color="primary"
                      >
                        Save my details
                      </button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    open={save_password_dialog}
                    onClose={save_password_dialog_close}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Create a new password to easily retrieve your details in
                      future purchases.
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {/* <p></p> */}
                        <>
                          <div class="form-group my-4">
                            <input
                              class="form-control"
                              type="password"
                              id="password"
                              name="password"
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder="Password"
                              value={password}
                            />
                          </div>
                          <div class="form-group my-4">
                            <input
                              class="form-control"
                              type="password"
                              id="re_password"
                              name="re_password"
                              onChange={(e) => setRePassword(e.target.value)}
                              value={re_password}
                              placeholder="Confirm Password"
                            />
                            {password !== re_password ? (
                              <small
                                id="emailHelp"
                                class="form-text text-danger"
                              >
                                Your two passwords do not match
                              </small>
                            ) : (
                              ""
                            )}
                            {password === "" ? (
                              <small
                                id="emailHelp"
                                class="form-text text-danger"
                              >
                                Please enter a password to use for login
                              </small>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      {/* <button 
                            class="btn btn-lg btn-danger" 
                            type="submit"
                            onClick={complete_purchase}
                            color="primary">
                              Cancel
                          </button> */}

                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        disabled={password !== re_password || password === ""}
                        onClick={() => {
                          save_password_dialog_close();
                          set_stage("delivery_details");
                        }}
                        color="primary"
                      >
                        Save password
                      </button>
                    </DialogActions>
                  </Dialog>

                  <div class="row">
                    <div class="col-6">
                      {is_agreement_checked ? (
                        <button
                          type="submit"
                          class="btn btn-primary"
                          disabled={
                            cartItems.length === 0 ||
                            first_name_invalid ||
                            last_name_invalid ||
                            phone_invalid ||
                            email_invalid
                          }
                          onClick={() => {
                            save_password_dialog_open();
                          }}
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="submit"
                          class="btn btn-primary"
                          disabled={
                            cartItems.length === 0 ||
                            first_name_invalid ||
                            last_name_invalid ||
                            phone_invalid ||
                            email_invalid
                          }
                          onClick={() => {
                            save_details_dialog_open();
                          }}
                        >
                          Next
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {stage === "delivery_details" ? (
              <>
                <h2>Delivery details</h2>
                <div class="row">
                  <div class="form-group col-lg-6 my-3">
                    <label for="phone">Address line 1</label>
                    <Textbox
                      attributesWrapper={{}}
                      attributesInput={{
                        id: "address1",
                        name: "address1",
                        type: "text",
                        placeholder: "Street address",
                      }}
                      // validate={validate}
                      validationCallback={(res) =>
                        set_address_line1_invalid(res)
                      }
                      value={address_line1}
                      disabled={false}
                      classNameInput=""
                      classNameWrapper=""
                      classNameContainer=""
                      customStyleInput={{}}
                      customStyleWrapper={{}}
                      customStyleContainer={{}}
                      onChange={(address_line1, e) => {
                        set_address_line1(e.target.value);
                      }}
                      onBlur={(e) => {
                        console.log(e);
                      }}
                      // onFocus={(e) => {console.log(e);}}
                      // onClick={(e) => {console.log(e);}}
                      validationOption={{
                        name: "address1",
                        check: true,
                        required: true,
                        type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                        // showMsg: true,
                        // min: 2,
                        // max: 10,
                        // length: 2,
                        // compare: '3',
                        // reg: /^\d{18}|\d{15}$/,
                        // regMsg: 'failed in reg.test(${value})',
                        // locale: 'en-US',
                        // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                        // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                        // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                        //   if (res != 'milk') {
                        //     return 'Name cannot be other things but milk';
                        //   }
                        //   return true;
                        // }
                      }}
                    />
                  </div>
                  <div class="form-group col-lg-6 my-3">
                    <label for="phone">Address line 2</label>
                    <Textbox
                      attributesWrapper={{}}
                      attributesInput={{
                        id: "address2",
                        name: "address2",
                        type: "text",
                        placeholder: "Address line 2",
                      }}
                      // validate={validate}
                      validationCallback={(res) =>
                        set_address_line2_invalid(res)
                      }
                      value={address_line2}
                      disabled={false}
                      classNameInput=""
                      classNameWrapper=""
                      classNameContainer=""
                      customStyleInput={{}}
                      customStyleWrapper={{}}
                      customStyleContainer={{}}
                      onChange={(address_line2, e) => {
                        set_address_line2(e.target.value);
                      }}
                      onBlur={(e) => {
                        console.log(e);
                      }}
                      // onFocus={(e) => {console.log(e);}}
                      // onClick={(e) => {console.log(e);}}
                      validationOption={{
                        name: "address2",
                        // check: true,
                        required: false,
                        type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                        // showMsg: true,
                        // min: 2,
                        // max: 10,
                        // length: 2,
                        // compare: '3',
                        // reg: /^\d{18}|\d{15}$/,
                        // regMsg: 'failed in reg.test(${value})',
                        // locale: 'en-US',
                        // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                        // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                        // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                        //   if (res != 'milk') {
                        //     return 'Name cannot be other things but milk';
                        //   }
                        //   return true;
                        // }
                      }}
                    />
                  </div>

                  <div class="form-group col-lg-6 my-3">
                    <label for="phone">Suburb</label>
                    <Textbox
                      attributesWrapper={{}}
                      attributesInput={{
                        id: "suburb",
                        name: "suburb",
                        type: "text",
                        placeholder: "suburb",
                      }}
                      // validate={validate}
                      validationCallback={(res) => set_suburb_invalid(res)}
                      value={suburb}
                      disabled={false}
                      classNameInput=""
                      classNameWrapper=""
                      classNameContainer=""
                      customStyleInput={{}}
                      customStyleWrapper={{}}
                      customStyleContainer={{}}
                      onChange={(suburb, e) => {
                        set_suburb(e.target.value);
                      }}
                      onBlur={(e) => {
                        console.log(e);
                      }}
                      // onFocus={(e) => {console.log(e);}}
                      // onClick={(e) => {console.log(e);}}
                      validationOption={{
                        name: "suburb",
                        check: true,
                        required: true,
                        type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                        // showMsg: true,
                        // min: 2,
                        // max: 10,
                        // length: 2,
                        // compare: '3',
                        // reg: /^\d{18}|\d{15}$/,
                        // regMsg: 'failed in reg.test(${value})',
                        // locale: 'en-US',
                        // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                        // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                        // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                        //   if (res != 'milk') {
                        //     return 'Name cannot be other things but milk';
                        //   }
                        //   return true;
                        // }
                      }}
                    />
                  </div>
                  <div class="form-group col-lg-6 my-3">
                    <label for="phone">City</label>
                    <select
                      class="form-control"
                      name="city"
                      id="city"
                      onChange={(e) => set_city(e.target.value)}
                      style={{ height: "26px" }}
                    >
                      <option value={city}>{city}</option>
                      {cities.length > 0
                        ? cities.map((c) => (
                            <option key={c._id} value={c.city}>
                              {c.city}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                  <div class="form-group col-lg-6 my-3">
                    <label for="phone">Province</label>
                    <Textbox
                      attributesWrapper={{}}
                      attributesInput={{
                        id: "province",
                        name: "province",
                        type: "text",
                        placeholder: "Province",
                      }}
                      // validate={validate}
                      validationCallback={(res) => set_province_invalid(res)}
                      value={province}
                      disabled={false}
                      classNameInput=""
                      classNameWrapper=""
                      classNameContainer=""
                      customStyleInput={{}}
                      customStyleWrapper={{}}
                      customStyleContainer={{}}
                      onChange={(province, e) => {
                        set_province(e.target.value);
                      }}
                      onBlur={(e) => {
                        console.log(e);
                      }}
                      // onFocus={(e) => {console.log(e);}}
                      // onClick={(e) => {console.log(e);}}
                      validationOption={{
                        name: "province",
                        // check: true,
                        required: false,
                        type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                        // showMsg: true,
                        // min: 2,
                        // max: 10,
                        // length: 2,
                        // compare: '3',
                        // reg: /^\d{18}|\d{15}$/,
                        // regMsg: 'failed in reg.test(${value})',
                        // locale: 'en-US',
                        // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                        // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                        // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                        //   if (res != 'milk') {
                        //     return 'Name cannot be other things but milk';
                        //   }
                        //   return true;
                        // }
                      }}
                    />
                  </div>
                  <div class="form-group col-lg-6 my-3">
                    <label for="phone">Country</label>
                    <Textbox
                      attributesWrapper={{}}
                      attributesInput={{
                        id: "country",
                        name: "country",
                        type: "text",
                        placeholder: "Country",
                      }}
                      // validate={validate}
                      validationCallback={(res) => set_country_invalid(res)}
                      value={country}
                      disabled={true}
                      classNameInput=""
                      classNameWrapper=""
                      classNameContainer=""
                      customStyleInput={{}}
                      customStyleWrapper={{}}
                      customStyleContainer={{}}
                      onChange={(country, e) => {
                        set_country(e.target.value);
                      }}
                      onBlur={(e) => {
                        console.log(e);
                      }}
                      // onFocus={(e) => {console.log(e);}}
                      // onClick={(e) => {console.log(e);}}
                      validationOption={{
                        name: "country",
                        check: true,
                        required: true,
                        type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                        // showMsg: true,
                        // min: 2,
                        // max: 10,
                        // length: 2,
                        // compare: '3',
                        // reg: /^\d{18}|\d{15}$/,
                        // regMsg: 'failed in reg.test(${value})',
                        // locale: 'en-US',
                        // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                        // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                        // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                        //   if (res != 'milk') {
                        //     return 'Name cannot be other things but milk';
                        //   }
                        //   return true;
                        // }
                      }}
                    />
                  </div>
                  <div class="form-group col-12 my-3">
                    <label for="phone">Delivery instructions</label>
                    <Textarea
                      attributesWrapper={{}}
                      attributesInput={{
                        id: "description",
                        name: "description",
                        type: "text",
                        placeholder:
                          "Any special instructions on what we should do when we arrive at your house",
                      }}
                      // validate={validate}
                      validationCallback={(res) => set_description_invalid(res)}
                      value={description}
                      disabled={false}
                      classNameInput=""
                      classNameWrapper=""
                      classNameContainer=""
                      customStyleInput={{}}
                      customStyleWrapper={{}}
                      customStyleContainer={{}}
                      onChange={(description, e) => {
                        set_description(e.target.value);
                      }}
                      onBlur={(e) => {
                        console.log(e);
                      }}
                      // onFocus={(e) => {console.log(e);}}
                      // onClick={(e) => {console.log(e);}}
                      validationOption={{
                        name: "description",
                        // check: true,
                        required: false,
                        type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                        // showMsg: true,
                        // min: 2,
                        // max: 10,
                        // length: 2,
                        // compare: '3',
                        // reg: /^\d{18}|\d{15}$/,
                        // regMsg: 'failed in reg.test(${value})',
                        // locale: 'en-US',
                        // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                        // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                        // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                        //   if (res != 'milk') {
                        //     return 'Name cannot be other things but milk';
                        //   }
                        //   return true;
                        // }
                      }}
                    />
                  </div>

                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      // disabled={address_line1_invalid || suburb_invalid || city_invalid }
                      onClick={() => {
                        set_stage("payment_details");
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {stage === "payment_details" ? (
              <>
                {action_loading ? (
                  ""
                ) : (
                  <h2>Select your payment method below</h2>
                )}

                {!action_loading ? (
                  <div class="row">
                    <div class="col-6">
                      <button
                        type="submit"
                        class="btn btn-primary btn-checkout"
                        onClick={() => {
                          set_payment_method("Paynow");
                          complete_purchase("Paynow");
                        }}
                      >
                        <img
                          src="/assets/img/paynow-logo.png"
                          alt="Paynow"
                          class="img-fluid img-checkout"
                        />
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div class="spinner-border text-orange" role="status"></div>
                    &nbsp;Please wait
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CheckoutScreen;
