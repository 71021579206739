import React, { useState, useEffect } from "react";
import { getStoreDetails } from "../actions/productActions";
import { addNewReview } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, updateQuantity } from "../actions/cartActions";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import RNI from "@deberoppa/react-numeric-input";

import { API_URL, buyer_city } from "../config";

import jwt_decode from "jwt-decode";

function SellerShopScreen(props) {
  const dispatch = useDispatch();
  const username = props.match.params.username
    ? props.match.params.username
    : "";

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const checkLoggedIn = () => {
    const token_string = localStorage.getItem("token");
    try {
      var userObject = jwt_decode(token_string);
      return userObject;
    } catch (error) {
      return "";
    }
  };
  const loggedIn = checkLoggedIn();

  const [error_message, set_error_message] = useState("");
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState("");
  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  // eslint-disable-next-line
  const [first_page_load, set_first_page_load] = useState(true);

  const [added_to_cart_message, set_added_to_cart_message] = useState("");
  const [added_to_wishlist_message, set_added_to_wishlist_message] =
    useState("");
  const [item_added_to_cart_or_wishlist, set_item_added_to_cart_or_wishlist] =
    useState("");

  // eslint-disable-next-line
  const [featured_categories, set_featured_categories] = useState([]);
  // eslint-disable-next-line
  const [all_categories, set_all_categories] = useState([]);
  // eslint-disable-next-line
  const [all_product_types, set_all_product_types] = useState([]);

  const [retrieved_products, set_retrieved_products] = useState([]);
  const [display_products, set_display_products] = useState([]);

  // eslint-disable-next-line
  const [cities, set_cities] = useState([]);
  const [in_cart_products, set_in_cart_products] = useState([]);
  // eslint-disable-next-line
  const [in_wish_list_products, set_in_wish_list_products] = useState([]);
  // eslint-disable-next-line
  const [cart_items, set_cart_items] = useState([]);

  // eslint-disable-next-line
  const [add_category, set_add_category] = useState("");
  // eslint-disable-next-line
  const [add_sub_category, set_add_sub_category] = useState("");
  // eslint-disable-next-line
  const [add_product, set_add_product] = useState("");

  const [new_rating, set_new_rating] = useState("1");
  const [new_comment, set_new_comment] = useState("");

  const [seller_email, set_seller_email] = useState("");
  const [seller_description, set_seller_description] = useState("");
  const [seller_phone, set_seller_phone] = useState("");
  const [seller_name, set_seller_name] = useState("");
  const [seller_image, set_seller_image] = useState("");
  const [seller_reviews, set_seller_reviews] = useState([]);
  const [seller_rating, set_seller_rating] = useState(0);

  const [seller_city, set_seller_city] = useState("");
  const [seller_country, set_seller_country] = useState("");
  const [seller_delivery, set_seller_delivery] = useState("");

  const [add_a_review, set_add_a_review] = React.useState(false);
  const add_a_review_open = () => {
    set_add_a_review(true);
  };

  const add_a_review_close = () => {
    set_add_a_review(false);
  };

  const [total_pages, set_total_pages] = useState(1);
  const [current_page, set_current_page] = useState(1);
  // eslint-disable-next-line
  const [show_max, set_show_max] = useState(10);
  const x_per_page = 12;

  const handlePageChange = (selectedPage) => {
    set_current_page(selectedPage.selected + 1);
    const should_start_on = x_per_page * selectedPage.selected;
    const should_end_on = x_per_page * (selectedPage.selected + 1);

    let _display_products = [];
    for (let i = should_start_on; i < should_end_on; i++) {
      if (
        retrieved_products[i] !== undefined ||
        retrieved_products[i] !== null
      ) {
        _display_products.push(retrieved_products[i]);
      }
    }
    set_display_products(_display_products);
  };

  let paginationConfig = {
    pageCount: total_pages,
    pageRangeDisplayed: show_max,
    marginPagesDisplayed: 2,
    previousLabel: "Previous",
    nextLabel: "Next",
    breakLabel: "...",
    onPageChange: handlePageChange,
    containerClassName: "pagination",
    subContainerClassName: "pages pagination",
    activeClassName: "active",
    forcePage: current_page - 1,
  };

  const get_quantity_in_cart = (_product_name) => {
    let _product_quantity = "";
    if (cartItems.length > 0) {
      for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i].name === _product_name) {
          _product_quantity = cartItems[i].quantity;
          break;
        }
      }
    }
    return _product_quantity;
  };

  const reviewSeller = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const update_data = {
      seller_email,
      review: new_comment,
      rating: new_rating,
    };

    const response = await addNewReview(update_data);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      await search_products();
      set_success_message("Thank you for your review");
    }

    add_a_review_close();
    set_action_loading(false);

    return;
  };

  const load_page_data = async () => {
    set_action_loading(true);

    let _in_cart_products = [];
    if (cartItems.length > 0) {
      set_cart_items(cartItems);
      for (let i = 0; i < cartItems.length; i++) {
        _in_cart_products.push(cartItems[i].name);
      }
    }
    set_in_cart_products(_in_cart_products);

    if (
      localStorage.getItem("wish_list_items") !== null ||
      localStorage.getItem("wish_list_items") !== ""
    ) {
      set_in_wish_list_products(
        JSON.parse(localStorage.getItem("wish_list_items"))
      );
    }

    set_action_loading(false);

    return;
  };

  const search_products = async () => {
    set_action_loading(true);
    set_error_message("");
    //  set_success_message('');
    // id, seller, farm, product_meta, sub_category_meta, category_meta, product_name, quantity, quantity_units, min_price, max_price, delivery_zone, featured, on_sale, sort_order, sort_by, search_term, latitude, longitude
    let get_retrieved_products;

    try {
      get_retrieved_products = await getStoreDetails(username);

      if (get_retrieved_products.success === false) {
        set_error_message(get_retrieved_products.message);
      } else {
        const { products, seller_details, reviews } = get_retrieved_products;
        // console.log("seller details", seller_details.seller_details);
        // set_seller_email(seller_details.email);
        // set_seller_phone(seller_details.phone); remove email and phone number from seller
        set_seller_name(seller_details.display_name);
        set_seller_image(seller_details.image);

        set_seller_reviews(seller_details.reviews);
        set_seller_description(seller_details.description);

        const _address_details = seller_details.seller_details[0];
        set_seller_city(_address_details.city);
        set_seller_country(_address_details.country);

        const { delivery } = seller_details;
        if (delivery.provides === true) {
          set_seller_delivery("Provides delivery");
        }

        // get seller rating

        let _reviews = seller_details.reviews;

        let _total_ratings = 0;
        for (let i = 0; i < _reviews.length; i++) {
          _total_ratings += _reviews[i].rating;
        }

        let _rating = _total_ratings / _reviews.length;

        set_seller_rating(_rating);

        let _total_pages = Math.ceil(products.length / x_per_page);

        let _display_products = [];
        if (products.length > x_per_page) {
          set_total_pages(_total_pages);
          for (let i = 0; i < x_per_page; i++) {
            _display_products.push(products[i]);
          }

          set_display_products(_display_products);
        } else {
          set_display_products(products);
        }
        set_retrieved_products(products);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching shop details: ${error.message} `
      );
    }
    set_action_loading(false);
    return;
  };

  const add_to_cart = async (product_id, product_name) => {
    set_item_added_to_cart_or_wishlist("");

    set_added_to_cart_message("");
    set_added_to_wishlist_message("");

    await dispatch(addToCart(product_id, 1));

    const new_cart_items = JSON.parse(
      localStorage.getItem("shopping_cart_items")
    );

    let _in_cart_products = [];
    if (new_cart_items.length > 0) {
      set_cart_items(new_cart_items);
      for (let i = 0; i < new_cart_items.length; i++) {
        _in_cart_products.push(new_cart_items[i].name);
      }
    }
    set_in_cart_products(_in_cart_products);

    set_item_added_to_cart_or_wishlist(product_id);
    set_added_to_cart_message(`${product_name} added to cart`);
  };

  const update_quantity = async (product_id, action) => {
    set_item_added_to_cart_or_wishlist("");

    set_added_to_cart_message("");
    set_added_to_wishlist_message("");

    await dispatch(updateQuantity(product_id, action));

    const new_cart_items = JSON.parse(
      localStorage.getItem("shopping_cart_items")
    );

    let _in_cart_products = [];
    if (new_cart_items.length > 0) {
      set_cart_items(new_cart_items);
      for (let i = 0; i < new_cart_items.length; i++) {
        _in_cart_products.push(new_cart_items[i].name);
      }
    }
    set_in_cart_products(_in_cart_products);

    set_item_added_to_cart_or_wishlist(product_id);
    set_added_to_cart_message(`Quantity updated`);
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      search_products(username);
      set_page_loaded(true);
      set_first_page_load(false);
    }
    // eslint-disable-next-line
  }, []);

  const page_content = (
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>
          <span class="green-text">{seller_name} </span>{" "}
          <span class="orange-text"></span>
        </h2>
      </div>

      <div class="row">
        <div class="col-12">
          {error_message !== "" ? (
            <div class="alert alert-danger" role="alert">
              {error_message}
            </div>
          ) : (
            ""
          )}
          {success_message !== "" ? (
            <div class="alert alert-success" role="alert">
              {success_message}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-3">
          <div class="row">
            <div class="col-md-12">
              <img
                src={`${API_URL}/${seller_image}`}
                alt={seller_name}
                class="img-thumbnail seller-image-in-product"
              />
            </div>
            <div class="col-md-12 text-middle mt-5">
              <h4>
                Based in {seller_city}, {seller_country}
              </h4>
              <h4>{seller_delivery}</h4>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-9">
          {action_loading ? ( // so that only products will be loading at this time
            <>
              <div>
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only"></span>
                  </div>
                </div>
              </div>
            </>
          ) : error_message !== "" ? ( // correct here for things to display properly
            <div class="row">
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  {error_message}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div class="row text-center ">
                {retrieved_products.length > 0 ? (
                  display_products.map((p) =>
                    p !== undefined ? (
                      <div
                        key={p._id}
                        class="col-md-4 d-flex text-center"
                        data-aos="fade-up"
                      >
                        <div class="info-box product-box">
                          <Link to={`/product/${p.product_meta}/${p._id}`}>
                            {/* <img src={`${API_URL}/${p.pictures[0]}`} alt={p.product_name} /> */}
                            <img
                              src={`${API_URL}/${p.pictures[0]}`}
                              alt={p.product_name}
                              style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "cover",
                                maxHeight: "200px",
                              }}
                            />
                            <h3 class="green-text">{p.product_name}</h3>
                          </Link>

                          <p class="green-text">
                            {p.currency}
                            {p.price} {p.quantity_units === "each" ? "" : "per"}{" "}
                            {p.quantity_units}
                          </p>

                          <div class="row">
                            <div
                              class="col-6 text-left"
                              style={{ marginLeft: 0 }}
                            >
                              {p.selling_method === "direct" ||
                              buyer_city === "Nationwide" ||
                              buyer_city === "Everywhere available" ? (
                                <Link
                                  to={`/product/${p.product_meta}/${p._id}`}
                                  class="mt-2 contact-seller"
                                >
                                  <button class="btn btn-primary btn-sm mb-2">
                                    Get details
                                  </button>
                                </Link>
                              ) : (
                                <div class="row mt-2">
                                  <div class="col-md-2"></div>
                                  <div class="col-md-2 text-right">
                                    <i class="bi bi-basket"></i>
                                  </div>
                                  <div class="col-md-6">
                                    {in_cart_products.includes(
                                      p.product_name
                                    ) ? (
                                      <>
                                        <button
                                          style={{
                                            color: "#fff",
                                            fontSize: "12px",
                                          }}
                                          class="btn btn-primary btn-sm mb-2"
                                          onClick={() =>
                                            update_quantity(p._id, "decrement")
                                          }
                                        >
                                          -
                                        </button>
                                        &nbsp;&nbsp;&nbsp;
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                          }}
                                        >
                                          {get_quantity_in_cart(p.product_name)}
                                        </span>
                                        &nbsp;&nbsp;&nbsp;
                                        <button
                                          style={{
                                            color: "#fff",
                                            fontSize: "12px",
                                          }}
                                          class="btn btn-primary btn-sm mb-2"
                                          onClick={() =>
                                            update_quantity(p._id, "increment")
                                          }
                                        >
                                          +
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        style={{
                                          width: "100%",
                                          color: "#fff",
                                          fontSize: "12px",
                                        }}
                                        class="btn btn-primary btn-sm mb-2"
                                        onClick={() =>
                                          add_to_cart(p._id, p.product_name)
                                        }
                                      >
                                        Add to cart
                                      </button>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div class="col-5 text-right">
                              <Link
                                to={`/order-product/${p.product_meta}/${p._id}/1`}
                                class="mt-2 contact-seller"
                              >
                                <button class="btn btn-primary btn-sm mb-2">
                                  Order Now
                                </button>
                              </Link>
                            </div>
                          </div>

                          <div class="row my-5">
                            {added_to_cart_message !== "" &&
                            item_added_to_cart_or_wishlist === p._id ? (
                              <>
                                <div class="col-md-2"></div>
                                <div class="col-md-8">
                                  <div class="alert alert-success" role="alert">
                                    {added_to_cart_message}.{" "}
                                    <Link to="/cart">Open cart</Link>
                                  </div>
                                </div>
                                <div class="col-md-2"></div>
                              </>
                            ) : (
                              ""
                            )}
                            {added_to_wishlist_message !== "" &&
                            item_added_to_cart_or_wishlist === p._id ? (
                              <>
                                <div class="col-md-2"></div>
                                <div class="col-md-8">
                                  <div class="alert alert-success" role="alert">
                                    {added_to_wishlist_message}.{" "}
                                    <Link to="/wishlist">Open wishlist</Link>
                                  </div>
                                </div>
                                <div class="col-md-2"></div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  )
                ) : (
                  <div class="col-12 d-flex text-center" data-aos="fade-up">
                    <div class="alert alert-danger" role="alert">
                      No results found. You can try searching again
                    </div>
                  </div>
                )}
              </div>

              <div className="row text-center">
                <div className="col-12">
                  {retrieved_products.length > x_per_page ? (
                    <ReactPaginate {...paginationConfig} />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div class="row mt-5" id="reviews">
                <div class="col-md-12 mt-5 mb-5">
                  <p>{seller_description}</p>
                </div>

                <div class="col-md-12 text-middle mt-5">
                  <h3>
                    <u>Ratings &amp; Reviews</u>
                  </h3>
                  {seller_reviews === undefined || seller_reviews.length < 1 ? (
                    <>
                      <p>
                        This seller hasn't received enough ratings or reviews to
                        display a reviews summary
                      </p>
                      <a href="#reviews">Be the first to review</a>
                    </>
                  ) : (
                    <>
                      <p>
                        <span class="rating-value">
                          {seller_rating.toFixed(1)}
                        </span>
                        {/* { seller_reviews.length } */}
                        &nbsp;{seller_reviews.length}
                        {/* Ratings */}
                        &nbsp;
                        {seller_reviews.length === 1
                          ? "Rating"
                          : "Total ratings"}
                      </p>

                      <a href="#reviews">Seller reviews</a>
                    </>
                  )}
                </div>

                <div class="col-12">
                  {loggedIn === "" ||
                  loggedIn === null ||
                  loggedIn === undefined ? (
                    <Link
                      to="/login"
                      class="btn btn-primary btn-sm"
                      style={{ color: "#fff", fontSize: "12px" }}
                    >
                      Login to add a review
                    </Link>
                  ) : (
                    <button
                      onClick={() => add_a_review_open()}
                      class="btn btn-primary btn-sm"
                      style={{ color: "#fff", fontSize: "12px" }}
                    >
                      Add a review
                    </button>
                  )}

                  <div class="row">
                    {seller_reviews
                      .slice(0)
                      .reverse()
                      .map((r) => (
                        <div class="col-md-6 mt-5 pr-2">
                          <h5>{r.reviewer}</h5>
                          {r.rating === 1 ? (
                            <>
                              <i class="bi bi-star-fill green-text"></i>
                              &nbsp;
                              <i class="bi bi-star green-text"></i>
                              &nbsp;
                              <i class="bi bi-star green-text"></i>
                              &nbsp;
                              <i class="bi bi-star green-text"></i>
                              &nbsp;
                              <i class="bi bi-star green-text"></i>
                            </>
                          ) : r.rating === 2 ? (
                            <>
                              <i class="bi bi-star-fill green-text"></i>
                              &nbsp;<i class="bi bi-star-fill green-text"></i>
                              &nbsp;<i class="bi bi-star green-text"></i>
                              &nbsp;<i class="bi bi-star green-text"></i>
                              &nbsp;<i class="bi bi-star green-text"></i>
                            </>
                          ) : r.rating === 3 ? (
                            <>
                              <i class="bi bi-star-fill green-text"></i>
                              &nbsp;<i class="bi bi-star-fill green-text"></i>
                              &nbsp;<i class="bi bi-star-fill green-text"></i>
                              &nbsp;<i class="bi bi-star green-text"></i>
                              &nbsp;<i class="bi bi-star green-text"></i>
                            </>
                          ) : r.rating === 4 ? (
                            <>
                              <i class="bi bi-star-fill green-text"></i>
                              &nbsp;<i class="bi bi-star-fill green-text"></i>
                              &nbsp;<i class="bi bi-star-fill green-text"></i>
                              &nbsp;<i class="bi bi-star-fill green-text"></i>
                              &nbsp;<i class="bi bi-star green-text"></i>
                            </>
                          ) : r.rating === 5 ? (
                            <>
                              <i class="bi bi-star-fill green-text"></i>
                              &nbsp;<i class="bi bi-star-fill green-text"></i>
                              &nbsp;<i class="bi bi-star-fill green-text"></i>
                              &nbsp;<i class="bi bi-star-fill green-text"></i>
                              &nbsp;<i class="bi bi-star-fill green-text"></i>
                            </>
                          ) : (
                            ""
                          )}

                          <p>{r.review}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <section id="contact" className="contact">
        {page_content}
      </section>

      <Dialog
        open={add_a_review}
        onClose={add_a_review_close}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={reviewSeller}>
          <DialogTitle id="form-dialog-title">
            <h4>Add a review</h4>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div class="col-md-12 form-group">
                <label>Rating</label>
                <br />
                <RNI
                  min={1}
                  max={5}
                  step={1}
                  class="form-control"
                  value={new_rating}
                  onChange={(e) => set_new_rating(e.target.value)}
                />
              </div>

              <div class="form-group mt-3">
                <label>Comments (optional)</label>
                <br />
                <textarea
                  class="form-control"
                  rows="6"
                  placeholder="Hold the bottom right corner to expand"
                  value={new_comment}
                  onChange={(e) => set_new_comment(e.target.value)}
                ></textarea>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              className="btn btn-lg btn-success"
              type="button"
              onClick={add_a_review_close}
              color="primary"
            >
              Cancel
            </button>

            <button
              className="btn btn-lg btn-success"
              type="submit"
              color="primary"
              disabled={action_loading}
            >
              Add
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
export default SellerShopScreen;
