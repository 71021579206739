import React, { useEffect, useState } from "react";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import { updateUserDetails } from "../actions/userActions";
import { Textbox } from "react-inputs-validation";
import { validate_multiple_roles } from "../validations";
import { Link } from "react-router-dom";
import Spinner from "../components/spinner";

function AccountSettingsScreen(props) {
  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["admin", "technical-support", "seller", "buyer"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }
  // console.log(user_profile)
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  const [page_loaded, set_page_loaded] = useState(false);

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");

  const [old_password, set_old_password] = useState("");
  const [new_password1, set_new_password1] = useState("");
  const [new_password2, set_new_password2] = useState("");

  const [new_password1_invalid, set_new_password1_invalid] = useState(true);
  const [new_password2_invalid, set_new_password2_invalid] = useState(true);
  const [old_password_invalid, set_old_password_invalid] = useState(true);

  // eslint-disable-next-line
  useEffect(() => {
    if (page_loaded === false) {
      set_page_loaded(true);
      load_page();
    }
  });

  /**
   * Loads a page date
   */
  const load_page = async () => {
    set_success_message("");
    set_error_message("");

    // set_action_loading(false)
    return;
  };

  /**
   *
   * @returns Updates a user password
   */
  const update_user_password = async (e) => {
    e.preventDefault();
    set_action_loading(true);
    set_success_message("");
    set_error_message("");

    if (new_password2_invalid) {
      set_error_message("Please correct your password and try again.");
      set_action_loading(false);
      return;
    }

    const update_data = {
      new_password1,
      new_password2,
      old_password,
    };

    const update_key = "password";

    try {
      const add_action = await updateUserDetails(update_data, update_key);
      if (add_action.success === true) {
        set_new_password1("");
        set_new_password2("");
        set_old_password("");
        set_new_password1_invalid(true);
        set_new_password2_invalid(true);
        set_old_password_invalid(true);
        set_success_message("Password successfully updated.");
      } else {
        set_error_message(add_action.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message("An error occured updating your password");
    }

    set_action_loading(false);
    return;
  };

  return (
    <>
      <main id="main">
        <section class="intro-single">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-lg-8">
                <div class="title-single-box">
                  <h1 class="title-single">Account Settings</h1>
                  {/* <span class="color-text-a">Here is a list of your listed properties</span> */}
                </div>
              </div>
              <div class="col-md-12 col-lg-4">
                <nav
                  aria-label="breadcrumb"
                  class="breadcrumb-box d-flex justify-content-lg-end"
                >
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Account Settings
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section class="contact">
          <div class="container">
            <div class="row">
              <div class="col-md-12 section-t3">
                <div class="row">
                  {/* <div class="col-md-12 mb-3">
                      <a class="btn btn-primary btn-sm" href="/list-a-property/rent">Advertise rental property</Link>
                      &nbsp;
                      <a class="btn btn-primary btn-sm" href="/list-a-property/sell">Sell a property</Link>
                    </div> */}
                  <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <form onSubmit={update_user_password} class="main-form">
                      <h3 class="mb-4">Change password</h3>
                      <div class="row">
                        <div class="col-md-12">
                          {error_message !== "" ? (
                            <div class="alert alert-danger" role="alert">
                              {error_message}
                            </div>
                          ) : (
                            ""
                          )}
                          {success_message !== "" ? (
                            <div class="alert alert-success" role="alert">
                              {success_message}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-3">
                          <div class="form-group">
                            <label>Old password</label>
                            <Textbox
                              attributesWrapper={{}}
                              attributesInput={{
                                id: "oldPassword",
                                name: "oldPassword",
                                type: "password",
                                placeholder: "Old password",
                              }}
                              // validate={validate}
                              validationCallback={(res) =>
                                set_old_password_invalid(res)
                              }
                              value={old_password}
                              disabled={false}
                              classNameInput=""
                              classNameWrapper=""
                              classNameContainer=""
                              customStyleInput={{}}
                              customStyleWrapper={{}}
                              customStyleContainer={{}}
                              onChange={(old_password, e) => {
                                set_old_password(e.target.value);
                              }}
                              onBlur={(e) => {
                                console.log(e);
                              }}
                              // onFocus={(e) => {console.log(e);}}
                              // onClick={(e) => {console.log(e);}}
                              validationOption={{
                                name: "Old password",
                                check: true,
                                required: true,
                                type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                                // showMsg: true,
                                // min: 2,
                                // max: 10,
                                // length: 2,
                                // compare: '3',
                                // reg: /^\d{18}|\d{15}$/,
                                // regMsg: 'failed in reg.test(${value})',
                                // locale: 'en-US',
                                // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                                // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                                // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                                //   if (res != 'milk') {
                                //     return 'Name cannot be other things but milk';
                                //   }
                                //   return true;
                                // }
                              }}
                            />
                          </div>
                        </div>

                        <div class="col-md-12 mb-3">
                          <div class="form-group">
                            <label>New password</label>
                            <Textbox
                              attributesWrapper={{}}
                              attributesInput={{
                                id: "password",
                                name: "password",
                                type: "password",
                                placeholder: "New password",
                                minLength: "6",
                                maxLength: "14",
                              }}
                              // validate={validate}
                              validationCallback={(res) =>
                                set_new_password1_invalid(res)
                              }
                              value={new_password1}
                              disabled={false}
                              classNameInput=""
                              classNameWrapper=""
                              classNameContainer=""
                              customStyleInput={{}}
                              customStyleWrapper={{}}
                              customStyleContainer={{}}
                              onChange={(new_password1, e) => {
                                set_new_password1(e.target.value);
                              }}
                              onBlur={(e) => {
                                console.log(e);
                              }}
                              // onFocus={(e) => {console.log(e);}}
                              // onClick={(e) => {console.log(e);}}
                              validationOption={{
                                name: "New password",
                                check: true,
                                required: true,
                                type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                                // showMsg: true,
                                // min: 2,
                                // max: 10,
                                // length: 2,
                                // compare: '3',
                                // reg: /^\d{18}|\d{15}$/,
                                // regMsg: 'failed in reg.test(${value})',
                                // locale: 'en-US',
                                // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                                // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                                // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                                //   if (res != 'milk') {
                                //     return 'Name cannot be other things but milk';
                                //   }
                                //   return true;
                                // }
                              }}
                            />
                          </div>
                        </div>

                        <div class="col-md-12 mb-3">
                          <div class="form-group">
                            <label>Confirm new password</label>
                            <Textbox
                              attributesWrapper={{}}
                              attributesInput={{
                                id: "confirmPassword",
                                name: "confirmPassword",
                                type: "password",
                                placeholder: "Confirm new password",
                                minLength: "6",
                                maxLength: "14",
                              }}
                              // validate={validate}
                              validationCallback={(res) =>
                                set_new_password2_invalid(res)
                              }
                              value={new_password2}
                              disabled={false}
                              classNameInput=""
                              classNameWrapper=""
                              classNameContainer=""
                              customStyleInput={{}}
                              customStyleWrapper={{}}
                              customStyleContainer={{}}
                              onChange={(new_password2, e) => {
                                set_new_password2(e.target.value);
                              }}
                              onBlur={(e) => {
                                console.log(e);
                              }}
                              // onFocus={(e) => {console.log(e);}}
                              // onClick={(e) => {console.log(e);}}
                              validationOption={{
                                name: "Confirm new password",
                                check: true,
                                required: true,
                                type: "string", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                                // showMsg: true,
                                // min: 2,
                                // max: 10,
                                // length: 2,
                                // compare: '3',
                                // reg: /^\d{18}|\d{15}$/,
                                // regMsg: 'failed in reg.test(${value})',
                                // locale: 'en-US',
                                // msgOnError: "Your two passwords do not match",
                                // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                                customFunc: (res) => {
                                  // Optional.[Func].Default: none. Custom function. Returns true or err message
                                  if (res !== new_password1) {
                                    return "Your two passwords do not match";
                                  }
                                  return true;
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div class="col-md-12 text-center">
                          <button
                            type="submit"
                            class="btn btn-primary"
                            disabled={
                              action_loading ||
                              old_password_invalid ||
                              new_password1_invalid
                            }
                          >
                            {!action_loading ? (
                              "Change password"
                            ) : (
                              <>
                                <div
                                  class="spinner-border text-orange"
                                  role="status"
                                ></div>
                                <Spinner />
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-2"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
export default AccountSettingsScreen;
