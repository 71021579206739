import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import store from "./store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_OAUTH_CLIENT_ID } from "./config";
import { ProductsProvider } from "./contexts/ProductsContext";

ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <ProductsProvider>
        <App />
      </ProductsProvider>
    </GoogleOAuthProvider>
    ;
  </Provider>,
  document.getElementById("root")
);
