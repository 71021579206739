import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getProducts, updateProduct, deductQuantity } from '../actions/productActions';
import { getCategories } from '../actions/categoryActions';
import { validate_role } from '../validations';
import { Textbox, Checkbox } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { API_URL, IMAGES_URL, API_KEY, get_display_date} from '../config';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function SellerProductScreen(props) {

    const [selectedImage, setSelectedImage] = useState(null);
    //const [crop, setCrop] = useState({ aspect: 4 / 3 });
	const [crop, setCrop] = useState({ aspect: 4 / 3, x: 0, y: 0, width: 240, height: 180 });
    const [croppedImageFile, setCroppedImageFile] = useState(null);
    const [isCropModalOpen, setIsCropModalOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);
	const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
	
    const [product_name, set_product_name] = useState('');
    const [quantity, set_quantity] = useState('');
    const [quantity_units, set_quantity_units] = useState('');
    const [created_at, set_created_at] = useState('');	
	
	const imageRef = useRef();
    const fileInputRef = useRef(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setSelectedImage(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  
  
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [confirmationError, setConfirmationError] = useState("");
  
  // Function to handle the crop

const onCropComplete = (crop) => {
  if (!selectedImage || !crop.width || !crop.height) return;

  const canvas = document.createElement('canvas');
  const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
  const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
  canvas.width = 400; // Width of the output image
  canvas.height = 300; // Height of the output image
  const ctx = canvas.getContext('2d');

  const image = new Image();
  image.src = selectedImage;
  image.onload = () => {
    ctx.drawImage(
      image,
      crop.x * scaleX, // Scaled x-coordinate
      crop.y * scaleY, // Scaled y-coordinate
      crop.width * scaleX, // Scaled width
      crop.height * scaleY, // Scaled height
      0,
      0,
      400,
      300
    );

    canvas.toBlob((blob) => {
      const croppedFile = new File([blob], "cropped.jpeg", { type: 'image/jpeg' });
      setCroppedImageFile(croppedFile);
    }, 'image/jpeg');
  };
};




    const [file, setFile] = useState(''); // storing the uploaded file
    // eslint-disable-next-line
    const [progress, setProgess] = useState(0); // progess bar
    const el = useRef(); // accesing input element    
	
	


const handleChange = (e) => {
  if (e.target.files && e.target.files.length > 0) {
    const file = e.target.files[0];
    setFile(file);	  
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
      setIsCropModalOpen(true);
    };
    reader.readAsDataURL(file);
  }
    setIsConfirmed(false); // Reset confirmation state
    setSelectedImage(null); // Reset selected image
    setCroppedImageFile(null); // Reset cropped image file  
};

const handleOkClick = () => {
  if (!consent_photo) {
    setConfirmationError("Please confirm this is an original photo of your produce. If not, please cancel");
    return;
  }
  if (crop.width && crop.height && selectedImage) {
    onCropComplete(crop);
	uploadFile();	
    setIsCropModalOpen(false);
    setConfirmationError(""); // Reset the error message
    setSelectedImage(null); // Reset selected image after successful crop
	set_consent_photo(false);
  }
};

const handleCancelClick = () => {
    setIsCropModalOpen(false); // Close the modal
    setSelectedImage(null); // Reset selected image
    setCroppedImageFile(null); // Reset cropped image file
	if (fileInputRef.current) fileInputRef.current.value = ''; // Reset file input
	set_consent_photo(false);

};

const onImageLoaded = (image) => {
  imageRef.current = image; // Set the image reference
  const imageWidth = image.naturalWidth;
  const imageHeight = image.naturalHeight;
  setImageDimensions({ width: imageWidth, height: imageHeight });

  // Calculate initial crop for 4:3 ratio
  const targetRatio = 4 / 3;
  let cropWidth = Math.min(imageWidth, imageHeight * targetRatio);
  let cropHeight = cropWidth / targetRatio;
  
  // If the height exceeds image height after calculating width, adjust it
  if (cropHeight > imageHeight) {
    cropHeight = imageHeight;
    cropWidth = cropHeight * targetRatio;
  }

  const x = (imageWidth - cropWidth) / 2;
  const y = (imageHeight - cropHeight) / 2;

  setCrop({
    aspect: targetRatio,
    width: cropWidth,
    height: cropHeight,
    x: x,
    y: y,
  });
};

    const [delete_stock_dialog, set_delete_stock_dialog] = React.useState(false);

    const delete_stock_dialog_open = () => {
      set_delete_stock_dialog(true);
      };
    
      const delete_stock_dialog_close = () => {
      set_delete_stock_dialog(false);
      };
  
  // eslint-disable-next-line
  let user_profile;
  const permitted_role = 'seller';
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = 'login';
    if (permitted_role === 'seller') login_link = 'access-forbidden'
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const id = props.match.params.id ? props.match.params.id : '';

  const [agree, set_agree] = useState(false);

  const [consent_photo, set_consent_photo] = useState(false);

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');

  const [upload_error_message, set_upload_error_message] = useState('');
  const [upload_success_message, set_upload_success_message] = useState('');

  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  // eslint-disable-next-line
  const [add_category, set_add_category] = useState('');
  // eslint-disable-next-line
  const [add_sub_category, set_add_sub_category] = useState('');
  const [add_product, set_add_product] = useState('');
  // eslint-disable-next-line
  const [add_description, set_add_description] = useState('');
  const [add_units, set_add_units] = useState('');
  const [add_quantity, set_add_quantity] = useState('');
  const [add_price, set_add_price] = useState('');

  const [deduct_quantity, set_deduct_quantity] = useState('');
  const [deduct_description, set_deduct_description] = useState('');

  // eslint-disable-next-line
  const [deduct_quantity_invalid, set_deduct_quantity_invalid] = useState(true);

  const [product_details, set_product_details] = useState([]);
  const [product_sales, set_product_sales] = useState([]);
  const [product_pictures, set_product_pictures] = useState([]);
  // eslint-disable-next-line
  const [all_categories, set_all_categories] = useState([]);
  // eslint-disable-next-line
  const [all_product_types, set_all_product_types] = useState([]);

  // eslint-disable-next-line
  const [display_sub_categories, set_display_sub_categories] = useState([]);
  // eslint-disable-next-line
  const [display_product_types, set_display_product_types] = useState([]);
  // eslint-disable-next-line
  const [display_units, set_display_units] = useState([]);  


  const uploadFile = async () => {
    set_success_message('');
    set_error_message('');
    set_upload_success_message('');
    set_upload_error_message('');


    set_action_loading(true);

    // Check if cropped image is ready
    if (!croppedImageFile) {
      set_upload_error_message('No cropped image file is ready for upload.');
      set_action_loading(false);
      return;
    }
  
  
    //   TODO: get these from config file 
    const token_string = localStorage.getItem('token');
    const formData = new FormData();        

    formData.append('file', croppedImageFile); // Use the cropped image file

    formData.append('api_key', API_KEY);
    formData.append('product_id', id);
    await axios.post(`${API_URL}/products/upload-image`, 
        formData, {
        headers: {
          Authorization: 'Bearer ' + token_string
        },
      }, {
        onUploadProgress: (ProgressEvent) => {
            let progress = Math.round(
            ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
            setProgess(progress);
        }
    }).then(res => {
        const { data } = res;
        if (data.success === true) {   
          set_upload_success_message('New image successfully added.'); 
        } else if(data.success === false) {
            set_upload_error_message(data.message);
        } else {
            set_upload_error_message(`An error occured uploading product image`);
        }
        // console.log(res);
        // getFile({ 
        //     name: res.data.name,
        //     path: 'http://127.0.0.1:4002/routes/public/' + res.data.path
        // });
    }).catch(
        // set_upload_error_message('An error occured uploading product image')
    );

    let get_product_details;
          try {
            get_product_details = await getProducts(id, 'seller', 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', 'quantity', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order' );
            if (get_product_details.success === false) {
              set_error_message(get_product_details.message);
            }
            else {
                // console.log(get_product_details);
                const { data } = get_product_details;
                set_product_pictures(data[0].pictures);
            }
          } catch (error) {
            set_error_message(`An error occured fetching categories: ${error.message} `);
          }
    set_action_loading(false);

    setIsCropModalOpen(false); // Close the modal after upload
    setSelectedImage(null); // Reset selected image after upload
    setCroppedImageFile(null); // Reset cropped image file after upload
    if (fileInputRef.current) fileInputRef.current.value = ''; // Reset file input after successful upload
	
}


    /**
   * Deducts quantity. record offsite sales
   * 
   * @param {*} e 
   */
  const deductProductQuantity = async (e) =>  {
    e.preventDefault();
    if (page_loaded === false) {
        return;
    }

    set_success_message('');
    set_error_message('');
    set_upload_success_message('');
    set_upload_error_message('');

    set_action_loading(true);

    if (deduct_description === '') {
      set_error_message('Description cannot be null. Please try again');
      set_action_loading(false);
      return;
    }

    const response = await deductQuantity(id, deduct_quantity, deduct_description); 
    if (response.success === false) {
        set_error_message(response.message); 
    } else {
      let get_product_details;
      let _product_details;
      try {
        get_product_details = await getProducts(id, 'seller', 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', 'quantity', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order' );
        if (get_product_details.success === false) {
          set_error_message(get_product_details.message);
        }
        else {
            // console.log(get_product_details);
            const { data } = get_product_details;
            _product_details = data[0];
            set_add_category(_product_details.category_id);
            set_add_sub_category(_product_details.sub_category_meta);
            set_add_product(_product_details.product_id);
            set_add_description(_product_details.description);
            set_add_units(_product_details.quantity_units);
            set_add_quantity(_product_details.quantity);
            set_add_price(_product_details.price);
            set_product_details(data[0]);
            // console.log('product sales', );
            set_product_sales(data[0].sales);
            set_product_pictures(data[0].pictures);
        }
      } catch (error) {
        set_error_message(`An error occured fetching categories: ${error.message} `);
      }

      set_success_message('Deduction successfully done.');
    }
    set_action_loading(false);

    return; 
  }


  /**
   * Deducts all remaining inventory items of this product
   * 
   * @param {*} e 
   */
  const deductAllStockQuantity = async (e) =>  {
    e.preventDefault();
    if (page_loaded === false) {
        return;
    }

    set_success_message('');
    set_error_message('');
    set_upload_success_message('');
    set_upload_error_message('');

    set_action_loading(true);

    const response = await deductQuantity(id, add_quantity, '4'); 
    if (response.success === false) {
        set_error_message(response.message); 
    } else {
      props.history.push('/seller-products');

      set_success_message('Product successfully removed from stock inventory');
    }
    set_action_loading(false);

    return; 
  }


  // const category_changed = (new_category) => {
  //   let _display_sub_categories = [];
  //   let _display_product_types = [];

  //   set_add_category(new_category);
  //   set_add_sub_category('');
  //   set_add_product('');

  //   if (new_category !== '') {
  //     for(let i = 0; i < all_categories.length; i++ ) {
  //       if (all_categories[i]._id === new_category) {
  //         _display_sub_categories = all_categories[i].sub_categories;
  //       }
  //     }

  //     for (let i = 0; i < all_product_types.length; i++) {
  //       if (all_product_types[i].category_id === new_category) {
  //         _display_product_types.push(all_product_types[i]);
  //       }
  //     }
  //   }
    
  //   set_display_sub_categories(_display_sub_categories);
  //   set_display_product_types(_display_product_types);    
  // }

  // const sub_category_changed = (new_sub_category) => {
  //   let _display_product_types = [];

  //   set_add_sub_category(new_sub_category);
  //   set_add_product('');

  //   if (new_sub_category !== '') {
  //     for (let i = 0; i < all_product_types.length; i++) {
  //       if (all_product_types[i].sub_category_meta === new_sub_category) {
  //         _display_product_types.push(all_product_types[i]);
  //       }
  //     }
  //   }
    
  //   set_display_product_types(_display_product_types);    
  // }

  // const product_type_changed = (new_product_type) => {
  //   let _display_units = [];

  //   set_add_product(new_product_type);

  //   if (new_product_type !== '') {
  //     for (let i = 0; i < all_product_types.length; i++) {
  //       if (all_product_types[i]._id === new_product_type) {
  //         console.log(all_product_types[i]._id, all_product_types[i].units);
  //         _display_units = all_product_types[i].units;
  //       }
  //     }
  //   }
    
  //   set_display_units(_display_units);
  // }


  const load_page_data = async () => { 
     let get_all_categories;
     let get_product_details;
     let _product_details;

     try {
        get_product_details = await getProducts(id, 'seller', 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', 'quantity', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order' );
        if (get_product_details.success === false) {
          set_error_message(get_product_details.message);
        }
        else {
            // console.log(get_product_details);
            const { data } = get_product_details;
            _product_details = data[0];
            set_add_category(_product_details.category_id);
            set_add_sub_category(_product_details.sub_category_meta);
            set_add_product(_product_details.product_id);
            set_add_description(_product_details.description);
            set_add_units(_product_details.quantity_units);
            set_add_quantity(_product_details.quantity);
            set_add_price(_product_details.price);
            set_product_details(data[0]);
            // console.log('product sales', );
            set_product_sales(data[0].sales);
            set_product_pictures(data[0].pictures);
			
			set_product_name(data[0].product_name);
			set_quantity(data[0].quantity);
			set_quantity_units(data[0].quantity_units);
			set_created_at(formatDate(data[0].createdAt));			
        }
      } catch (error) {
        set_error_message(`An error occured fetching categories: ${error.message} `);
      }

      try {
        get_all_categories = await getCategories('id', 'meta', 'active');
        if (get_all_categories.success === false) {
          set_error_message(get_all_categories.message);
        }
        else {
          const { data } = get_all_categories;
          set_all_categories(data);
        }
      } catch (error) {
        set_error_message(`An error occured fetching categories: ${error.message} `);
      }

    return;
  }

  /**
   * Updates user profile
   * @param {*} e 
   */
  const updateProduce = async (e) =>  {
    e.preventDefault();

    set_success_message('');
    set_error_message('');
    set_upload_success_message('');
    set_upload_error_message('');

    set_action_loading(true);

    const update_data = {
      product_id: add_product,
      price: add_price,
      quantity: add_quantity,
    };

    const response = await updateProduct(update_data, id, 'basic_detail'); 
    if (response.success === false) {
        set_error_message(response.message); 
    } else {
      let get_product_details;
      let _product_details;
      try {
        get_product_details = await getProducts(id, 'seller', 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', 'quantity', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order' );
        if (get_product_details.success === false) {
          set_error_message(get_product_details.message);
        }
        else {
            // console.log(get_product_details);
            const { data } = get_product_details;
            _product_details = data[0];
            set_add_category(_product_details.category_id);
            set_add_sub_category(_product_details.sub_category_meta);
            set_add_product(_product_details.product_id);
            set_add_description(_product_details.description);
            set_add_units(_product_details.quantity_units);
            set_add_quantity(_product_details.quantity);
            set_add_price(_product_details.price);
            set_product_details(data[0]);
            // console.log('product sales', );
            set_product_sales(data[0].sales);
            set_product_pictures(data[0].pictures);
			
			
        }
      } catch (error) {
        set_error_message(`An error occured fetching categories: ${error.message} `);
      }

      set_success_message('Product details successfully updated');
    }
    set_action_loading(false);

    return; 
  }

  
  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }

  useEffect(() => {
    if (page_loaded === false ) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);


  /**
   * Removes a product image
   * @param {*} e 
   */
   const removeImage = async (remove_image) =>  {
    // e.preventDefault();

    set_success_message('');
    set_error_message('');
    set_upload_success_message('');
    set_upload_error_message('');

    set_action_loading(true);

    const update_data = {
      remove_image,
    };

    const response = await updateProduct(update_data, id, 'remove_image'); 
    if (response.success === false) {
        set_error_message(response.message); 
    } else {
      let get_product_details;
      let _product_details;
      try {
        get_product_details = await getProducts(id, 'seller', 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', 'quantity', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order' );
        if (get_product_details.success === false) {
          set_error_message(get_product_details.message);
        }
        else {
            // console.log(get_product_details);
            const { data } = get_product_details;
            _product_details = data[0];
            set_add_category(_product_details.category_id);
            set_add_sub_category(_product_details.sub_category_meta);
            set_add_product(_product_details.product_id);
            set_add_description(_product_details.description);
            set_add_units(_product_details.quantity_units);
            set_add_quantity(_product_details.quantity);
            set_add_price(_product_details.price);
            set_product_details(data[0]);
            // console.log('product sales', );
            set_product_sales(data[0].sales);
            set_product_pictures(data[0].pictures);
        }
      } catch (error) {
        set_error_message(`An error occured fetching categories: ${error.message} `);
      }

      set_success_message('Product details successfully updated');
    }
    set_action_loading(false);

    return; 
  }


  

  return (
    <>
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2><span class="green-text">Viewing your</span> <span class="orange-text">product details:</span></h2>
          <h2><span class="green-text"> {product_name}, added on {created_at}</span></h2>		  
        </div>       


        {!page_loaded  ? ( // so that only products will be loading at this time 
          <div>Loading ... </div>
        ) : (
          <>
            <div class="row">
              <div class="col-12">
                  {error_message !== '' ? (
                      <div class="alert alert-danger" role="alert">                        
                        {error_message}
                      </div>
                    ): ('')}
                    {success_message !== '' ? (
                      <div class="alert alert-success" role="alert">                        
                          {success_message}
                      </div>
                    ): ('')}     
                </div>
            </div>
          <div class="row">
            

            <div class="col-12">
                <h2>Basic details</h2>
                  <form onSubmit={updateProduce}>
                      <div class="row">
                        <div class="form-group col-md-4 my-3"> 
                          <label for="">Category</label>
                          <input class="form-control" value={product_details.category} disabled />
                        </div>

                        <div class="form-group col-md-4 my-3"> 
                          <label for="">Sub category</label>
                          <input class="form-control" value={product_details.sub_category} disabled />
                        </div>

                        <div class="form-group col-md-4 my-3"> 
                          <label for="">Product</label>
                          <input class="form-control" value={product_details.product_name} disabled />
                        </div>

                        <div class="form-group col-md-4 my-3"> 
                          <label for="">Units</label>
                          <input class="form-control" value={product_details.quantity_units} disabled />
                        </div>

                        <div class="form-group col-md-4 my-3">
                          <label for="add_quantity">Quantity</label>
                         
                          <input class="form-control" value={add_quantity} min="1" onChange={(e) => { set_add_quantity(e.target.value); }} />
                        </div>

                        {/* <div class="form-group col-md-4 my-3"> 
                          <label for="">Is this food organic ?</label>
                          <input class="form-control" value={add_organic === true ? 'Yes' : 'No'} disabled />
                        </div> */}

                        <div class="form-group col-md-4 my-3">
                        <label for="add_price">Price {add_units === 'each' ? '' : 'per'} {add_units} ({product_details.currency})</label>
                          <input type="number" step="0.01" class="form-control" value={add_price} required name="add_price" onChange={(e) => { set_add_price(e.target.value); }} min="0.01"/>
                        </div>

                        

                      

                        
                      </div>

                      <div class="form-group col-12 my-3 text-center">
                        <button 
                          class="btn btn-lg btn-success" 
                          type="submit"
                          disabled={action_loading}
                          // check invalids 
                        >
                          <i class="glyphicon glyphicon-plus"></i> Update product details
                        </button>
                      </div>
                </form>

                <hr/>
          </div>


          
          <div class="col-md-12">
              <h2>Update pictures</h2>
              <div class="row">


                <div class="col-12">
                  <div class="row">
                    {product_pictures.map((c) => (    
                                        <div class="col-md-3" key={c}>
                                          <div class="info-box product-box">
                                            <img src={`${IMAGES_URL}/${c}`} alt="Product" width="400" height="300" />
                                            <br/><br/>
                                            { c === 'defaults/default-image.png' ? (''):(
                                              <button onClick={() => { removeImage(c) }} class="btn btn-lg btn-success" >                   
                                                Remove this image
                                              </button>
                                            )}
                                            
                                          </div>
                                        </div>
                                    ))}

                  </div>
                </div>



                <div class="row">
                    <div class="col-12">
                      {upload_error_message !== '' ? (
                          <div class="alert alert-danger" role="alert">
                            
                            {upload_error_message}
                          </div>
                        ): ('')}
                        {upload_success_message !== '' ? (
                          <div class="alert alert-success" role="alert">
                            
                              {upload_success_message}
                          </div>
                        ): ('')}     
                    </div>
			    
                    <div class="col-12">
			    
                    </div>
			    
                    <div class="form-group col-4 my-3 text-center">
                        <label class="mb-2">Recommended image dimensions are 400*300</label>
                        <input type="file" onChange={handleChange} ref={fileInputRef} />   
			    
			    
                        {isCropModalOpen && (
                          <div style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '20px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                            }}>
                            <div style={{ marginBottom: '20px', color: '#fff', fontSize: '18px' }}>
                              Select the area of the photo to show for the product
                            </div>
                            <div style={{ width: '400px', height: 'auto', position: 'relative' }}>
                              <ReactCrop
                                src={selectedImage}
                                crop={crop}
                                onImageLoaded={onImageLoaded}
                                ruleOfThirds
                                onChange={(newCrop) => setCrop(newCrop)}
                                onComplete={onCropComplete}
                              />
                            </div>
                            <div className="d-flex align-items-center">
                                 <Checkbox
                                   attributesInput={{
                                     id: 'consent_photo',
                                     name: 'consent_photo',
                                   }}
                                   onBlur={() => {}} 
                                   checked={consent_photo}
                                   onChange={(e) => {
                                     set_consent_photo(e);
                                   }}
                                   labelHtml={
                                     <div style={{ color: '##fff', marginTop: '2px' }}>
                                       I confirm this is an original photo of the actual produce I am selling
                                     </div>
                                   }
                                   validationOption={{
                                     name: 'consent_photo',
                                     check: true,
                                     required: true,
                                   }}
                                 />	
                            </div>
                            {confirmationError && <p className="text-danger">{confirmationError}</p>}
                            <div className="button-group mt-3">
                              <button className="btn btn-primary me-2" onClick={handleOkClick}>Upload new image</button>
                              <button className="btn btn-secondary" onClick={handleCancelClick}>Cancel</button>
                            </div>    
                          </div>
                        )}				
                        {/* <div className="progessBar" style={{ width: progress }}>
                        {progress}
                        </div> */}
                        
                    {/* {data.path && <img src={data.path} alt={data.name} />} */}
                    </div>
			    
                
			    
			    
                    <div class="col-4"></div>
                    <div class="col-4"></div>
			    
			    
                
                </div>
                <hr/>
			    
            
        </div>
              <hr/>
          </div>

          <div class="col-md-12">
              <h2>Sales &amp; quantity deductions</h2>
                    {product_sales.length > 0 ? (
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <tr>
                                    <th>Date of transaction</th>
                                    <th>Quantity</th>
                                    <th>Sale type</th>
                                    <th>Description</th>
                                </tr>
                                
                                {product_sales.map((c) => (    
                                    <tr key={c._id}>
                                      <td>{get_display_date(c.date_time)}</td>
<td>{c.deduct_quantity}</td>
                                <td>{c.sale_type}</td>
                                <td>
                                  {c.description === 1 ? 'I sold this quantity to other buyers (not from Farm-Fresh)':''}
                                  {c.description === 2 ? 'This quantity has gone bad and no longer fit for sale':''}
                                  {c.description === 3 ? 'I gifted someone with this quantity':''}
                                  {c.description === 4 ? 'Quantity is no longer in stock':''}
                                </td>
                                    </tr>          
                                ))}
                            </table>
                        </div>
                    ):('No sales or deductions made so far.')}
                    

                    <br/><br/><br/>
                    <h2>Deduct quantity</h2>
                    <form onSubmit={deductProductQuantity}>
                      <div class="row">
                      <div class="form-group col-md-4 my-3">
                          <label for="deduct_quantity">Deduct quantity</label>
                          <Textbox
                            attributesWrapper={{}}
                            attributesInput={{
                              id: "deduct_quantity",
                              name: "deduct_quantity",
                              type: "number",
                              placeholder: ""
                            }}
                            // validate={validate}
                            validationCallback={res =>
                              set_deduct_quantity_invalid(res)
                            } 
                            value={deduct_quantity}
                            disabled={false}
                            classNameInput=""
                            classNameWrapper=""
                            classNameContainer=""
                            customStyleInput={{}}
                            customStyleWrapper={{}}
                            customStyleContainer={{}}
                            onChange={(deduct_quantity, e) => {
                              set_deduct_quantity(e.target.value);
                            }}
                            onBlur={e => {
                              console.log(e);
                            }}
                            // onFocus={(e) => {console.log(e);}}
                            // onClick={(e) => {console.log(e);}}
                            validationOption={{
                              name: "deduct_quantity",
                              check: true,
                              required: true,
                              type: 'number', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                              // showMsg: true,
                              min: 1, 
                              max: {add_quantity},
                              // length: 2,
                              // compare: '3',
                              // reg: /^\d{12}|\d{14}$/,
                              // regMsg: 'Invalid phone number',
                              // locale: 'en-US',
                              // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                              // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                              // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                              //   if (res != 'milk') {
                              //     return 'Name cannot be other things but milk';
                              //   }
                              //   return true;
                              // }
                            }}
                          />
                        </div>
                        
                        <div class="form-group col-md-4 my-3"> 
                          <label for="">Why are you deducting this quantity?</label>
                          <select  class="form-control" name="deduct_description" id="deduct_description" onChange={(e) => set_deduct_description(e.target.value)}>
                                <option value=""></option>
                                <option value="1">I sold this quantity to other buyers (not from Farm-Fresh)</option>
                                <option value="2">This quantity has gone bad and no longer fit for sale</option>
                                <option value="3">I gifted someone with this quantity</option>
                                {/* <option value="4">Quantity is no longer in stock</option> */}
                          </select>
                        </div>

                        

                      

                        
                      </div>

                      <div class="form-group col-12 my-3 text-center">
                        <button 
                          class="btn btn-lg btn-success" 
                          type="submit"
                          disabled={action_loading}
                          // TODO: check invalids 
                        >
                          {
                            !action_loading ? ('Deduct quantity'):(
                              <>
                                <div class="spinner-border text-orange" role="status">
                                </div> 
                                &nbsp;Please wait
                              </>
                            )
                          }
                        </button>

                        
                      </div>
                </form>


                {/* <form onSubmit={}> */}
                      <div class="form-group col-12 my-3 text-left">
                        <button 
                          class="btn btn-lg btn-success" 
                          type="submit"
                          onClick={delete_stock_dialog_open}
                          disabled={action_loading}
                        >
                          {
                          !action_loading ? ('Remove product from my stock inventory'):(
                            <>
                              <div class="spinner-border text-orange" role="status">
                              </div> 
                              &nbsp;Please wait
                            </>
                          )
                        }
                        </button>
                      </div>
                      
                {/* </form> */}
                <Dialog open={delete_stock_dialog} onClose={delete_stock_dialog_close} aria-labelledby="form-dialog-title">
												<DialogTitle id="form-dialog-title">Confirm removing all stock items</DialogTitle>
												<DialogContent>
												<DialogContentText>
													Are you sure you want to remove all stock items? 
												</DialogContentText>
												</DialogContent>
												<DialogActions>
                          <button 
                            class="btn btn-lg btn-success" 
                            type="submit"
                            onClick={delete_stock_dialog_close} color="primary">
                            Cancel
                          </button>

                          <button 
                            class="btn btn-lg btn-success" 
                            type="submit"
                            onClick={deductAllStockQuantity} color="primary">
                              Yes, remove all items
                          </button>
												</DialogActions>
											</Dialog>

              <hr/>
          </div>

          {/* <div class="col-md-12">
              <h2>Notes</h2>
                TBI
              <hr/>
          </div> */}

            </div>
            </>
        ) }
        
      </div>
    </section>
    </>
  );
}
export default SellerProductScreen;
