import React, { useState, useEffect } from "react";
import { validate_role } from "../validations";
import {
  getOrdersV2,
  getOrdersV3,
  updateOrderItem,
  createOrderV2,
} from "../actions/orderActions";
//import { getMessages } from '../actions/messageActions';
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";
import { red } from "@material-ui/core/colors";
import Spinner from "../components/spinner";

function BuyerOrderScreen(props) {
  const id = props.match.params.id ? props.match.params.id : "";

  let user_profile;
  const permitted_role = "buyer";
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = "login";
    if (permitted_role === "buyer") login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [error_message, set_error_message] = useState("");
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState("");
  const [page_loaded, set_page_loaded] = useState(false);

  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  const [unread_messages_count, set_unread_messages_count] = useState(false);

  // eslint-disable-next-line
  const [order_details, set_order_details] = useState({});
  const [product_details, set_product_details] = useState({});
  const [buyer_details, set_buyer_details] = useState({});

  const [message_details, set_message_details] = useState({});
  const [messages, set_messages] = useState({});

  const [acceptance_details, set_acceptance_details] = useState({});
  const [preparation_details, set_preparation_details] = useState({});
  const [ready_details, set_ready_details] = useState({});
  const [collected_details, set_collected_details] = useState({});
  const [paid_details, set_paid_details] = useState({});

  const [mark_order_ready, set_mark_order_ready] = React.useState(false);
  const mark_order_ready_open = () => {
    set_mark_order_ready(true);
  };

  const mark_order_ready_close = () => {
    set_mark_order_ready(false);
  };

  const [start_preparing_dialog, set_start_preparing_dialog] =
    React.useState(false);

  const start_preparing_dialog_open = () => {
    set_start_preparing_dialog(true);
  };

  const start_preparing_dialog_close = () => {
    set_start_preparing_dialog(false);
  };

  const load_page_data = async () => {
    set_action_loading(true);
    let get_orders;
    try {
      get_orders = await getOrdersV2(id, "mine", "seller", "status");
      //   console.log(get_orders.data[0])
      if (get_orders.success) {
        set_order_details(get_orders.data[0]);
        set_product_details(get_orders.data[0].product_details);
        set_buyer_details(get_orders.data[0].buyer_details);
      } else {
        set_error_message("Order details not found");
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching order details: ${error.message} `
      );
    }

    // set_orders_to_display(_orders_to_display);

    let get_messages;
    try {
      get_messages = await getOrdersV3(id, "buyer", "seller", "status", "yes");
      // console.log(get_orders.data[0])
      if (get_messages.success) {
        set_message_details(get_messages.data[0]);
        set_messages(get_messages.data[0].messages);
        //console.log(get_messages.data[0].messages);
        set_unread_messages_count(get_messages.data[0].unread_messages_count);
      } else {
        set_error_message("Messages not found");
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching messages: ${error.message} `
      );
    }

    // set_orders_to_display(_orders_to_display);

    set_action_loading(false);

    return;
  };

  /**
   * Makes an action
   *
   */
  const markOrderReady = async () => {
    // e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const update_data = {
      status: "completed",
    };

    const response = await updateOrderItem(update_data, id, "completed");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message("Order successfully marked as complete");
      load_page_data();
    }

    mark_order_ready_close();
    set_action_loading(false);

    return;
  };

  /**
   * Makes an action
   *
   */
  const startPreparingOrder = async () => {
    // e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const update_data = {
      status: "preparing",
    };

    const response = await updateOrderItem(update_data, id, "preparing");
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message("Order in preparing stage now");
      load_page_data();
    }

    start_preparing_dialog_close();
    set_action_loading(false);

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  const redo_order = async (product_id, quantity) => {
    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    try {
      const response = await createOrderV2(product_id, quantity);
      if (response.success) {
        await load_page_data();

        set_success_message("New order successfully placed.");
      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message(
        "An error occured in completing your order. Please try again"
      );
    }

    set_action_loading(false);

    return;
  };

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="black-text">Viewing Order</span> <br />{" "}
              <span class="green-text">
                {order_details.quantity} {product_details.quantity_units} of{" "}
                {product_details.product_name}
              </span>
            </h2>
          </div>

          <div class="row">
            <div class="col-md-12 m-5">
              {unread_messages_count > 0 ? (
                <p class="alert alert-success">
                  You have {unread_messages_count} new messages for this order.
                  &nbsp;
                  <Link
                    to={`/messages/${order_details._id}`}
                    class="btn btn-success btn-sm"
                    style={{ color: "#fff", fontSize: "12px" }}
                    onClick={() => {
                      set_unread_messages_count(0); // Add this line to set total_unread_messages to 0
                    }}
                  >
                    View messages
                  </Link>
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== "" ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : (
                    ""
                  )}
                  {success_message !== "" ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div class="row"></div>

              <div class="row">
                <div class="col-3"></div>
                <div class="col-6">
                  <div class="table-responsive mb-5">
                    <table class="table table-bordered">
                      <thead></thead>

                      <tbody>
                        <tr>
                          <th>Order Number</th>
                          <th>{order_details._id}</th>
                        </tr>

                        <tr>
                          <th>Order Status</th>
                          <th>{order_details.status}</th>
                        </tr>

                        <tr>
                          <th>Product</th>
                          <th>{product_details.product_name}</th>
                        </tr>

                        <tr>
                          <th>Quantity Required</th>
                          <th>
                            {order_details.quantity}{" "}
                            {product_details.quantity_units}
                          </th>
                        </tr>

                        <tr>
                          <th>Price</th>
                          <th>
                            {product_details.currency}
                            {product_details.price * order_details.quantity}
                          </th>
                        </tr>

                        <tr>
                          <th></th>
                          <th>
                            <button
                              class="btn btn-primary"
                              onClick={() => {
                                redo_order(
                                  order_details.product,
                                  order_details.quantity
                                );
                              }}
                            >
                              Redo order
                            </button>
                          </th>
                        </tr>

                        {/* <tr>
                          <th>Next Activity</th>
                          <th>
                            {order_details.status === 'invited' ? ('Accept') : ('')}
                            {order_details.status === 'accepted' ? ('Prepare') : ('')}
                            {order_details.status === 'preparing' ? ('Complete') : ('')}
                            {order_details.status === 'completed' ? ('Delivery Agent Collects') : ('')}
                            {order_details.status === 'collected' ? ('Get Payout') : ('')}

                            {order_details.status === 'accepted' ? (
                              <>
                                <br />
                                <button onClick={() => start_preparing_dialog_open()} class="btn btn-primary" >Start preparing order</button>
                              </>
                            ) : ('')}

                            {order_details.status === 'preparing' ? (
                              <>
                                <br />
                                <button onClick={() => mark_order_ready_open()} class="btn btn-primary" >Mark as ready</button>
                              </>
                            ) : ('')}
                          </th>
                        </tr> */}

                        {/* <tr>
                          <th>Scheduled Date of Next Activity</th>
                          <th>
                            {order_details.status === 'invited' ? (ready_details.acceptance) : ('')}
                            {order_details.status === 'accepted' ? (ready_details.preparation) : ('')}
                            {order_details.status === 'preparing' ? (ready_details.ready) : ('')}
                            {order_details.status === 'completed' ? (ready_details.collected) : ('')}
                            {order_details.status === 'collected' ? (ready_details.paid) : ('')}
                          </th>
                        </tr> */}

                        {/* <tr>
                          <th>Scheduled Date of Collection</th>
                          <th>{ready_details.date_time}</th>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>

                  {/* <h3>Timing of Activities for Order {order_details.display_order_number}</h3>
                  <div class="table-responsive mb-5">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Activity</th>
                          <th>Status</th>
                          <th>Date and Time</th>
                          <th>Time Type</th>
                        </tr>

                      </thead>

                      <tbody>
                        <tr>
                          <th>1</th>
                          <th>Acceptance</th>
                          <td>{acceptance_details.status}</td>
                          <td>{acceptance_details.date_time}</td>
                          <td>{acceptance_details.time_type}</td>
                        </tr>

                        <tr>
                          <th>2</th>
                          <th>Preparation</th>
                          <td>{preparation_details.status}</td>
                          <td>{preparation_details.date_time}</td>
                          <td>{preparation_details.time_type}</td>
                        </tr>

                        <tr>
                          <th>3</th>
                          <th>Ready for collection</th>
                          <td>{ready_details.status}</td>
                          <td>{ready_details.date_time}</td>
                          <td>{ready_details.time_type}</td>
                        </tr>

                        <tr>
                          <th>4</th>
                          <th>Collected</th>
                          <td>{collected_details.status}</td>
                          <td>{collected_details.date_time}</td>
                          <td>{collected_details.time_type}</td>
                        </tr>

                        <tr>
                          <th>5</th>
                          <th>Paid</th>
                          <td>{paid_details.status}</td>
                          <td>{paid_details.date_time}</td>
                          <td>{paid_details.time_type}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div> */}

                  <div class="mt-5">
                    <Link
                      to={`/messages/${order_details._id}`}
                      class="btn btn-primary"
                    >
                      Send new message to seller
                    </Link>
                    &nbsp;
                    <Link
                      to={`/messages/${order_details._id}`}
                      class="btn btn-primary"
                    >
                      View order messages
                    </Link>
                  </div>
                </div>
                <div class="col-3"></div>
              </div>

              <div class="row"></div>
            </>
          )}
        </div>
      </section>

      <Dialog
        open={mark_order_ready}
        onClose={mark_order_ready_close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Is the product ready for collection?
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText> */}
          <form onSubmit={markOrderReady}>
            After making it as ready our delivery agents will come collect the
            order.
          </form>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={mark_order_ready_close}
            color="primary"
          >
            Cancel
          </button>

          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={markOrderReady}
            color="primary"
            disabled={action_loading}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={start_preparing_dialog}
        onClose={start_preparing_dialog_close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Start preparing order</DialogTitle>
        <DialogContent>
          {/* <DialogContentText> */}
          <form onSubmit={startPreparingOrder}>
            Please be sure to finish before the stated time, so that we can come
            pick up with no challenges.
          </form>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={start_preparing_dialog_close}
            color="primary"
          >
            Cancel
          </button>

          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={startPreparingOrder}
            color="primary"
            disabled={action_loading}
          >
            Start
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default BuyerOrderScreen;
