import React, { useState, useEffect } from 'react';
import { validate_multiple_roles } from '../validations';
import { getSellers, deleteSeller, verifySeller, verifyOrganicSeller, updateUser } from '../actions/userActions';
import { updateOrder } from '../actions/orderActions';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { API_URL, suspension_types } from '../config'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


function SuspensionScreen(props) {
  const id = props.match.params.id ? props.match.params.id : '';

  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ['admin', 'sales-support'];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = 'access-forbidden';
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }



  const [suspend_account_dialog, set_suspend_account_dialog] = React.useState(false);

  const suspend_account_dialog_open = () => {
    set_suspend_account_dialog(true);
  };

  const suspend_account_dialog_close = () => {
    set_suspend_account_dialog(false);
  };


  const [error_message, set_error_message] = useState('');
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState('');
  const [page_loaded, set_page_loaded] = useState(false);

  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  const [order_details, set_order_details] = useState({});
  const [seller_details, set_seller_details] = useState({});
  // eslint-disable-next-line 
  const [review_reasons, set_review_reasons] = useState('');

  const [review_status, set_review_status] = useState('Active');

  const [suspension_details, set_suspension_details] = useState({});



  const load_page_data = async () => {

    set_action_loading(true);
    let get_seller_details;

    try {
      get_seller_details = await getSellers(id, 'role', 'status');

      if (get_seller_details.success) {
        const { data } = get_seller_details;
        set_order_details(data[0]);
        console.log('data0', data[0])
        //   console.log(data[0].seller_details, data[0])
        set_seller_details(data[0].seller_details[0]);
        set_suspension_details(data[0].suspension_details);
        // set_organic_details(data[0].organic);
        // set_order_items(data[0].products);

      } else {
        set_error_message(get_seller_details.message);
      }
    } catch (error) {
      set_error_message(`An error occured fetching seller orders: ${error.message} `);
    }


    // when the time to pul seller product comes, pull this one out. 
    //  try {
    //     get_seller_products = await getOrderItems('id', id, 'seller_id', 'product', 'status');

    //     if (get_seller_products.success) {
    //       const { data } = get_seller_products;
    //       set_order_items(data);
    //     } else {
    //       set_error_message(get_seller_products.message);
    //     }
    //   } catch (error) {
    //     set_error_message(`An error occured fetching seller orders: ${error.message} `);
    //   }

    set_action_loading(false);
    return;
  }

  /**
   * Updates user profile
   * @param {*} e 
   */
  // eslint-disable-next-line 
  const suspend_user_account = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const update_data = {
      status: 'Paid'
    };

    const response = await updateOrder(update_data, id, 'payment');
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      await load_page_data();

      set_success_message('Payment status successfully updated');
    }
    set_action_loading(false);

    return;
  }

  const review_user_account = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const update_data = {
      review_status,
      review_reasons,
      review_id: suspension_details._id,
    };

    const response = await updateUser(update_data, id, 'review_ban');
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      await load_page_data();

      // suspend_account_dialog_close()

      set_success_message('Success');
    }
    set_action_loading(false);

    return;
  }

  /**
  * Deletes user details
  * @param {*} e 
  */
  const delete_user_account = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const response = await deleteSeller(id);
    if (response.success === false) {
      set_error_message(response.message);
    } else {

      set_success_message('Account deleted');
      props.history.push('/sellers');
    }
    set_action_loading(false);

    return;
  }

  /**
 * Verifies user acccount details
 * @param {*} e 
 */
  const verify_user_account = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const response = await verifySeller(id);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message('Account successfully verified');
      await load_page_data();
    }
    set_action_loading(false);

    return;
  }

  /**
* Verifies organic acccount details
* @param {*} e 
*/
  const verify_organic_account = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const response = await verifyOrganicSeller(id);
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message('Organic farmer successfully verified');
      await load_page_data();
    }
    set_action_loading(false);

    return;
  }

  /**
 * Updates user profile
 * @param {*} e 
 */
  // eslint-disable-next-line 
  const activate_user_account = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    const update_data = {
    };

    const response = await updateOrder(update_data, id, 'cancel');
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      await load_page_data();

      set_success_message('Order cancelled');
    }
    set_action_loading(false);

    return;
  }



  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  const confirmAndDeleteUserAccount = async (e) => {
    e.preventDefault();
    const isConfirmed = window.confirm("Are you sure you want to delete this user? This action cannot be undone.");
    if (isConfirmed) {
      delete_user_account(e);
    }
  };



  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2><span class="black-text">Review suspension for </span> <span class="green-text">{order_details.first_name} {order_details.last_name} </span></h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time 
            <div>Loading ... </div>
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== '' ? (
                    <div class="alert alert-danger" role="alert">
                      <button type="button" class="close" data-dismiss="alert">&times;</button>
                      {error_message}
                    </div>
                  ) : ('')}
                  {success_message !== '' ? (
                    <div class="alert alert-success" role="alert">
                      <button type="button" class="close" data-dismiss="alert">&times;</button>
                      {success_message}
                    </div>
                  ) : ('')}
                </div>
              </div>

              <div class="row">

              </div>


              <div class="row">


                <div class="col-12">
                  <h2>Suspension Details</h2>
                  <div class="table-responsive mb-5">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Full name</th>
                          <td>{order_details.first_name} {order_details.last_name}</td>
                        </tr>

                      </thead>

                      <tbody>
                        <tr>
                          <th>Suspension Type</th>
                          <td>{order_details.suspension_status}</td>
                        </tr>

                        {/* <tr>
                          <th>Suspended by</th>
                          <td></td>
                        </tr> */}

                        <tr>
                          <th>Suspended on</th>
                          <td>{suspension_details.createdAt}</td>
                        </tr>

                        <tr>
                          <th>Reasons</th>
                          <td>{suspension_details.reasons}</td>
                        </tr>

                        <tr>
                          <th>Expiry Date</th>
                          <td>{suspension_details.expiry}</td>
                        </tr>


                        {/*                       
                        <tr>
                          <th>Verification status</th>
                          <td>{order_details.verified ? ('Verified') : (<>
                            <button
                              class="btn btn-lg btn-success"
                              type="submit"
                              onClick={verify_user_account} color="primary">
                              Verify account details
                            </button>
                          </>)}</td>
                        </tr> */}

                      </tbody>
                    </table>
                  </div>




                  <h2>Appeal Details</h2>
                  <div class="table-responsive mb-5">
                    <table class="table table-bordered">


                      <tbody>
                        <tr>
                          <th>Date of appeal</th>
                          <td>{suspension_details.appeal_date}</td>
                        </tr>


                        <tr>
                          <th>Reasons</th>
                          <td>{suspension_details.reason_for_appeal}{suspension_details.supporting_evidence}</td>
                        </tr>

                        <tr>
                          <th>Supporting evidence</th>
                          <td>

                            {suspension_details.supporting_documents ? (<a href={`${API_URL}/${suspension_details.supporting_documents}`} target="_blank">View evidence</a>) : (<></>)}

                          </td>
                        </tr>


                        {/*                       
                        <tr>
                          <th>Verification status</th>
                          <td>{order_details.verified ? ('Verified') : (<>
                            <button
                              class="btn btn-lg btn-success"
                              type="submit"
                              onClick={verify_user_account} color="primary">
                              Verify account details
                            </button>
                          </>)}</td>
                        </tr> */}

                      </tbody>
                    </table>
                  </div>



                  <h2>Review Details</h2>
                  {suspension_details.review_status && suspension_details.review_status === 'Active' ? (
                    <div class="table-responsive mb-5">
                      <table class="table table-bordered">
                        <tbody>
                          <tr>
                            <th>Made on</th>
                            <td></td>
                          </tr>

                          <tr>
                            <th>Final decision</th>
                            <td></td>
                          </tr>

                          <tr>
                            <th>Issue status</th>
                            <td></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>) : (
                    <>

                      <form onSubmit={review_user_account}>

                        <div class="row">
                          <div class="col-md-6">
                            <label>New status</label>

                            <select class="form-control" onChange={(e) => set_review_status(e.target.value)}>
                              <option value="Active">Active</option>
                              {suspension_types.map(st => (
                                <option value={st.status}>{st.status}</option>
                              ))}
                            </select>

                          </div>

                          <div class="col-md-12 mt-5">
                            <label>Recommendations to seller</label>
                            <textarea class="form-control" onChange={(e) => set_review_reasons(e.target.value)}>
                            </textarea>
                          </div>

                          <div class="col-md-12 mt-3">
                            <small>You can refer to the <a href="/admin-help">help section</a> to learn more on escalating the statuses.</small>
                          </div>

                          <div class="col-md-12 mt-3">
                            <button
                              class="btn btn-lg btn-success"
                              type="submit"
                              onClick={review_user_account} color="primary"
                              disabled={action_loading} >
                              Update account
                            </button>
                          </div>
                        </div>
                      </form>

                    </>
                  )}








                </div>
                <div class="col-3"></div>

              </div>

              <div class="row">

              </div>


            </>
          )}

        </div>
      </section>

      <Dialog open={suspend_account_dialog} onClose={suspend_account_dialog_close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"><h2>Suspend Account</h2></DialogTitle>
        <DialogContent>
          {/* <DialogContentText> */}
          <form onSubmit={activate_user_account}>

            <div class="row">
              <div class="col-md-6">
                <label>Suspension Type</label>

                <select class="form-control">
                  <option></option>
                </select>
              </div>

              <div class="col-md-6">
                <label>Duration</label>

                <input class="form-control" type="" />
              </div>

              <div class="col-md-12">
                <label>Reasons</label>

                <textarea class="form-control" >
                </textarea>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={suspend_account_dialog_close} color="primary">
            Cancel
          </button>

          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={activate_user_account} color="primary"
            disabled={action_loading} >
            Suspend Account
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default SuspensionScreen;
