import React, { useState, useEffect } from "react";
import { validate_role } from "../validations";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import {
  getOrderItems,
  getOrdersV2,
  updateOrder,
  createRecurringOrder,
  getRecurringOrders,
  updateRecurringOrder,
} from "../actions/orderActions";
import {
  retrieveProducts,
  getInventoryAlerts,
} from "../actions/productActions";
import { get_display_date } from "../config";
import { Link } from "react-router-dom";
import { getProducts } from "../actions/productActions";
import { getNotifications } from "../actions/notificationActions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getCategories, getProductTypes } from "../actions/categoryActions";
import stringSimilarity from "string-similarity";
import {
  addVerification,
  updateVerification,
} from "../actions/verificationActions";

import {
  getVerifications,
  updateVerification2,
} from "../actions/verificationActions";
import Spinner from "../components/spinner";

function VerifierDashboardScreen(props) {
  let user_profile;
  const permitted_role = "verifier";
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = "login";
    if (permitted_role === "verifier") login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const [confirm_farm_visit_dialog, set_confirm_farm_visit_dialog] =
    React.useState(false);
  const confirm_farm_visit_dialog_open = () => {
    set_confirm_farm_visit_dialog(true);
  };
  const confirm_farm_visit_dialog_close = () => {
    set_confirm_farm_visit_dialog(false);
  };

  const [read_farmer_details_dialog, set_read_farmer_details_dialog] =
    React.useState(false);
  const [remove_recurring_purchase_item, set_remove_recurring_purchase_item] =
    useState({});

  const read_farmer_details_dialog_open = (_item) => {
    // console.log(_item)
    set_remove_recurring_purchase_item(_item);
    set_read_farmer_details_dialog(true);
  };

  const read_farmer_details_dialog_close = () => {
    set_read_farmer_details_dialog(false);
  };

  const link_cell_formatter = (cell, row) => {
    return (
      <div>
        <button
          class="btn btn-lg"
          onClick={() => read_farmer_details_dialog_open(row)}
        >
          Cancel
        </button>
        {/* <Link to={`${cell}/${row.age}`}>{cell}</Link> */}
      </div>
    );
  };

  const [search_product_found, set_search_product_found] = useState(false);
  const [alternatively_search_product, set_alternatively_search_product] =
    useState(false);

  const [searched_product, set_searched_product] = useState("");
  const [possible_search_alternatives, set_possible_search_alternatives] =
    useState([]);

  // eslint-disable-next-line
  const [error_message, set_error_message] = useState("");
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState("");
  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [new_notifications, set_new_notifications] = useState([]);

  const [overdue_notifications, set_overdue_notifications] = useState([]);

  const [all_products, set_all_products] = useState([]);

  const [selected_notification, set_selected_notification] = useState({});
  const [visit_date, set_visit_date] = useState("");
  const [visit_time, set_visit_time] = useState("");
  const [visit_end_time, set_visit_end_time] = useState("");

  const [selected_verification_id, set_selected_verification_id] = useState("");
  const [selected_seller_id, set_selected_seller_id] = useState("");
  const [message_to_seller, set_message_to_seller] = useState("");

  const [recurring_orders, set_recurring_orders] = useState([]);

  const [all_categories, set_all_categories] = useState([]);
  // eslint-disable-next-line
  const [all_product_types, set_all_product_types] = useState([]);
  const [
    all_product_types_in_alphabetical_order,
    set_all_product_types_in_alphabetical_order,
  ] = useState([]);

  const [display_sub_categories, set_display_sub_categories] = useState([]);
  // eslint-disable-next-line
  const [display_product_types, set_display_product_types] = useState([]);
  const [display_units, set_display_units] = useState([]);

  const [add_category, set_add_category] = useState("");

  const [add_sub_category, set_add_sub_category] = useState("");
  const [add_product, set_add_product] = useState("");
  const [add_product_id, set_add_product_id] = useState("");
  const [add_product_name, set_add_product_name] = useState("");
  // eslint-disable-next-line
  const [add_description, set_add_description] = useState("");
  const [add_units, set_add_units] = useState("");
  const [add_quantity, set_add_quantity] = useState("");
  const [add_repeat_period, set_add_repeat_period] = useState("daily");
  const [repeat_periods, set_repeat_periods] = useState([
    "daily",
    "weekly",
    "fortnightly",
    "monthly",
  ]);

  const [active_orders, set_active_orders] = useState("");
  const [products_count, set_products_count] = useState("");
  const [products_last_updated, set_products_last_updated] = useState("");
  const [products_last_updated_warning, set_products_last_updated_warning] =
    useState(false);
  // eslint-disable-next-line
  const [inventory_alerts_count, set_inventory_alerts_count] = useState("");
  // eslint-disable-next-line
  const [inventory_alerts_warning, set_inventory_alerts_warning] =
    useState(false);
  // eslint-disable-next-line
  const [inventory_alerts_text, set_inventory_alerts_text] = useState("");
  const [sales_history_value, set_sales_history_value] = useState("");
  // eslint-disable-next-line
  const [perfomance_summary_rating, set_perfomance_summary_rating] =
    useState("");
  // eslint-disable-next-line
  const [
    perfomance_summary_rating_last_update,
    set_perfomance_summary_rating_last_update,
  ] = useState("");
  // eslint-disable-next-line
  const [case_logs, set_case_logs] = useState("");

  const category_changed = (new_category) => {
    let _display_sub_categories = [];
    let _display_product_types = [];

    // console.log(new_category, new_category)

    set_add_category(new_category);
    set_add_sub_category("");

    if (new_category !== "") {
      for (let i = 0; i < all_categories.length; i++) {
        if (all_categories[i]._id === new_category) {
          _display_sub_categories = all_categories[i].sub_categories;
        }
      }

      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].category_id === new_category) {
          _display_product_types.push(all_product_types[i]);
        }
      }
    }

    set_display_sub_categories(_display_sub_categories);
    set_display_product_types(_display_product_types);
  };

  const sub_category_changed = (new_sub_category) => {
    let _display_product_types = [];

    set_add_sub_category(new_sub_category);

    if (new_sub_category !== "") {
      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].sub_category_meta === new_sub_category) {
          _display_product_types.push(all_product_types[i]);
        }
      }
    }

    set_display_product_types(_display_product_types);
  };

  const product_type_changed = (new_product_type) => {
    let _display_units = [];

    set_add_product(new_product_type);

    // console.log(new_product_type)

    if (new_product_type !== "") {
      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].product_meta === new_product_type) {
          set_add_product_id(all_product_types[i]._id);
          set_add_product_name(all_product_types[i].product);
          // console.log('found')
          // console.log(all_product_types[i]._id, all_product_types[i].units);
          _display_units = all_product_types[i].units;
        }
      }
    }

    // console.log('display units', _display_units)

    set_display_units(_display_units);
  };

  const product_type_changed_with_search = (new_product_type) => {
    let _display_units = [];

    set_add_product(new_product_type);
    set_possible_search_alternatives([]);

    let _found = false;
    if (new_product_type !== "") {
      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].product === new_product_type) {
          // console.log('found', all_product_types[i].category_id, all_product_types[i].sub_category_meta)
          set_add_product_id(all_product_types[i]._id);
          set_add_product_name(all_product_types[i].product);
          category_changed(all_product_types[i].category_id);
          sub_category_changed(all_product_types[i].sub_category_meta);
          _display_units = all_product_types[i].units;
          _found = true;
        }
      }
    }

    let _possible_search_alternatives = [];
    if (_found === false) {
      // find possible matches

      // const best_match = stringSimilarity.findBestMatch(searched_product, all_product_types_in_alphabetical_order);
      // console.log(best_match)
      for (let i = 0; i < all_product_types_in_alphabetical_order.length; i++) {
        const similarity = stringSimilarity.compareTwoStrings(
          searched_product,
          all_product_types_in_alphabetical_order[i]
        );
        if (similarity > 0.4) {
          _possible_search_alternatives.push(
            all_product_types_in_alphabetical_order[i]
          );
        }
      }
      set_possible_search_alternatives(_possible_search_alternatives);
    }

    set_search_product_found(_found);
    set_display_units(_display_units);
  };

  const get_user_profile = async () => {
    set_action_loading(true);
    let get_notification;
    let _new_notifications = [];
    try {
      get_notification = await getNotifications(
        "id",
        user_profile._id,
        "sender",
        "false"
      );
      if (get_notification.success) {
        let { data } = get_notification;
        // set_new_notifications(data)
        for (let i = 0; i < data.length; i++) {
          data[i].seller_id = data[i].extra_details.seller_id;
          data[i].seller_name = data[i].extra_details.seller_name;
          data[i].seller_address = data[i].extra_details.seller_address;
          _new_notifications.push(data[i]);
        }
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching new orders: ${error.message} `
      );
    }

    set_new_notifications(_new_notifications);
    let get_verifications;
    try {
      get_verifications = await getVerifications(
        "id",
        user_profile._id,
        "seller_id",
        "Pending"
      );

      if (get_verifications.success) {
        let { data } = get_verifications;

        set_overdue_notifications(data);

        // for (let i = 0; i < data.length; i++) {
        // if (new Date() > new Date(new Date(data[i].visit_date))) {
        // set_notifications_bell(true)
        // }
        // }
      }
    } catch (error) {
      // set_error_message(`No pending verification requests found`);
    }

    set_action_loading(false);

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      get_user_profile();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  const add_new_verification = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    if (visit_date === "") {
      set_error_message("Visit date cannot be null");
      return;
    }

    if (visit_time === "") {
      set_error_message("Visit start time cannot be null");
      return;
    }

    if (visit_end_time === "") {
      set_error_message("Visit end time cannot be null");
      return;
    }

    set_action_loading(true);

    const update_data = {
      visit_date,
      visit_time,
      visit_end_time,
      seller_id: selected_notification.extra_details.seller_id,

      notification_id: selected_notification._id,
    };

    // console.log(update_data);
    // return

    const response = await addVerification(update_data);
    // console.log(response)
    // return;
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      await get_user_profile();
      //   add_recurring_order_dialog_close()
      confirm_farm_visit_dialog_close();
      set_selected_notification({});
      set_success_message("Confirmation successful");
    }
    set_action_loading(false);

    return;
  };

  const confirm_new_date = async (e) => {
    e.preventDefault();
    set_error_message("");
    set_success_message("");
    // set_order_confirmation_success_message('')

    let update_data = {
      visit_date,
      visit_time,
      visit_end_time,
      message_to_seller,
      seller_id: selected_seller_id,
    };

    let make_action;
    try {
      make_action = await updateVerification2(
        update_data,
        selected_verification_id,
        "new_visit_date"
      );
      if (make_action.success) {
        await get_user_profile();

        // set_order_confirmation_success_message(make_action.message)
        set_success_message("Success");
        // set_buyer_to_message(make_action.buyer)
        confirm_farm_visit_dialog_close();
        return;
      } else {
        set_error_message(make_action.message);
      }
    } catch (error) {
      set_error_message(
        `An error occured confrirming availabiity: ${error.message} `
      );
    }
  };

  return (
    <>
      <section
        id="contact"
        class="contact"
        style={{ backgroundColor: "#f8f8f8" }}
      >
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="black-text">Verifier</span>{" "}
              <span class="green-text">Dashboard</span>
            </h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-12">
                  {error_message !== "" ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : (
                    ""
                  )}
                  {success_message !== "" ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 m-5">
                  {new_notifications.length > 0
                    ? new_notifications.map((no) =>
                        !no.read ? (
                          <p class="alert alert-success">
                            {no.message}
                            &nbsp;
                            <button
                              type="button"
                              class="btn btn-success btn-sm"
                              style={{ color: "#fff", fontSize: "12px" }}
                              onClick={() => {
                                read_farmer_details_dialog_open();
                                set_selected_notification(no);
                              }}
                            >
                              View farmer details
                            </button>
                          </p>
                        ) : (
                          ""
                        )
                      )
                    : ""}

                  {overdue_notifications.length > 0
                    ? overdue_notifications.map((no) => (
                        <>
                          {no.contested_time ? (
                            <p class="alert alert-danger">
                              Seller {no.farmer_name} is asking for a different
                              verification date.
                              <br />
                              Proposed date and time:{" "}
                              {no.proposed_visit_date.slice(0, 10)}{" "}
                              {no.proposed_visit_time} to{" "}
                              {no.proposed_visit_end_time}
                              <br />
                              Message: {no.message_to_verifier}
                              <br />
                              <Link
                                type="button"
                                class="btn btn-success btn-sm"
                                style={{ color: "#fff", fontSize: "12px" }}
                                to={`/verify-seller/${no._id}`}
                              >
                                View details
                              </Link>
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                class="btn btn-success btn-sm"
                                style={{ color: "#fff", fontSize: "12px" }}
                                onClick={() => {
                                  confirm_farm_visit_dialog_open();
                                  set_selected_seller_id(no.seller_id);
                                  set_selected_verification_id(no._id);
                                }}
                              >
                                Confirm date
                              </button>
                            </p>
                          ) : (
                            <></>
                          )}
                          {new Date() > new Date(new Date(no.visit_date)) ? (
                            <p class="alert alert-danger">
                              You have an overdue verification task. Please
                              arrange with the seller to do the verification as
                              soon as possible. &nbsp;
                              <Link
                                type="button"
                                class="btn btn-success btn-sm"
                                style={{ color: "#fff", fontSize: "12px" }}
                                to={`/verify-seller/${no._id}`}
                              >
                                View details
                              </Link>
                            </p>
                          ) : (
                            ""
                          )}
                        </>
                      ))
                    : ""}
                </div>
              </div>
            </>
          )}
        </div>
      </section>

      {/*  */}

      <Dialog
        open={read_farmer_details_dialog}
        onClose={read_farmer_details_dialog_close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Farmer details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <th>Seller name</th>
                  <td>{selected_notification.seller_name}</td>
                </tr>
                <tr>
                  <th></th>
                  <td></td>
                </tr>
                <tr>
                  <th>Seller address</th>
                  <td>{selected_notification.seller_address}</td>
                </tr>
              </table>
            </div>

            <p>Do you want to accept this assignment to verify this seller?</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={read_farmer_details_dialog_close}
            color="primary"
          >
            No
          </button>

          <button
            class="btn btn-lg btn-success"
            type="submit"
            onClick={() => {
              confirm_farm_visit_dialog_open();
              read_farmer_details_dialog_close();
            }}
            color="primary"
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirm_farm_visit_dialog}
        onClose={confirm_farm_visit_dialog_close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Planned visit date</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div class="form-group">
              <label class="form-group">
                Please send a message to the seller telling him what date and
                time you can visit them for a verification
              </label>
              <br />
              <br />
            </div>
            <div class="form-group">
              <label>Visit date</label>
              <input
                type="date"
                class="form-control"
                value={visit_date}
                onChange={(e) => set_visit_date(e.target.value)}
              />
            </div>
            <div class="form-group">
              <label>Start time</label>
              <input
                type="time"
                class="form-control"
                value={visit_time}
                onChange={(e) => set_visit_time(e.target.value)}
              />
            </div>
            <div class="form-group">
              <label>End time</label>
              <input
                type="time"
                class="form-control"
                value={visit_end_time}
                onChange={(e) => set_visit_end_time(e.target.value)}
              />
            </div>
            <div class="form-group">
              <label>Message to seller</label>
              <textarea
                class="form-control"
                onChange={(e) => set_message_to_seller(e.target.value)}
              >
                {message_to_seller}
              </textarea>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            class="btn btn-lg btn-success"
            // type="submit"
            onClick={confirm_farm_visit_dialog_close}
            color="primary"
          >
            Cancel
          </button>

          {selected_verification_id !== "" ? (
            <form onSubmit={confirm_new_date}>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
                class="btn btn-lg btn-success"
                type="submit"
                // onClick={()=>remove_recurring_order(remove_recurring_purchase_item.id)}
                color="primary"
              >
                Send new date and time
              </button>
            </form>
          ) : (
            <form onSubmit={add_new_verification}>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
                class="btn btn-lg btn-success"
                type="submit"
                // onClick={()=>remove_recurring_order(remove_recurring_purchase_item.id)}
                color="primary"
              >
                Send message
              </button>
            </form>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
export default VerifierDashboardScreen;
