import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { validate_multiple_roles } from "../validations";
import { getSellers } from "../actions/userActions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import Spinner from "../components/spinner";

function SuspensionsScreen(props) {
  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["admin", "technical-support"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const [error_message, set_error_message] = useState("");
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState("");
  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  const [sellers, set_sellers] = useState([]);
  // eslint-disable-next-line
  const [status, set_status] = useState("active");

  const productList = useSelector((state) => state.productList);
  const { loading, error } = productList;

  // eslint-disable-next-line
  const dispatch = useDispatch();
  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  const link_cell_formatter = (cell, row) => {
    return (
      <div>
        <Link to={`${cell}/${row.age}`}>{cell}</Link>
      </div>
    );
  };

  const load_page_data = async () => {
    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    set_sellers([]);

    let get_retrieved_sellers;
    try {
      //console.log('in try')
      get_retrieved_sellers = await getSellers("id", "seller", "status");
      // console.log('ret prod', get_retrieved_sellers)

      if (get_retrieved_sellers.success === false) {
        set_error_message(get_retrieved_sellers.message);
      } else {
        const { data } = get_retrieved_sellers;
        // console.log('retrieved sellers', data);
        let sellers_ = [];

        for (let i = 0; i < data.length; i++) {
          if (data[i].suspension_status !== "Active") {
            sellers_.push({
              full_name: data[i].first_name + " " + data[i].last_name,
              id: data[i]._id,
              suspension_status: data[i].suspension_status,
              expiry_date: data[i].suspension_details.expiry.slice(0, 10),
              action: (
                <Link class="" to={`/review-suspension/${data[i]._id}`}>
                  <i class="bi bi-eye"></i> review
                </Link>
              ),
            });
          }
        }

        set_sellers(sellers_);
      }
    } catch (error) {
      console.log("error", error.message);
      set_error_message(`An error occured fetching sellers: ${error.message} `);
    }

    //set_action_loading(false);
  };

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">Suspensions / Bans</span>{" "}
              <span class="orange-text"></span>
            </h2>
          </div>

          <div class="row"></div>

          {loading ? ( // so that only sellers will be loading at this time
            <Spinner />
          ) : error ? (
            <div class="row">
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  No suspensions/bans found.
                </div>
              </div>
            </div>
          ) : (
            <div class="row">
              <div class="col-12">
                {" "}
                {/* ADD THIS LINE (AND THE CORRESPONDING </div>) OTHERWISE THE PAGINATION DOESNT WORK */}
                <BootstrapTable data={sellers} search pagination>
                  <TableHeaderColumn dataField="full_name">
                    Full name
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="suspension_status">
                    Status
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="expiry_date">
                    Expiry Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="action"
                    dataFormat={link_cell_formatter}
                    isKey={true}
                  ></TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
export default SuspensionsScreen;
