import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signinBuyer } from "../actions/userActions";
import Spinner from "../components/spinner";

function LoginSellerScreen(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const userSignin = useSelector((state) => state.userSignin);
  const { loading, userInfo, error } = userSignin;
  const dispatch = useDispatch();
  const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "shop";

  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {
      //
    };
    // eslint-disable-next-line
  }, [userInfo]);

  // console.log('props', props);

  const submitHandler = (e) => {
    e.preventDefault();
    // console.log(userInfo);
    dispatch(signinBuyer(email, password, props, redirect));
    // console.log('after dispatch', userInfo);
  };
  return (
    <section id="about" class="about main-content mt-5 login-content">
      <div class="container-fluid" data-aos="fade-up">
        <div class="row mt-5">
          <div class="col-md-4"></div>
          <div class="col-md-4 text-center">
            <h3 class="green-text">SIGN IN</h3>
            {error && (
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  {error}
                </div>
              </div>
            )}

            <form onSubmit={submitHandler}>
              <div class="form-group my-4">
                <input
                  class="form-control"
                  type="email"
                  name="email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </div>
              <div class="form-group my-4">
                <input
                  class="form-control"
                  type="password"
                  id="password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </div>
              <div class="form-group my-4 text-end">
                <Link to="forgot-password" className="green-text">
                  Forgot password ?
                </Link>
              </div>
              <div class="form-group my-4">
                {loading && <Spinner />}
                <button
                  type="submit"
                  class="btn btn-primary form-control login-button"
                  disabled={loading}
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
    </section>
  );
}
export default LoginSellerScreen;
