import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { validate_role } from "../validations";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { getProducts } from "../actions/productActions";
import { Link } from "react-router-dom";
import Spinner from "../components/spinner";

function AdminProductsScreen(props) {
  // eslint-disable-next-line
  let user_profile;
  const permitted_role = "admin";
  const check_permissions = validate_role(permitted_role);
  if (check_permissions.permitted === false) {
    let login_link = "login";
    if (permitted_role === "seller") login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  const productList = useSelector((state) => state.productList);
  const { loading, error } = productList;
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);
  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [error_message, set_error_message] = useState("");
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState("");

  const [all_products, set_all_products] = useState([]);

  useEffect(() => {
    if (page_loaded === false) {
      set_page_loaded(true);
      load_page();
    }
    // eslint-disable-next-line
  }, []);

  const link_cell_formatter = (cell, row) => {
    return (
      <div>
        <Link to={`${cell}/${row.age}`}>{cell}</Link>
      </div>
    );
  };

  /*
   * Loads a page date
   */
  const load_page = async () => {
    set_success_message("");
    set_error_message("");

    // console.log('about to laod page')

    set_all_products([]);

    let get_retrieved_products;
    try {
      console.log("in try");
      get_retrieved_products = await getProducts(
        "id",
        "seller",
        "farm",
        "product_meta",
        "sub_category_meta",
        "category_meta",
        "product_name",
        "quantity",
        "quantity_units",
        "min_price",
        "max_price",
        "delivery_zone",
        "featured",
        "on_sale",
        "sort_order"
      );
      // console.log('ret prod', get_retrieved_products)
      if (get_retrieved_products.success === false) {
        set_error_message(get_retrieved_products.message);
      } else {
        const { data } = get_retrieved_products;
        // console.log('retrieved products', data);
        let all_products_ = [];

        for (let i = 0; i < data.length; i++) {
          all_products_.push({
            product_name: data[i].product_name,
            id: data[i]._id,
            price: data[i].price,
            quantity: data[i].quantity,
            category: data[i].category,
            selling_method: data[i].selling_method,
            action: (
              <Link class="" to={`/admin-product/${data[i]._id}`}>
                <i class="bi bi-pencil"></i> Update detail
              </Link>
            ),
          });
        }

        set_all_products(all_products_);
      }
    } catch (error) {
      console.log("error", error.message);
      set_error_message(
        `An error occured fetching products: ${error.message} `
      );
    }

    // set_action_loading(false)
    return;
  };

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">Listed</span>{" "}
              <span class="orange-text"> Products</span>
            </h2>
          </div>

          {loading ? ( // so that only products will be loading at this time
            <Spinner />
          ) : error ? (
            <div class="row">
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  You do not yet have any products listed. Press the 'Add new
                  product' button to start
                </div>
              </div>
            </div>
          ) : (
            <div class="row">
              <BootstrapTable data={all_products} search pagination>
                <TableHeaderColumn dataField="product_name">
                  Name
                </TableHeaderColumn>
                <TableHeaderColumn dataField="category">
                  Category
                </TableHeaderColumn>
                <TableHeaderColumn dataField="quantity">
                  Quantity
                </TableHeaderColumn>
                <TableHeaderColumn dataField="price">Price</TableHeaderColumn>
                <TableHeaderColumn dataField="selling_method">
                  Selling method
                </TableHeaderColumn>
                {/* <TableHeaderColumn dataField='status'>Status</TableHeaderColumn> */}
                <TableHeaderColumn
                  dataField="action"
                  dataFormat={link_cell_formatter}
                  isKey={true}
                ></TableHeaderColumn>
              </BootstrapTable>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
export default AdminProductsScreen;
