import React, { useState, useEffect } from "react";
import { getProductsV3, notifyProductNeed } from "../actions/productActions";
import {
  getCategoriesV2,
  getCities,
  getProductTypes,
} from "../actions/categoryActions";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  addToWishList,
  updateQuantity,
} from "../actions/cartActions";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import InputRange from "react-input-range";
import { validate_multiple_roles } from "../validations";

import { IMAGES_URL, buyer_city } from "../config";

import "react-input-range/lib/css/index.css";

function ShopScreen(props) {
  //console.log(props);
  const dispatch = useDispatch();
  let sent_link_sub_category = props.match.params.link_sub_category
    ? props.match.params.link_sub_category
    : "";
  if (sent_link_sub_category === "sub_cat") {
    sent_link_sub_category = "";
  }
  const sent_search_term = props.match.params.search_term
    ? props.match.params.search_term
    : "";

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  let user_profile;
  const permitted_roles = ["seller", "buyer", "admin"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    // intentionally left blank
  } else {
    user_profile = check_permissions.user_object;
  }

  // console.log('usr profile', user_profile)

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");

  const [notify_error_message, set_notify_error_message] = useState("");
  const [notify_success_message, set_notify_success_message] = useState("");

  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  const [first_page_load, set_first_page_load] = useState(true);

  const [search_radius, set_search_radius] = useState({ value: 50 });

  const [added_to_cart_message, set_added_to_cart_message] = useState("");
  const [added_to_wishlist_message, set_added_to_wishlist_message] =
    useState("");
  const [item_added_to_cart_or_wishlist, set_item_added_to_cart_or_wishlist] =
    useState("");

  // eslint-disable-next-line
  const [featured_categories, set_featured_categories] = useState([]);
  const [all_categories, set_all_categories] = useState([]);
  // eslint-disable-next-line
  const [all_product_types, set_all_product_types] = useState([]);
  const [
    all_product_types_in_alphabetical_order,
    set_all_product_types_in_alphabetical_order,
  ] = useState([]);

  const [retrieved_products, set_retrieved_products] = useState([]);
  const [display_products, set_display_products] = useState([]);

  const [
    search_term_found_in_product_types,
    set_search_term_found_in_product_types,
  ] = useState(false);

  const [cities, set_cities] = useState([]);
  const [in_cart_products, set_in_cart_products] = useState([]);
  // eslint-disable-next-line
  const [in_wish_list_products, set_in_wish_list_products] = useState([]);
  // eslint-disable-next-line
  const [cart_items, set_cart_items] = useState([]);

  // eslint-disable-next-line
  const [add_category, set_add_category] = useState("");
  // eslint-disable-next-line
  const [add_sub_category, set_add_sub_category] = useState("");
  // eslint-disable-next-line
  const [add_product, set_add_product] = useState("");

  const [display_sub_categories, set_display_sub_categories] = useState([]);
  // eslint-disable-next-line
  const [display_product_types, set_display_product_types] = useState([]);

  // eslint-disable-next-line
  const [link_sub_category, set_link_sub_category] = useState(
    sent_link_sub_category
  );
  const [selected_search_term, set_selected_search_term] =
    useState(sent_search_term);
  // eslint-disable-next-line
  const [selected_min_price, set_selected_min_price] = useState("");
  // eslint-disable-next-line
  const [selected_max_price, set_selected_max_price] = useState("");
  const [found_search_term, set_found_search_term] = useState("");
  const [found_product_units, set_found_product_units] = useState([]);
  const [found_product_id, set_found_product_id] = useState([]);

  const [looking_for_quantity, set_looking_for_quantity] = useState("");
  const [looking_for_units, set_looking_for_units] = useState("");

  const [notify_admin_stage, set_notify_admin_stage] = useState("1");

  const [looking_for_pick_or_deliver, set_looking_for_pick_or_deliver] =
    useState("");
  const [looking_for_search_radius, set_looking_for_search_radius] =
    useState("");

  const [total_pages, set_total_pages] = useState(1);
  const [current_page, set_current_page] = useState(1);
  // eslint-disable-next-line
  const [show_max, set_show_max] = useState(10);
  const x_per_page = 12;

  // console.log('client city', localStorage.getItem('buyer_city'));

  const handlePageChange = (selectedPage) => {
    set_current_page(selectedPage.selected + 1);
    const should_start_on = x_per_page * selectedPage.selected;
    const should_end_on = x_per_page * (selectedPage.selected + 1);

    let _display_products = [];
    for (let i = should_start_on; i < should_end_on; i++) {
      if (
        retrieved_products[i] !== undefined ||
        retrieved_products[i] !== null
      ) {
        _display_products.push(retrieved_products[i]);
      }
    }
    set_display_products(_display_products);
  };

  let paginationConfig = {
    pageCount: total_pages,
    pageRangeDisplayed: show_max,
    marginPagesDisplayed: 2,
    previousLabel: "Previous",
    nextLabel: "Next",
    breakLabel: "...",
    onPageChange: handlePageChange,
    containerClassName: "pagination",
    subContainerClassName: "pages pagination",
    activeClassName: "active",
    forcePage: current_page - 1,
  };

  const get_quantity_in_cart = (_product_name) => {
    let _product_quantity = "";
    if (cartItems.length > 0) {
      for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i].name === _product_name) {
          _product_quantity = cartItems[i].quantity;
          break;
        }
      }
    }
    return _product_quantity;
  };

  const clear_search = async () => {
    set_link_sub_category(sent_link_sub_category);
    set_selected_search_term("");
    set_selected_min_price("");
    set_selected_max_price("");

    set_add_category("");
    set_add_sub_category("");
    set_add_product("");

    // now do a fresh search
    search_products(
      "sub_category_in_link",
      "search_term",
      "category_meta",
      "sub_category_meta",
      "product",
      "unit",
      "min_price",
      "max_price"
    );
  };

  const category_changed = (new_category) => {
    let _display_sub_categories = [];
    let _display_product_types = [];
    let category_meta = "";

    set_add_category(new_category);
    set_add_sub_category("");
    set_add_product("");

    if (new_category !== "") {
      for (let i = 0; i < all_categories.length; i++) {
        if (all_categories[i]._id === new_category) {
          _display_sub_categories = all_categories[i].sub_categories;
          category_meta = all_categories[i].meta;
        }
      }

      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].category_id === new_category) {
          _display_product_types.push(all_product_types[i]);
        }
      }
    }

    set_display_sub_categories(_display_sub_categories);
    set_display_product_types(_display_product_types);

    search_products(
      "sub_category_in_link",
      "search_term",
      category_meta,
      "sub_category_meta",
      "product",
      "unit",
      "min_price",
      "max_price"
    );
    // (sub_category_in_link, search_term, category, sub_category, product, unit, min_price, max_price)
  };

  const sub_category_changed = (new_sub_category) => {
    let _display_product_types = [];

    set_add_sub_category(new_sub_category);
    set_add_product("");

    if (new_sub_category !== "") {
      for (let i = 0; i < all_product_types.length; i++) {
        if (all_product_types[i].sub_category_meta === new_sub_category) {
          _display_product_types.push(all_product_types[i]);
        }
      }
    }

    set_display_product_types(_display_product_types);

    // console.log('new sub cat', new_sub_category)

    search_products(
      "sub_category_in_link",
      "search_term",
      "category",
      new_sub_category,
      "product",
      "unit",
      "min_price",
      "max_price"
    );
  };

  const product_changed = (new_product) => {
    let _display_product_types = [];

    // set_add_sub_category(new_sub_category);
    // set_search_term(new_product);

    // if (new_sub_category !== '') {
    //   for (let i = 0; i < all_product_types.length; i++) {
    //     if (all_product_types[i].sub_category_meta === new_sub_category) {
    //       _display_product_types.push(all_product_types[i]);
    //     }
    //   }
    // }

    // set_display_product_types(_display_product_types);

    // console.log('new sub cat', new_sub_category)

    search_products(
      "sub_category_in_link",
      new_product,
      "category",
      "sub_category_meta",
      "product",
      "unit",
      "min_price",
      "max_price"
    );
  };

  const price_changed = (new_min_price, new_max_price) => {
    let _retrieved_products = [];

    let converted_min_price = parseFloat(new_min_price);
    let converted_max_price = parseFloat(new_max_price);
    if (new_max_price === "") {
      converted_max_price = 1000000;
    }
    if (new_min_price === "") {
      converted_min_price = 0;
    }

    for (let i = 0; i < retrieved_products.length; i++) {
      if (
        retrieved_products[i].seller_suspension_status === "Active" ||
        retrieved_products[i].seller_suspension_status === "Warning"
      ) {
        if (
          retrieved_products[i].price >= converted_min_price &&
          retrieved_products[i].price <= converted_max_price
        ) {
          _retrieved_products.push(retrieved_products[i]);
        }
      }
    }

    set_retrieved_products(retrieved_products);

    let _total_pages = Math.ceil(_retrieved_products.length / x_per_page);
    let _display_products = [];
    if (_retrieved_products.length > x_per_page) {
      set_total_pages(_total_pages);
      for (let i = 0; i < x_per_page; i++) {
        _display_products.push(_retrieved_products[i]);
      }

      set_display_products(_display_products);
    } else {
      set_display_products(_retrieved_products);
    }
  };

  const load_page_data = async () => {
    set_action_loading(true);

    let get_featured_categories;
    let get_all_categories;
    let get_cities;

    let _in_cart_products = [];
    if (cartItems.length > 0) {
      set_cart_items(cartItems);
      for (let i = 0; i < cartItems.length; i++) {
        _in_cart_products.push(cartItems[i].name);
      }
    }
    set_in_cart_products(_in_cart_products);

    if (
      localStorage.getItem("wish_list_items") !== null ||
      localStorage.getItem("wish_list_items") !== ""
    ) {
      set_in_wish_list_products(
        JSON.parse(localStorage.getItem("wish_list_items"))
      );
    }

    try {
      let country = localStorage.getItem("buyer_country_meta");
      let country_meta;
      if (country === "") {
        country_meta = "country_meta";
      } else {
        country_meta = country;
      }
      console.log("country_meta for getCities query", country_meta);
      get_cities = await getCities("id", "meta", "status", country_meta);
      if (get_cities.success) {
        const { data } = get_cities;
        set_cities(data);
      } else {
        set_error_message("No cities found");
      }
    } catch (error) {
      set_error_message(`An error occured fetching cities: ${error.message} `);
    }

    try {
      get_featured_categories = await getCategoriesV2(
        "id",
        "meta",
        "active",
        "featured",
        5,
        "priority"
      );
      if (get_featured_categories.success === false) {
        set_error_message(get_featured_categories.message);
      } else {
        const { data } = get_featured_categories;
        set_featured_categories(data);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching featured categories: ${error.message} `
      );
    }

    try {
      get_all_categories = await getCategoriesV2(
        "id",
        "meta",
        "active",
        "featured",
        "limit",
        "priority"
      );
      if (get_all_categories.success === false) {
        set_error_message(get_all_categories.message);
      } else {
        const { data } = get_all_categories;
        set_all_categories(data);
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching all categories: ${error.message} `
      );
    }

    let get_all_product_types;
    try {
      get_all_product_types = await getProductTypes(
        "id",
        "meta",
        "active",
        "search_term",
        "category_id",
        "sub_category_meta"
      );
      if (get_all_product_types.success === false) {
        set_error_message(get_all_product_types.message);
      } else {
        const { data } = get_all_product_types;
        //  console.log(data);
        set_all_product_types(data);

        let _all_product_types_in_alphabetical_order = [];
        for (let i = 0; i < data.length; i++) {
          _all_product_types_in_alphabetical_order.push(data[i].product);
        }

        set_all_product_types_in_alphabetical_order(
          _all_product_types_in_alphabetical_order.sort()
        );
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching product types: ${error.message} `
      );
    }

    set_action_loading(false);

    return;
  };

  const search_products = async (
    sub_category_in_link,
    search_term,
    category,
    sub_category,
    product,
    unit,
    min_price,
    max_price
  ) => {
    // console.log('params on load', sub_category_in_link, search_term, category, sub_category, product, unit, min_price, max_price)
    set_action_loading(true);
    set_error_message("");
    set_notify_success_message("");
    set_notify_error_message("");
    set_notify_admin_stage("1");
    set_looking_for_quantity("");
    set_looking_for_units("");
    //  set_success_message('');
    // id, seller, farm, product_meta, sub_category_meta, category_meta, product_name, quantity, quantity_units, min_price, max_price, delivery_zone, featured, on_sale, sort_order, sort_by, search_term, latitude, longitude
    let get_retrieved_products;
    let _search_term = "search_term";
    let _product_meta = "product_meta";
    let _sub_category_meta = "sub_category_meta";
    let _category_meta = "category_meta";
    let _product_name = "product_name";
    let _quantity = 0;
    let _quantity_units = "quantity_units";
    let _min_price = "min_price";
    let _max_price = "max_price";
    let city = "city";

    if (sub_category_in_link !== "sub_category_in_link") {
      if (sub_category_in_link.includes("all-")) {
        _category_meta = sub_category_in_link.replace("all-", ""); // drop the all-
      } else {
        _sub_category_meta = sub_category_in_link;
      }
    }

    if (!(buyer_city === null || buyer_city === "")) {
      city = buyer_city;
    }

    if (search_term !== "") {
      _search_term = search_term;
    }

    // console.log(_search_term)

    if (category !== "" && first_page_load === false) {
      _category_meta = category;
    }

    if (sub_category !== "" && first_page_load === false) {
      _sub_category_meta = sub_category;
    }

    if (_sub_category_meta === "") {
      _sub_category_meta = "sub_category_meta";
    }

    //  console.log('id', 'seller', 'farm', _product_meta, _sub_category_meta, _category_meta, _product_name, _quantity, _quantity_units, _min_price, _max_price, 'delivery_zone', 'featured', 'on_sale', 'sort_order', 'sort_by', _search_term, 'latitude', 'longitude', city)

    try {
      get_retrieved_products = await getProductsV3(
        "id",
        "seller",
        "farm",
        _product_meta,
        _sub_category_meta,
        _category_meta,
        _product_name,
        _quantity,
        _quantity_units,
        _min_price,
        _max_price,
        "delivery_zone",
        "featured",
        "on_sale",
        "sort_order",
        "sort_by",
        _search_term,
        "latitude",
        "longitude",
        city
      );
      console.log("get_retrieved_products: ", get_retrieved_products);
      if (get_retrieved_products.success === false) {
        set_error_message(get_retrieved_products.message);
      } else {
        const { data } = get_retrieved_products;
        // console.log('retrieved products', data);
        let _listed_ids = [];
        let v3_search_trimmed_data = [];
        for (let i = 0; i < data.length; i++) {
          let insert = true;

          // console.log('insert', insert)

          // buyer city iri kuti worldwide here ?
          // kana iri kuti worldwide insert = true
          if (localStorage.getItem("buyer_city") !== "Everywhere available") {
            // buyer country meta mubrowser yakafanana here neiri muproduct?
            // kana zvakasiyana then dont insert
            if (
              localStorage.getItem("buyer_country_meta") !==
              data[i].country_meta
            ) {
              insert = false;
            } else {
              // else insert but make some checks
              if (localStorage.getItem("buyer_city") !== "Nationwide") {
                // are we looking for nationwide
                if (localStorage.getItem("buyer_city") !== data[i].city) {
                  // if not then lets check if city is similar
                  insert = false;
                }
              }
            }
          }

          // console.log(localStorage.getItem('buyer_country_meta') === data[i].country_meta || localStorage.getItem('buyer_city') === 'Nationwide')

          if (insert && !_listed_ids.includes(data[i]._id)) {
            v3_search_trimmed_data.push(data[i]);
            _listed_ids.push(data[i]._id);
          }
        }

        // v3_search_trimmed_data = data

        if (v3_search_trimmed_data.length < 1) {
          set_error_message(
            "There are currently no products in the shop that match your search criteria."
          );
        } else {
          let _total_pages = Math.ceil(
            v3_search_trimmed_data.length / x_per_page
          );
          // console.log(v3_search_trimmed_data.length, x_per_page, total_pages);

          let _display_products = [];
          if (v3_search_trimmed_data.length > x_per_page) {
            set_total_pages(_total_pages);
            for (let i = 0; i < x_per_page; i++) {
              _display_products.push(v3_search_trimmed_data[i]);
            }
            // console.log('display products', _display_products);
            set_display_products(_display_products);
          } else {
            set_display_products(v3_search_trimmed_data);
          }
          set_retrieved_products(v3_search_trimmed_data);
        }
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching product types: ${error.message} `
      );
    }

    // console.log('search results', get_retrieved_products);
    set_found_search_term("");
    set_search_term_found_in_product_types(false);
    for (let i = 0; i < all_product_types_in_alphabetical_order.length; i++) {
      let searched_term_in_small = search_term.replace(/ /g, "-");
      searched_term_in_small = searched_term_in_small.toLowerCase();

      let found_term_in_small = all_product_types_in_alphabetical_order[
        i
      ].replace(/ /g, "-");
      found_term_in_small = found_term_in_small.toLowerCase();

      if (
        all_product_types_in_alphabetical_order[i] === search_term ||
        found_term_in_small === searched_term_in_small
      ) {
        set_search_term_found_in_product_types(true);
        set_found_search_term(all_product_types_in_alphabetical_order[i]);

        for (let k = 0; k < all_product_types.length; k++) {
          if (
            all_product_types[k].product ===
            all_product_types_in_alphabetical_order[i]
          ) {
            set_found_product_units(all_product_types[k].units);
            set_found_product_id(all_product_types[k]._id);
          }
        }
      }
    }

    set_action_loading(false);
    return;
  };

  const add_to_cart = async (product_id, product_name) => {
    set_item_added_to_cart_or_wishlist("");

    set_added_to_cart_message("");
    set_added_to_wishlist_message("");

    await dispatch(addToCart(product_id, 1));

    const new_cart_items = JSON.parse(
      localStorage.getItem("shopping_cart_items")
    );

    let _in_cart_products = [];
    if (new_cart_items.length > 0) {
      set_cart_items(new_cart_items);
      for (let i = 0; i < new_cart_items.length; i++) {
        _in_cart_products.push(new_cart_items[i].name);
      }
    }
    set_in_cart_products(_in_cart_products);

    set_item_added_to_cart_or_wishlist(product_id);
    set_added_to_cart_message(`${product_name} added to cart`);
  };

  // eslint-disable-next-line
  const add_to_wishlist = (product_id, product_name) => {
    set_item_added_to_cart_or_wishlist("");

    set_added_to_wishlist_message("");
    set_added_to_cart_message("");

    dispatch(addToWishList(product_id, 1));

    set_item_added_to_cart_or_wishlist(product_id);
    set_in_wish_list_products(
      JSON.parse(localStorage.getItem("wish_list_items"))
    );
    set_added_to_wishlist_message(`${product_name} added to wishlist`);
  };

  const update_quantity = async (product_id, action) => {
    set_item_added_to_cart_or_wishlist("");

    set_added_to_cart_message("");
    set_added_to_wishlist_message("");

    await dispatch(updateQuantity(product_id, action));

    const new_cart_items = JSON.parse(
      localStorage.getItem("shopping_cart_items")
    );

    let _in_cart_products = [];
    if (new_cart_items.length > 0) {
      set_cart_items(new_cart_items);
      for (let i = 0; i < new_cart_items.length; i++) {
        _in_cart_products.push(new_cart_items[i].name);
      }
    }
    set_in_cart_products(_in_cart_products);

    set_item_added_to_cart_or_wishlist(product_id);
    set_added_to_cart_message(`Quantity updated`);
  };

  const remove_buyer_city = (new_city) => {
    localStorage.removeItem("buyer_city");
    window.location.reload();
    return;
  };

  const change_buyer_city = (new_city) => {
    if (page_loaded === true) {
      localStorage.setItem("buyer_city", new_city);
      window.location.reload();
    }

    return;
  };

  const notify_me_when_product_available = async (e) => {
    e.preventDefault();

    set_notify_admin_stage("4");
    set_notify_success_message("");
    set_notify_error_message("");

    set_action_loading(true);

    let user_profile;
    const permitted_roles = [
      "buyer",
      "seller",
      "admin",
      "sales-support",
      "technical-support",
    ];
    const check_permissions = await validate_multiple_roles(permitted_roles);
    // console.log(check_permissions)
    if (check_permissions.permitted === false) {
      set_notify_error_message(
        `Please log in or register as a buyer so that we can contact you when ${found_search_term} becomes available.`
      );
    } else {
      const update_data = {
        product: found_search_term,
        product_id: found_product_id,
        quantity: looking_for_quantity,
        quantity_units: looking_for_units,
        email: check_permissions.user_object.email,
        delivery: looking_for_pick_or_deliver,
        radius: looking_for_search_radius,
      };

      const response = await notifyProductNeed(update_data);
      if (response.success === false) {
        set_notify_error_message(response.message);
      } else {
        set_notify_success_message(
          "We are now looking for the produce you have requested and we will let you know when it is available"
        );
      }
    }

    set_action_loading(false);

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      // search_products('', link_sub_category, '', '', '', '', '', '');
      let _search_term = sent_search_term;
      if (_search_term === "") _search_term = "search_term";
      search_products(
        sent_link_sub_category,
        _search_term,
        "category",
        "sub_category",
        "product",
        "unit",
        "min_price",
        "max_price"
      );
      set_page_loaded(true);
      set_first_page_load(false);
    }
    // eslint-disable-next-line
  }, []);

  const page_content = (
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>
          <span class="green-text">Shop</span> <span class="orange-text"></span>
        </h2>
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="input-group mb-2">
              {/* <input type="text" id="form1" class="form-control"
              onChange={(e) => set_selected_search_term(e.target.value)}
              value={selected_search_term}
              placeholder="What are you looking for?"
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  search_products('sub_category_in_link', selected_search_term, 'category', 'sub_category', 'product', 'unit', 'min_price', 'max_price')
                }
              }} /> */}

              <input
                list="brow"
                class="form-control"
                onChange={(e) => {
                  set_selected_search_term(e.target.value);
                }}
                value={selected_search_term}
              />
              <datalist id="brow">
                {all_product_types_in_alphabetical_order.map((c) => (
                  <option value={c} />
                ))}
              </datalist>

              <button
                type="button"
                class="btn btn-primary"
                disabled={false}
                onClick={() =>
                  search_products(
                    "sub_category_in_link",
                    selected_search_term,
                    "category",
                    "sub_category",
                    "product",
                    "unit",
                    "min_price",
                    "max_price"
                  )
                }
              >
                <i class="fa fa-search">Search</i>
              </button>
            </div>

            <div class="form-group mb-5">
              <nav class="navbar navbar-expand-lg navbar-light bg-transparent">
                <div class="container-fluid">
                  <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    {/* <ul class="navbar-nav">
                              {featured_categories.map((c) => (              
                                  <li key={c._id} value={c._id} class="nav-item dropdown">
                                      eslint-disable-next-line
                                      <Link class="nav-link dropdown-toggle" to="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      {c.category}
                                      </Link>
                                      <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                          <li>
                                            <Link class="dropdown-item" to={`/shop/all-${c.meta}`}>
                                              All {c.category}
                                            </Link>
                                          </li> 
                                          {(c.sub_categories).length > 0 ? (
                                              (c.sub_categories).map((d) => (  
                                                  <>
                                                      <li>
                                                        <Link class="dropdown-item" to={`/shop/${d.sub_category_meta}`}>
                                                          {d.sub_category}
                                                        </Link>
                                                    </li>                                                            
                                                  </>
                                              ))
                                          ):('')}
                                          
                                      </ul>
                                  </li>
                              ))}
                                  
                                  
                              </ul> */}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-3">
          <div class="form-group col-md-12 my-3">
            <select
              class="form-control"
              name="add_category"
              id="add_category"
              onChange={(e) => category_changed(e.target.value)}
            >
              <option value="">Select category</option>
              {all_categories.map((c) => (
                <option key={c._id} value={c._id}>
                  {c.category}
                </option>
              ))}
            </select>
          </div>

          <div class="form-group col-md-12 my-3">
            <select
              class="form-control"
              name="add_sub_category"
              id="add_sub_category"
              onChange={(e) => sub_category_changed(e.target.value)}
            >
              <option value="">Select sub category</option>
              {display_sub_categories.map((c) => (
                <option key={c.sub_category_meta} value={c.sub_category_meta}>
                  {c.sub_category}
                </option>
              ))}
            </select>
          </div>

          <div class="form-group col-md-12 my-3">
            <select
              class="form-control"
              name="add_product"
              id="add_product"
              onChange={(e) => product_changed(e.target.value)}
            >
              <option value="">Select product</option>
              {display_product_types.map((c) => (
                <option key={c.product} value={c.product}>
                  {c.product}
                </option>
              ))}
            </select>
          </div>

          <div class="input-group my-3">
            <div class="input-group-prepend">
              <span
                class="input-group-text"
                id="validationTooltipMinPricePrepend"
              >
                Min price {localStorage.getItem("buyer_currency")}
              </span>
            </div>
            <input
              type="number"
              min="0.01"
              onChange={(e) =>
                price_changed(e.target.value, selected_max_price)
              }
              step="0.01"
              class="form-control"
              id="validationTooltipMinPrice"
              aria-describedby="validationTooltipMinPricePrepend"
            />
          </div>

          <div class="input-group my-3">
            <div class="input-group-prepend">
              <span
                class="input-group-text"
                id="validationTooltipMaxPricePrepend"
              >
                Max price {localStorage.getItem("buyer_currency")}
              </span>
            </div>
            <input
              type="number"
              min={selected_min_price}
              onChange={(e) =>
                price_changed(selected_min_price, e.target.value)
              }
              step="0.01"
              class="form-control"
              id="validationTooltipMaxPrice"
              aria-describedby="validationTooltipMaxPricePrepend"
            />
          </div>

          {user_profile !== undefined &&
          user_profile.city !== "" &&
          user_profile.suburb !== "" ? (
            <div class="input-group my-5 mr-5">
              <label class="mb-5">Specify search radius</label>
              <InputRange
                maxValue={500}
                minValue={5}
                value={search_radius.value}
                formatLabel={(value) => `${value}km`}
                onChange={(value) => set_search_radius({ value })}
              />
            </div>
          ) : (
            <></>
          )}

          <div class="input-group my-3">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              style={{ color: "#fff", fontSize: "12px" }}
              onClick={() => clear_search()}
            >
              Clear search
            </button>
          </div>
        </div>
        <div class="col-12 col-md-9">
          {action_loading ? ( // so that only products will be loading at this time
            <>
              <div>
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only"></span>
                  </div>
                </div>
              </div>
            </>
          ) : error_message !== "" ? ( // correct here for things to display properly
            <>
              {notify_admin_stage === "1" ? (
                <div class="row">
                  <div class="col-12">
                    {/* set_add_category(new_category);
    set_add_sub_category('');
    set_add_product(''); */}
                    {found_search_term === "" ||
                    add_category !== "" ||
                    add_sub_category !== "" ||
                    add_product !== "" ? (
                      <div class="alert alert-danger" role="alert">
                        There are currently no products in the marketplace that
                        match what you searched for.
                      </div>
                    ) : (
                      <div class="d-flex text-center">
                        <p>
                          Are you searching for {found_search_term}? - &nbsp;
                          <button
                            type="button"
                            class="btn btn-success btn-sm"
                            style={{ color: "#fff", fontSize: "12px" }}
                            onClick={() => set_notify_admin_stage("2")}
                          >
                            Yes
                          </button>
                          &nbsp; / &nbsp;
                          <button
                            type="button"
                            class="btn btn-success btn-sm"
                            style={{ color: "#fff", fontSize: "12px" }}
                            onClick={() => clear_search()}
                          >
                            No
                          </button>{" "}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              {notify_admin_stage === "2" ? (
                <div class="row">
                  <div class="col-12">
                    <div class="d-flex">
                      <p>
                        Unfortunately there are no sellers in the area currently
                        selling {found_search_term}. Would you like to us to
                        inform farmers you are looking for {found_search_term}{" "}
                        and notify you when it is available? {found_search_term}
                        ? - &nbsp;
                        <button
                          type="button"
                          class="btn btn-success btn-sm"
                          style={{ color: "#fff", fontSize: "12px" }}
                          onClick={() => set_notify_admin_stage("3")}
                        >
                          Yes
                        </button>
                        &nbsp; / &nbsp;
                        <button
                          type="button"
                          class="btn btn-success btn-sm"
                          style={{ color: "#fff", fontSize: "12px" }}
                          onClick={() => clear_search()}
                        >
                          No
                        </button>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {notify_admin_stage === "3" ? (
                <div class="row">
                  <div class="col-md-6">
                    <div class="d-flex text-center">
                      <form onSubmit={notify_me_when_product_available}>
                        <div class="form-group m-3">
                          <label>
                            Please specify the quantity of {found_search_term}{" "}
                            you are looking for
                          </label>
                          <input
                            type="number"
                            value={looking_for_quantity}
                            onChange={(e) =>
                              set_looking_for_quantity(e.target.value)
                            }
                            class="form-control"
                            min="0"
                          />
                        </div>

                        <div class="form-group m-3">
                          <label>Specify units</label>
                          <select
                            value={looking_for_units}
                            onChange={(e) =>
                              set_looking_for_units(e.target.value)
                            }
                            class="form-control"
                          >
                            <option value="">Select units</option>
                            {found_product_units.map((ftu) => (
                              <option value={ftu}>{ftu}</option>
                            ))}
                          </select>
                        </div>

                        <div class="form-group m-3">
                          <label>
                            Will you pick the produce yourself, or do you
                            require delivery?
                          </label>
                          <select
                            value={looking_for_pick_or_deliver}
                            onChange={(e) =>
                              set_looking_for_pick_or_deliver(e.target.value)
                            }
                            class="form-control"
                          >
                            <option value="">Select option</option>
                            <option value="self pickup">
                              I will pick the produce myself
                            </option>
                            <option value="requires delivery">
                              I will require delivery
                            </option>
                          </select>
                        </div>

                        {looking_for_pick_or_deliver === "self pickup" ? (
                          <div class="form-group m-3">
                            <label>
                              How far away do you want the search for the
                              produce?
                            </label>
                            <select
                              value={looking_for_search_radius}
                              onChange={(e) =>
                                set_looking_for_search_radius(e.target.value)
                              }
                              class="form-control"
                            >
                              <option value="">Select option</option>

                              <option value="within city">within city</option>
                              <option value="within my suburb">
                                within my suburb
                              </option>
                              <option value="5 km">5 km</option>
                              <option value="10 km">10 km</option>
                              <option value="20 km">20 km</option>
                              <option value="50 km">50 km</option>
                              <option value="100 km">100 km</option>
                              <option value="500 km">500 km</option>
                              <option value="nationwide">nationwide</option>
                              <option value="anywhere">anywhere</option>
                            </select>
                          </div>
                        ) : (
                          ""
                        )}

                        <div class="form-group m-3">
                          <button
                            type="submit"
                            class="btn btn-success btn-lg"
                            style={{ color: "#fff", fontSize: "17px" }}
                            disabled={
                              looking_for_quantity.length === 0 ||
                              looking_for_pick_or_deliver.length === 0 ||
                              looking_for_units.length === 0 ||
                              (looking_for_pick_or_deliver === "self pickup" &&
                                looking_for_search_radius.length === 0)
                            }
                          >
                            Notify mee
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {notify_admin_stage === "4" ? (
                <div class="row">
                  <div class="col-12">
                    {notify_success_message !== "" ? (
                      <>
                        <div class="alert alert-success" role="alert">
                          {notify_success_message}
                        </div>
                        <div>
                          <button
                            type="button"
                            class="btn btn-success btn-sm"
                            style={{ color: "#fff", fontSize: "12px" }}
                            onClick={() => clear_search()}
                          >
                            Continue shopping
                          </button>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {notify_error_message !== "" ? (
                      <>
                        <div class="alert alert-danger" role="alert">
                          {notify_error_message}
                        </div>
                        <div>
                          <Link
                            class="btn btn-success btn-sm"
                            style={{ color: "#fff", fontSize: "12px" }}
                            to={`/login?lf=${found_search_term}&q=${looking_for_quantity}&u=${looking_for_units}`}
                          >
                            Login
                          </Link>
                          &nbsp; or &nbsp;
                          <Link
                            class="btn btn-success btn-sm"
                            style={{ color: "#fff", fontSize: "12px" }}
                            to={`/register?lf=${found_search_term}&q=${looking_for_quantity}&u=${looking_for_units}`}
                          >
                            Register
                          </Link>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <div class="row text-center ">
                {retrieved_products.length > 0 ? (
                  display_products.map((p) =>
                    p !== undefined ? (
                      <div
                        key={p._id}
                        class="col-md-4 d-flex text-center"
                        data-aos="fade-up"
                      >
                        <div class="info-box">
                          <Link to={`/product/${p.product_meta}/${p._id}`}>
                            {/* FIX THIS LATER SO THAT YOU ARE NOT USING INLINE STYLES, BUT RATHER USE CSS */}
                            <img
                              src={`${IMAGES_URL}/${p.pictures[0]}`}
                              alt={p.product_name}
                              style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "cover",
                                maxHeight: "200px",
                              }}
                            />
                            <h3 class="green-text">{p.product_name}</h3>
                          </Link>

                          <p class="green-text">
                            {p.currency}
                            {p.price} {p.quantity_units === "each" ? "" : "per"}{" "}
                            {p.quantity_units}
                          </p>

                          <h4 class="green-text mt-2 mb-2">
                            <b>
                              Sold by {p.farm}{" "}
                              {buyer_city === "Nationwide"
                                ? ` in ${p.city}`
                                : ""}{" "}
                              {buyer_city === "Everywhere available"
                                ? ` in ${p.country}, ${p.city}`
                                : ""}
                            </b>
                          </h4>

                          <div class="row">
                            <div
                              class="col-6 text-left"
                              style={{ marginLeft: 0 }}
                            >
                              {p.selling_method === "direct" ||
                              buyer_city === "Nationwide" ||
                              buyer_city === "Everywhere available" ? (
                                <Link
                                  to={`/product/${p.product_meta}/${p._id}`}
                                  class="mt-2 contact-seller"
                                >
                                  <button class="btn btn-primary btn-sm mb-2">
                                    Get details
                                  </button>
                                </Link>
                              ) : (
                                <div class="row mt-2">
                                  <div class="col-md-2"></div>
                                  <div class="col-md-2 text-right">
                                    <i class="bi bi-basket"></i>
                                  </div>
                                  <div class="col-md-6">
                                    {in_cart_products.includes(
                                      p.product_name
                                    ) ? (
                                      <>
                                        <button
                                          style={{
                                            color: "#fff",
                                            fontSize: "12px",
                                          }}
                                          class="btn btn-primary btn-sm mb-2"
                                          onClick={() =>
                                            update_quantity(p._id, "decrement")
                                          }
                                        >
                                          -
                                        </button>
                                        &nbsp;&nbsp;&nbsp;
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                          }}
                                        >
                                          {get_quantity_in_cart(p.product_name)}
                                        </span>
                                        &nbsp;&nbsp;&nbsp;
                                        <button
                                          style={{
                                            color: "#fff",
                                            fontSize: "12px",
                                          }}
                                          class="btn btn-primary btn-sm mb-2"
                                          onClick={() =>
                                            update_quantity(p._id, "increment")
                                          }
                                        >
                                          +
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        style={{
                                          width: "100%",
                                          color: "#fff",
                                          fontSize: "12px",
                                        }}
                                        class="btn btn-primary btn-sm mb-2"
                                        onClick={() =>
                                          add_to_cart(p._id, p.product_name)
                                        }
                                      >
                                        Add to cart
                                      </button>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div class="col-5 text-right">
                              <Link
                                to={`/order-product/${p.product_meta}/${p._id}/1`}
                                class="mt-2 contact-seller"
                              >
                                <button class="btn btn-primary btn-sm mb-2">
                                  Order Now
                                </button>
                              </Link>
                            </div>
                          </div>

                          <div class="row my-5">
                            {added_to_cart_message !== "" &&
                            item_added_to_cart_or_wishlist === p._id ? (
                              <>
                                <div class="col-md-2"></div>
                                <div class="col-md-8">
                                  <div class="alert alert-success" role="alert">
                                    {added_to_cart_message}.{" "}
                                    <Link to="/cart">Open cart</Link>
                                  </div>
                                </div>
                                <div class="col-md-2"></div>
                              </>
                            ) : (
                              ""
                            )}
                            {added_to_wishlist_message !== "" &&
                            item_added_to_cart_or_wishlist === p._id ? (
                              <>
                                <div class="col-md-2"></div>
                                <div class="col-md-8">
                                  <div class="alert alert-success" role="alert">
                                    {added_to_wishlist_message}.{" "}
                                    <Link to="/wishlist">Open wishlist</Link>
                                  </div>
                                </div>
                                <div class="col-md-2"></div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  )
                ) : // check if X is an exisiting product or not
                search_term_found_in_product_types ? (
                  <div class="col-12 d-flex text-center" data-aos="fade-up">
                    <p>Are you searching for {found_search_term}? </p>
                  </div>
                ) : (
                  <div class="col-12 d-flex text-center" data-aos="fade-up">
                    <div class="alert alert-danger" role="alert">
                      There are currently no products in the marketplace that
                      match what you searched for.
                    </div>
                  </div>
                )}
              </div>

              <div className="row text-center">
                <div className="col-12">
                  {retrieved_products.length > x_per_page ? (
                    <ReactPaginate {...paginationConfig} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {buyer_city === null || buyer_city === "" ? (
        <div class="row text-center mt-5">
          <div class="col-md-4"></div>
          <div class="col-md-1" style={{ margin: "auto" }}>
            <img src="/assets/img/green-arrow.png" alt="Select" />
          </div>
          <div class="col-md-3">
            <label class="mb-2">
              Select your city / town to start shopping
            </label>
            <select
              class="form-control"
              name="buyer_city"
              id="buyer_city"
              onChange={(e) => change_buyer_city(e.target.value)}
            >
              <option value="">Select city/town</option>
              {cities.map((c) =>
                c.country === localStorage.getItem("buyer_country_meta") ? (
                  <option key={c.city} value={c.city}>
                    {c.city}
                  </option>
                ) : (
                  <></>
                )
              )}
              <option value="Nationwide">Nationwide</option>
              <option value="Everywhere available">Everywhere available</option>
            </select>
          </div>
          <div class="col-md-4"></div>
        </div>
      ) : (
        <div class="row text-center mt-5">
          <div class="col-md-3"></div>
          <div class="col-md-5">
            Currently shopping from sellers supplying&nbsp;
            <span
              class="green-text"
              // onClick={ remove_buyer_city }
            >
              {/* eslint-disable-next-line */}
              {/* <Link to="#" data-toggle="tooltip" title="Click to change city"> */}
              {localStorage.getItem("buyer_city")}
              {/* </Link> */}
            </span>
          </div>
          <div class="col-md-2">
            <span class="green-text hand-cursor" onClick={remove_buyer_city}>
              <u>Change city/town </u>
            </span>
          </div>
        </div>
      )}

      {buyer_city === null || buyer_city === "" ? (
        <section id="contact" class="contact">
          {/* <section id="contact" class="contact" style={{pointerEvents: "none"}}>   */}
          <div class="row">
            <div class="col-md-12"></div>
          </div>
        </section>
      ) : (
        <section id="contact" class="contact">
          {page_content}
        </section>
      )}
    </>
  );
}
export default ShopScreen;
