import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  signin,
  signin_with_google_credentials,
  resendSellerOTP,
  verifyBuyerOrSellerOTP,
  resendBuyerOrSellerOTP,
} from "../actions/userActions";
import queryString from "query-string";

import { useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import Spinner from "../components/spinner";

function SuspensionAppealLoginScreen(props) {
  const login_with_google = useGoogleLogin({
    onSuccess: (tokenResponse) => console.log("token", tokenResponse),
  });

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");
  // const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [otp, setOTP] = useState("");

  const [stage, set_stage] = useState("appeal");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const userSignin = useSelector((state) => state.userSignin);
  const { loading, userInfo, error } = userSignin;
  const dispatch = useDispatch();
  // let redirect = props.location.search ? props.location.search.split("=")[1] : 'seller-dashboard';
  let redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "seller-dashboard";

  console.log("redirect", redirect);

  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {
      //
    };
    // eslint-disable-next-line
  }, [userInfo]);

  // console.log('props', props);

  const scrollToTopCustom = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const resendOTP = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    let notify_admin = false;
    let notify_details = {};
    // check if has query strings
    const query = queryString.parse(props.location.search);
    if (query.lf !== undefined) {
      notify_details.product = query.lf;
      notify_admin = true;
      redirect = "shop";
    }

    if (query.q !== undefined) notify_details.quantity = query.q;
    if (query.u !== undefined) notify_details.units = query.u;

    try {
      const response = await resendBuyerOrSellerOTP(
        email,
        notify_admin,
        notify_details
      );
      scrollToTopCustom();
      set_error_message("");
      set_success_message("");
      console.log(response);
      if (response.success === true) {
        set_success_message(response.message);
      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message("An error occured resending OTP. Try again");
    }

    set_action_loading(false);

    return;
  };

  const submitOTP = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    if (otp === "") {
      set_error_message("Please send a valid OTP");
      set_action_loading(false);
      return;
    }

    let notify_admin = false;
    let notify_details = {};
    // check if has query strings
    const query = queryString.parse(props.location.search);
    if (query.lf !== undefined) {
      notify_details.product = query.lf;
      notify_admin = true;
      redirect = "shop";
    }

    if (query.q !== undefined) notify_details.quantity = query.q;
    if (query.u !== undefined) notify_details.units = query.u;

    try {
      const response = await verifyBuyerOrSellerOTP(
        email,
        otp,
        notify_admin,
        notify_details
      );
      scrollToTopCustom();
      set_error_message("");
      set_success_message("");

      if (response.success === true) {
        // localStorage.setItem('token', response.token);
        set_success_message("Account successfully verified. You can now login");
        // console.log(email, password)
        await dispatch(
          signin(email, password, notify_admin, notify_details, props, redirect)
        );
      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message("An error occured verifying your account details");
    }

    set_action_loading(false);

    return;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    // console.log(userInfo);
    let notify_admin = false;
    let notify_details = {};
    // check if has query strings
    const query = await queryString.parse(props.location.search);

    // console.log(query)
    if (query.lf !== undefined) {
      notify_details.product = query.lf;
      notify_admin = true;
      // redirect = '/shop?st=7'
      redirect = "/my-requests";
    }

    if (query.q !== undefined) notify_details.quantity = query.q;
    if (query.u !== undefined) notify_details.units = query.u;

    let user_type = localStorage.getItem("potential_user_type");
    if (user_type === null || user_type === undefined || user_type === "") {
      user_type = "buyer";
    }

    if (user_type === "buyer" && query.lf === undefined) {
      redirect = "shop";
    }

    await dispatch(
      signin(email, password, notify_admin, notify_details, props, redirect)
    );
  };

  const login_google_credential = async (google_credentials) => {
    let notify_admin = false;
    let notify_details = {};
    const query = await queryString.parse(props.location.search);

    if (query.lf !== undefined) {
      notify_details.product = query.lf;
      notify_admin = true;
      redirect = "/my-requests";
    }

    if (query.q !== undefined) notify_details.quantity = query.q;
    if (query.u !== undefined) notify_details.units = query.u;

    let user_type = localStorage.getItem("potential_user_type");
    if (user_type === null || user_type === undefined || user_type === "") {
      user_type = "buyer";
    }

    if (user_type === "buyer" && query.lf === undefined) {
      redirect = "shop";
    }

    await dispatch(
      signin_with_google_credentials(
        google_credentials,
        user_type,
        notify_admin,
        notify_details,
        props,
        redirect
      )
    );
  };

  return (
    <section id="about" class="about main-content mt-5 login-content">
      <div class="container-fluid" data-aos="fade-up">
        <div class="row mt-5">
          <div class="col-md-4"></div>
          <div class="col-md-4 text-center">
            <h3 class="green-text">Suspension / Ban Appeal</h3>

            {error && (
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  {error}
                </div>
              </div>
            )}

            {/*
		  {error_message !== '' && <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error_message}
            </div>
          </div>}
		  */}

            {error_message !== "" ? (
              <div class="alert alert-danger" role="alert">
                {error_message}
              </div>
            ) : (
              ""
            )}
            {success_message !== "" ? (
              <div class="alert alert-success" role="alert">
                {success_message}
              </div>
            ) : (
              ""
            )}

            {stage === "appeal" ? (
              <>
                <form onSubmit={submitHandler}>
                  <div class="form-group my-4">
                    <label>Reason for appeal</label>
                    <textarea
                      class="form-control"
                      type="email"
                      name="email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                    ></textarea>
                  </div>
                  <div class="form-group my-4">
                    <label>Supporting evidence</label>
                    <input
                      class="form-control"
                      type="file"
                      id="password"
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  {loading && <Spinner />}

                  <div class="form-group my-4">
                    <button
                      type="submit"
                      class="btn btn-primary form-control login-button"
                      disabled={loading}
                    >
                      Appeal
                    </button>
                  </div>

                  <div class="mt-5 text-center"></div>
                </form>
              </>
            ) : stage === "review" ? (
              <>
                <div class="alert alert-success" role="alert">
                  Your appeal is currently under review.
                </div>
              </>
            ) : (
              <form onSubmit={submitHandler}>
                <div class="form-group my-4">
                  <input
                    class="form-control"
                    type="email"
                    name="email"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Account email"
                  />
                </div>
                <div class="form-group my-4">
                  <input
                    class="form-control"
                    type="password"
                    id="password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Account password"
                  />
                </div>

                <div class="form-group my-4">
                  <button
                    type="submit"
                    class="btn btn-primary form-control login-button"
                    disabled={loading}
                  >
                    Start process
                  </button>
                </div>

                <div class="mt-5 text-center"></div>
              </form>
            )}
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
    </section>
  );
}
export default SuspensionAppealLoginScreen;
