// useSocket.js

// useSocket.js
import { useEffect } from "react";
import io from "socket.io-client";
import { API_URL } from "../config";

export const useSocket = (userId, orderId, onOrderUpdated, onOrderUpdated2) => {
  useEffect(() => {
    const socket = io(API_URL, { transports: ["websocket"] });

    socket.on("connect", () => {
      // console.log("Connected to the websockets server");
      // console.log("userId in useSocket.js: " + userId);
      // Join the room for the order
      if (orderId) {
        socket.emit("join_room", orderId);
        // console.log(`Joined room for order: ${orderId}`);
      }

      // Join the room for the user
      if (userId) {
        socket.emit("join_user_room", userId);
        // console.log(`Joined room for user: ${userId}`);
      }
    });

    socket.on("order_updated", (data) => {
      if (onOrderUpdated) {
        onOrderUpdated(data);
        console.log("order_updated being run now with data:");
        console.log(data);
      }
    });

    socket.on("order_updated2", (data) => {
      if (onOrderUpdated2) {
        onOrderUpdated2(data);
        // console.log("order_updated2 being run now with data:");
        // console.log(data);
      }
    });

    socket.on("order_updated3", (data) => {
      if (onOrderUpdated2) {
        onOrderUpdated2(data);
        // console.log("order_updated3 being run now with data:");
        // console.log(data);
      }
    });

    socket.on("new_order", (data) => {
      if (onOrderUpdated2) {
        onOrderUpdated2(data);
        // console.log("new_order being run now with data:");
        // console.log(data);
      }
    });

    socket.on("requested_product_found", (data) => {
      if (onOrderUpdated2) {
        onOrderUpdated2(data);
        // console.log("requested_product_found being run now with data:");
        // console.log(data);
      }
    });

    socket.on("product_updated", (data) => {
      if (onOrderUpdated2) {
        onOrderUpdated2(data);
        // console.log("product_updated being run now with data:");
        // console.log(data);
      }
    });

    return () => {
      socket.off("connect");
      socket.off("order_updated");
      socket.off("order_updated2");
      socket.off("order_updated3");
      socket.off("new_order"); //This specific was added on 2024-02-03. Check if it causes errors
      socket.off("requested_product_found"); //This specific was added on 2024-02-03. Check if it causes errors
      socket.off("product_updated"); //This specific was added on 2024-02-03. Check if it causes errors
      socket.disconnect();
    };
  }, [userId, orderId, onOrderUpdated]); // Include both userId and orderId in the dependency array
};
