import React, { useState, useEffect } from "react";
import { getRates, updateRate } from "../actions/cartActions";
import { validate_multiple_roles } from "../validations";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import Spinner from "../components/spinner";

function CurrenciesAndRatesScreen(props) {
  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["admin", "technical-support"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const id = props.match.params.id ? props.match.params.id : "";

  const [error_message, set_error_message] = useState("");
  const [success_message, set_success_message] = useState("");

  // eslint-disable-next-line
  const [
    update_subcategory_error_message,
    set_update_subcategory_error_message,
  ] = useState("");
  // eslint-disable-next-line
  const [
    update_subcategory_success_message,
    set_update_subcategory_success_message,
  ] = useState("");

  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [add_currency, set_add_currency] = useState("");
  const [buy_rate, set_buy_rate] = useState("");
  const [sell_rate, set_sell_rate] = useState("");
  // eslint-disable-next-line
  const [base_currency, set_base_currency] = useState("US");
  const [foreign_currencies, set_foreign_currencies] = useState([
    // { currency: 'US', buy: 1, sell: 1, midrate: 1 },
    { currency: "ZW", buy: 0, sell: 0, midrate: 0 },
  ]);

  // eslint-disable-next-line
  const [add_currency_invalid, set_add_currency_invalid] = useState(true);
  // eslint-disable-next-line
  const [sell_rate_invalid, set_sell_rate_invalid] = useState(true);
  // eslint-disable-next-line
  const [buy_rate_invalid, set_buy_rate_invalid] = useState(true);
  // eslint-disable-next-line

  const load_page_data = async () => {
    let get_rates;

    let _temp_currencies = [];

    for (let i = 0; i < foreign_currencies.length; i++) {
      try {
        get_rates = await getRates(
          base_currency,
          foreign_currencies[i].currency
        );
        console.log(base_currency, foreign_currencies[i].currency, get_rates);
        if (get_rates.success) {
          const { data } = get_rates;
          _temp_currencies.push({
            currency: foreign_currencies[i].currency,
            buy: data[0].buy,
            sell: data[0].sell,
            midrate: data[0].midrate,
          });
          console.log(data[0]);
        } else {
          _temp_currencies.push({
            currency: foreign_currencies[i].currency,
            buy: 0,
            sell: 0,
            midrate: 0,
          });
        }
      } catch (error) {
        set_error_message(
          `An error occured fetching category details: ${error.message} `
        );
      }
    }

    set_foreign_currencies(_temp_currencies);

    return;
  };

  /**
   * Updates user profile
   * @param {*} e
   */
  const updateProduce = async (e) => {
    e.preventDefault();

    set_success_message("");
    set_error_message("");
    set_update_subcategory_success_message("");
    set_update_subcategory_error_message("");

    set_action_loading(true);

    const response = await updateRate(
      base_currency,
      add_currency,
      buy_rate,
      sell_rate
    );
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      let get_rates;

      let _temp_currencies = [];

      for (let i = 0; i < foreign_currencies.length; i++) {
        try {
          get_rates = await getRates(
            base_currency,
            foreign_currencies[i].currency
          );
          console.log(base_currency, foreign_currencies[i].currency, get_rates);
          if (get_rates.success) {
            const { data } = get_rates;
            _temp_currencies.push({
              currency: foreign_currencies[i].currency,
              buy: data[0].buy,
              sell: data[0].sell,
              midrate: data[0].midrate,
            });
            console.log(data[0]);
          } else {
            _temp_currencies.push({
              currency: foreign_currencies[i].currency,
              buy: 0,
              sell: 0,
              midrate: 0,
            });
          }
        } catch (error) {
          set_error_message(
            `An error occured fetching category details: ${error.message} `
          );
        }
      }

      set_foreign_currencies(_temp_currencies);

      set_success_message("Rate successfully updated");
    }
    set_action_loading(false);

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">Currencies</span>{" "}
              <span class="orange-text"> &amp; Rates</span>
            </h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-md-12">
                  <h2>Rates board</h2>
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <tr>
                              <th>Currency</th>
                              <th>Buy</th>
                              <th>Sell</th>
                              <th>Midrate</th>
                            </tr>
                            {foreign_currencies.length > 0
                              ? foreign_currencies.map((c) => (
                                  <tr key={c.currency}>
                                    <td>{c.currency}</td>
                                    <td>{c.buy}</td>
                                    <td>{c.sell}</td>
                                    <td>{c.midrate}</td>
                                  </tr>
                                ))
                              : "No foreign currencies found."}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>

                <div class="col-12">
                  {error_message !== "" ? (
                    <div class="alert alert-danger" role="alert">
                      {error_message}
                    </div>
                  ) : (
                    ""
                  )}
                  {success_message !== "" ? (
                    <div class="alert alert-success" role="alert">
                      {success_message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h2>Update rate</h2>
                  <form onSubmit={updateProduce}>
                    <div class="row">
                      <div class="form-group col-md-4 my-3">
                        <label for="">Currency</label>
                        <select
                          class="form-control"
                          name="add_currency"
                          id="add_currency"
                          onChange={(e) => set_add_currency(e.target.value)}
                        >
                          <option value="">Select currency</option>
                          {foreign_currencies.map((c) => (
                            <option key={c._id} value={c._id}>
                              {c.currency}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="">Buy rate</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "buyRate",
                            name: "Buy rate",
                            type: "number",
                            placeholder: "",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_buy_rate_invalid(res)
                          }
                          value={buy_rate}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(buy_rate, e) => {
                            set_buy_rate(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          validationOption={{
                            name: "Buy rate",
                            check: true,
                            required: true,
                            type: "number", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            min: 0.01,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>

                      <div class="form-group col-md-4 my-3">
                        <label for="">Sell rate</label>
                        <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "sellRate",
                            name: "Sell rate",
                            type: "number",
                            placeholder: "",
                          }}
                          // validate={validate}
                          validationCallback={(res) =>
                            set_sell_rate_invalid(res)
                          }
                          value={sell_rate}
                          disabled={false}
                          classNameInput=""
                          classNameWrapper=""
                          classNameContainer=""
                          customStyleInput={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          onChange={(sell_rate, e) => {
                            set_sell_rate(e.target.value);
                          }}
                          onBlur={(e) => {
                            console.log(e);
                          }}
                          // onFocus={(e) => {console.log(e);}}
                          // onClick={(e) => {console.log(e);}}
                          validationOption={{
                            name: "Sell rate",
                            check: true,
                            required: true,
                            type: "number", // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                            // showMsg: true,
                            min: 1,
                            // max: 10,
                            // length: 2,
                            // compare: '3',
                            // reg: /^\d{12}|\d{14}$/,
                            // regMsg: 'Invalid phone number',
                            // locale: 'en-US',
                            // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']",
                            // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border."
                            // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                            //   if (res != 'milk') {
                            //     return 'Name cannot be other things but milk';
                            //   }
                            //   return true;
                            // }
                          }}
                        />
                      </div>
                    </div>

                    <div class="form-group col-12 my-3 text-center">
                      <button
                        class="btn btn-lg btn-success"
                        type="submit"
                        disabled={
                          action_loading ||
                          sell_rate_invalid ||
                          buy_rate_invalid ||
                          add_currency === ""
                        }
                        // check invalids
                      >
                        <i class="glyphicon glyphicon-plus"></i> Update{" "}
                        {add_currency} rate
                      </button>
                    </div>
                  </form>

                  <hr />
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
export default CurrenciesAndRatesScreen;
