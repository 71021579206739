import React, { useState, useEffect } from "react";
import { FaEnvelope } from "react-icons/fa"; // Import the mail icon component
import { getOrdersV3, createOrderV2 } from "../actions/orderActions";
import { validate_multiple_roles } from "../validations";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import moment from "moment";
import { Link } from "react-router-dom";
import { get_date_string, get_display_date } from "../config";
import Spinner from "../components/spinner";

export default function MyPurchasesScreen(props) {
  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["seller", "buyer"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const status = props.match.params.status
    ? props.match.params.status
    : "Pending";

  const [total_unread_messages, set_total_unread_messages] = useState("");
  const [error_message, set_error_message] = useState("");
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState("");

  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  const [orders, set_orders] = useState([]);

  // eslint-disable-next-line
  const [id, set_id] = useState("id");
  // eslint-disable-next-line
  const [phone, set_phone] = useState("phone");
  // eslint-disable-next-line
  const [email, set_email] = useState(user_profile.email);
  // eslint-disable-next-line
  const [city, set_city] = useState("city");
  // eslint-disable-next-line
  const [delivery_zone, set_delivery_zone] = useState("delivery_zone");
  // eslint-disable-next-line
  const [payment_method, set_payment_method] = useState("payment_method");
  // eslint-disable-next-line
  const [client_id, set_client_id] = useState("client_id");
  // eslint-disable-next-line
  // const [status, set_status] = useState('status');

  // eslint-disable-next-line
  const [add_city_invalid, set_add_city_invalid] = useState(true);

  const link_cell_formatter = (cell, row) => {
    return (
      <div>
        <Link className="btn btn-success btn-lg" to={`/buyer-order/${row._id}`}>
          View order
        </Link>{" "}
        &nbsp;
        {row.unread_messages_count > 0 && (
          <div className="d-inline-flex align-items-center ml-2">
            <span
              className="mr-1"
              style={{ fontWeight: "bold", color: "green" }}
            >
              {row.unread_messages_count}
            </span>
            <FaEnvelope style={{ color: "green" }} />
          </div>
        )}
      </div>
    );
  };

  const load_page_data = async () => {
    let get_orders;
    try {
      get_orders = await getOrdersV3(id, "mine", "seller", "status", "yes");
      if (get_orders.success) {
        let { data } = get_orders;
        let totalUnreadMessagesCount = 0;
        for (let i = 0; i < data.length; i++) {
          let currency = "";
          if (data[i].product_details.currency !== undefined) {
            currency = data[i].product_details.currency;
          }
          data[i].order_value = `${currency}${
            data[i].quantity * data[i].product_details.price
          }`;
          data[i].order_date = get_display_date(data[i].createdAt); //(data[i].createdAt).format('MMMM d, YYYY')
          data[i].customer = "";
          data[i].address = "";
          data[i].seller = data[i].seller_details.display_name;
          data[i].status = data[i].status;
          data[i].order_items =
            data[i].quantity +
            " " +
            data[i].product_details.price_per +
            " " +
            data[i].product_details.product_name;
          data[i].action = (
            <Link
              class="btn btn-success btn-lg"
              to={`/buyer-order/${data[i]._id}`}
            >
              Buy again
            </Link>
          );

          totalUnreadMessagesCount += data[i].unread_messages_count;
          console.log(data[i].unread_messages_count);
        }
        set_orders(data);
        console.log("orders");
        console.log(data);
        set_total_unread_messages(totalUnreadMessagesCount);
      } else {
        set_error_message("Your purchases history is empty.");
      }
    } catch (error) {
      set_error_message(
        `An error occured fetching purchases history: ${error.message} `
      );
    }

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  const redo_order = async (product_id, quantity) => {
    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    try {
      const response = await createOrderV2(product_id, quantity);
      if (response.success) {
        await load_page_data();

        set_success_message("New order successfully placed.");
      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message(
        "An error occured in completing your order. Please try again"
      );
    }

    set_action_loading(false);

    return;
  };

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">Purchases</span>{" "}
              <span class="orange-text">History</span>
            </h2>
          </div>

          <div class="row">
            <div class="col-md-12 m-5">
              {total_unread_messages > 0 ? (
                <p class="alert alert-success">
                  You have {total_unread_messages} new messages from Sellers.
                  View the orders that have new messages{" "}
                  <FaEnvelope style={{ color: "green" }} />. &nbsp;
                  <button
                    type="button"
                    class="btn btn-success btn-sm"
                    style={{ color: "#fff", fontSize: "12px" }}
                    onClick={() => {
                      set_total_unread_messages(0); // Add this line to set total_unread_messages to 0
                    }}
                  >
                    Okay
                  </button>
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-12">
                      {error_message !== "" ? (
                        <div class="alert alert-danger" role="alert">
                          {error_message}
                        </div>
                      ) : (
                        ""
                      )}
                      {success_message !== "" ? (
                        <div class="alert alert-success" role="alert">
                          {success_message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div class="row">
            <div class="col-12">
              <BootstrapTable data={orders} search pagination>
                <TableHeaderColumn dataField="order_date">
                  Order date
                </TableHeaderColumn>
                {/* <TableHeaderColumn dataField='tracking_number'>Tracking number</TableHeaderColumn> */}
                <TableHeaderColumn dataField="order_items">
                  Description
                </TableHeaderColumn>
                <TableHeaderColumn dataField="order_value">
                  Order value
                </TableHeaderColumn>
                <TableHeaderColumn dataField="seller">Seller</TableHeaderColumn>
                <TableHeaderColumn dataField="status">Status</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='status'>Status</TableHeaderColumn> */}
                <TableHeaderColumn
                  dataField="action"
                  dataFormat={link_cell_formatter}
                  isKey={true}
                ></TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
