import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_SAVE_REQUEST,
  PRODUCT_SAVE_SUCCESS,
  PRODUCT_SAVE_FAIL,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_REVIEW_SAVE_REQUEST,
  PRODUCT_REVIEW_SAVE_FAIL,
  PRODUCT_REVIEW_SAVE_SUCCESS,
} from '../constants/productConstants';

import axios from 'axios';
import Axios from 'axios';
import { get_display_date } from '../config';

import { API_URL, API_KEY } from "../config";

let token_string = localStorage.getItem('token');

const listProducts = (id, seller, farm, product_meta, sub_category_meta, category_meta, product_name, quantity, quantity_units, min_price, max_price, delivery_zone, featured, on_sale, sort_order ) => async (dispatch) => {
  // console.log('in list products', category, searchKeyword, sortOrder);

  try {
    dispatch({ type: PRODUCT_LIST_REQUEST });
    const { data } = await axios.get(
      `${API_URL}/products?id=${id}&seller=${seller}&farm=${farm}&product_meta=${product_meta}&sub_category_meta=${sub_category_meta}&category_meta=${category_meta}&product_name=${product_name}&quantity=${quantity}&quantity_units=${quantity_units}&min_price=${min_price}&max_price=${max_price}&delivery_zone=${delivery_zone}&featured=${featured}&on_sale=${on_sale}&sort_order=${sort_order}`
    );
    // console.log('data', data);
    dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data.data });
  } catch (error) {
    // console.log(error.message);
    dispatch({ type: PRODUCT_LIST_FAIL, payload: 'An error occured fetching products' });
  }
};

const saveProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_SAVE_REQUEST, payload: product });
    const {
      userSignin: { userInfo },
    } = getState();
    if (!product._id) {
      const { data } = await Axios.post('/api/products', product, {
        headers: {
          Authorization: 'Bearer ' + userInfo.token,
        },
      });
      dispatch({ type: PRODUCT_SAVE_SUCCESS, payload: data });
    } else {
      const { data } = await Axios.put(
        '/api/products/' + product._id,
        product,
        {
          headers: {
            Authorization: 'Bearer ' + userInfo.token,
          },
        }
      );
      dispatch({ type: PRODUCT_SAVE_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({ type: PRODUCT_SAVE_FAIL, payload: error.message });
  }
};

const detailsProduct = (productId) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST, payload: productId });
    const { data } = await axios.get('/api/products/' + productId);
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_DETAILS_FAIL, payload: error.message });
  }
};

const deleteProdcut = (productId) => async (dispatch, getState) => {
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    dispatch({ type: PRODUCT_DELETE_REQUEST, payload: productId });
    const { data } = await axios.delete('/api/products/' + productId, {
      headers: {
        Authorization: 'Bearer ' + userInfo.token,
      },
    });
    dispatch({ type: PRODUCT_DELETE_SUCCESS, payload: data, success: true });
  } catch (error) {
    dispatch({ type: PRODUCT_DELETE_FAIL, payload: error.message });
  }
};

const saveProductReview = (productId, review) => async (dispatch, getState) => {
  try {
    const {
      userSignin: {
        userInfo: { token },
      },
    } = getState();
    dispatch({ type: PRODUCT_REVIEW_SAVE_REQUEST, payload: review });
    const { data } = await axios.post(
      `/api/products/${productId}/reviews`,
      review,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );
    dispatch({ type: PRODUCT_REVIEW_SAVE_SUCCESS, payload: data });
  } catch (error) {
    // report error
    dispatch({ type: PRODUCT_REVIEW_SAVE_FAIL, payload: error.message });
  }
};


/**
 * Adds a new product
 * @param {Object} add_data 
 */
const addProduct = async (add_data) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.post(
      `${API_URL}/products`, 
      { 
        api_key: API_KEY, 
        ...add_data,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not add product. Please try again. Please try again',
    }
  }
}

const getProducts = async (id, seller, farm, product_meta, sub_category_meta, category_meta, product_name, quantity, quantity_units, min_price, max_price, delivery_zone, featured, on_sale, sort_order ) => {
  try {
    const { data } = await axios.get(
      `${API_URL}/products?id=${id}&seller=${seller}&farm=${farm}&product_meta=${product_meta}&sub_category_meta=${sub_category_meta}&category_meta=${category_meta}&product_name=${product_name}&quantity=${quantity}&quantity_units=${quantity_units}&min_price=${min_price}&max_price=${max_price}&delivery_zone=${delivery_zone}&featured=${featured}&on_sale=${on_sale}&sort_order=${sort_order}`
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Failed to retrieve products details. Please reload page',
    }
  }
}

const getProductsV2 = async (id, seller, farm, product_meta, sub_category_meta, category_meta, product_name, quantity, quantity_units, min_price, max_price, delivery_zone, featured, on_sale, sort_order, sort_by, search_term, latitude, longitude) => {
  try {
    const { data } = await axios.get(
      `${API_URL}/products/search?id=${id}&seller=${seller}&farm=${farm}&product_meta=${product_meta}&sub_category_meta=${sub_category_meta}&category_meta=${category_meta}&product_name=${product_name}&quantity=${quantity}&quantity_units=${quantity_units}&min_price=${min_price}&max_price=${max_price}&delivery_zone=${delivery_zone}&featured=${featured}&on_sale=${on_sale}&sort_order=${sort_order}&sort_by=${sort_by}&search_term=${search_term}&latitude=${latitude}&longitude=${longitude}`
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'There are currently no products in the marketplace that match what you searched for.',
    }
  }
}

const getProductsV3 = async (id, seller, farm, product_meta, sub_category_meta, category_meta, product_name, quantity, quantity_units, min_price, max_price, delivery_zone, featured, on_sale, sort_order, sort_by, search_term, latitude, longitude, city) => {
  // console.log('v3 open', id, seller, farm, product_meta, sub_category_meta, category_meta, product_name, quantity, quantity_units, min_price, max_price, delivery_zone, featured, on_sale, sort_order, sort_by, search_term, latitude, longitude, city)
  try {
    const { data } = await axios.get(
      `${API_URL}/products/search?id=${id}&seller=${seller}&farm=${farm}&product_meta=${product_meta}&sub_category_meta=${sub_category_meta}&category_meta=${category_meta}&product_name=${product_name}&quantity=${quantity}&quantity_units=${quantity_units}&min_price=${min_price}&max_price=${max_price}&delivery_zone=${delivery_zone}&featured=${featured}&on_sale=${on_sale}&sort_order=${sort_order}&sort_by=${sort_by}&search_term=${search_term}&latitude=${latitude}&longitude=${longitude}&city=${city}`
    );
    // console.log('v3 data', data)
    return data;
    
  } catch (error) {
    // console.log('v3 error', error.message)
    return {
      success: false,
      message: 'There are currently no products in the marketplace that match what you searched for.',
    }
  }
}

const getProductsByIds = async (ids) => {
  // console.log('v3 open', id, seller, farm, product_meta, sub_category_meta, category_meta, product_name, quantity, quantity_units, min_price, max_price, delivery_zone, featured, on_sale, sort_order, sort_by, search_term, latitude, longitude, city)
  try {
    const { data } = await axios.get(
      `${API_URL}/products/search-by-multiple-ids?ids=${ids}`
    );
    // console.log('v3 data', data)
    return data;
    
  } catch (error) {
    // console.log('v3 error', error.message)
    return {
      success: false,
      message: 'There are currently no products in the marketplace that match what you searched for.',
    }
  }
}

const getInventoryAlerts = async () => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await axios.get(
      `${API_URL}/products/inventory-alerts`,
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Failed to retrieve inventory alerts',
    }
  }
}


/**
 * Updates product details
 * @param {Object} update_data 
 * @param {String} id
 * @param {String} update_key
 */
const updateProduct = async (update_data, id, update_key) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.patch(
      `${API_URL}/products`, 
      { 
        api_key: API_KEY, 
        update_data,
        id,
        update_key,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: `Sorry we could not update product details. ${error.message}`,
    }
  }
}


const updateBuyerRequestedProduct = async (update_data, id, update_key) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.patch(
      `${API_URL}/products/buyer-requested`, 
      { 
        api_key: API_KEY, 
        update_data,
        id,
        update_key,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: `Sorry we could not update product details. ${error.message}`,
    }
  }
}

/**
 * Deducts part of the quantity of the product.
 * 
 * @param {String} product_id 
 * @param {String} deduct_quantity 
 * @param {String} description 
 */
const deductQuantity = async (product_id, deduct_quantity, description) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.post(
      `${API_URL}/products/deduct-quantity`, 
      { 
        api_key: API_KEY, 
        product_id,
        deduct_quantity,
        description,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: `Sorry we could not update product details. ${error.message}`,
    }
  }
}

const retrieveProducts = async (id, seller, farm, product_meta, sub_category_meta, category_meta, product_name, quantity, quantity_units, min_price, max_price, delivery_zone, featured, on_sale, sort_order ) => {
  // console.log('in list products', category, searchKeyword, sortOrder);

  try {
    const { data } = await axios.get(
      `${API_URL}/products?id=${id}&seller=${seller}&farm=${farm}&product_meta=${product_meta}&sub_category_meta=${sub_category_meta}&category_meta=${category_meta}&product_name=${product_name}&quantity=${quantity}&quantity_units=${quantity_units}&min_price=${min_price}&max_price=${max_price}&delivery_zone=${delivery_zone}&featured=${featured}&on_sale=${on_sale}&sort_order=${sort_order}`
    );
    return data;
  } catch (error) {
    // console.log(error.message);
  }
};

const retrieveProductsNeedingUpdate = async (seller_id ) => {
  // console.log('in list products', category, searchKeyword, sortOrder);
  let seller = seller_id;
  let id = 'id';
  let farm = 'farm';
  let product_meta = 'product_meta';
  let sub_category_meta = 'sub_category_meta';
  let category_meta = 'category_meta';
  let product_name = 'product_name';
  let quantity = 'quantity';
  let quantity_units = 'quantity_units';
  let min_price = 'min_price';
  let max_price = 'max_price';
  let delivery_zone = 'delivery_zone';
  let featured = 'featured';
  let on_sale = 'on_sale';
  let sort_order = 'sort_order';

//id, seller, farm, product_meta, sub_category_meta, category_meta, product_name, quantity, quantity_units, min_price, max_price, delivery_zone, featured, on_sale, sort_order
//'id', user_profile._id, 'farm', 'product_meta', 'sub_category_meta', 'category_meta', 'product_name', '0', 'quantity_units', 'min_price', 'max_price', 'delivery_zone', 'featured', 'on_sale', 'sort_order');


  try {
    const { data } = await axios.get(
      `${API_URL}/products?id=${id}&seller=${seller}&farm=${farm}&product_meta=${product_meta}&sub_category_meta=${sub_category_meta}&category_meta=${category_meta}&product_name=${product_name}&quantity=${quantity}&quantity_units=${quantity_units}&min_price=${min_price}&max_price=${max_price}&delivery_zone=${delivery_zone}&featured=${featured}&on_sale=${on_sale}&sort_order=${sort_order}`
    );
    console.log('data from retrieveProductsNeedingUpdate',data.data);
	let products = data.data
	console.log('count of data from retrieveProductsNeedingUpdate',products.length);
    let _products_last_updated;
	let _products_last_updated_warning = false;
    let _products_count = products.length;
    if (_products_count > 0) {

      let outdated_products = []
      for (let i = 0; i < _products_count; i++) {
        // console.log(new Date(data[i].updatedAt), new Date())
	  
        const difference_in_time = (new Date()).getTime() - (new Date(products[i].updatedAt)).getTime()
        const difference_in_days = difference_in_time / (1000 * 3600 * 24);
	  
        // console.log(data[i].longevity, difference_in_days)
        if (products[i].longevity < difference_in_days) {
          outdated_products.push(products[i])
        }
	  
      }
	  return {
        success: true,
        data: outdated_products,
      }
	} else {
      return {
        success: false,
        message: 'No outdated products found.',
      }
	}
  } catch (error) {
    console.log(error)
    return {
      success: false,
      message: 'Failed to retrieve outdated products. Please reload page',
    }
  }

};



const retrieveBuyerProducts = async (id, product, buyer, status, read) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await axios.get(
      //`${API_URL}/products/buyer-requested?id=${id}&product=${product}&buyer=${buyer}&status=${status}`,
      `${API_URL}/products/buyer-requested?id=${id}&product=${product}&buyer=${buyer}&status=${status}&read=${read}`,
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
	console.log('retrieveBuyerProducts data',data);
    return data;
  } catch (error) {
    // console.log(error.message);
  }
};

const retrieveBuyerRequests = async (id, product, buyer, status) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await axios.get(
      //`${API_URL}/products/buyer-requested?id=${id}&product=${product}&buyer=${buyer}&status=${status}`,
      `${API_URL}/products/buyer-requests?id=${id}&product=${product}&buyer=${buyer}&status=${status}`,
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
	console.log('retrieveBuyerRequests data',data);
    return data;
  } catch (error) {
    // console.log(error.message);
  }
};


const retrieveBuyerProductsV2 = async (product, buyer, status, read) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await axios.get(
      //`${API_URL}/products/buyer-requested?id=${id}&product=${product}&buyer=${buyer}&status=${status}`,
      `${API_URL}/products/buyer-requested?product=${product}&buyer=${buyer}&status=${status}&read=${read}`,
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
	console.log('retrieveBuyerProducts data',data);
    return data;
  } catch (error) {
    // console.log(error.message);
  }
};

const retrieveBuyerProductsV3 = async (product, buyer, status) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await axios.get(
      //`${API_URL}/products/buyer-requested?id=${id}&product=${product}&buyer=${buyer}&status=${status}`,
      `${API_URL}/products/buyer-requested-v3?product=${product}&buyer=${buyer}&status=${status}`,
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
	console.log('retrieveBuyerProducts data',data);
    return data;
  } catch (error) {
    // console.log(error.message);
  }
};


const retrieveSellerRequestedProducts = async (id, seller, status) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await axios.get(
      `${API_URL}/products/seller-requested?id=${id}&seller=${seller}&status=${status}`,
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    // console.log(error.message);
  }
};

const getStoreDetails = async (username) => {
  try {
    const { data } = await axios.get(
      `${API_URL}/sellers/store-details?username=${username}`
    );
    return data;
  } catch (error) {
    // console.log(error.message);
  }
};

const notifyProductNeed = async (add_data) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.post(
      `${API_URL}/products/notify-need`, 
      { 
        api_key: API_KEY, 
        ...add_data,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not send your notification. Please try again',
    }
  }
}

export {
  deductQuantity,
  listProducts,
  getProducts,
  getProductsV2,
  getProductsV3,
  getProductsByIds,
  detailsProduct,
  saveProduct,
  deleteProdcut,
  saveProductReview,
  addProduct,
  updateProduct,
  getInventoryAlerts,
  getStoreDetails,
  retrieveProducts,
  notifyProductNeed,
  retrieveBuyerProducts,
  retrieveBuyerRequests,
  retrieveBuyerProductsV2,
  retrieveBuyerProductsV3,
  updateBuyerRequestedProduct,
  retrieveSellerRequestedProducts,
  retrieveProductsNeedingUpdate,
};
