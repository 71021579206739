// export const API_URL = 'https://peleka-market-dev-api.herokuapp.com';
export const API_URL = process.env.REACT_APP_API_URL
export const API_KEY = process.env.REACT_APP_API_KEY
export const APP_URL = process.env.REACT_APP_PUBLIC_URL
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL
export const token_string = localStorage.getItem('token');
export const buyer_city = localStorage.getItem('buyer_city');

export const GOOGLE_OAUTH_CLIENT_ID = '573253605860-vosc2e2640uepoj8s4kp83ff3dek2lv4.apps.googleusercontent.com'

export const GOOGLE_OAUTH_CLIENT_SECRET = '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'

export const GOOGLE_OAUTH_SCOPE = 'https://www.googleapis.com/auth/drive'

export const get_date = (firestore_timestamp) => new Date(firestore_timestamp.seconds * 1000);

export const get_month = (month_number) => {
    switch (month_number) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      break;
    }
  };

export const get_date_string = (firestore_timestamp) => {
    // console.log('in');
    let created_date;
    created_date = firestore_timestamp;
    // console.log('in', created_date);
  
    if (firestore_timestamp.seconds) {
      created_date = get_date(firestore_timestamp);
    }
  
    const day = created_date.getDate() < 10 ? `0${created_date.getDate()}` : created_date.getDate();
    // const seconds = created_date.getSeconds() < 10 ? `0${created_date.getSeconds()}` : created_date.getSeconds();
    const minutes = created_date.getMinutes() < 10 ? `0${created_date.getMinutes()}` : created_date.getMinutes();
    const hours = created_date.getHours() < 10 ? `0${created_date.getHours()}` : created_date.getHours();
  
    const created_date_string = `${day} ${get_month(created_date.getMonth() + 1)} ${created_date.getFullYear()} at ${hours}:${minutes}`;
  
    return created_date_string;
  };

  export const get_display_date = (mongo_date_string) => {
    
    // let firestore_timestamp = ;
    let firestore_timestamp = new Date(new Date(mongo_date_string));

    

    const created_date = firestore_timestamp;

    const now = new Date();
  
    const created_date_utc = Date.UTC(
      created_date.getFullYear(),
      created_date.getMonth(),
      created_date.getDate(),
      created_date.getHours(),
      created_date.getMinutes(),
      created_date.getSeconds(),
      created_date.getMinutes(),
    );
    const now_utc = Date.UTC(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
      now.getMilliseconds(),
    );
  
    const seconds_ago = Math.floor((now_utc - created_date_utc) / (1000));
  
    if (seconds_ago < 60) {
      return `${seconds_ago} ${seconds_ago === 1 ? 'second' : 'seconds'} ago`;
    }
  
    const minutes_ago = Math.floor((now_utc - created_date_utc) / (1000 * 60));
  
    if (minutes_ago < 60) {
      return `${minutes_ago} ${minutes_ago === 1 ? 'min' : 'mins'} ago`;
    }
  
    const hours_ago = Math.floor((now_utc - created_date_utc) / (1000 * 60 * 60));
  
    if (hours_ago < 24) {
      return `${hours_ago} ${hours_ago === 1 ? 'hour' : 'hours'} ago`;
    }
  
    // return 'nothing';
    return get_date_string(firestore_timestamp);
  };

  export const PELEKA_DELIVERY_CITIES = [
    'Gweru', 'Gwelo', 'gweru', 'gwelo', 'GWERU', 'GWELO'
  ];

  export const FFD_STAFF_ROLES = [
    { role: 'admin',  display_name: 'System administrator' },
    { role: 'technical-support',  display_name: 'Technical support' },
    { role: 'sales-support',  display_name: 'Sales support' },
    { role: 'field-support',  display_name: 'Field support' },
    { role: 'delivery-support',  display_name: 'Delivery support' },
    { role: 'verifier',  display_name: 'Verifier' },
  ]

  export const FFD_CUSTOMER_ROLES = [
    { role: 'seller',  display_name: 'Seller' },
    { role: 'farmer',  display_name: 'Farmer' },
    { role: 'aggregator',  display_name: 'Aggregator' },
    { role: 'verifier',  display_name: 'Verifier' },
    { role: 'observer',  display_name: 'Observer' },
  ]

  export const FFD_DEFAULT_COUNTRY = 'zimbabwe'
  export const FFD_COUNTRIES_METAS = ['zimbabwe', 'botswana']
  export const FFD_COUNTRIES = [
    {
      country: 'Botswana',
      meta: 'botswana',
      currency: 'P',
    },
    {
      country: 'Zimbabwe',
      meta: 'zimbabwe',
      currency: '$',
    }, 
  ]

  export const suspension_types = [
    {
      status: 'Warning',
      reasons: [
        'Late responses to customer inquiries',
        'Minor inaccuracies in product descriptions',
        'Delayed shipment or delivery',
        'Minor policy violations (e.g., incomplete documentation)',
      ],
      definition: 'A formal notice issued for minor infractions that do not severely impact the platform or other users but require correction',
      examples: [
        'Responding late to customer inquiries more than twice in a month.',
        'Listing a product with minor inaccuracies in the description.',
        'Shipping orders later than the promised date by 1-2 days without prior notification.',
      ]
    },
    {
      status: 'Temporary Restriction',
      reasons: [
        'Repeated late responses to inquiries',
        'Consistently inaccurate product descriptions',
        'Frequent delays in shipment or delivery',
        'Non-compliance with quality standards',
        'Moderate policy violations (e.g., missing deadlines for documentation submission)',
      ],
      definition: 'Imposed for repeated minor offenses or a single moderate offense. Limits specific activities of the user for a set duration.',
      examples: [
        'Consistently responding late to customer inquiries over a period of a month.',
        'Repeated inaccuracies in product listings after a warning has been issued.',
        'Frequent shipment delays occurring more than three times in a single month.',
      ]
    },
    {
      status: 'Probation',
      reasons: [
        'Multiple instances of temporary restriction violations',
        'Customer complaints of moderate severity',
        'Moderate financial discrepancies (e.g., pricing issues)',
        'Violation of community guidelines (e.g., disrespectful communication)',
      ],
      definition: 'A period of close monitoring for users who have committed serious or repeated moderate offenses. Further misconduct may lead to harsher penalties.',
      examples: [
        'Continuing to list products with inaccuracies despite previous temporary restrictions.',
        'Receiving multiple moderate complaints from customers regarding product quality.',
        'Engaging in disrespectful or non-professional communication with customers or staff.',
      ]
    },
    {
      status: 'Partial Suspension',
      reasons: [
        'Severe customer complaints (e.g., consistent product misrepresentation)',
        'Serious policy violations (e.g., selling prohibited items)',
        'Legal complaints or disputes',
        'Evidence of unethical business practices (e.g., exploitation, discrimination)',
      ],
      definition: 'A substantial punitive measure barring the user from certain activities, such as listing new products or making transactions, for a serious infraction.',
      examples: [
        'Selling items that are significantly not as described, leading to serious customer complaints.',
        'Violating major platform policies, like attempting to bypass transaction fees.',
        'Engaging in discriminatory practices against customers or other sellers.',
      ]
    },
    {
      status: 'Full Suspension',
      reasons: [
        'Continued violations despite partial suspension',
        'Severe financial discrepancies or fraud',
        'Serious legal violations (e.g., selling stolen goods)',
        'Harmful behavior towards other users or the platform',
      ],
      definition: 'A temporary complete halt of all user privileges due to very serious offenses, pending a review',
      examples: [
        'Continued policy violations after a partial suspension.',
        'Engaging in fraudulent activities like manipulating prices or falsifying product origins.',
        'Demonstrating harmful behavior towards other users, like harassment or threats.',
      ]
    },
    {
      status: 'Permanent Ban',
      reasons: [
        'Illegal activities (e.g., selling illegal substances)',
        'Severe fraud or financial misconduct',
        'Systematic violation of major policies',
        'Threats, harassment, or extreme unethical behavior',
      ],
      definition: 'The most severe action, permanently revoking the user’s access to the platform due to extreme misconduct.',
      examples: [
        'Engaging in illegal activities such as selling stolen or prohibited items.',
        'Systematic and severe fraud, such as setting up fake listings or scamming customers.',
        'Repeated severe violations of major platform policies despite prior suspensions.',
      ]
    },
  ]



  







