import React, { useState, useEffect } from "react";
import {
  retrieveBuyerProductsV3,
  updateBuyerRequestedProduct,
} from "../actions/productActions";
import { validate_multiple_roles } from "../validations";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Spinner from "../components/spinner";

export default function MyRequestsScreen(props) {
  // eslint-disable-next-line
  let user_profile;
  const permitted_roles = ["seller", "buyer"];
  const check_permissions = validate_multiple_roles(permitted_roles);
  if (check_permissions.permitted === false) {
    const login_link = "access-forbidden";
    props.history.push(login_link);
  } else {
    user_profile = check_permissions.user_object;
  }

  // eslint-disable-next-line
  const status = props.match.params.status
    ? props.match.params.status
    : "Pending";

  const [error_message, set_error_message] = useState("");
  // eslint-disable-next-line
  const [success_message, set_success_message] = useState("");

  const [page_loaded, set_page_loaded] = useState(false);
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  const [orders, set_orders] = useState([]);

  // eslint-disable-next-line
  const [id, set_id] = useState("id");
  // eslint-disable-next-line
  const [phone, set_phone] = useState("phone");
  // eslint-disable-next-line
  const [email, set_email] = useState(user_profile.email);
  // eslint-disable-next-line
  const [city, set_city] = useState("city");
  // eslint-disable-next-line
  const [delivery_zone, set_delivery_zone] = useState("delivery_zone");
  // eslint-disable-next-line
  const [payment_method, set_payment_method] = useState("payment_method");
  // eslint-disable-next-line
  const [client_id, set_client_id] = useState("client_id");
  // eslint-disable-next-line
  // const [status, set_status] = useState('status');

  // eslint-disable-next-line
  const [add_city_invalid, set_add_city_invalid] = useState(true);

  const link_cell_formatter = (cell, row) => {
    console.log("row", row);
    return row.status === "Available" ? (
      <div>
        {/* props.history.push(`/shop/sub_cat/${_product}`) */}
        {/* props.history.push(`/shop/sub_cat/${row.product}`) */}
        {/*  <Link class="" to={`/product/${row.link}/${row.listed_product_id}`}><i class="bi bi-cart"></i> Order item</Link> */}
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={() =>
            read_buyer_requested_notification(row.product, row.listings, true)
          }
        >
          View Products
        </button>
        &nbsp;
        <button
          class="btn btn-danger btn-lg"
          onClick={() => cancelRequest(row._id)}
        >
          Delete request
        </button>
      </div>
    ) : (
      <div>
        <button
          class="btn btn-danger btn-lg"
          onClick={() => cancelRequest(row._id)}
        >
          Delete request
        </button>
      </div>
    );
  };

  //we have to rewrite this, and maybe create a new component, which lists all the products found for that crop
  const read_buyer_requested_notification = async (
    _product,
    listing,
    redirect
  ) => {
    set_error_message("");
    set_success_message("");
    let update_data = {
      read: true,
    };
    const listing_ids_array = listing.map((item) => item.id);
    console.log("ids_array", listing_ids_array);
    const listing_ids_array_string = listing_ids_array.join(",");
    console.log("listing_ids_array_string", listing_ids_array_string);

    //let make_action;
    try {
      if (redirect) {
        props.history.push(
          `/buyer-requests/${_product}?listing=${listing_ids_array_string}`
        );
      }

      return;
      //} else {
      //  set_error_message(make_action.message)
      //}
    } catch (error) {
      set_error_message(
        `An error occured fetching new orders: ${error.message} `
      );
    }
  };

  const load_page_data = async () => {
    let get_buyer_requested;
    try {
      get_buyer_requested = await retrieveBuyerProductsV3(
        "product",
        "buyer",
        "status"
      );
      console.log("get_buyer_requested", get_buyer_requested);

      if (get_buyer_requested.success) {
        let { data } = get_buyer_requested;

        let processedData = [];

        for (let productName in data) {
          const productData = data[productName];
          const summary = productData.summary;
          const listings = productData.listings;

          let productInfo = {
            _id: summary._id,
            product: summary.product_name,
            quantity: summary.required_qty,
            quantity_units: summary.required_qty_units,
            status:
              summary.number_found > 0 ? "Available" : "Not yet available",
            listings: listings,
          };

          processedData.push(productInfo);
        }

        set_orders(processedData);
        console.log("MyRequestsScreen data", processedData);
      } else {
        set_error_message("Your requests history is empty.");
      }
    } catch (error) {
      set_error_message(`An error occurred: ${error.message}`);
    }

    return;
  };

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  const cancelRequest = async (requested_id) => {
    // e.preventDefault();

    set_success_message("");
    set_error_message("");

    set_action_loading(true);

    const update_data = {};

    const response = await updateBuyerRequestedProduct(
      update_data,
      requested_id,
      "delete"
    );
    if (response.success === false) {
      set_error_message(response.message);
    } else {
      set_success_message("Request successfully deleted.");
      load_page_data();
    }

    set_action_loading(false);

    return;
  };

  const quantityFormatter = (cell, row) => {
    // Assuming 'cell' is the quantity and row.quantity_units contains the units
    return `${cell} ${row.quantity_units}`;
  };

  return (
    <>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              <span class="green-text">My Products</span>{" "}
              <span class="orange-text">Requests History</span>
            </h2>
          </div>

          {!page_loaded ? ( // so that only products will be loading at this time
            <Spinner />
          ) : (
            <>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-12">
                      {error_message !== "" ? (
                        <div class="alert alert-danger" role="alert">
                          {error_message}
                        </div>
                      ) : (
                        ""
                      )}
                      {success_message !== "" ? (
                        <div class="alert alert-success" role="alert">
                          {success_message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div class="row">
            <div class="col-12">
              <BootstrapTable data={orders} search pagination>
                <TableHeaderColumn dataField="product">
                  Produce name
                </TableHeaderColumn>
                {/* Other columns */}
                <TableHeaderColumn
                  dataField="quantity"
                  dataFormat={quantityFormatter}
                >
                  Requested Quantity
                </TableHeaderColumn>
                {/* Remove the quantity_units column since it's now included in the quantity column */}
                <TableHeaderColumn dataField="status">Status</TableHeaderColumn>
                <TableHeaderColumn
                  dataField="action"
                  dataFormat={link_cell_formatter}
                  isKey={true}
                ></TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
