import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { registerSeller, signin_with_google_credentials, verifySellerOTP, signin, resendSellerOTP } from '../actions/userActions';
import { Textbox, Checkbox } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import queryString from 'query-string'
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import ScrollToTop from "react-scroll-to-top";



function RegisterSellerScreen(props) {
  const dispatch = useDispatch();
  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');
  // const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [tradingName, setTradingName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [sellerType, setSellerType] = useState('Farm');
  const [show_password, set_show_password] = useState(false);

  const [firstNameInvalid, setFirstNameInvalid] = useState(true);
  const [lastNameInvalid, setLastNameInvalid] = useState(true);
  const [tradingNameInvalid, setTradingNameInvalid] = useState(true);
  const [phoneInvalid, setPhoneInvalid] = useState(true);
  const [emailInvalid, setEmailInvalid] = useState(true);
  const [passwordInvalid, setPasswordInvalid] = useState(true);
  const [rePasswordInvalid, setRePasswordInvalid] = useState(true);

  const [agree, set_agree] = useState(false);

  const [otp, setOTP] = useState('');

  const [stage, set_stage] = useState('create-account');

  const userRegister = useSelector(state => state.userRegister);
  const { userInfo, error } = userRegister;

  const redirect = props.location.search ? props.location.search.split("=")[1] : '/';

  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {
      //
    };
    // eslint-disable-next-line
  }, [userInfo]);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const scrollToTopCustom = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const submitHandler = async (e) => {    
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    try {
      const response = await registerSeller(firstName, lastName, tradingName, phone, email, password, sellerType); 
      scrollToTopCustom()

      if (response.success === true) {
        
        // localStorage.setItem('token', response.token);
        set_success_message(response.message);
        // set_success_message('Please check your email and enter the one time pin (OTP) sent to you below to confirm your account.');
        set_stage('confirm-account')
        // props.history.push('/seller-dashboard');
        // setFirstName('');
        // setLastName('');
        // setTradingName('');
        // setPhone('');
        // setEmail('');
        // setPassword('');
        // setRePassword('');
        // setSellerType('Farm');
      } else {
        set_error_message(response.message);  
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message('An error occured signing up your account');
    }
    
    set_action_loading(false);

    return; 
  }


  const resendOTP = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    // if (otp === '') {
    //   set_error_message('Please send a valid OTP')
    //   set_action_loading(false)
    //   return
    // }

    let notify_admin = false
    let notify_details = {}
    // check if has query strings 
    const query = queryString.parse(props.location.search)
    if (query.lf !== undefined) {
      notify_details.product = query.lf
      notify_admin = true
      redirect = 'shop'
    }

    if (query.q !== undefined)
      notify_details.quantity = query.q
    if (query.u !== undefined)
      notify_details.units = query.u

    try {
      const response = await resendSellerOTP(
        email, notify_admin, notify_details
      );
      scrollToTopCustom()
      set_error_message('')
      set_success_message('')

      if (response.success === true) {
        set_success_message(response.message)
      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message('An error occured resending OTP. Try again');
    }

    set_action_loading(false);

    return;
  }

  const submitOTP = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');

    set_action_loading(true);

    if (otp === '') {
      set_error_message('Please send a valid OTP')
      set_action_loading(false)
      return
    }

    let notify_admin = false
    let notify_details = {}
    // check if has query strings 
    const query = queryString.parse(props.location.search)
    if (query.lf !== undefined) {
      notify_details.product = query.lf
      notify_admin = true
      redirect = 'shop'
    }

    if (query.q !== undefined)
      notify_details.quantity = query.q
    if (query.u !== undefined)
      notify_details.units = query.u

    try {
      const response = await verifySellerOTP(
        email, otp, notify_admin, notify_details
      );
      scrollToTopCustom()
      set_error_message('')
      set_success_message('')

      if (response.success === true) {
        // localStorage.setItem('token', response.token);
        set_success_message('Account successfully verified. You can now login')
        // console.log(email, password)
        await dispatch(signin(email, password, notify_admin, notify_details, props, redirect));
        // console.log('after dispatch', userInfo);
        // props.history.push(redirect);
        // setFirstName('');
        // setLastName('');
        // setTradingName('');
        // setPhone('');
        // setEmail('');
        // setPassword('');
        // setRePassword('');
        // setSellerType('Farm');
      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message('An error occured verifying your account details');
    }

    set_action_loading(false);

    return;
  }

  const login_google_credential = async (google_credentials) => {
    let notify_admin = false
    let notify_details = {}
    const query = await queryString.parse(props.location.search)

    if (query.lf !== undefined) {
      notify_details.product = query.lf
      notify_admin = true
      redirect = '/my-requests'
    }

    if (query.q !== undefined)
      notify_details.quantity = query.q
    if (query.u !== undefined)
      notify_details.units = query.u

    await dispatch(signin_with_google_credentials(google_credentials, 'seller', notify_admin, notify_details, props, redirect));
  }

  

  return (
  
  <section id="about" class="about main-content mt-5">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row mt-5">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
        {stage === 'create-account' ? (<h3 class="green-text">SELLER REGISTRATION</h3>) : ('')}
            {stage === 'confirm-account' ? (<h3 class="green-text">CONFIRM ACCOUNT DETAILS</h3>) : ('')}

          {error && <div class="col-12">
            <div class="alert alert-danger" role="alert">
              {error}
            </div>
          </div> }

          <div class="col-12">
                  {error_message !== '' ? (
                      <div class="alert alert-danger" role="alert">
                        {error_message}
                      </div>
                    ): ('')}
                    {success_message !== '' ? (
                      <div class="alert alert-success" role="alert">
                          {success_message}
                      </div>
                    ): ('')}     
                </div>
                            
                {stage === 'create-account' ? (
          <form onSubmit={submitHandler} >
            <div class="form-group my-4">
            

              <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "Name",
                      name: "Name",
                      type: "text",
                      placeholder: "First name"
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      setFirstNameInvalid(res)
                    } 
                    value={firstName}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(firstName, e) => {
                      setFirstName(e.target.value);
                      // set_agree(false)
                    }}
                    onBlur={e => {
                      console.log(e);
                      // set_agree(true)
                      set_agree(false)
                    }}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "First name",
                      check: true,
                      required: true,
                      type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      // reg: /^\d{18}|\d{15}$/,
                      // regMsg: 'failed in reg.test(${value})',
                      // locale: 'en-US',
                      // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                      //   if (res != 'milk') {
                      //     return 'Name cannot be other things but milk';
                      //   }
                      //   return true;
                      // }
                    }}
                  />
            </div>
            <div class="form-group my-4">
              {/* <input  class="form-control" type="name" name="last_name" id="last_name" onChange={(e) => setLastName(e.target.value)} placeholder="Surname" /> */}
              <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "Surname",
                      name: "Surname",
                      type: "text",
                      placeholder: "Surname"
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      setLastNameInvalid(res)
                    } 
                    value={lastName}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(laststName, e) => {
                      setLastName(e.target.value);
                    }}
                    onBlur={e => {
                      console.log(e);
                      
                    }}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "Surname",
                      check: true,
                      required: true,
                      type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      // reg: /^\d{18}|\d{15}$/,
                      // regMsg: 'failed in reg.test(${value})',
                      // locale: 'en-US',
                      // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                      //   if (res != 'milk') {
                      //     return 'Name cannot be other things but milk';
                      //   }
                      //   return true;
                      // }
                    }}
                  />
            </div>
            <div class="form-group my-4">
              {/* <input  class="form-control" type="text" name="phone" id="phone" onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" /> */}
              <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "Phone",
                      name: "Phone",
                      type: "text",
                      placeholder: "Phone number"
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      setPhoneInvalid(res)
                    } 
                    value={phone}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(phone, e) => {
                      setPhone(e.target.value);
                    }}
                    onBlur={e => {
                      console.log(e);
                    }}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "Phone number",
                      check: true,
                      required: true,
                      type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      reg: /^\d{7}|\d{16}$/,
                      regMsg: 'Invalid phone number',
                      // locale: 'en-US',
                      // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                      //   if (res != 'milk') {
                      //     return 'Name cannot be other things but milk';
                      //   }
                      //   return true;
                      // }
                    }}
                  />
            </div>
            <div class="form-group my-4">
              {/* <input class="form-control" type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email" /> */}
              <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "email",
                      name: "email",
                      type: "text",
                      placeholder: "Email"
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      setEmailInvalid(res)
                    } 
                    value={email}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(email, e) => {setEmail(e.target.value);}}
                    onBlur={e => { console.log(e);}}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "Email address",
                      check: true,
                      required: true,
                      //type: 'email', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      // reg: /^\d{18}|\d{15}$/,
                      // regMsg: 'failed in reg.test(${value})',
                      // locale: 'en-US',
                      // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                      //   if (res != 'milk') {
                      //     return 'Name cannot be other things but milk';
                      //   }
                      //   return true;
                      // }
                    }}
                  />
                  
            </div>
            <div class="form-group my-4">
              {/* <input  class="form-control" type="text" name="trading_name" id="trading_name" onChange={(e) => setTradingName(e.target.value)} placeholder="Trading Name" /> */}
              <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "farmName",
                      name: "farmName",
                      type: "text",
                      placeholder: "Trading name"
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      setTradingNameInvalid(res)
                    } 
                    value={tradingName}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(tradingName, e) => {
                      setTradingName(e.target.value);
                      
                    }}
                    onBlur={e => { console.log(e);}}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "Trading name",
                      check: true,
                      required: true,
                      type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      // reg: /^\d{18}|\d{15}$/,
                      // regMsg: 'failed in reg.test(${value})',
                      // locale: 'en-US',
                      // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      // customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                      //   if (res != 'milk') {
                      //     return 'Name cannot be other things but milk';
                      //   }
                      //   return true;
                      // }
                    }}
                  />
            </div>
            <div class="form-group my-4">
                  {/* <input class="form-control" type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" /> */}
                  
                  <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "password",
                      name: "password",
                      type: show_password ? "text" : "password",
                      placeholder: "Password",
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      setPasswordInvalid(res)
                    }
                    value={password}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(password, e) => {
                      setPassword(e.target.value);
                    }}
                    onBlur={e => { console.log(e); }}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "Account password",
                      check: true,
                      required: true,
                      type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      // reg: /^\d{18}|\d{15}$/,
                      // regMsg: 'failed in reg.test(${value})',
                      // locale: 'en-US',
                      // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                        if (res.length < 6 || res.length > 14) {
                          if (res.length < 6) {
                            return 'Your password is too short. Your password must be between 6 and 14 characters'
                          } else if (res.length > 14) {
                            return 'Your password is too long. Your password must be between 6 and 14 characters'
                          }
                        }
                        return true;
                      }
                    }}
                  />
                </div>
                <div class="form-group my-4">
                  {/* <input class="form-control" type="password" id="rePassword" name="rePassword" onChange={(e) => setRePassword(e.target.value)} placeholder="Confirm Password" /> */}
                  <Textbox
                    attributesWrapper={{}}
                    attributesInput={{
                      id: "confirmPassword",
                      name: "confirmPassword",
                      type: show_password ? "text" : "password",
                      placeholder: "Confirm password",
                    }}
                    // validate={validate}
                    validationCallback={res =>
                      setRePasswordInvalid(res)
                    }
                    value={rePassword}
                    disabled={false}
                    classNameInput=""
                    classNameWrapper=""
                    classNameContainer=""
                    customStyleInput={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    onChange={(rePassword, e) => {
                      setRePassword(e.target.value);
                    }}
                    onBlur={e => { console.log(e); }}
                    // onFocus={(e) => {console.log(e);}}
                    // onClick={(e) => {console.log(e);}}
                    validationOption={{
                      name: "Confirm password",
                      check: true,
                      required: true,
                      type: 'string', // Validation type, options are ['string', 'number', 'alphanumeric', 'alpha'].
                      // showMsg: true,
                      // min: 2, 
                      // max: 10,
                      // length: 2,
                      // compare: '3',
                      // reg: /^\d{18}|\d{15}$/,
                      // regMsg: 'failed in reg.test(${value})',
                      // locale: 'en-US',
                      // msgOnError: "Your two passwords do not match", 
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." 
                      customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                        if (res !== password) {
                          return 'Your two passwords do not match';
                        }
                        return true;
                      }
                    }}
                  />
                  
                  <button type="button" class="green-text mt-3 bi bi-eye btn-transparent" onClick={()=>{set_show_password(!show_password)}} style={{backgroundColor: 'transparent', border: '0px',}}> {show_password ? "Hide" : "Show"} password</button>
                </div>

            <div class="form-group my-4">
              <select  class="form-control" name="seller_type" id="seller_type" onChange={(e) => setSellerType(e.target.value)}>
                <option>Sell produce from</option>
                <option value="Farm">Farm</option>
                            <option value="Home">Plot</option>
                            <option value="Market Stall">Market Stall</option>
                            <option value="Shop/Tuckshop/Spaza">Shop</option>
                            <option value="Supermarket">Supermarket</option>
                            <option value="Other">Other</option>
              </select>
            </div>

            <div class="form-group my-4 text-left">
            {/* <small>Your phone number and email will be displayed online to enable buyers to contact you</small> */}
            <br/>
			<div>
			<h3 class="green-text">Agreement and Confirmation Statement</h3>
			</div>
			
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <div style={{ color: '#4a4a4a', flexGrow: 1 }}>
                I hereby confirm that all information provided in this application is true, accurate, and complete to the best of my knowledge. I understand that any false statements, misrepresentations, or omissions may result in denial of my application or termination of partnership if discovered at a later date. I agree to comply with all the terms and guidelines of the platform and to conduct my business activities in an ethical and lawful manner
              </div>
    		  <div style={{ border: '2px solid black', width: '25px', height: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Checkbox
                attributesInput={{
                  id: 'agree',
                  name: 'agree',
                }}
                onBlur={() => {}} 
                checked={agree}
                onChange={(e) => {
                  set_agree(e);
                }}
                style={{ height: '20px', width: '20px' }} // Adjust size as needed
                labelHtml={<div />} // Empty div for no text next to checkbox
                validationOption={{
                  name: 'agree',
                  check: true,
                  required: true,
				  showMsg: true, // Optional.[Bool].Default: true. To display error message.
                  msgOnError: "Confirm agree if you want to register." // Custom error message.

                }}
              />
            </div>
            </div>
            </div>
            
            <div class="form-group my-4">
              <button 
                type="submit" 
                class="btn btn-primary" 
                disabled={ action_loading || firstNameInvalid || lastNameInvalid || phoneInvalid || emailInvalid || tradingNameInvalid || passwordInvalid || rePasswordInvalid || !agree}
                //  || lastNameInvalid || phoneInvalid || emailInvalid 
              >
                {
                  !action_loading ? ('Register'):(
                    <>
                      <div class="spinner-border text-orange" role="status">
                        {/* <span class="sr-only">Loading...</span> */}
                      </div> 
                      &nbsp;Creating account
                    </>
                  )
                }
                
              </button>
              {/* &nbsp;or&nbsp; */}
              {/* <Link to={redirect === "/" ? "login-seller" : "login-seller?redirect=" + redirect} className="btn btn-primary faded-green-shade" >Login</Link> */}
            </div>

            <div class="form-group my-4 text-center">
              Already have an account? <Link to="login-seller" className="green-text" >Login</Link>
            </div>


            <div class="mt-5 text-center">
              {/* <button onClick={() => login_with_google()}>
                Sign in with google
              </button> */}

              <GoogleLogin
                onSuccess={credentialResponse => {
                  // console.log(credentialResponse);
                  login_google_credential(credentialResponse)
                }}
                onError={() => {
                  // console.log('Login Failed');
                  set_error_message('Login with your Google account failed. Please try again')
                }}
              />

            </div>
          </form>

) : ('')}



{stage === 'confirm-account' ? (
  <>
    <form onSubmit={submitOTP} >
    
      <div class="form-group my-4">
        <input class="form-control" type="number" min="1" name="otp" id="otp" onChange={(e) => setOTP(e.target.value)} placeholder="OTP" />
    
      </div>
    
      <div class="form-group my-4 text-center">
        <button
          type="submit"
          class="btn btn-primary"
          onClick={scrollToTopCustom()}
          disabled={action_loading}
        //  || lastNameInvalid || phoneInvalid || emailInvalid 
        >
          {
            !action_loading ? ('Confirm') : (
              <>
                <div class="spinner-border text-orange" role="status">
                  {/* <span class="sr-only">Loading...</span> */}
                </div>
                &nbsp;Please wait
              </>
            )
          }
    
        </button>
        {/* &nbsp;or&nbsp; */}
        {/* <Link to={redirect === "/" ? "login-seller" : "login-seller?redirect=" + redirect} className="btn btn-primary faded-green-shade" >Login</Link> */}
      </div>
    </form>

    <div class="form-group my-4 text-center">
      <form onSubmit={resendOTP} >
        OTP not received? &nbsp;
        <button
            class="btn btn-lg btn-success"
            type="submit"
          onClick={scrollToTopCustom()}
          disabled={action_loading}
        //  || lastNameInvalid || phoneInvalid || emailInvalid 
        >
          {
            !action_loading ? ('Resend OTP') : (
              <>
                <div class="spinner-border text-orange" role="status">
                  {/* <span class="sr-only">Loading...</span> */}
                </div>
                &nbsp;Please wait
              </>
            )
          }
	  
        </button>
      </form>
    </div>
  </>

) : ('')}

        </div>
        <div class="col-md-4"></div>
    </div>
    </div>
  </section> 
  )
}
export default RegisterSellerScreen;